/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Spinner
 * @desc   :  This file redered the loader
 *
 *  */

import React from "react";
import "./Spinner.module.css";

const Spinner = () => {
  return (
    <div>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
