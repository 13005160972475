/**
 * @author    Clarion
 * @version 1.0
 * @file   :  fetchData
 * @desc   :  This file is the redux-reducer file used to set redux state  for api ajax data, FXS config file data, and session data
 */

import * as actionTypes from "../action/actionTypes";
import { IMAGE_SCALE } from "./../../utility/AppsConst";
const initialState = {
  sessionData: [],
  fxConfig: [],
  previewImageUrl: "",
  loading: false,
  loadingImage: false,
  errMessage: "",
  errMessageSession: "",
  errMessageConfig: "",
  imageLoadingVal: 0,
  scale: IMAGE_SCALE,
  lastImageWidth: 0,
  isDisplayControlPanel: true,
  isDisplayPreviewPanel: true,
  isMobileFlag: false,
  instanceArrList: [],
  previousInstance:[]
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchDataReducer
 * @desc   :  This function is for set the redux state for responce comming from api ajax call
 * @return -  object of newly saved state
 */

export const fetchDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SESSION_DATA_START:
      return {
        ...state,
        loading: true,
        errMessageSession: "",
        imageLoadingVal: state.imageLoadingVal + 1,
      };

    case actionTypes.DECGRISE_LOADER_COUNT:
      return {
        ...state,
        loading: false,
        errMessageSession: "",
        imageLoadingVal: state.imageLoadingVal - 1,
      };

    case actionTypes.FETCH_SESSION_DATA_FAIL:
      return {
        ...state,
        loading: false,
        errMessageSession: action.errMessage,
        imageLoadingVal: 0,
      };
    case actionTypes.FETCH_SESSION_DATA_SUCCESS:
      return {
        ...state,
        sessionData: action.sessionData,
        loading: false,
        errMessageSession: "",
        imageLoadingVal: state.imageLoadingVal - 1,
      };
    case actionTypes.FETCH_PREVIEW_START:
      return {
        ...state,
        loadingImage: true,
        errMessage: "",
        imageLoadingVal: state.imageLoadingVal + 1,
      };
    case actionTypes.FETCH_PREVIEW_FAIL:
      return {
        ...state,
        loadingImage: false,
        errMessage: action.errMessage,
        imageLoadingVal: 0,
      };
    case actionTypes.FETCH_PREVIEW_ABORT:
        return {
          ...state,
          loadingImage: false,
          errMessage: "",
          imageLoadingVal: state.imageLoadingVal - 1,
        };
    case actionTypes.FETCH_PREVIEW_SUCCESS:
      return {
        ...state,
        previewImageUrl: action.previewImageUrl,
        loadingImage: false,
        errMessage: "",
        imageLoadingVal: state.imageLoadingVal - 1,
      };
    case actionTypes.FETCH_FX_CONFIG_START:
      return {
        ...state,
        loading: true,
        errMessageConfig: "",
        imageLoadingVal: state.imageLoadingVal + 1,
      };
    case actionTypes.FETCH_FX_CONFIG_FAIL:
      return {
        ...state,
        loading: false,
        errMessageConfig: action.errMessage,
        imageLoadingVal: 0,
      };
    case actionTypes.FETCH_FX_CONFIG_SUCCESS:
      return {
        ...state,
        fxConfig: action.fxConfig,
        loading: false,
        errMessageConfig: "",
        imageLoadingVal: state.imageLoadingVal - 1,
      };

    case actionTypes.FETCH_JSON_SUCCESS:
      return {
        ...state,
        jsonData: action.jsonData,
        imageLoadingVal:
          action.flag !== "showLoader"
            ? state.imageLoadingVal - 1
            : state.imageLoadingVal - 0,
      };
    case actionTypes.HIDE_LOADER:
      return {
        ...state,
        loading: false,
        imageLoadingVal: 0,
      };
    case actionTypes.SET_FONT_MAP_DATA:
      return {
        ...state,
        fontMapData: action.fontMapData.fonts,
      };
    case actionTypes.SET_SESSION_FILE_ID:
      return {
        ...state,
        sessionFileID: action.sessionFileID,
      };

    case actionTypes.SET_IMAGE_WIDTH:
      return {
        ...state,
        imageWith: action.data,
        scale: action.scale,
      };

    case actionTypes.SET_LAST_IMAGE_WIDTH:
      return {
        ...state,
        lastImageWidth: action.data,
      };

    case actionTypes.SET_DATA_TOGGLE_PREVIEW:
      return {
        ...state,
        isDisplayControlPanel: action.data.isDisplayControlPanel,
        isDisplayPreviewPanel: action.data.isDisplayPreviewPanel,
      };
    case actionTypes.SET_MOBILE_TOGGLE:
      return {
        ...state,
        isMobileFlag: action.data,
      };
    case actionTypes.SET_INSTANCE_LIST:
      return {
        ...state,
        instanceArrList: action.data,
      };
    case actionTypes.SET_OLD_INSTANCE_DATA:
      return {
        ...state,
        previousInstance: action.data,
      };

    default:
      return state;
  }
};
