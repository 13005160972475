/**
 * @author    Clarion
 * @version 1.0
 * @file   :  AppsConst
 * @desc   :  All Application constant
 */

/*----- Node application url ----*/
export const NODE_API_URL = "https://ge.computerforms.biz/api/";

/*----- XML file path for both session and fxs_config files ----*/
export const XML_FILE_PATH = "https://ge.computerforms.biz/api/xmlFiles/";

/*----- Constant for background images ----*/
export const BACKGROUND_IMAGES_PATH = "images/backgrounds/";

/*----- LOGO path dispaly at Front END ----*/
export const FRONT_END_LOGO_DISPLAY_PATH = "https://ge.computerforms.biz/api/Logos/";

/*----- PANTONE COLOR FILE PATH ----*/
export const PANTONE_COLOR_FILE_PATH = "xmlFiles/ID_PantoneColors";

/**
 * Render document api url
 */
export const QUEUE_BY_TEMPLATE = NODE_API_URL + "get-document";

/**
 * Rest API end point URL
 */

export const API_URL = "https://gerender.computerforms.biz/api/v1/";

/**
 * Zoom Functionality constant
 */
export const IMAGE_SCALE = 1;
export const IMAGE_WIDTH_INCREMENT = 150;

/**
 * Upload logo file validation constant
 */

export const UPLOAD_IMAGE_FILE_TYPES = [
  "ai",
  "eps",
  "jpg",
  "pdf",
  "png",
  "psd",
  "tif",
];

export const UPLOAD_IMAGE_FILE_SIZE = 10485760;

export const IMAGE_FILES = "jpg, png, svg,";

/**
 * Domain constant where you can set logo, title,exit url and config file path
 */

export const DOMAIN_INFORMATION = {
    "ge.computerforms.biz": {
       logo: "cfi_logo.jpg",
       title: "Computer Forms",
       CHECKOUT_NEW_ORDER: "https://computerforms.biz/cart_processge",
       CHECKOUT_OVERWRITE_ORDER:
         "https://cfiadmin.computerforms.biz/adminorders/admin_orderprocessge.aspx",
       CHECKOUT_COPY_ORDER: "https://computerforms.biz/cart_processreorderge",
       CHECKOUT_EXACT_ORDER: "https://computerforms.biz/cart_processreorderge",
       CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
       PHONE_NUMBER:"1-800-547-8027",
       LOGIN_PAGE:"https://www.computerforms.biz/customer_login"
     },
     "ge.redwing.computerforms.biz": {
       logo: "redwing_logo.gif",
       title: "Redwing Forms",
       CHECKOUT_NEW_ORDER: "https://redwing.computerforms.biz/cart_processge",
       CHECKOUT_OVERWRITE_ORDER:
         "https://redwing.computerforms.biz/admin/adminorders/admin_orderprocessge.aspx",
       CHECKOUT_COPY_ORDER:
         "https://redwing.computerforms.biz/cart_processreorderge",
       CHECKOUT_EXACT_ORDER:
         "https://redwing.computerforms.biz/cart_processreorderge",
       CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
       PHONE_NUMBER:"1-800-458-0158",
       LOGIN_PAGE:"https://redwing.computerforms.biz/customer_login"
     },
     "ge-redwing.computerforms.biz": {
       logo: "redwing_logo.gif",
       title: "Redwing Forms",
       CHECKOUT_NEW_ORDER: "https://redwing.computerforms.biz/cart_processge",
       CHECKOUT_OVERWRITE_ORDER:
         "https://redwing.computerforms.biz/admin/adminorders/admin_orderprocessge.aspx",
       CHECKOUT_COPY_ORDER:
         "https://redwing.computerforms.biz/cart_processreorderge",
       CHECKOUT_EXACT_ORDER:
         "https://redwing.computerforms.biz/cart_processreorderge",
       CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
       PHONE_NUMBER:"1-800-458-0158",
       LOGIN_PAGE:"https://redwing.computerforms.biz/customer_login"
     },
     "ge.sagecreforms.biz": {
       logo: "sagecre_logo.png",
       title: "Sagecre Forms",
       CHECKOUT_NEW_ORDER: "https://sagecreforms.biz/cart_processge",
       CHECKOUT_OVERWRITE_ORDER:
         "https://sagecreforms.biz/admin/adminorders/admin_orderprocessge.aspx",
       CHECKOUT_COPY_ORDER: "https://sagecreforms.biz/cart_processreorderge",
       CHECKOUT_EXACT_ORDER:
         "https://sagecreforms.biz/cart_processreorderge",
       CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
       PHONE_NUMBER:"1-800-760-7929",
       LOGIN_PAGE:"https://www.sagecreforms.biz/customer_login"
     },
 "ge.ezchecks.biz": {
    logo: "ezchecks_logo.png",
    title: "Ezchecks Forms",
    CHECKOUT_NEW_ORDER: "https://ezchecks.biz/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://ezchecks.biz/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER:
      "https://ezchecks.biz/cart_processreorderge",
    CHECKOUT_EXACT_ORDER:
      "https://ezchecks.biz/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-855-460-5553",
    LOGIN_PAGE:"https://www.ezchecks.biz/customer_login"
  },
  "ge-redwing.silcn.co": {
    logo: "redwing_logo.gif",
    title: "Redwing Forms",
    CHECKOUT_NEW_ORDER: "https://redwing.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://redwing.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER:
      "https://redwing.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER:
      "https://redwing.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-800-458-0158",
    LOGIN_PAGE:"https://redwing.computerforms.biz/customer_login"
  },
  "ge-sagecreforms.silcn.co": {
    logo: "sagecre_logo.png",
    title: "Sagecre Forms",
    CHECKOUT_NEW_ORDER: "https://sage.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://sage.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER: "https://sage.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER:
      "https://sage.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-800-760-7929",
    LOGIN_PAGE:"https://www.sagecreforms.biz/customer_login"
  },
  "ge-ezchecks.silcn.co": {
    logo: "ezchecks_logo.png",
    title: "Ezchecks Forms",
    CHECKOUT_NEW_ORDER: "https://ezchecks.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://ezchecks.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER:
      "https://ezchecks.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER:
      "https://ezchecks.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "ezchecks-fxs_config.xml",
    PHONE_NUMBER:"1-855-460-5553",
    LOGIN_PAGE:"https://www.ezchecks.biz/customer_login"
  },
  localhost: {
    logo: "cfi_logo.jpg",
    title: "Computer Forms",
    CHECKOUT_NEW_ORDER: "https://cfi.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://cfi.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-800-547-8027",
    LOGIN_PAGE:"https://www.computerforms.biz/customer_login"
  },
  default: {
    logo: "cfi_logo.jpg",
    title: "Computer Forms",
    CHECKOUT_NEW_ORDER: "https://cfi.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://cfi.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-800-547-8027",
    LOGIN_PAGE:"https://www.computerforms.biz/customer_login"
  },
  "cfi-ge.silcn.co": {
    logo: "cfi_logo.jpg",
    title: "Computer Forms",
    CHECKOUT_NEW_ORDER: "https://cfi.davaware.com/cart_processge",
    CHECKOUT_OVERWRITE_ORDER:
      "https://cfi.davaware.com/admin/adminorders/admin_orderprocessge.aspx",
    CHECKOUT_COPY_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CHECKOUT_EXACT_ORDER: "https://cfi.davaware.com/cart_processreorderge",
    CONFIG_FILE: XML_FILE_PATH + "main-fxs_config_BKEdit.xml",
    PHONE_NUMBER:"1-800-547-8027",
    LOGIN_PAGE:"https://www.computerforms.biz/customer_login"
  },
};

export const SESSION_FILE_PATH = NODE_API_URL + "session/";

export const DEFAULT_SCREEN_SHADE = "14.5";
