/**
 * @author    Clarion
 * @version 1.0
 * @file   :  <Name of File> inputSetting
 * @desc   :  <Description>This file is for the input text fiels to set the states for the input values for text fields
 */
import * as _ from "lodash";
import * as actionTypes from "../action/actionTypes";
import {
  getSectionSateObject,
  getSectionByTitle,
  getArrayNameBySectionName,
  setBoxDiff,
  getVlaueFormSessionByKey,
} from "./../../utility/Utility";

const initialState = {
  settings: [],
  signOverLine: "first",
  tabIndex: 0,
  past: [],
  present: null, // (?) How do we initialize the present?
  future: [],
  phantomLogo: [],
  mainLogo: [],
  logoOption: false,
  pantonCustermerUploadObj: {},
  popUpClass: "pop-up hidePopup",
  queueArr: [],
  previewAPIFlag: false,
  bulletFlag:false,
  isChangedApplied:false,
  tempLogoColorsArray:[]
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  inputDataReducer
 * @desc   :  This function is for set the redux state for input values in text fields
 * @return -  object of newly saved state
 */

export const inputDataReducer = (state = initialState, action) => {
  const { past, present, future } = state;

  switch (action.type) {
    case actionTypes.GET_INPUT_SETTINGS:
      return {
        ...state,
        initialState: action.setting,
      };

    case actionTypes.SET_COMPANY_OVER_SIGN_INFORMATION:
      return {
        ...state,
        companySignOverInfo: action.companySignOverInfo,
      };

    case actionTypes.SET_OVER_SIGN_LINE_FLAG:

      const signLine = [
        {
          attributes: {
            NAME: "SigLine",
          },
          value: action.signOverLine,
          isInputChanged: action.flag,
          // isChangedApplied: true
        },
      ];

      return {
        ...state,
        signLine: signLine,
        isInputChanged: true,
      };

    case actionTypes.SET_SIGN_OVER_INPUT_DATA:
      var companyIndex = state.companySignOverInfo.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      const newsignArr = [...state.companySignOverInfo];
      newsignArr[companyIndex].value = action.event.target.value;
      newsignArr.forEach((element, index) => {
        if (index === companyIndex) {
          newsignArr[index]["isInputChanged"] = true;
          newsignArr[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
            newsignArr[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        companySignOverInfo: newsignArr,
        isFocusInput: false,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_STUB_DATA:
      return {
        ...state,
        stubData: action.stubData,
        isInputChanged:action.flag
      };

    case actionTypes.SET_STUB_INPUT_DATA:
      var stubIndex = state.stubData.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      let tempStyleObj = state.styleObj;
      const newStubArr = [...state.stubData];
      if (action.value === false) {
        
        if(action.name === "FXS_Stub1Single" && action.spanindex !=-1){
          if(newStubArr[stubIndex].styleObj){
            newStubArr[stubIndex].styleObj[action.spanindex].value =  action.event.target.value;
            console.log("tempStyleObj",tempStyleObj)
            tempStyleObj.value =  action.event.target.value;
          }else{
            newStubArr[stubIndex].styleObj = {}
            newStubArr[stubIndex].styleObj[action.spanindex].value =  action.event.target.value;
            tempStyleObj.value =  action.event.target.value;
            
          }
        }else{
          newStubArr[stubIndex].value = action.event.target.value;
        }        
      } else {
        if(action.name === "FXS_Stub1Single" && action.spanindex !=-1){
          if(newStubArr[stubIndex].styleObj){
            newStubArr[stubIndex].styleObj[action.spanindex].value =  action.value;
            tempStyleObj.value =  action.value;
          }else{
            newStubArr[stubIndex].styleObj = {}
            newStubArr[stubIndex].styleObj[action.spanindex].value =  action.value;
            tempStyleObj.value =  action.value;
          }
        }else{
          newStubArr[stubIndex].value = action.value;
        }
        
      }

      // if (action.name === "FXS_Stub1Single") {
      //   let newStyleObj = {};
      //   if (newStubArr[stubIndex].styleObj !== "undefined") {
      //     newStyleObj = newStubArr[stubIndex].styleObj;
      //   }

      //   const spliStr = newStubArr[stubIndex].value.split("•");
      //   let previousObj = {};
      //   for (let index = 0; index < spliStr.length; index++) {
      //     const contStringByUnd = spliStr[index].replace(/[^A-Z0-9]+/gi, "_");
      //     const contStringByUndStr = spliStr[index].replace(/[^A-Z0-9]+/gi, "");

      //     for (const innerKey in newStubArr[stubIndex].styleObj) {
      //       if (
      //         newStubArr[stubIndex].styleObj.hasOwnProperty(innerKey) &&
      //         innerKey.replace(/[^A-Z0-9]+/gi, "") == contStringByUndStr
      //       ) {
      //         previousObj = newStubArr[stubIndex].styleObj[innerKey];
      //         // newStyleObj[contStringByUnd] = previousObj;
      //       } else {
      //         if (Object.keys(previousObj).length > 0) {
      //           newStyleObj[contStringByUnd] = previousObj;
      //           previousObj = {};
      //         } else if (typeof newStyleObj[contStringByUnd] === "undefined") {
      //           if (
      //             newStubArr[stubIndex].styleObj &&
      //             newStubArr[stubIndex].styleObj[
      //               Object.keys(newStubArr[stubIndex].styleObj)[index - 1]
      //             ]
      //           ) {
      //             newStyleObj[contStringByUnd] =
      //               newStubArr[stubIndex].styleObj[
      //                 Object.keys(newStubArr[stubIndex].styleObj)[index - 1]
      //               ];
      //           }
      //         }
      //       }
      //     }
      //   }

      //   newStubArr[stubIndex].styleObj = newStyleObj;
      // }

      newStubArr.forEach((element, index) => {
        if (index === stubIndex) {
          newStubArr[index]["isInputChanged"] = true;
          newStubArr[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
            newStubArr[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        stubData: newStubArr,
        isFocusInput: false,
        isInputChanged: true,
        styleObj:tempStyleObj,
        // isChangedApplied: true
      };

    case actionTypes.SET_BANK_INFO_DATA:
      return {
        ...state,
        bankInfo: action.bankInfo,
      };

    case actionTypes.SET_BANK_INFO_INPUT_DATA:
      var bankIndex = state.bankInfo.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      const newBankInfoArr = [...state.bankInfo];
      newBankInfoArr[bankIndex]["value"] = action.event.target.value;

      newBankInfoArr.forEach((element, index) => {
        if (index === bankIndex) {
          newBankInfoArr[index]["isInputChanged"] = true;
          newBankInfoArr[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
             newBankInfoArr[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        bankInfo: newBankInfoArr,
        isFocusInput: false,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_FRACTIONAL_INFO_DATA:
      return {
        ...state,
        fractionalInfo: action.fractionalInfo,
      };

    case actionTypes.SET_FRACTIONAL_INFO_INPUT_DATA:
      var fractionIndex = state.fractionalInfo.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      const newfractionIndexInfoArr = [...state.fractionalInfo];
      newfractionIndexInfoArr[fractionIndex]["value"] =
        action.event.target.value;

      newfractionIndexInfoArr.forEach((element, index) => {
        if (index === fractionIndex) {
          newfractionIndexInfoArr[index]["isInputChanged"] = true;
          newfractionIndexInfoArr[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
            newfractionIndexInfoArr[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        fractionalInfo: newfractionIndexInfoArr,
        isFocusInput: true,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_CHECK_INFO_DATA:
      return {
        ...state,
        checkInfo: action.checkInfo,
        isFocusInput: true,
      };

    case actionTypes.SET_CHECK_INFO_INPUT_DATA:
      const checkInfoArr = [...state.checkInfo];
      const jsonParse = state.JSONINFO && state.JSONINFO[0] && JSON.parse(state.JSONINFO[0].value);
      const NewJSONINFO = { ...jsonParse };

      var checkIndex = checkInfoArr.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      NewJSONINFO.faceUp = (NewJSONINFO.faceUp)||1;
      const jobRun=getVlaueFormSessionByKey(
        action.sessionData,
        "Quantity",
        "cdata"
      );
      NewJSONINFO['jobRun'] =jobRun

      checkInfoArr[checkIndex]["value"] = action.event.target.value;

      let FXS_CheckNumber1 = action.event.target.value;
      let preEndStr = "";
      if (FXS_CheckNumber1 && FXS_CheckNumber1.length < 6) {
        let FXS_CheckNumber1Len = 6 - FXS_CheckNumber1.length;
        for (let index = 0; index < FXS_CheckNumber1Len; index++) {
          preEndStr = preEndStr + "0";
        }

        FXS_CheckNumber1 = preEndStr + FXS_CheckNumber1;
      }
      if(action.name==='FXS_CheckNumber2'){
        checkInfoArr[checkIndex]["value"] = (FXS_CheckNumber1!="")?"C" + FXS_CheckNumber1:"";
      } else {
        checkInfoArr[checkIndex + 1]["value"] = (FXS_CheckNumber1!="")?"C" + FXS_CheckNumber1:"";
      }
      NewJSONINFO.startingNumber = action.event.target.value;
      /**
       * #5279-Ending Check Number Change Done
       */
      NewJSONINFO.endingNumber =
        (Number(FXS_CheckNumber1) +
        Number(NewJSONINFO.jobRun || 0))-1;
      if (action.event.target.value==""){
        if(action.name==='FXS_CheckNumber1'){
         checkInfoArr[checkIndex+1]["value"] = "C000000";
         checkInfoArr[checkIndex]["value"] = "0000";
        } else if(action.name==='FXS_CheckNumber2'){
          checkInfoArr[checkIndex]["value"] = "C000000";
        }
      }       
      
      checkInfoArr.forEach((element, index) => {
        if (index === checkIndex) {
          checkInfoArr[index]["isInputChanged"] = true;
          if(action.name==='FXS_CheckNumber1'){
            checkInfoArr[checkIndex + 1]["isInputChanged"] = true;
            // checkInfoArr[checkIndex + 1]["isInputChangedFlag"] = true;
          }
          // checkInfoArr[index]["isInputChangedFlag"] = true;
          checkInfoArr[index]["displayValue"]=(action.event.target.value !="")?action.event.target.value:""
        } else {
          if (index !== checkIndex + 1) {
            if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
              checkInfoArr[index]["isInputChanged"] = false;
              if(action.name==='FXS_CheckNumber1'){
              checkInfoArr[checkIndex + 1]["isInputChanged"] = false;
              checkInfoArr[checkIndex + 1]["displayValue"]=(action.event.target.value !="")?action.event.target.value:""
              }
            }
          }
        }
      });
     console.log('checkInfoArr',checkInfoArr)
     const jsonInfoArr1 = [
        {
          attributes: {
            NAME: "JSONINFO",
          },
          value: JSON.stringify(NewJSONINFO),
          isInputChanged: true,
        },
      ];
      
      return {
        ...state,
        checkInfo: checkInfoArr,
        JSONINFO: jsonInfoArr1,                
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_ROUTING_STATE:
      return {
        ...state,
        RoutingValue: action.routingValue,
      };

    case actionTypes.SET_ROUTING_VALUE:
      const newRoutingArr = [...state.RoutingValue];
      newRoutingArr[0].value = action.routingValue;
      newRoutingArr[0].isInputChanged = true;
      return {
        ...state,
        RoutingValue: newRoutingArr,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_ACCOUNT_STATE:
      return {
        ...state,
        accountValue: action.accountValue,
      };

    case actionTypes.SET_ACCOUNT_VALUE:
      const newAccountArr = [...state.accountValue];
      newAccountArr[0].value = action.accountValue;
      newAccountArr[0].isInputChanged = true;
      return {
        ...state,
        accountValue: newAccountArr,
        isInputChanged: true,
        // isChangedApplied: true
      };
    case actionTypes.SET_COMPANY_INFORMATION:
      return {
        ...state,
        companyInfo: action.companyInfo,
      };

    case actionTypes.SET_INPUT_DATA:
      console.log('SET_INPUT_DATA')
      var companyIndex1 = state.companyInfo.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      if (companyIndex1 != -1) {
        const newArr = [...state.companyInfo];
        newArr[companyIndex1].value = action.event.target.value;
        newArr.forEach((element, index) => {
          if (index === companyIndex1) {
            newArr[index]["isInputChanged"] = true;
            newArr[index]["isInputChangedFlag"] = true;
          } else {
            if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
             // newArr[index]["isInputChanged"] = false;
            }
            
          }
        });

        return {
          ...state,
          isFocusInput: true,
          companyInfo: newArr,
          isInputChanged: true,
          // isChangedApplied: true
        };
      }

    case actionTypes.SET_SIGN_INPUT_DATA:
      var companyIndex = state.compnaySignInfo.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      if (companyIndex != -1) {
        const newsignArr = [...state.compnaySignInfo];
        newsignArr[companyIndex].value = action.event.target.value;
        newsignArr.forEach((element, index) => {
          if (index === companyIndex) {
            newsignArr[index]["isInputChanged"] = true;
            newsignArr[index]["isInputChangedFlag"] = true;
          } else {
            if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
              newsignArr[index]["isInputChanged"] = false;
            }
          }
        });
        return {
          ...state,
          compnaySignInfo: newsignArr,
          isFocusInput: true,
          isInputChanged: true,
          // isChangedApplied: true
        };
      }

    case actionTypes.SET_COMPANY_SIGN_INFORMATION:
      return {
        ...state,
        compnaySignInfo: action.companySignInfo,
      };

    case actionTypes.SET_DEFAULT_BOX_DIFF:
      const reduxSectionState = getArrayNameBySectionName(
        getSectionByTitle(action.boxValues.attributes.NAME)
      );
      let styleObjNew = {};
      const findReduxStateIndex = state[reduxSectionState].findIndex((p) => {
        return p.attributes.NAME === action.boxValues.attributes.NAME;
      });
      let displayTooltip = false;
      if (findReduxStateIndex != -1) {
        const newReduxStateInformation = [...state[reduxSectionState]];
        if (!newReduxStateInformation[findReduxStateIndex].isFocusInput) {
          newReduxStateInformation[findReduxStateIndex].isFocusInput = true;
          displayTooltip = true;
        }
        if (newReduxStateInformation[findReduxStateIndex].styleObj) {
          const leys = Object.keys(
            newReduxStateInformation[findReduxStateIndex].styleObj
          );
          if (leys && leys[0] !== "textAlign") {
            styleObjNew =
              newReduxStateInformation[findReduxStateIndex].styleObj;
          } else {
            styleObjNew = setBoxDiff({}, action.boxValues.BOXDEF);
          }
        } else {
          styleObjNew = setBoxDiff({}, action.boxValues.BOXDEF);
        }
      }
      let boxfocusName = action.boxValues.attributes.NAME;
       if(action.FXS_Stub1Single_index !=-1){
        boxfocusName = action.boxValues.attributes.NAME +"_"+action.FXS_Stub1Single_index
        // const boxesstyle=setBoxDiff({}, action.boxValues.BOXDEF);
        // console.log('boxesstyle',boxesstyle)
        // styleObjNew = styleObjNew[action.FXS_Stub1Single_index]
        // styleObjNew={...boxesstyle,...styleObjNew}
        console.log("styleObjNew",styleObjNew);

      }
      return {
        ...state,
        isFocusInput: true,
        boxName: boxfocusName,
        boxDef: action.boxValues.BOXDEF,
        styleObj: _.cloneDeep(styleObjNew),
        filedFocusName: action.boxValues.attributes.NAME,
        displayTooltip: displayTooltip,
        spanindex:action.FXS_Stub1Single_index,
      };
    case actionTypes.SET_STYLE_OBJECT:
      const findCompanyIndex = state[action.section].findIndex((p) => {
        return p.attributes.NAME === action.name;
      });

      if (action.name.includes("FXS_Title")) {
        state[action.section].forEach((element) => {
          if (element.styleObj) {
            element.styleObj.textAlign = action.styleObj.textAlign;
          } else {
            element.styleObj = {};
            element.styleObj.textAlign = action.styleObj.textAlign;
          }
        });
      }
      // console.log("findCompanyIndex",findCompanyIndex)
      if (findCompanyIndex != -1) {
        const newCompnayInfo = [...state[action.section]];
        // console.log("SET_STYLE_OBJECT",state.spanindex)
        // console.log("action.name",action.name)
        let oldStyle;
        if(action.name === "FXS_Stub1Single"){
          oldStyle = {...newCompnayInfo[findCompanyIndex]["styleObj"]};
          // console.log("action.styleObj",action.styleObj)
          oldStyle[state.spanindex] = action.styleObj;
          newCompnayInfo[findCompanyIndex]["styleObj"] = oldStyle;
          newCompnayInfo[findCompanyIndex]["isInputChanged"] = true;
          // console.log("oldStyle",oldStyle)
          // newCompnayInfo[findCompanyIndex]["isInputChangedFlag"] = true;
        }else{
          newCompnayInfo[findCompanyIndex]["styleObj"] = action.styleObj;
          newCompnayInfo[findCompanyIndex]["isInputChanged"] = true;
          oldStyle = action.styleObj;
          // newCompnayInfo[findCompanyIndex]["isInputChangedFlag"] = true;
        }
        
        return {
          ...state,
          ...getSectionSateObject(action.section, newCompnayInfo),
          isInputChanged: true,
          styleObj: oldStyle,
          // isChangedApplied: true
        };
      }

    case actionTypes.SET_INPUT_FOCUS_OUT_FALSE:
      return {
        ...state,
        isFocusInput: false,
      };

    case actionTypes.SET_IS_INPUT_CHANGED:
      return {
        ...state,
        isInputChanged: action.isInputChanged,
      };
    case actionTypes.SET_COLOR_DEFAULT:
      return {
        ...state,
        PantosColor: [
          {
            attributes: { NAME: "DefaultColor" },
            value: action.colors.textBtn.name,
            isInputChanged: false,
          },
        ],
        BorderColor: [
          {
            attributes: { NAME: "BorderColor" },
            value: action.colors.lineBtn.name,
            isInputChanged: false,
          },
        ],
        DefaultColor: [
          {
            attributes: { NAME: "PantosColor" },
            value: action.colors.bgBtn.name,
            isInputChanged: false,
          },
        ],
      };

    case actionTypes.SET_COLOR_CHANGED:
      const DefaultArr = [
        {
          attributes: {
            NAME: action.button,
          },
          value: action.color,
          isInputChanged: action.flag,
        },
      ];
      if (action.button === "DefaultColor") {
        return {
          ...state,
          isInputChanged: action.flag,
          PantosColor: DefaultArr,
          // isChangedApplied: true
        };
      } else if (action.button === "BorderColor") {
        return {
          ...state,
          isInputChanged: action.flag,
          BorderColor: DefaultArr,
          // isChangedApplied: true
        };
      } else if (action.button === "PantosColor") {
        return {
          ...state,
          isInputChanged: action.flag,
          DefaultColor: DefaultArr,
          // isChangedApplied: true
        };
      }

    case actionTypes.SET_TAB_INDEX:
      return {
        ...state,
        tabIndex: action.index,
      };

    case actionTypes.SET_BACKGROUND_IMAGE:
      const DefaultBGArr = [
        {
          attributes: {
            NAME: "FXSBackground",
          },
          value: action.data.FILE,
          isInputChanged: action.flag,
        },
      ];
      return {
        ...state,
        backgroundImage: DefaultBGArr,
        bgPreview: action.data.PREVIEW,
        bgPreviewName: action.data.NAME,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_REDUX_HISTORY:
      switch (action.historyType) {
        case "REDO":
          const next = future[0];
          const newFuture = future.slice(1);
          let tempObj;
          if (past && past.length > 0) {
            tempObj = [_.cloneDeep(...past), _.cloneDeep(present)];
          } else {
            tempObj = [_.cloneDeep(present)];
          }
          var bgPreview,bgPreviewName;
          if(next.backgroundImage && next.backgroundImage.length > 0){
            const bgIndex = action.bgArray.findIndex(p=>p.FILE == next.backgroundImage[0].value);
            if(bgIndex!=-1){
              bgPreview = action.bgArray[bgIndex].PREVIEW;
              bgPreviewName = action.bgArray[bgIndex].NAME;
            }else{
              bgPreview = "";
              bgPreviewName = "";
            }
          }

          const findSignleLineNext = next.stubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
          let flagBulletNext = state.bulletFlag;
          if(findSignleLineNext !==-1){
            next.stubData[findSignleLineNext].isInputChanged = true;
            if(next.stubData[findSignleLineNext].value && next.stubData[findSignleLineNext].value.includes("•")){
              flagBulletNext=true;
            }else{
              flagBulletNext=false;
            }
          }

          return {
            ...state,
            past: tempObj,
            present: _.cloneDeep(next),
            future: _.cloneDeep(newFuture),
            isInputChanged: true,
            ...next,
            bgPreview:bgPreview,
            bgPreviewName:bgPreviewName,
            bulletFlag:flagBulletNext
          };

        case "UNDO":
          const deepPast = _.cloneDeep(past);
          // console.log("deepPast",deepPast)
          const previous = deepPast[deepPast.length - 1];
          const newPast = deepPast.slice(0, deepPast.length - 1);
          var bgPreview,bgPreviewName;
          if(previous.backgroundImage && previous.backgroundImage.length > 0){
            const bgIndex = action.bgArray.findIndex(p=>p.FILE == previous.backgroundImage[0].value);
            if(bgIndex!=-1){
              bgPreview = action.bgArray[bgIndex].PREVIEW;
              bgPreviewName = action.bgArray[bgIndex].NAME;
            }else{
              bgPreview = "";
              bgPreviewName = "";
            }
          }
          // console.log("previous",_.cloneDeep(previous))
          const findSignleLine = previous.stubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
          let flagBullet = state.bulletFlag;
          if(findSignleLine !==-1){
            previous.stubData[findSignleLine].isInputChanged = true;
            if(previous.stubData[findSignleLine].value && previous.stubData[findSignleLine].value.includes("•")){
               flagBullet=true;
            }else{
              flagBullet=false;
            }
          }
          return {
            ...state,
            past: _.cloneDeep(newPast),
            present: _.cloneDeep(previous),
            isInputChanged: true,
            future: [_.cloneDeep(present), _.cloneDeep(...future)],
            ...previous,
            bgPreviewName:bgPreviewName,
            bgPreview:bgPreview,
            bulletFlag:flagBullet 
          };
        case "RESTALL":
          const firstSate = _.cloneDeep(past[0]);
          const findSignleLinefirstSate = firstSate.stubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
          let flagBulletFirst = state.bulletFlag;
          if(findSignleLinefirstSate !==-1){
            firstSate.stubData[findSignleLinefirstSate].isInputChanged = true;
            if(firstSate.stubData[findSignleLinefirstSate].value && firstSate.stubData[findSignleLinefirstSate].value.includes("•")){
              flagBulletFirst=true;
            }else{
              flagBulletFirst=false;
            }
          }
          if (past && past.length > 0) {
            past.forEach((element) => {
              for (const key in element) {
                if (element.hasOwnProperty(key)) {
                  if (
                    key != "LogoType" &&
                    key != "logoColorsArr" &&
                    key != "additionalColor" &&
                    key !="bulletFlag" &&
                    element[key]
                  ) {
                    element[key].forEach((element1, index) => {
                      
                      if (
                        element1 &&
                        element1.isInputChanged &&
                        firstSate[key] &&
                        firstSate[key][index]
                      ) {
                        firstSate[key][index]["isInputChanged"] =
                          element1.isInputChanged;
                      }
                    });
                  }
                }
              }
            });
          }

          if (future && future.length > 0) {
            future.forEach((element) => {
              for (const key in element) {
                if (element.hasOwnProperty(key)) {
                  if (
                    key != "LogoType" &&
                    key != "logoColorsArr" &&
                    key != "additionalColor" && 
                    key !="bulletFlag"
                  ) {
                    element[key].forEach((element1, index) => {
                      if (element1.isInputChanged && firstSate[key][index]) {
                        firstSate[key][index]["isInputChanged"] =
                          element1.isInputChanged;
                      }
                    });
                  }
                }
              }
            });
          }

          var bgPreview,bgPreviewName;
          if(firstSate.backgroundImage && firstSate.backgroundImage.length > 0){
            const bgIndex = action.bgArray.findIndex(p=>p.FILE == firstSate.backgroundImage[0].value);
            if(bgIndex!=-1){
              bgPreview = action.bgArray[bgIndex].PREVIEW;
              bgPreviewName = action.bgArray[bgIndex].NAME;
            }else{
              bgPreview = "";
              bgPreviewName = "";
            }
          }
          console.log("firstSate",firstSate)
          return {
            ...state,
            past: [],
            present: _.cloneDeep(firstSate),
            isInputChanged: true,
            future: [],
            ...firstSate,
            LogoType: "",
            logoColorsArr: "",
            additionalColor: "",
            bgPreviewName:bgPreviewName,
            bgPreview:bgPreview,
            bulletFlag:flagBulletFirst
          };
        case "NEW":
          if (present !== null) {
            for (const key in action.dataObj) {
              if (action.dataObj.hasOwnProperty(key)) {
                if (
                  key != "LogoType" &&
                  key != "logoColorsArr" &&
                  key != "additionalColor"
                ) {
                  action.dataObj[key].forEach((element, index) => {
                    if (element.isInputChanged) {
                      if (state.present[key][index]) {
                        state.present[key][index]["isInputChanged"] =
                          element.isInputChanged;
                      } else {
                        state.present[key][index] = {};
                        state.present[key][index]["isInputChanged"] =
                          element.isInputChanged;
                      }
                    }
                    // else {
                    //   state.present[key][index]["isInputChanged"] = false;
                    // }
                  });
                }
              }
            }
            
            past.push(_.cloneDeep(state.present));
          }
          return {
            ...state,
            present: _.cloneDeep(action.dataObj),
            past: past,
            future: [],
          };
        default:
          return {
            ...state,
          };
      }

    case actionTypes.SET_LOGO_IMAGES:
      return {
        ...state,
        logoFiles: action.imagrData.logoFiles,
      };

    case actionTypes.SET_MAIN_LOGO_IMAGE:
      const DefaultMainLogoArr = [
        {
          attributes: {
            NAME: "FXS_Logo1",
          },
          value: action.data,
          isInputChanged: action.flag,
        },
      ];
      if (action.data && action.data != "None") {
        DefaultMainLogoArr[0].mainLogoName = action.logoName;
      }

      return {
        ...state,
        mainLogo: DefaultMainLogoArr,
        isInputChanged: true,
        mainLogoName: action.logoName,
        // isChangedApplied: true
      };

    case actionTypes.SET_PHANTOM_LOGO_IMAGE:
      const phantomLogoArr = [
        {
          attributes: {
            NAME: "FXS_Phantom",
          },
          value: action.data,
        },
      ];
      return {
        ...state,
        phantomLogo: action.data,
        isInputChanged: true,
        phatomLogoName: action.logoName,
        // isChangedApplied: true
      };

    case actionTypes.SET_ON_CHANGED_PHANTOM_LOGO_IMAGE:
      var phantomIndex = state.phantomLogo.findIndex(
        (element) => element.attributes.NAME == action.fieldName
      );
      const newphantomLogo = [...state.phantomLogo];
      newphantomLogo[phantomIndex].value = action.data;
      newphantomLogo[phantomIndex].phatomLogoName = action.logoName;

      newphantomLogo.forEach((element, index) => {
        if (index === phantomIndex) {
          newphantomLogo[index]["isInputChanged"] = true;
          // newphantomLogo[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
            newphantomLogo[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        phantomLogo: newphantomLogo,
        isInputChanged: true,
        phatomLogoName: action.logoName,
        // isChangedApplied: true
      };

    case actionTypes.LOGO_OPTION:
      return {
        ...state,
        logoOption: action.data,
      };

    case actionTypes.SET_GEN_DATA:
      return {
        ...state,
        genData: action.stubData,
      };

    case actionTypes.SET_GEN_INPUT_DATA:
      var genIndex = state.genData.findIndex(
        (element) => element.attributes.NAME == action.name
      );
      const newGenArr = [...state.genData];
      newGenArr[genIndex].value = action.event.target.value;

      newGenArr.forEach((element, index) => {
        if (index === genIndex) {
          newGenArr[index]["isInputChanged"] = true;
          newGenArr[index]["isInputChangedFlag"] = true;
        } else {
          if(state.orderType && state.orderType.toLowerCase() !=="overwrite" && !state.issaveOverwriteInst){
            newGenArr[index]["isInputChanged"] = false;
          }
        }
      });

      return {
        ...state,
        genData: newGenArr,
        isFocusInput: true,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_DEFAULT_JSONINFO:
      const jsonInfoArr = [
        {
          attributes: {
            NAME: "JSONINFO",
          },
          value: action.data,
          isInputChanged: action.flag
        },
      ];
      return {
        ...state,
        JSONINFO: jsonInfoArr,
        isFocusInput: false,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.SET_CURRENT_PAGE:
      const pageArr = [
        {
          attributes: {
            NAME: "page",
          },
          value: action.data.toString(),
          isInputChanged: action.flag,
        },
      ];
      return {
        ...state,
        isFocusInput: false,
        isInputChanged: true,
        page: pageArr,
        // isChangedApplied: true
      };

    case actionTypes.SET_DESIGN_INSTRUCTIONS:
      const Design_InstructionsArr = [
        {
          attributes: {
            NAME: "Design_Instructions",
          },
          value: action.data,
          isInputChanged: action.flag,
        },
      ];
      return {
        ...state,
        isFocusInput: false,
        isInputChanged: true,
        Design_Instructions: Design_InstructionsArr,
        // isChangedApplied: true
      };

    case actionTypes.SET_SECURITY_BACKGROUND_IMAGE:
      const DefaultSecurityBGArr = [
        {
          attributes: {
            NAME: "FXSSecurity",
          },
          value: action.data.FILE,
          isInputChanged: action.flag,
          
        },
      ];
      return {
        ...state,
        backgroundImage: DefaultSecurityBGArr,
        bgPreview: action.data.PREVIEW,
        bgPreviewName: action.data.NAME,
        isInputChanged: true,
        // isChangedApplied: true
      };

    case actionTypes.INPUT_CHNAGED_ON_ZOOPM:
      return {
        ...state,
        isInputChanged: true,
      };

    case actionTypes.SET_IMAGE_TYPE:
      const imageTypeArr = [
        {
          attributes: {
            NAME: "LogoType",
          },
          value: action.data,
          isInputChanged: action.flag,
        },
      ];
      return {
        ...state,
        imageType: imageTypeArr,
      };

    case actionTypes.SET_LOGO_COLOR:
      return {
        ...state,
        logoDC: action.data,
      };

    case actionTypes.SET_LOGO_TYPE:
      return {
        ...state,
        LogoType: action.data,
      };

    case actionTypes.ADDITIONAL_COLORS:
      return {
        ...state,
        additionalColor: action.data,
      };
    case actionTypes.SET_LOGO_COLOR_ARR:  
  
      let firstLogoArr
if(action.data!==""){
      //console.log("aaSET_LOGO_COLOR_ARR-inside -state.firstLogo,state.firstLogo,state.logoColorsArr,action.data---",state.firstLogo,state.firstLogo,state.logoColorsArr,action.data) 
      
      if(!state.firstLogo || state.firstLogo.length===0 || state.logoColorsArr===""){
        firstLogoArr=state.logoColorsArr
      } else { 
        firstLogoArr=state.firstLogo
      }
    const tempLogoColorsArrayLocal=  state.tempLogoColorsArray.length>0?[...state.tempLogoColorsArray,...action.data]:action.data
    return {
        ...state,
        logoColorsArr:tempLogoColorsArrayLocal.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i),
        firstLogo:firstLogoArr,
        tempLogoColorsArray:tempLogoColorsArrayLocal
      };
    }else{
      return {
        ...state,
        logoColorsArr:action.data,
      };
    }

    case actionTypes.SET_PANTONE_COLORS_ARRAY:
      return {
        ...state,
        pantoneColorArr: action.data,
      };
    case actionTypes.SET_TOOLTIP_FLAG:
      return {
        ...state,
        displayTooltip: false,
      };
    case actionTypes.SET_CUSTOMER_LOGO_FLAG:
      return {
        ...state,
        isCustomerLogo: action.data,
      };

    case actionTypes.SET_CUSTOMER_LOGO_FLAG_PHANTOM:
      return {
        ...state,
        pantonCustermerUploadObj: action.data,
      };

    case actionTypes.SET_FORMATING_POPUP_CURRENT_CLASS:
      return {
        ...state,
        popUpClass: action.data,
      };

    case actionTypes.UPDATE_QUEUE_ARRAY:
      return {
        ...state,
        queueArr: action.data,
      };

    case actionTypes.API_INPROGRESS_FLAG:
      return {
        ...state,
        previewAPIFlag: action.data,
      };

    case actionTypes.SET_BULLATE_TOGGLE_FLAG:
      return {
        ...state,
        bulletFlag: action.data,
      };

    case actionTypes.IS_CHANGED_APPLIED:
      return {
        ...state,
        isChangedApplied: action.data,
      };

    case actionTypes.IS_SAVE_OVERWRITE_INST:
    return {
      ...state,
      issaveOverwriteInst: action.data,
    };

    case actionTypes.SET_ORDER_TYPE:
      return {
        ...state,
        orderType: action.data,
      };
    default:
      return state;
  }
};
