import React from "react";
import "./LogoType.css";

export const LogoType = (props) => {
  //Display popup style logic
  let openPopup = {
    display: "none",
  };
  if (props.togglePopup) {
    openPopup = {
      display: "Block",
    };
  }
  //logic if selected logo is image or not
  let classNotImgae = "classNotImgae";
  if (props.isDisplayImage) {
    classNotImgae = "";
  }

  return (
    <div>
      <div id="place-logo-modal" className="modal" style={openPopup}>
        <div className="modal-content">
          <div className="modal-header">
            <h3> CONFIRM LOGO PLACEMENT </h3>
            <span className="close" onClick={props.closePopup}>
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="logo-position">
              <div className="place-the-logo-type" id="place-the-bg">
                <div className="radio-wrapper">
                  <div className="grid-item grid-item-6">
                    <span>
                      <input
                        type="radio"
                        id="side-logo"
                        name="radio_group_logo"
                        value="Side"
                        checked={
                          props.imageType &&
                          props.imageType[0] &&
                          props.imageType[0].value === "Side"
                            ? true
                            : false
                        }
                        onChange={(event) => props.ImagetypeChanged(event)}
                      />
                      <label htmlFor="side-logo">SIDE</label>
                    </span>
                    <div>
                      <div className={classNotImgae + " choose-the-logo-type"}>
                        {props.isDisplayImage ? (
                          <img
                            src={props.imgSrc}
                            alt={props.imgSrc}
                            title="Preview.gif"
                            id="side-logo-img"
                          />
                        ) : (
                          <div>Your Logo Here</div>
                        )}
                      </div>
                      <div className="company-information">
                        <div className="company-info-logo company-info-name text-ellipsis">
                          <strong>
                            {props.companyInfo &&
                            props.companyInfo[0] &&
                            props.companyInfo[0].value
                              ? props.companyInfo[0].value + ", "
                              : "YOUR COMPANY NAME"}
                          </strong>
                        </div>
                        <div className="company-info-logo company-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[1] &&
                          props.companyInfo[1].value
                            ? props.companyInfo[1].value + ", "
                            : "STREET ADDRESS, SUITE#"}
                        </div>
                        <div className="company-info-logo company-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[2] &&
                          props.companyInfo[2].value
                            ? props.companyInfo[2].value + ", "
                            : "CITY, STATE, ZIP"}
                        </div>
                        <div className="company-info-logocompany-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[3] &&
                          props.companyInfo[3].value
                            ? props.companyInfo[3].value
                            : "PHONE(S)"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item grid-item-6 logo-place-top">
                    <span>
                      <input
                        type="radio"
                        id="top-logo"
                        name="radio_group_logo"
                        value="Top"
                        checked={
                          props.imageType &&
                          props.imageType[0] &&
                          props.imageType[0].value === "Top"
                            ? true
                            : false
                        }
                        onChange={(event) => props.ImagetypeChanged(event)}
                      />
                      <label htmlFor="top-logo">TOP</label>
                    </span>
                    <div>
                      <div className={classNotImgae + " choose-the-logo-type"}>
                        {props.isDisplayImage ? (
                          <img
                            src={props.imgSrc}
                            alt={props.imgSrc}
                            title="Preview.gif"
                          />
                        ) : (
                          <div>Your Logo Here</div>
                        )}
                      </div>
                      <div className="company-information">
                        <div className="company-info-logo company-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[1] &&
                          props.companyInfo[1].value
                            ? props.companyInfo[1].value + ", "
                            : "STREET ADDRESS, SUITE#"}
                        </div>
                        <div className="company-info-logo company-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[2] &&
                          props.companyInfo[2].value
                            ? props.companyInfo[2].value + ", "
                            : "CITY, STATE, ZIP"}
                        </div>
                        <div className="company-info-logocompany-info-name text-ellipsis">
                          {props.companyInfo &&
                          props.companyInfo[3] &&
                          props.companyInfo[3].value
                            ? props.companyInfo[3].value
                            : "PHONE(S)"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<div className="text-center"><h4>Your logo will be placed in the form for preview purposes only.  We will convert your logo and provide you a proof before the order is processed.</h4></div>
            {props.uploadErrorMsg && (
              <div className="text-danger">{props.uploadErrorMsg}</div>
            )}
            <div className="text-right pull-right">
              <button
                className="button-square button-black m-r-10"
                onClick={props.closePopup}
              >
                Cancel
              </button>
              <button
                className="button-square button-blue m-r-15"
                onClick={props.uploadFile}
              >
                Place Logo
              </button>
            </div>
            <div className="clear-float"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
