/**
 * @author    Clarion
 * @version 1.0
 * @file   :  configXMLFISdata
 * @desc   :  All the common function are written in this file fro xml parsing of fxs config file
 */

const getElementsAtrributesFunction = (keyPro, array) => {
  for (let keyEle in array) {
    if (array.hasOwnProperty(keyEle)) {
      keyPro[array[keyEle].name] = {
        ...array[keyEle].attributes
      };
      if (
        keyPro[array[keyEle].name] &&
        keyPro[array[keyEle].name].ACCESS === "ADMIN"
      ) {
        getElementsAtrributesFunction(
          keyPro[array[keyEle].name],
          array[keyEle].elements
        );
      }
    }
  }
  return keyPro;
};

/**
 * @author    Clarion
 * @version 1.0
 * @file   :  colorPropSet
 * @desc   :  this function filer the user type color from color set element
 * @returns:array of colors for user type
 */

const colorPropSet = (arrEle, colorElements) => {
  for (let colorKeyEle in colorElements) {
    if (
      colorElements.hasOwnProperty(colorKeyEle) &&
      colorElements[colorKeyEle].elements[0].text
    ) {
      arrEle[colorElements[colorKeyEle].name] =
        colorElements[colorKeyEle].elements[0].text;
    }
  }
  return arrEle;
};

/**
 * @author    Clarion
 * @version 1.0
 * @file   :  adminColors
 * @desc   :  this function filer the admin color from color set element
 * @returns:array of admin colors
 */

const adminColors = (arrEle, colorElements) => {
  for (let colorKeyEle in colorElements) {
    arrEle[colorKeyEle] = {};
    if (colorElements.hasOwnProperty(colorKeyEle)) {
      arrEle[colorKeyEle] = colorPropSet(
        arrEle[colorKeyEle],
        colorElements[colorKeyEle].elements
      );
    }
  }
  return arrEle;
};

/**
 * @author    Clarion
 * @version 1.0
 * @file   :  convertXMLData
 * @desc   :  this function is for convert array with minimise nested child
 * @returns:array of xml config data
 */

export const convertXMLData = xmlData => {
  const xmlObjData = [...xmlData.elements[0].elements];
  const tempObj = {};
  for (let x in xmlObjData) {
    if (xmlObjData.hasOwnProperty(x)) {
      tempObj[xmlObjData[x].name] = {};

      switch (xmlObjData[x].name) {
        case "BOXDEFS":
          for (let key in xmlObjData[x].elements) {
            if (
              xmlObjData[x].elements.hasOwnProperty(key) &&
              xmlObjData[x].elements[key].attributes &&
              xmlObjData[x].elements[key].attributes.NAME
            ) {
              tempObj[xmlObjData[x].name][
                xmlObjData[x].elements[key].attributes.NAME
              ] = {};
              tempObj[xmlObjData[x].name][
                xmlObjData[x].elements[key].attributes.NAME
              ] = getElementsAtrributesFunction(
                tempObj[xmlObjData[x].name][
                  xmlObjData[x].elements[key].attributes.NAME
                ],
                xmlObjData[x].elements[key].elements
              );
            }
          }
          break;
        case "BOXES":
          const newArr = xmlObjData[x].elements.map(element => {
            let BoxDiff = {};
            if (element.elements && element.elements[0].elements) {
              for (let key in element.elements[0].elements) {
                if (element.elements[0].elements.hasOwnProperty(key)) {
                  BoxDiff[element.elements[0].elements[key].name] = {
                    ...element.elements[0].elements[key].attributes
                  };
                }
              }
            }
            let elementType = "text";
            if (
              element.attributes &&
              element.attributes.BOXDEF &&
              tempObj.BOXDEFS[element.attributes.BOXDEF].EDITABLE.IMAGE
            ) {
              elementType = "image";
            }
            if (element.type === "comment") {
              elementType = "comment";
            }
            return {
              name: element.name || "comment",
              attributes: element.attributes,
              elementType: elementType,
              BOXDEF: BoxDiff,
              comment: element.comment
            };
          });
          tempObj["BOXES"] = newArr;
          break;
        case "TABS":
          const tabsArr = [];
          for (const key in xmlObjData[x].elements) {
            if (xmlObjData[x].elements.hasOwnProperty(key)) {
              tabsArr[key] = { ...xmlObjData[x].elements[key].attributes };
              tabsArr[key]["elements"] = [];
              for (const keyele in xmlObjData[x].elements[key].elements) {
                if (
                  xmlObjData[x].elements[key].elements.hasOwnProperty(keyele)
                ) {
                  tabsArr[key]["elements"][keyele] = {};
                  tabsArr[key]["elements"][keyele]["attributes"] = {
                    ...xmlObjData[x].elements[key].elements[keyele].attributes
                  };
                  tabsArr[key]["elements"][keyele]["NOTES"] = [];
                  for (const notKey in xmlObjData[x].elements[key].elements[
                    keyele
                  ].elements) {
                    if (
                      xmlObjData[x].elements[key].elements[
                        keyele
                      ].elements.hasOwnProperty(notKey)
                    ) {
                      tabsArr[key]["elements"][keyele]["NOTES"][
                        xmlObjData[x].elements[key].elements[keyele].elements[
                          notKey
                        ].attributes.NAME
                      ] =
                        xmlObjData[x].elements[key].elements[keyele].elements[
                          notKey
                        ].elements[0].cdata;
                    }
                  }
                }
              }
            }
          }
          tempObj["TABS"] = tabsArr;
          break;

        case "LABELS":
          const lables = xmlObjData[x].elements.map(ele => {
            return {
              name: ele.attributes.NAME,
              elements: { ...ele.elements[0] }
            };
          });
          tempObj["LABELS"] = lables;
          break;

        case "COLORSETS":
          const colorArr = [];
          for (let key in xmlObjData[x].elements) {
            if (xmlObjData[x].elements.hasOwnProperty(key)) {
              colorArr[xmlObjData[x].elements[key].attributes.NAME] = [];
              for (let colorKey in xmlObjData[x].elements[key].elements) {
                colorArr[xmlObjData[x].elements[key].attributes.NAME][
                  colorKey
                ] = {};

                if (
                  xmlObjData[x].elements[key].elements[colorKey].attributes &&
                  xmlObjData[x].elements[key].elements[colorKey].attributes
                    .DEFAULT
                ) {
                  colorArr[xmlObjData[x].elements[key].attributes.NAME][
                    colorKey
                  ]["default"] = true;
                }

                if (
                  !xmlObjData[x].elements[key].elements[colorKey].attributes ||
                  xmlObjData[x].elements[key].elements[colorKey].attributes
                    .ACCESS != "ADMIN"
                ) {
                  colorArr[xmlObjData[x].elements[key].attributes.NAME][
                    colorKey
                  ] = {
                    ...colorPropSet(
                      colorArr[xmlObjData[x].elements[key].attributes.NAME][
                        colorKey
                      ],
                      xmlObjData[x].elements[key].elements[colorKey].elements
                    )
                  };
                }

                if (
                  xmlObjData[x].elements[key].elements[colorKey].attributes &&
                  xmlObjData[x].elements[key].elements[colorKey].attributes
                    .ACCESS
                ) {
                  delete colorArr[xmlObjData[x].elements[key].attributes.NAME][
                    colorKey
                  ];
                  colorArr[xmlObjData[x].elements[key].attributes.NAME][
                    xmlObjData[x].elements[key].elements[
                      colorKey
                    ].attributes.ACCESS
                  ] = [];

                  colorArr[xmlObjData[x].elements[key].attributes.NAME][
                    xmlObjData[x].elements[key].elements[
                      colorKey
                    ].attributes.ACCESS
                  ] = adminColors(
                    colorArr[xmlObjData[x].elements[key].attributes.NAME][
                      xmlObjData[x].elements[key].elements[colorKey].attributes
                        .ACCESS
                    ],
                    xmlObjData[x].elements[key].elements[colorKey].elements
                  );
                }
              }
            }
          }
          tempObj["COLORSETS"] = colorArr;
          break;

        case "FONTFAMILIES":
          const constFontArr = {};
          for (const key in xmlObjData[x].elements) {
            if (xmlObjData[x].elements.hasOwnProperty(key)) {
              constFontArr[xmlObjData[x].elements[key].attributes.NAME] = {};
              constFontArr[xmlObjData[x].elements[key].attributes.NAME][
                "DEFAULT"
              ] = xmlObjData[x].elements[key].attributes.DEFAULT;
              constFontArr[xmlObjData[x].elements[key].attributes.NAME][
                "FONTS"
              ] = xmlObjData[x].elements[key].elements.map(element => {
                const fontMap = [];
                if (element.elements && element.elements.length > 0) {
                  for (
                    let index = 0;
                    index < element.elements.length;
                    index++
                  ) {
                    fontMap.push(element.elements[index].attributes);
                  }
                }
                return {
                  ...element.attributes,
                  fontMap: fontMap
                };
              });
            }
          }
          tempObj["FONTFAMILIES"] = constFontArr;
          break;

        case "BACKGROUNDS":
          const backgroundArr = xmlObjData[x].elements.map(element => {
            return {
              ...element.attributes
            };
          });
          backgroundArr["PREVIEWPATH"] =
            xmlObjData[x].attributes["PREVIEWPATH"];
          backgroundArr["FILEPATH"] = xmlObjData[x].attributes["FILEPATH"];
          tempObj["BACKGROUNDS"] = backgroundArr;
          break;

        case "LAYERS":
          const layersArr = xmlObjData[x].elements.map(element => {
            return {
              ...element.attributes
            };
          });
          tempObj["LAYERS"] = layersArr;
          break;

        default:
          break;
      }
    }
  }
  return tempObj;
};
