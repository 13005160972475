/**
 * @author    Clarion
 * @version 1.0
 * @file   :  actionTypes
 * @desc   :  This file of constant used for redux action call
 */

export const FETCH_SESSION_DATA_SUCCESS = "FETCH_SESSION_DATA_SUCCESS";
export const FETCH_SESSION_DATA_START = "FETCH_SESSION_DATA_START";
export const FETCH_SESSION_DATA_FAIL = "FETCH_SESSION_DATA_FAIL";

export const FETCH_PREVIEW_SUCCESS = "FETCH_PREVIEW_SUCCESS";
export const FETCH_PREVIEW_START = "FETCH_PREVIEW_START";
export const FETCH_PREVIEW_FAIL = "FETCH_PREVIEW_FAIL";
export const FETCH_PREVIEW_ABORT = "FETCH_PREVIEW_ABORT";

export const FETCH_FX_CONFIG_SUCCESS = "FETCH_FX_CONFIG_SUCCESS";
export const FETCH_FX_CONFIG_START = "FETCH_FX_CONFIG_START";
export const FETCH_FX_CONFIG_FAIL = "FETCH_FX_CONFIG_FAIL";

export const GET_INPUT_SETTINGS = "GET_INPUT_SETTINGS";

export const SET_INPUT_DATA = "SET_INPUT_DATA";

export const SET_COMPANY_INFORMATION = "SET_COMPANY_INFORMATION";
export const SET_COMPANY_SIGN_INFORMATION = "SET_COMPANY_SIGN_INFORMATION";
export const SET_SIGN_INPUT_DATA = "SET_SIGN_INPUT_DATA";

export const SET_COMPANY_OVER_SIGN_INFORMATION =
  "SET_COMPANY_OVER_SIGN_INFORMATION";

export const SET_SIGN_OVER_INPUT_DATA = "SET_SIGN_OVER_INPUT_DATA";

export const SET_OVER_SIGN_LINE_FLAG = "SET_OVER_SIGN_LINE_FLAG";
export const SET_STUB_DATA = "SET_STUB_DATA";
export const SET_STUB_INPUT_DATA = "SET_STUB_INPUT_DATA";
export const SET_BANK_INFO_DATA = "SET_BANK_INFO_DATA";
export const SET_BANK_INFO_INPUT_DATA = "SET_BANK_INFO_INPUT_DATA";
export const SET_FRACTIONAL_INFO_DATA = "SET_FRACTIONAL_INFO_DATA";
export const SET_FRACTIONAL_INFO_INPUT_DATA = "SET_FRACTIONAL_INFO_INPUT_DATA";

export const SET_CHECK_INFO_DATA = "SET_CHECK_INFO_DATA";
export const SET_CHECK_INFO_INPUT_DATA = "SET_CHECK_INFO_INPUT_DATA";
export const SET_ROUTING_STATE = "SET_ROUTING_STATE";
export const SET_ROUTING_VALUE = "SET_ROUTING_VALUE";

export const SET_ACCOUNT_STATE = "SET_ACCOUNT_STATE";
export const SET_ACCOUNT_VALUE = "SET_ACCOUNT_VALUE";

export const FETCH_JSON_SUCCESS = "FETCH_JSON_SUCCESS";

export const SET_DEFAULT_BOX_DIFF = "SET_DEFAULT_BOX_DIFF";
export const SET_STYLE_OBJECT = "SET_STYLE_OBJECT";

export const SET_INPUT_FOCUS_OUT_FALSE = "SET_INPUT_FOCUS_OUT_FALSE";
export const SET_IS_INPUT_CHANGED = "SET_IS_INPUT_CHANGED";
export const SET_COLOR_CHANGED = "SET_COLOR_CHANGED";
export const SET_COLOR_DEFAULT = "SET_COLOR_DEFAULT";
export const SET_TAB_INDEX = "SET_TAB_INDEX";
export const SET_BACKGROUND_IMAGE = "SET_BACKGROUND_IMAGE";

export const SET_REDUX_HISTORY = "SET_REDUX_HISTORY";
export const HIDE_LOADER = "HIDE_LOADER";
export const SET_LOGO_IMAGES = "SET_LOGO_IMAGES";
export const SET_MAIN_LOGO_IMAGE = "SET_MAIN_LOGO_IMAGE";
export const SET_PHANTOM_LOGO_IMAGE = "SET_PHANTOM_LOGO_IMAGE";

export const LOGO_OPTION = "LOGO_OPTION";

export const SET_FONT_MAP_DATA = "SET_FONT_MAP_DATA";

export const SET_GEN_DATA = "SET_GEN_DATA";
export const SET_GEN_INPUT_DATA = "SET_GEN_INPUT_DATA";
export const SET_ON_CHANGED_PHANTOM_LOGO_IMAGE =
  "SET_ON_CHANGED_PHANTOM_LOGO_IMAGE";

export const SET_DEFAULT_JSONINFO = "SET_DEFAULT_JSONINFO";
export const SET_JSONINFO = "SET_JSONINFO";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_DESIGN_INSTRUCTIONS = "SET_DESIGN_INSTRUCTIONS";

export const SET_SESSION_FILE_ID = "SET_SESSION_FILE_ID";
export const SET_SECURITY_BACKGROUND_IMAGE = "SET_SECURITY_BACKGROUND_IMAGE";

export const SET_IMAGE_WIDTH = "SET_IMAGE_WIDTH";
export const SET_LAST_IMAGE_WIDTH = "SET_LAST_IMAGE_WIDTH";
export const INPUT_CHNAGED_ON_ZOOPM = "INPUT_CHNAGED_ON_ZOOPM";
export const SET_IMAGE_TYPE = "SET_IMAGE_TYPE";

export const SET_DATA_TOGGLE_PREVIEW = "SET_DATA_TOGGLE_PREVIEW";
export const SET_MOBILE_TOGGLE = "SET_MOBILE_TOGGLE";
export const SET_INSTANCE_LIST = "SET_INSTANCE_LIST";

export const SET_LOGO_COLOR = "SET_LOGO_COLOR";
export const ADDITIONAL_COLORS = "ADDITIONAL_COLORS";
export const SET_LOGO_COLOR_ARR = "SET_LOGO_COLOR_ARR";
export const SET_LOGO_TYPE = "SET_LOGO_TYPE";
export const SET_PANTONE_COLORS_ARRAY = "SET_PANTONE_COLORS_ARRAY";
export const SET_TOOLTIP_FLAG = "SET_TOOLTIP_FLAG";
export const SET_CUSTOMER_LOGO_FLAG = "SET_CUSTOMER_LOGO_FLAG";

export const SET_CUSTOMER_LOGO_FLAG_PHANTOM = "SET_CUSTOMER_LOGO_FLAG_PHANTOM";
export const DECGRISE_LOADER_COUNT = "DECGRISE_LOADER_COUNT";
export const SET_FORMATING_POPUP_CURRENT_CLASS =
  "SET_FORMATING_POPUP_CURRENT_CLASS";
export const UPDATE_QUEUE_ARRAY = "UPDATE_QUEUE_ARRAY";
export const API_INPROGRESS_FLAG = "API_INPROGRESS_FLAG";
export const SET_BULLATE_TOGGLE_FLAG = "SET_BULLATE_TOGGLE_FLAG";
export const IS_CHANGED_APPLIED="IS_CHANGED_APPLIED";
export const SET_OLD_INSTANCE_DATA="SET_OLD_INSTANCE_DATA";
export const IS_SAVE_OVERWRITE_INST = "IS_SAVE_OVERWRITE_INST";
export const SET_ORDER_TYPE = "SET_ORDER_TYPE";
