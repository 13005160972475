/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Styles
 * @desc   :  File For Rendering Styles tab section in control panel
 */
import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { BackgroundPopup } from "./../Common/BackgroundPopup/BackgroundPopup";
import { ColorSelectorPopup } from "./../../components/Common/ColorSelectorPopup/ColorSelectorPopup";
import * as action from "../../store/action/fetchData";
import * as inputSettingction from "../../store/action/inputSettings";
import {
  DEFAULT_SCREEN_SHADE,
  UPLOAD_IMAGE_FILE_TYPES,
  UPLOAD_IMAGE_FILE_SIZE,
  IMAGE_FILES,
} from "./../../utility/AppsConst";
import { LogoPopUp } from "../Common/LogoPopUp/LogoPopUp";
import { LogoType } from "./../LogoType/LogoType";
import {
  mergeTwoObjectColors,
  getTile,
  appliedColor,
  getVlaueFormJsonByKey,
  getVlaueFormSessionByKey,
  getBGObjectFormByJsonData,
  appliedPantoneColor,
  getBgDefault,
  getDefaultColor,
  getPantoneTile,
  getValueArrayIndex,
  checkInputIsInJsonData,
  getLogoType,
  getLogoColor,
  removeCharAtLast,
  getScreenshadeByColorName,
  getColorNameString,
  getLogoDetailsByFolder
} from "./../../utility/Utility";

import "./Styles.css";
import { cond } from "lodash";
import ReactTooltip from "react-tooltip";

class Styles extends React.Component {
  constructor(props) {
    super(props);
    this.colorArry = [];
    this.colorArryTemp=[]
    this.colorArryCopy = [];
    this.pantoneColorArr = [];
    this.currentBtn = "";
    const bgArray = _.cloneDeep(this.props.configData.BACKGROUNDS);
    const SKUvalues = getVlaueFormSessionByKey(
      this.props.sessionData,
      "SKU",
      "cdata"
    );
    const User_Level = getVlaueFormSessionByKey(
      this.props.sessionData,
      "User_Level",
      "cdata"
    );

    const docType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );

    const Colors = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Colors",
      "cdata"
    );

    const pathValue = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Logo_Path",
      "cdata"
    );
    const FXSLogo1 = getVlaueFormJsonByKey(
      this.props.jsonData,
      "FXS_Logo1",
      "content"
    );
    let mainLogo = "";
    let isLogoDis = true;
    if (FXSLogo1 && FXSLogo1 !== -1) {
      const mainLogoArr = FXSLogo1.split(/[\\\/]/);
      mainLogo = mainLogoArr[mainLogoArr.length - 2];
    }
    const FXSPhantom = getVlaueFormJsonByKey(
      this.props.jsonData,
      "FXS_Phantom",
      "content"
    );
    let phantomLogo = "";
    if (FXSPhantom && FXSPhantom !== -1) {
      const phLogoArr = FXSPhantom.split(/[\\\/]/);
      phantomLogo = phLogoArr[phLogoArr.length - 2];
    }
    const splitArr = pathValue.split(/[\\\/]/);

    const findLogoIndex = this.props.jsonData.findIndex(
      (element) => element.name === "FXS_Logo1"
    );

    const findPantomIndex = this.props.jsonData.findIndex(
      (element) => element.name && element.name.includes("FXS_Phantom")
    );

    if (mainLogo || phantomLogo) {
      this.props.onLogoFlagChanged(true);
    }
    if (findLogoIndex === -1 && findPantomIndex===-1) {
      isLogoDis = false;
    }

    this.state = {
      textBtnShoWHide: "none",
      lineBtnShoWHide: "none",
      bgBtnShoWHide: "none",
      colorObj: {
        textBtn: {
          name: "",
          color: "",
        },
        lineBtn: {
          name: "",
          color: "",
        },
        bgBtn: {
          name: "",
          color: "",
        },
      },
      colorCode: Colors,
      isBgPopupOpen: false,
      bgArray: bgArray,
      PREVIEWPATH: this.props.configData.BACKGROUNDS.PREVIEWPATH,
      FILEPATH: this.props.configData.BACKGROUNDS.FILEPATH,
      selectedImage: {},
      selectedImageBG: {},
      isSelected: false,
      isSecurityCheck: false,
      isColorPopupOpen: false,
      colorArray: [],
      SKUvalues: SKUvalues,
      User_Level: User_Level,

      imageFolder: splitArr[splitArr.length - 2],
      customerFolder: splitArr[splitArr.length - 3],
      mainLogo_image_name: mainLogo,
      phantomLogo_image_name: phantomLogo,
      isLogoDis: isLogoDis,
      isLogoPopupOpen: false,
      isLogoSelected: false,
      pathValue: pathValue,
      docType: docType,
      isSecuritybg: true,
      selectedLogo:{},
      updateColorObj:{
        textBtn: {
          name: "",
          color: "",
        },
        lineBtn: {
          name: "",
          color: "",
        },
        bgBtn: {
          name: "",
          color: "",
        }
      }
    };
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  handleClickEvent
   * @desc   :  This function used for hide the all drop down in the style section clicking on any where in the document
   * @return -
   */

  handleClickEvent(e) {
    const { textBtnShoWHide, lineBtnShoWHide, bgBtnShoWHide } = this.state;
    let wasOutside = false;

    if (this.defaultColorRef && this.defaultColorRef.contains(e.target)) {
      wasOutside = false;
    } else if (this.lineColorRef && this.lineColorRef.contains(e.target)) {
      wasOutside = false;
    } else if (this.bgColorRef && this.bgColorRef.contains(e.target)) {
      wasOutside = false;
    } else {
      wasOutside = true;
    }

    if (
      wasOutside &&
      (bgBtnShoWHide === "block" ||
        lineBtnShoWHide === "block" ||
        textBtnShoWHide === "block")
    ) {
      this.setState({
        bgBtnShoWHide: "none",
        lineBtnShoWHide: "none",
        bgBtnShoWHide: "none",
      });
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  This is lifecycle hook exicuted whenever component mount this function reads the color configration and set the local state accordingly
   * @return -
   */

  async componentDidMount() {
    const findColorIndex = this.props.sessionData.findIndex(
      (element) => element.name === "Colors"
    );
    const findUserIndex = this.props.sessionData.findIndex(
      (element) => element.name === "User_Level"
    );
    const documentType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    let isdocumentType = false;
    if (
      documentType &&
      documentType !== -1 &&
      (documentType === "Reorder-Exact" || documentType === "Exact")
    ) {
      isdocumentType = true;
    }

    if (findColorIndex !== -1) {
      this.setState({
        colorCode: this.props.sessionData[findColorIndex].cdata,
        documentType: isdocumentType,
      });
    }
    const SKUvalue1 = getVlaueFormSessionByKey(
      this.props.sessionData,
      "SKU",
      "cdata"
    );
    if (
      parseInt(this.props.sessionData[findColorIndex].cdata) === 0 &&
      documentType.toLowerCase() === "new order" && !SKUvalue1.startsWith("FS")
    ) {
      const bgObj = getBgDefault(this.props.configData.BACKGROUNDS);
      if (bgObj !== -1) {
        this.setState({
          selectedImageBG: bgObj,
        });
        this.props.onBGChanged(bgObj);
      }
    } else if (this.props.bgImage && this.props.bgImage.length > 0) {
      const bgOBJ = {
        FILE: this.props.bgImage[0].value,
        NAME: this.props.bgPreviewName,
        PREVIEW: this.props.bgPreview,
      };
      this.setState({
        selectedImageBG: bgOBJ,
      });
    } else {
      const FXSBackground = getVlaueFormJsonByKey(
        this.props.jsonData,
        "FXS_Background",
        "content"
      );
      if (FXSBackground) {
        const bgFileObj = getBGObjectFormByJsonData(
          this.props.configData.BACKGROUNDS,
          FXSBackground
        );
        if (bgFileObj !== -1) {
          this.setState({
            selectedImageBG: bgFileObj,
          });
        }
      }
    }
    const stateColorObj = { ...this.state.colorObj };

    if (
      this.props.sessionData[findColorIndex].cdata &&
      parseInt(this.props.sessionData[findColorIndex].cdata) !== 0
    ) {
      if (this.props.sessionData[findUserIndex].cdata === "Admin") {
        this.colorArry = mergeTwoObjectColors(
          this.props.configData.COLORSETS.DEFAULT,
          this.props.configData.COLORSETS.DEFAULT.ADMIN
        );
        this.colorArryTemp=this.colorArry
      } else {
        const defaultColor = [...this.props.configData.COLORSETS.DEFAULT];
        defaultColor.pop();
        this.colorArry = defaultColor;
        this.colorArryTemp=this.colorArry

      }
    } else {
      const SKUvalue = getVlaueFormSessionByKey(
        this.props.sessionData,
        "SKU",
        "cdata"
      );
      
      if (SKUvalue.startsWith("FS")) {
        this.setState({
          isSecurityCheck: true,
        });

        const FXSBackground = getVlaueFormJsonByKey(
          this.props.jsonData,
          "FXS_Security",
          "content"
        );
        if(FXSBackground===-1){
          this.setState({
            isSecuritybg:false
          })
        }

        this.colorArry = this.props.configData.COLORSETS.SECURITY;
        this.colorArryTemp=this.colorArry
        /**
         *Redmine #6139-  Color selection bug

         */
        // if(documentType.toLowerCase() === "new order"){
          // this.colorArry
          // const defaultCheckColorIndex = this.props.configData.COLORSETS.SECURITY.findIndex(
          //   (p) => p.default === true
          // );
/**
 * #5397 Stamp template issue - FS session file Background Color not loading on load -
 */  

          let defaultCheckColorIndex
          if(FXSBackground!=-1){
            defaultCheckColorIndex = this.props.configData.COLORSETS.SECURITY.findIndex(
              (p) => p.BACKGROUNDFILE === FXSBackground
            );
          } else {
            defaultCheckColorIndex = this.props.configData.COLORSETS.SECURITY.findIndex(
              (p) => p.default === true
            );
         }
      
          if (defaultCheckColorIndex !== -1) {
            const FXSBackground = this.props.configData.COLORSETS.SECURITY[
              defaultCheckColorIndex
            ].BACKGROUNDFILE;
            
              this.setState({
                selectedImageBG: FXSBackground,
              });

              const newColorStr =
              this.props.configData.COLORSETS.SECURITY[
                    defaultCheckColorIndex
                  ].NAME +
              "| " +
              getScreenshadeByColorName( this.props.configData.COLORSETS.SECURITY[
                defaultCheckColorIndex
              ].NAME, this.colorArry );
              const getTileData = getTile(this.colorArry);
              const tileIndex =getTileData.findIndex(
                (p) => p.BACKGROUNDFILE === FXSBackground
              );
              stateColorObj.textBtn.color = getTileData[tileIndex].rgba;
              stateColorObj.textBtn.name = this.props.configData.COLORSETS.SECURITY[
                defaultCheckColorIndex
              ].DISPLAY_NAME;
              stateColorObj.textBtn.colorName = this.props.configData.COLORSETS.SECURITY[
                defaultCheckColorIndex
              ].NAME;
              stateColorObj.bgBtn.name = this.props.configData.COLORSETS.SECURITY[
                defaultCheckColorIndex
              ].DISPLAY_NAME;
              stateColorObj.bgBtn.color = getTileData[tileIndex].rgba;
              this.props.onColorChanged(newColorStr, 'PantosColor');
          //}
        }
      } else {
        this.colorArry = this.props.configData.COLORSETS.VALUELINE;
        this.colorArryTemp=this.colorArry
        const getBgColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Pantos, Screens",
          "content"
        );

/**
 * #5397 Stamp template issue - FS session file Background Color not loading on load -
 */        
        let defaultCheckColorIndex
        if(getBgColorJSON!=-1){
          defaultCheckColorIndex = this.props.configData.COLORSETS.VALUELINE.findIndex(
            (p) => p.NAME === getBgColorJSON
          );
        } else {
          defaultCheckColorIndex = this.props.configData.COLORSETS.VALUELINE.findIndex(
            (p) => p.default === true
          );
       }
        
        if(defaultCheckColorIndex !==-1){
        const newColorStr =
        this.props.configData.COLORSETS.VALUELINE[
              defaultCheckColorIndex
            ].NAME +
        "| " +
        getScreenshadeByColorName( this.props.configData.COLORSETS.VALUELINE[
          defaultCheckColorIndex
        ].NAME, this.colorArry );
        const getTileData = getTile(this.colorArry);
        const tileIndex =getTileData.findIndex(
          (p) => p.NAME === getBgColorJSON
        );
        stateColorObj.textBtn.color = getTileData[tileIndex].rgba;
        stateColorObj.textBtn.name = this.props.configData.COLORSETS.VALUELINE[
          defaultCheckColorIndex
        ].DISPLAY_NAME;
        stateColorObj.textBtn.colorName = this.props.configData.COLORSETS.VALUELINE[
          defaultCheckColorIndex
        ].NAME;
  
        stateColorObj.bgBtn.name = this.props.configData.COLORSETS.VALUELINE[
          defaultCheckColorIndex
        ].DISPLAY_NAME;
        stateColorObj.bgBtn.color = getTileData[tileIndex].rgba;
        this.props.onColorChanged(newColorStr, 'PantosColor');
      }
      }
    }
    const deepCloneColorArr = _.cloneDeep(this.colorArry);
    const colorArray = await action.getPantoneColors();
    if (colorArray.length > 0) {
      this.pantoneColorArr = colorArray;

      this.props.setReduxPantoneColor(colorArray);
      if (
        this.state.User_Level === "Admin" &&
        this.state.SKUvalues.startsWith("FP")
      ) {
        const getBgColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Pantos, Screens",
          "content"
        );
        const gettintBgColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Pantos, Screens",
          "tint"
        );
        if (getBgColorJSON !== -1 && gettintBgColorJSON == "0.25") {
          const fIndex = colorArray.findIndex(
            (p) => p.attributes.name === getBgColorJSON
          );
          if (fIndex !== -1) {
            stateColorObj.textBtn.color = colorArray[fIndex].attributes.hex;
            stateColorObj.textBtn.name = colorArray[fIndex].attributes.name;
            this.setState({
              pantoneColorName: {
                name: colorArray[fIndex].attributes.name,
                hex: colorArray[fIndex].attributes.hex,
              },
            });
          }
        }
        const getLineColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Borders, Lines",
          "content"
        );
        const gettintLineColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Borders, Lines",
          "tint"
        );
        if (getLineColorJSON !== -1 && gettintLineColorJSON === "undefined") {
          const flIndex = colorArray.findIndex(
            (p) => p.attributes.name === getLineColorJSON
          );
          if (flIndex !== -1) {
            stateColorObj.lineBtn.color = colorArray[flIndex].attributes.hex;
            stateColorObj.lineBtn.name = colorArray[flIndex].attributes.name;
            this.setState({
              pantoneColorName: {
                name: colorArray[flIndex].attributes.name,
                hex: colorArray[flIndex].attributes.hex,
              },
            });
          }
        }
        const getTextColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Default",
          "content"
        );
        const gettintTextColorJSON = getVlaueFormJsonByKey(
          this.props.jsonData,
          "Default",
          "tint"
        );

        if (getTextColorJSON !== -1 && gettintTextColorJSON === "undefined") {
          const ftIndex = colorArray.findIndex(
            (p) => p.attributes.name === getTextColorJSON
          );
          if (ftIndex !== -1) {
            stateColorObj.bgBtn.color = colorArray[ftIndex].attributes.hex;
            stateColorObj.bgBtn.name = colorArray[ftIndex].attributes.name;
            this.setState({
              pantoneColorName: {
                name: colorArray[ftIndex].attributes.name,
                hex: colorArray[ftIndex].attributes.hex,
              },
            });
          }
        }
      }
      const defaultColors = getDefaultColor(this.colorArry);
      //#5353-Custom colors Bug Changes
      // if (defaultColors !== -1) {
      //   stateColorObj.bgBtn.name = defaultColors.DISPLAY_NAME;
      //   stateColorObj.lineBtn.name = defaultColors.DISPLAY_NAME;
      //   stateColorObj.textBtn.name = defaultColors.DISPLAY_NAME;
      // }

      this.setState({
        colorArray: colorArray,
        colorObj: stateColorObj,
        getPantoneTile: getPantoneTile(colorArray),
      });
    }

    let bgColor = getVlaueFormJsonByKey(
      this.props.jsonData,
      "Pantos, Screens",
      "content"
    );
    if (this.props.PantosColor !== "undefined") {
      bgColor = this.props.PantosColor && this.props.PantosColor[0].value;
    }
    let textColor = getVlaueFormJsonByKey(
      this.props.jsonData,
      "Default",
      "content"
    );
    if (this.props.DefaultColor !== "undefined") {
      textColor = this.props.DefaultColor && this.props.DefaultColor[0].value;
    }
    let lineColor = getVlaueFormJsonByKey(
      this.props.jsonData,
      "Borders, Lines",
      "content"
    );
    if (this.props.BorderColor !== "undefined") {
      lineColor = this.props.BorderColor && this.props.BorderColor[0].value;
    }
    const backgroundImg = getVlaueFormJsonByKey(
      this.props.jsonData,
      "FXS_Background",
      "content"
    );

    const FXSBackgroundImg = getVlaueFormJsonByKey(
      this.props.jsonData,
      "FXS_Security",
      "content"
    );
    this.setState({
      bgColor: bgColor,
      textColor: textColor,
      lineColor: lineColor,
      backgroundImg: backgroundImg,
      colorArryCopy: _.cloneDeep(this.colorArry),
      FXSBackgroundImg : FXSBackgroundImg
    });

    document.addEventListener("click", this.handleClickEvent);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  componentWillUnmount
   * @desc   :  This is lifecycle hook exicuted whenever component unmount this function remove the event click event
   * @return -
   */

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickEvent);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  clickTogalEventEvent
   * @desc   :  This is used for toggle event for text button dropdown
   * @return -
   */

  clickTogalEventEvent(e, data) {
    if (this.state.textBtnShoWHide === "none") {
      this.setState({
        textBtnShoWHide: "block",
        lineBtnShoWHide: "none",
        bgBtnShoWHide: "none",
        defaultRGB: "",
      });
    } else {
      this.setState({
        textBtnShoWHide: "none",
      });
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  clickLineTogalEventEvent
   * @desc   :  This is used for toggle event for line button dropdown
   * @return -
   */

  clickLineTogalEventEvent(e, data) {
    if (this.state.lineBtnShoWHide === "none") {
      this.setState({
        textBtnShoWHide: "none",
        lineBtnShoWHide: "block",
        bgBtnShoWHide: "none",
      });
    } else {
      this.setState({
        lineBtnShoWHide: "none",
      });
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  clickBackgroundTogalEventEvent
   * @desc   :  This is used for toggle event for background button dropdown
   * @return -
   */

  clickBackgroundTogalEventEvent(e, data) {
    if (this.state.bgBtnShoWHide === "none") {
      this.setState({
        textBtnShoWHide: "none",
        lineBtnShoWHide: "none",
        bgBtnShoWHide: "block",
      });
    } else {
      this.setState({
        bgBtnShoWHide: "none",
      });
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  clickEvent
   * @desc   :  This function is used to set the background color to button and set api call after selecting color
   * @return -
   */

  clickEvent(event, data, name) {
    // console.log("_.cloneDeep(this.state.colorObj)ddd",_.cloneDeep(this.state.colorObj))
    const stateColorObj = _.cloneDeep(this.state.colorObj);
    this.currentBtn = name;
    let newColorStr = data.NAME + "|" + data.SCREEN_SHADE;
    const SKUvalues = getVlaueFormSessionByKey(
      this.props.sessionData,
      "SKU",
      "cdata"
    );
    const colorCode = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Colors",
      "cdata"
    );

    if (name === "PantosColor") {
      stateColorObj.textBtn.color = data.rgba;
      stateColorObj.textBtn.name = data.DISPLAY_NAME;
      stateColorObj.textBtn.colorName = data.NAME;

      newColorStr =
        data.NAME +
        "| " +
        getScreenshadeByColorName(data.NAME, this.state.colorArryCopy);

      if (parseInt(colorCode) === 0 && SKUvalues.startsWith("FS")) {
        const bgObj = {
          FILE: data.BACKGROUNDFILE,
          NAME: data.BACKGROUNDFILE,
          PREVIEW: data.BACKGROUNDFILE,
          TYPE: "PICT",
        };
        this.props.onSecurityBGChanged(bgObj, true);
      } else {
        this.setBGDefault();
      }
    } else if (name === "BorderColor") {
      let oldLineBtnName = stateColorObj.lineBtn.name;
      stateColorObj.lineBtn.color = data.rgba;
      stateColorObj.lineBtn.name = data.DISPLAY_NAME;
      stateColorObj.lineBtn.colorName = data.NAME;

      newColorStr = data.NAME + "|100";
      stateColorObj.lineBtn.ColorName =
        data.NAME + "|" + getScreenshadeByColorName(data.NAME, this.colorArry);
    } else if (name === "DefaultColor") {
      stateColorObj.bgBtn.color = data.rgba;
      stateColorObj.bgBtn.name = data.DISPLAY_NAME;
      stateColorObj.bgBtn.colorName = data.NAME;
      this.props.setLogoColor(data.NAME);
      const tinDefulatVal = getVlaueFormJsonByKey(
        this.props.jsonData,
        "Default",
        "tint"
      );
      if (!tinDefulatVal) {
        newColorStr = data.NAME + "|100";
      } else {
        newColorStr = data.NAME + "|100";
      }
    }
    if (
      parseInt(colorCode) === 2 &&
      !this.props.LogoType &&
      !this.props.additionalColor
    ) {
      this.logoOneAddZero(stateColorObj, data, name, colorCode);
    } else if (
      parseInt(colorCode) === 2 &&
      (this.props.additionalColor == 1 || this.props.additionalColor == 0) &&
      this.props.LogoType === 1
    ) {
      // console.log("_clone",_.cloneDeep(this.state.colorObj))
      this.logoOnceAddOne(stateColorObj, data, name, colorCode);
    } else if (
      parseInt(colorCode) === 2 &&
      this.props.additionalColor == 2 &&
      (this.props.LogoType === 1 || this.props.LogoType === 2)
    ) {
      this.logoOneAddTwo(stateColorObj, data, name, colorCode);
    } else if (
      parseInt(colorCode) === 2 &&
      this.props.additionalColor == 1 &&
      (this.props.LogoType === 2 || this.props.LogoType === 3)
    ) {
      this.logoOnceAddOne(stateColorObj, data, name, colorCode);
    }
    // console.log("_.cloneDeep(stateColorObj) 1",_.cloneDeep(stateColorObj))

    this.setState({
      textBtnShoWHide: "none",
      lineBtnShoWHide: "none",
      bgBtnShoWHide: "none",
      colorObj: _.cloneDeep(stateColorObj), 
    });

    this.props.onColorChanged(newColorStr, name);

    this.props.onBlurHandaler();
  }

  logoTwoAddTwo = (stateColorObj, data, name, colorCode) => {
    if (name === "PantosColor") {
      if (stateColorObj.lineBtn.name == "" && stateColorObj.bgBtn.name != "") {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "BorderColor");
      } else if (
        stateColorObj.bgBtn.name != "" &&
        stateColorObj.lineBtn.name == ""
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "DefaultColor");
      }
    } else if (name === "BorderColor") {
      if (stateColorObj.textBtn.name == "" && stateColorObj.bgBtn.name != "") {
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          data.NAME +
          "|" +
          getScreenshadeByColorName(data.NAME, this.colorArry);
        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      } else if (
        stateColorObj.textBtn.name != "" &&
        stateColorObj.bgBtn.name == ""
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "DefaultColor");
      }
    } else if (name === "DefaultColor") {
      if (
        stateColorObj.textBtn.name == "" &&
        stateColorObj.lineBtn.name != ""
      ) {
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          data.NAME +
          "|" +
          getScreenshadeByColorName(data.NAME, this.colorArry);
        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      } else if (
        stateColorObj.textBtn.name != "" &&
        stateColorObj.lineBtn.name == ""
      ) {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "BorderColor");
      }
    }
    this.setState({
      colorObj: stateColorObj,
    });
  };

  //logo One additional color two

  logoOneAddTwo = (stateColorObj, data, name, colorCode) => {
    if (name === "PantosColor") {
      if (
        this.state.colorObj.lineBtn.color &&
        data.DISPLAY_NAME != stateColorObj.bgBtn.name &&
        this.checkColorPresent(this.state.colorObj.lineBtn.colorName) ==
          false &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false &&
        this.checkColorPresent(data.NAME) == false
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "DefaultColor");
      }
    } else if (name === "BorderColor") {
      if (
        data.DISPLAY_NAME != stateColorObj.textBtn.name &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false &&
        this.checkColorPresent(this.state.colorObj.textBtn.colorName) ==
          false &&
        this.checkColorPresent(data.NAME) == false
      ) {
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          data.NAME +
          "|" +
          getScreenshadeByColorName(data.NAME, this.colorArry);
        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      }
    } else if (name === "DefaultColor") {
      if (
        this.state.colorObj.textBtn.colorName &&
        data.DISPLAY_NAME != stateColorObj.lineBtn.name &&
        this.checkColorPresent(this.state.colorObj.textBtn.colorName) ==
          false &&
        this.checkColorPresent(this.state.colorObj.lineBtn.colorName) ==
          false &&
        this.checkColorPresent(data.NAME) == false
      ) {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        this.props.onColorChanged(colorName, "BorderColor");
      }
    }
  };

  checkColorPresent = (colorName) => {
    const colorArray = this.props.logoColorsArr;
    if (colorArray && colorArray.length > 0) {
      const colorIndex =
        colorArray && colorArray.findIndex((p) => p.DEFAULT_COLOR == colorName);
      if (colorIndex !== -1) {
        return true;
      }
      return false;
    }
    return false;
  };

  //Logo one addditiona color one

  logoOnceAddOne = (stateColorObj, data, name, colorCode) => {
      
    // console.log("in console",name)
    if (name === "PantosColor") {
      if (
        data.DISPLAY_NAME != stateColorObj.bgBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.bgBtn.colorName = data.NAME;

        this.props.onColorChanged(colorName, "DefaultColor");
      }
      if (
        data.DISPLAY_NAME != stateColorObj.lineBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.lineBtn.colorName) == false
      ) {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.lineBtn.colorName = data.NAME;

        this.props.onColorChanged(colorName, "BorderColor");
      }

      if (
        data.DISPLAY_NAME != stateColorObj.lineBtn.name &&
        this.checkColorPresent(data.NAME) == true &&
        this.checkColorPresent(this.state.colorObj.lineBtn.colorName) == false &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false
      ) {
        
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.lineBtn.colorName = data.NAME;
        this.props.onColorChanged(colorName, "BorderColor");
      }

    } else if (name === "BorderColor") {
      if (
        data.DISPLAY_NAME != stateColorObj.textBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.textBtn.colorName) == false
      ) {
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.colorName = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          data.NAME +
          "|" +
          getScreenshadeByColorName(data.NAME, this.colorArry);
        stateColorObj.textBtn.colorName = data.NAME;

        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      }
      if (
        data.DISPLAY_NAME != stateColorObj.bgBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.bgBtn.colorName = data.NAME;

        this.props.onColorChanged(colorName, "DefaultColor");
      }

      if (
        data.DISPLAY_NAME != stateColorObj.bgBtn.name &&
        this.checkColorPresent(data.NAME) == true &&
        this.checkColorPresent(this.state.colorObj.bgBtn.colorName) == false &&
        this.checkColorPresent(this.state.colorObj.textBtn.colorName) == false
      ) {
        stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.bgBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.bgBtn.colorName = data.NAME;

        this.props.onColorChanged(colorName, "DefaultColor");
      }

    } else if (name === "DefaultColor") {
      if (
        data.DISPLAY_NAME != stateColorObj.textBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.textBtn.colorName) == false
      ) {
        
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          data.NAME +
          "|" +
          getScreenshadeByColorName(data.NAME, this.colorArry);
        stateColorObj.textBtn.colorName = data.NAME;
        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      }

      if (
        data.DISPLAY_NAME != stateColorObj.lineBtn.name &&
        this.checkColorPresent(data.NAME) == false &&
        this.checkColorPresent(this.state.colorObj.lineBtn.colorName) == false
      ) {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = data.NAME + "|100";
        stateColorObj.lineBtn.colorName = data.NAME;

        this.props.onColorChanged(colorName, "BorderColor");
      }
      
    }
    this.setState({
      colorObj: _.cloneDeep(stateColorObj),
    });
  };
  //logo color 1 and addtional color zero
  logoOneAddZero = (
    stateColorObj,
    data,
    name,
    colorCode,
    IsPantonClr = false,
    prevColorObjState=this.state.colorObj
  ) => {
    if (name === "PantosColor") {
      if (
        parseInt(colorCode) === 2 &&
        stateColorObj.textBtn.name !== stateColorObj.bgBtn.name &&
        stateColorObj.bgBtn.name != stateColorObj.lineBtn.name
      ) {
        if (!stateColorObj.bgBtn.color && stateColorObj.lineBtn.color) {
          stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.bgBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          this.props.onColorChanged(colorName, "DefaultColor");
        } else if (stateColorObj.bgBtn.color && !stateColorObj.lineBtn.color) {
          stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.lineBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          // this.setBGDefault();
          this.props.onColorChanged(colorName, "BorderColor");
        } else if (
          prevColorObjState.textBtn.name == stateColorObj.bgBtn.name &&
          (data.DISPLAY_NAME != prevColorObjState.lineBtn.name)
        ) {
          stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.bgBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          this.props.onColorChanged(colorName, "DefaultColor");
        } else if (
          stateColorObj.lineBtn.name == prevColorObjState.textBtn.name &&
          (data.DISPLAY_NAME !=prevColorObjState.bgBtn.name)
        ) {
          stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.lineBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          this.props.onColorChanged(colorName, "BorderColor");
        }
      }
    } else if (name === "BorderColor") {
      if (
        parseInt(colorCode) === 2 &&
        stateColorObj.lineBtn.name !== stateColorObj.bgBtn.name &&
        stateColorObj.bgBtn.name !== stateColorObj.textBtn.name
      ) {
        if (!stateColorObj.bgBtn.color && stateColorObj.textBtn.color) {
          stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.bgBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          this.props.onColorChanged(colorName, "DefaultColor");
        } else if (stateColorObj.bgBtn.color && !stateColorObj.textBtn.color) {
          stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.textBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName =
            (data.NAME || data.name) +
            "|" +
            getScreenshadeByColorName((data.NAME || data.name), this.colorArry);
          this.setBGDefault();
          this.props.onColorChanged(colorName, "PantosColor");
        } else if (
          prevColorObjState.lineBtn.name == stateColorObj.textBtn.name &&
          data.DISPLAY_NAME != prevColorObjState.bgBtn.name
        ) {
          stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.textBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName =
          (data.NAME || data.name) +
            "|" +
            getScreenshadeByColorName((data.NAME || data.name), this.colorArry);
          this.setBGDefault();
          this.props.onColorChanged(colorName, "PantosColor");
        } else if (
          stateColorObj.bgBtn.name == prevColorObjState.lineBtn.name &&
          data.DISPLAY_NAME != prevColorObjState.textBtn.name
        ) {
          stateColorObj.bgBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.bgBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME || data.name) + "|100";
          this.props.onColorChanged(colorName, "DefaultColor");
        }
      }
    } else if (name === "DefaultColor") {
      if (
        parseInt(colorCode) === 2 &&
        stateColorObj.bgBtn.name !== stateColorObj.lineBtn.name &&
        stateColorObj.bgBtn.name !== stateColorObj.textBtn.name
      ) {
        if (!stateColorObj.textBtn.color && stateColorObj.lineBtn.color) {
          stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.textBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName =
            (data.NAME || data.name) +
            "|" +
            getScreenshadeByColorName((data.NAME || data.name), this.colorArry);
          this.setBGDefault();
          this.props.onColorChanged(colorName, "PantosColor");
        } else if (
          stateColorObj.textBtn.color &&
          !stateColorObj.lineBtn.color
        ) {
          stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
          stateColorObj.lineBtn.name = data.DISPLAY_NAME
            ? data.DISPLAY_NAME
            : data.name;
          const colorName = (data.NAME||data.name) + "|100";
          // this.setBGDefault();
          this.props.onColorChanged(colorName, "BorderColor");
        }
      }
      if (
        stateColorObj.textBtn.color &&
        prevColorObjState.bgBtn.name == stateColorObj.textBtn.name &&
        data.DISPLAY_NAME != prevColorObjState.lineBtn.name
      ) {
        stateColorObj.textBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.textBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName =
          (data.NAME || data.name) +
          "|" +
          getScreenshadeByColorName((data.NAME || data.name), this.colorArry);
        this.setBGDefault();
        this.props.onColorChanged(colorName, "PantosColor");
      } else if (
        stateColorObj.lineBtn.color &&
        stateColorObj.lineBtn.name == prevColorObjState.bgBtn.name &&
        data.DISPLAY_NAME != prevColorObjState.textBtn.name
      ) {
        stateColorObj.lineBtn.color = data.rgba ? data.rgba : data.hex;
        stateColorObj.lineBtn.name = data.DISPLAY_NAME
          ? data.DISPLAY_NAME
          : data.name;
        const colorName = (data.NAME||data.name) + "|100";
        this.props.onColorChanged(colorName, "BorderColor");
      }
    }
    this.setState({
      colorObj: stateColorObj,
    });
  };

  setBGDefault = () => {
    if (typeof this.state.selectedImageBG.FILE !== "undefined") {
      this.props.onBGChanged(this.state.selectedImageBG, true);
    } else {
      const bgObj = getBgDefault(this.props.configData.BACKGROUNDS);

      if (bgObj !== -1) {
        this.props.onBGChanged(bgObj, true);
      }
    }
  };
  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  mouseEnterHandler
   * @desc   :  This function called After hovering mouse on colors it will display the color name and background of color
   * @return -
   */

  mouseEnterHandler = (event, data, element) => {
    document.getElementById(element + "_elements_name").innerHTML =
      data.DISPLAY_NAME;
    document.getElementById(element + "_elements_color").style.background =
      data.rgba;
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  mouseLeaveHandler
   * @desc   :  This function called After mouse leaves on colors it will reset the color name and background of color
   * @return -
   */

  mouseLeaveHandler = (event, data, element) => {
    if (data.name) {
      document.getElementById(element + "_elements_name").innerHTML = data.name;
      document.getElementById(element + "_elements_color").style.background =
        data.color;
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  togglePopup
   * @desc   :  This function open or close the background list popup according to flag
   * @return -
   */

  togglePopup = (flag) => {
    this.setState({ isBgPopupOpen: flag, isSelected: false });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  imageSelected
   * @desc   :  This function set the local state after selecting image
   * @return -
   */

  imageSelected = (data) => {
    this.setState({ selectedImageBG: data, isSelected: true });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setTheBG
   * @desc   :  This function set the redux state background image and give the api call to reload the image
   * @return -
   */

  setTheBG = () => {
    if (this.state.selectedImageBG.PREVIEW && this.state.isSelected) {
      const newColorStr = this.props.DefaultColor[0].value || "";
      this.props.onColorChanged(newColorStr, "PantosColor");
      this.props.onBGChanged(this.state.selectedImageBG, true);
      this.props.onBlurHandaler();
    }
    this.setState({ isBgPopupOpen: false, isSelected: false });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  faceUpDown
   * @desc   :  This is called when the facup/down value changed
   * @return -
   */

  faceUpDown = (event) => {
    const jsonParse = this.props.JSONINFO && this.props.JSONINFO[0] && JSON.parse(this.props.JSONINFO[0].value);
    const NewJSONINFO = { ...jsonParse };
    
    NewJSONINFO.faceUp = event.target.value;
    this.setState({
      faceUpDown : event.target.value
    })
    
    NewJSONINFO.jobRun = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Quantity",
      "cdata"
    );
    const checkNumberIndex = this.props.checkInfo.findIndex(
      (p) => p.attributes.NAME === "FXS_CheckNumber1"
    );
    if (checkNumberIndex !== -1) {
      NewJSONINFO.startingNumber = this.props.checkInfo[checkNumberIndex].value;
      NewJSONINFO.endingNumber =(Number(this.props.checkInfo[checkNumberIndex].value) +Number(NewJSONINFO.jobRun))-1;
    }
    
    console.log("NewJSONINFO",NewJSONINFO)
    this.props.setNewJSONInfo(JSON.stringify(NewJSONINFO), true);
    this.props.onBlurHandaler(false)

    /**
     * Sarita
     * #5279 Code Change-Update faceUpDown value to server on change
     */
    let newSignOverInfo = [];
    if (this.props.tempArr.signOverLine != "none") {
      newSignOverInfo = [...this.props.tempArr.companySignOverInfo];
    }

    this.props.tempArr.JSONINFO[0].value=JSON.stringify(NewJSONINFO)
    this.props.tempArr.JSONINFO[0].isInputChanged=true
    const newArrState = [
      ...(this.props.tempArr.companyInfo||[]),
      ...newSignOverInfo,
      ...(this.props.tempArr.stubData||[]),
      ...(this.props.tempArr.compnaySignInfo||[]),
      ...(this.props.tempArr.bankInfo||[]),
      ...(this.props.tempArr.fractionalInfo||[]),
      ...(this.props.tempArr.checkInfo||[]),
      ...(this.props.tempArr.RoutingValue||[]),
      ...(this.props.tempArr.accountValue||[]),
      ...(this.props.tempArr.DefaultColor||[]),
      ...(this.props.tempArr.PantosColor||[]),
      ...(this.props.tempArr.BorderColor||[]),
      ...(this.props.tempArr.signLine||[]),
      ...(this.props.tempArr.backgroundImage||[]),
      ...(this.props.tempArr.mainLogo||[]),
      ...(this.props.tempArr.phantomLogo||[]),
      ...(this.props.tempArr.genData||[]),
      ...(this.props.tempArr.JSONINFO),
      ...(this.props.tempArr.imageType||[]),
    ];

   this.props.fetchPreviewData(newArrState, "focusout_json", "json",true,false,true);

  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  toggleColorPopup
   * @desc   :  This function open or close the color  popup according to flag
   * @return -
   */

  toggleColorPopup = async (flag, name) => {
    if (flag === true) {
      this.setState({
        pColorType: name,
      });
    } else {
      this.setState({
        pColorType: name,
      });
    }
    this.setState({ isColorPopupOpen: flag });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  filterColorArrayFn
   * @desc   :  This is called on change the color text from color popup
   * @return -
   */

  filterColorArrayFn = (event) => {
    let filterArr = [];
    const tempColorArray = [...this.state.colorArray];
    filterArr = tempColorArray.filter((element) => {
      return element.attributes.name
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    this.setState({ isOpenDropdown: true });
    this.setState({
      filterColorArray: filterArr,
      pantoneColors: event.target.value,
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setPantoneColor
   * @desc   :  This is called whenever user select the color from dropdown
   * @return -
   */

  setPantoneColor = (colordata) => {
    const colorObjPre=_.cloneDeep(this.state.colorObj)
    this.setState({
      isOpenDropdown: false,
      pantoneColorName: colordata,
      pantoneColors: colordata.name,
      updateColorObj:colorObjPre
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  applyPantoneColor
   * @desc   :  This is called whenever user click on place the color button and reload the image with new color
   * @return -
   */

  applyPantoneColor = () => {
    const stateColorObj = { ...this.state.colorObj };
    let newColorStr = this.state.pantoneColorName.name;
    const colorCode = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Colors",
      "cdata"
    );
    
    if (this.state.pColorType === "DefaultColor") {
      stateColorObj.bgBtn.color = this.state.pantoneColorName.hex;
      stateColorObj.bgBtn.name = this.state.pantoneColorName.name;
    } else if (this.state.pColorType === "BorderColor") {
      stateColorObj.lineBtn.color = this.state.pantoneColorName.hex;
      stateColorObj.lineBtn.name = this.state.pantoneColorName.name;
    } else if (this.state.pColorType === "PantosColor") {
      stateColorObj.textBtn.color = this.state.pantoneColorName.hex;
      newColorStr =
        this.state.pantoneColorName.name + "|" + DEFAULT_SCREEN_SHADE;

      stateColorObj.textBtn.name = this.state.pantoneColorName.name;
      if (typeof this.state.selectedImageBG.FILE !== "undefined") {
        this.props.onBGChanged(this.state.selectedImageBG, true);
      }
    }

    // #5464 - 1 color Premium Job when apply custom color all 3 layers color should update in GE
    if (parseInt(colorCode) === 1 &&
    !this.state.SKUvalues.startsWith("FV") &&
    !this.state.SKUvalues.startsWith("FS")){
      stateColorObj.lineBtn.color=this.state.pantoneColorName.hex;
      stateColorObj.lineBtn.name = this.state.pantoneColorName.name;
      stateColorObj.textBtn.color=this.state.pantoneColorName.hex;
      stateColorObj.textBtn.name = this.state.pantoneColorName.name;
      if (typeof this.state.selectedImageBG.FILE !== "undefined") {
        this.props.onBGChanged(this.state.selectedImageBG, true);
      }
    }

    if (
      parseInt(colorCode) === 2 &&
      !this.props.LogoType &&
      !this.props.additionalColor
    ) {
      this.logoOneAddZero(
        stateColorObj,
        this.state.pantoneColorName,
        this.state.pColorType,
        colorCode,
        true,
        this.state.updateColorObj
      );
    } else if (
      parseInt(colorCode) === 2 &&
      (this.props.additionalColor == 1 || this.props.additionalColor == 0) &&
      this.props.LogoType === 1
    ) {
      this.logoOnceAddOne(
        stateColorObj,
        this.state.pantoneColorName,
        this.state.pColorType,
        colorCode,
        true
      );
    } else if (
      parseInt(colorCode) === 2 &&
      this.props.additionalColor == 2 &&
      this.props.LogoType === 1 &&
      this.props.LogoType === 2
    ) {
      this.logoOneAddTwo(
        stateColorObj,
        this.state.pantoneColorName,
        this.state.pColorType,
        colorCode,
        true
      );
    } else if (
      parseInt(colorCode) === 2 &&
      this.props.additionalColor == 1 &&
      this.props.LogoType === 2
    ) {
      this.logoOnceAddOne(
        stateColorObj,
        this.state.pantoneColorName,
        this.state.pColorType,
        colorCode,
        true
      );
    }
  
    
    // #5464 - 1 color Premium Job when apply custom color all 3 layers color should update in GE
    if (parseInt(colorCode) === 1 &&
    !this.state.SKUvalues.startsWith("FV") &&
    !this.state.SKUvalues.startsWith("FS")){
      this.props.onColorChanged(newColorStr, 'BorderColor');
      this.props.onColorChanged(this.state.pantoneColorName.name + "|" + DEFAULT_SCREEN_SHADE, 'PantosColor');
      // #5464 - 1 color Premium Job when apply custom color logo color should also change.
      this.props.setLogoColor(newColorStr);
      if (this.props.mainLogo && this.props.mainLogo.length > 0) {
        this.props.onLogoChanged(
          this.props.mainLogo[0].value,
          this.props.mainLogoName,
          true
        );
      }
    }
      this.setState({
        isColorPopupOpen: false,
       colorObj: _.cloneDeep(stateColorObj)
      });
      this.props.onColorChanged(newColorStr, this.state.pColorType);
      this.props.onBlurHandaler();
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  clickChooseColorEvent
   * @desc   :  This function is used to set the background color to button and set api call after selecting color
   * @return -
   */

  clickChooseColorEvent(event, data) {
    const stateColorObj = { ...this.state.colorObj };
    let newColorStr = data.NAME + "|" + data.SCREEN_SHADE;

    this.props.setLogoColor(data.NAME);

    stateColorObj.textBtn.color = data.rgba;
    stateColorObj.textBtn.name = data.DISPLAY_NAME;
    if (data.SCREEN_SHADE === "") {
      newColorStr = data.NAME + "| ";
    }
    if (this.props.mainLogo && this.props.mainLogo.length > 0) {
      this.props.onLogoChanged(
        this.props.mainLogo[0].value,
        this.props.mainLogoName,
        true
      );
    }
    this.setBGDefault();
    this.props.onColorChanged(newColorStr, "PantosColor");

    stateColorObj.lineBtn.color = data.rgba;
    stateColorObj.lineBtn.name = data.DISPLAY_NAME;
    const tinLineVal = getVlaueFormJsonByKey(
      this.props.jsonData,
      "Borders, Lines",
      "tint"
    );
    if (!tinLineVal) {
      newColorStr = data.NAME + "|8";
    } else {
      newColorStr = data.NAME + "|" + tinLineVal;
    }
    this.props.onColorChanged(newColorStr, "BorderColor");

    stateColorObj.bgBtn.color = data.rgba;
    stateColorObj.bgBtn.name = data.DISPLAY_NAME;
    const tinDefulatVal = getVlaueFormJsonByKey(
      this.props.jsonData,
      "Default",
      "tint"
    );
    if (!tinDefulatVal) {
      newColorStr = data.NAME + "|-1";
    } else {
      //newColorStr = data.NAME + "|" + tinDefulatVal;
      // #6533-Default layer getting screened , Default color layer should be set to 100 for tintValue
      newColorStr = data.NAME + "|100";
    }
    this.props.onColorChanged(newColorStr, "DefaultColor");

    this.setState({
      textBtnShoWHide: "none",
      lineBtnShoWHide: "none",
      bgBtnShoWHide: "none",
      colorObj: stateColorObj,
    });

    this.props.onBlurHandaler();
  }

  componentDidUpdate = (nextProps) => {
    const isPhantomLogoExists=this.props.phantomLogo.findIndex(item=>item.value !=='None')

    if (
      nextProps.additionalColor &&
      parseInt(nextProps.additionalColor) === 0 &&
      this.props.LogoType === 2 && ((this.props.mainLogo && this.props.mainLogo.length>0 && this.props.mainLogo[0].value=='None')|| isPhantomLogoExists==-1)
    ) {
      const tempArr = [];
      if (this.props.logoColorsArr && this.props.logoColorsArr.length > 0) {
        this.colorArry.forEach((p) => {
          if (
            p.NAME === this.props.logoColorsArr[0].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[1].DEFAULT_COLOR
          ) {
            tempArr.push(p);
          }
        });

        this.colorArry = tempArr;
      }
    } else if (
      (nextProps.additionalColor &&
        parseInt(nextProps.additionalColor) === 0 &&
        this.props.LogoType === 3 && ((this.props.mainLogo && this.props.mainLogo.length>0 && this.props.mainLogo[0].value=='None')|| isPhantomLogoExists==-1)) ||
      this.props.LogoType === 4 
    ) {
      let tempArr = [];
      if (this.props.logoColorsArr && this.props.logoColorsArr.length > 0) {
        this.colorArry.forEach((p) => {
          if (
            p.NAME === this.props.logoColorsArr[0].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[1].DEFAULT_COLOR
          ) {
            tempArr.push(p);
          }
        });

        if (tempArr.length < 4 && this.props.LogoType === 4) {
          tempArr = this.getRestrictedPantoneColor();
        } else if (tempArr.length < 3 && this.props.LogoType === 3) {
          tempArr = this.getRestrictedPantoneColor();
        }

        this.colorArry = tempArr;
      }
    } else if (
      this.state.colorArryCopy &&
      this.state.colorArryCopy.length > 0
    ) {
      if (this.state.SKUvalues && this.state.SKUvalues.startsWith("FP")) {
        const tempmColorArr = [];
        this.state.colorArryCopy.forEach((p) => {
          if (p.SCREEN_SHADE != "") {
            tempmColorArr.push(p);
          }
        });
        this.colorArry = tempmColorArr;
      }
    }
    
    // if (this.props.logoColorsArr && this.props.logoColorsArr.length > 0) {
    //   const pantoneTileColor =
    //     this.props.pantoneColorArr &&
    //     getPantoneTile(this.props.pantoneColorArr);
    //   this.props.logoColorsArr.forEach((element) => {
    //     const findIndexBr = getValueArrayIndex(
    //       this.colorArry,
    //       "NAME",
    //       getColorNameString(
    //         this.colorArry,
    //         pantoneTileColor,
    //         element.DEFAULT_COLOR
    //       )
    //     );
    //     if (findIndexBr === -1) {
    //       const pIndex = pantoneTileColor && pantoneTileColor.findIndex(
    //         (p) =>
    //           p.NAME ===
    //           getColorNameString(
    //             this.colorArry,
    //             pantoneTileColor,
    //             element.DEFAULT_COLOR
    //           )
    //       );
    //       if (
    //         pIndex !== -1 &&
    //         this.state.colorCode &&
    //         parseInt(this.state.colorCode) > 0 && pantoneTileColor
    //       ) {
    //         this.colorArry.push(pantoneTileColor[pIndex]);
    //       }
    //     }
    //   });
    // }
    //5290 restrict colors when reached more than count
    const isPhantomLogo=this.props.phantomLogo.filter(item=>item.value !=='None')
    if(this.state.colorCode==2 && ((this.props.mainLogo && this.props.mainLogo.length>0 && this.props.mainLogo[0].value!='None') || isPhantomLogoExists!=-1)){
      const logoColorsData=[]
      const mainLogoDetails=getLogoDetailsByFolder(this.props.logoFiles,this.props.mainLogo[0].value)
      const color=getLogoColor(mainLogoDetails)
      let phantomLogoColorCount=0
      logoColorsData.push(...color)
      isPhantomLogo.forEach((item)=>{
        const phantomLogoDetails=getLogoDetailsByFolder(this.props.logoFiles,item.value)
        phantomLogoColorCount=Number(phantomLogoColorCount)+Number(phantomLogoDetails.ADDITIONAL_COLORS)
        const color=getLogoColor(phantomLogoDetails)
          logoColorsData.push(...color)
      })
      let result = this.colorArryTemp.filter(o1 => logoColorsData.some(o2 => (o1.NAME == o2.DEFAULT_COLOR || o1.DISPLAY_NAME.toLowerCase()==o2.DEFAULT_COLOR.toLowerCase().slice(0, -1).trimEnd())))
      const totalAdditionCount=Number(mainLogoDetails.ADDITIONAL_COLORS || 0)+Number(phantomLogoColorCount)
      if(result.length>0){
      if(result.length>1 && totalAdditionCount==0){
       this.colorArry = result;
      } else if(totalAdditionCount>0 && ((this.props.mainLogo && this.props.mainLogo.length>0 && this.props.mainLogo[0].value!='None') && isPhantomLogoExists!=-1) && result.length<(Number(this.state.colorCode)+Number(totalAdditionCount))){
     //  this.colorArry = result;
       this.colorArry=this.colorArryTemp
      } else if(totalAdditionCount+Number(this.state.colorCode)>result.length){
        this.colorArry=this.colorArryTemp
      } else {
        this.colorArry = result;
      }
    }
    }
    const colorObjTemp = _.cloneDeep(this.state.colorObj);
    const DefaultColor = nextProps.DefaultColor;
    const BorderColor = nextProps.BorderColor;
    const PantosColor = nextProps.PantosColor;
    let colorChanaged = false;
  
  };

  getRestrictedPantoneColor = () => {
    let tempArr = [];
    const pantonetile = getPantoneTile(this.pantoneColorArr);
    pantonetile &&
      pantonetile.length > 0 &&
      pantonetile.forEach((p) => {
        if (this.props.LogoType === 4) {
          if (
            p.NAME === this.props.logoColorsArr[0].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[1].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[2].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[3].DEFAULT_COLOR
          ) {
            tempArr.push(p);
          }
        } else if (this.props.LogoType === 3) {
          if (
            p.NAME === this.props.logoColorsArr[0].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[1].DEFAULT_COLOR ||
            p.NAME === this.props.logoColorsArr[2].DEFAULT_COLOR
          ) {
            tempArr.push(p);
          }
        }
      });
    return tempArr;
  };

  //Logo funcitons

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  isEnabledLogoOption
   * @desc   :  This function set the logo slider on off
   * @return -
   */

  isEnabledLogoOption = (event) => {
    if (event.target.checked) {
      this.props.onLogoFlagChanged(true);
    } else {
      this.props.onLogoFlagChanged(false);

      let onBlurCall = false;
      if (this.props.mainLogoName && this.props.mainLogoName.trim() != "") {
        this.props.onLogoChanged("None", "", true);
        onBlurCall = true;
      }

      let setPathomNone = false;
      const tempPhantomArr = [...this.props.phantomLogo];
      tempPhantomArr.forEach((element) => {
        if (
          checkInputIsInJsonData(
            this.props.jsonData,
            element.attributes.NAME
          ) &&
          element.value &&
          element.phatomLogoName
        ) {
          setPathomNone = true;
          this.props.onPhantomLogoChanged("None", "", element.attributes.NAME);
        }
      });
      if (setPathomNone) {
        onBlurCall = true;
      }
      if (onBlurCall) {
        this.props.onBlurHandaler();
      }
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  togglePopup
   * @desc   :  This function open or close the background list popup according to flag
   * @return -
   */

  togglePopupLogo = (flag, imageTyle, fieldName, currentImage) => {
    this.setState({
      isLogoPopupOpen: flag,
      isSelected: false,
      imageTyle: imageTyle,
      fieldName: fieldName,
      phantomLogo_image_name_selected: currentImage,
    });
    if (document.getElementById("customerFile")) {
      document.getElementById("customerFile").value = "";
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  imageSelected
   * @desc   :  This function set the local state after selecting image
   * @return -
   */

  imageLogoSelected = (data, imgType, logoFrom) => {
    if (this.state.imageTyle === "main_logo") {
      this.setState({
        selectedImage: data,
        isLogoSelected: true,
        mainLogo_image_name: data.DISPLAY_NAME,
        logoFrom: logoFrom,
      });
    } else {
      this.setState({
        selectedImage: data,
        isLogoSelected: true,
        phantomLogo_image_name: data.DISPLAY_NAME,
        logoFrom: logoFrom,
        phantomLogo_image_name_selected: data.DISPLAY_NAME,
      });
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setTheLogo
   * @desc   :  This function set the redux state logo image and give the api call to reload the image
   * @return -
   */

  setTheLogo = () => {
    if (
      typeof this.state.selectedImage !== "undefined" &&
      this.state.selectedImage.PREVIEW_FILE &&
      this.state.isLogoSelected
    ) {
      let DefaultMainLogo=[{
        attributes:{NAME:"FXS_Logo1"},
        isInputChanged: true,
        mainLogoName:'',
        value:'None'
      }]
       this.setState({selectedLogo:this.state.selectedImage})
      let finalMainLogoPath = this.state.logoFrom;
      if (this.state.logoFrom === "Customer_Logos") {
        finalMainLogoPath += "/" + this.state.imageFolder;
      }
      finalMainLogoPath += "/" + this.state.selectedImage.subFolder;
      finalMainLogoPath += "/" + this.state.selectedImage.XML_FILE;
      const colorArr = mergeTwoObjectColors(
        this.props.configData.COLORSETS.DEFAULT,
        this.props.configData.COLORSETS.DEFAULT.ADMIN
      );

      if (this.state.imageTyle === "main_logo") {
        this.props.onLogoChanged(
          finalMainLogoPath,
          this.state.mainLogo_image_name,
          true
        );
        DefaultMainLogo=[{
            attributes:{NAME:"FXS_Logo1"},
            isInputChanged: true,
            mainLogoName:this.state.mainLogo_image_name,
            value:finalMainLogoPath
        }]
        let logoPos = "Side";
        if (this.state.selectedImage.HORIZ == 1) {
          logoPos = "Top";
          const titleLines = [...this.props.companyInfo];
          titleLines.forEach((element) => {
            if (element.value && element.attributes.NAME !== "FXS_Title1") {
              if (!element.styleObj || element.styleObj == "undefined") {
                element.styleObj = {};
                element.styleObj.textAlign = "center";
              } else {
                element.styleObj.textAlign = "center";
              }
              element.isInputChanged = true;
            }
          });
          this.props.setCompanyInfoState(titleLines);
        } else {
          const titleLines = [...this.props.companyInfo];
          titleLines.forEach((element) => {
            if (!element.styleObj || element.styleObj == "undefined") {
              element.styleObj = {};
              element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
            } else {
              element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
            }
            element.isInputChanged = true;
          });
          this.props.setCompanyInfoState(titleLines);
        }

        this.props.setImageType(logoPos, true);
        this.props.setCustomerLogoFlag(false);
      } else {
        if (this.props.mainLogo && this.props.mainLogo.length > 0) {
          this.props.onLogoChanged(
            this.props.mainLogo[0].value,
            this.props.mainLogoName,
            true
          );
          this.props.setCustomerLogoFlag(false);
        }
        this.props.onPhantomLogoChanged(
          finalMainLogoPath,
          this.state.phantomLogo_image_name,
          this.state.fieldName
        );
      }

      const data = this.state.selectedImage;
      const logoType = getLogoType(data);
      this.props.setLogoType(logoType);

      const logoDefaultColor = {};
      if (
        parseInt(this.state.colorCode) === 1 &&
        data.FXS_COMPONENT &&
        data.FXS_COMPONENT.length > 0 &&
        data.COLORS[0].COLOR &&
        data.COLORS[0].COLOR.length > 0 &&
        logoType === 1
      ) {
        for (const key in data.FXS_COMPONENT) {
          if (data.FXS_COMPONENT.hasOwnProperty(key)) {
            if (data.FXS_COMPONENT[key].DEFAULT_COLOR) {
              logoDefaultColor.DEFAULT_COLOR =
                data.FXS_COMPONENT[key].DEFAULT_COLOR[0];
              logoDefaultColor.DEFAULT_SHADE =
                data.FXS_COMPONENT[key].DEFAULT_SHADE[0];
              logoDefaultColor.PHANTOM_SHADE =
                data.FXS_COMPONENT[key].PHANTOM_SHADE[0];
            }
          }
        }

        const defColor = logoDefaultColor.DEFAULT_COLOR + "| 100";
        const tinValue = getScreenshadeByColorName(
          logoDefaultColor.DEFAULT_COLOR,
          colorArr
        );
        const phColor = logoDefaultColor.DEFAULT_COLOR + "|" + tinValue;
        this.props.onColorChanged(defColor, "DefaultColor");
        this.props.onColorChanged(defColor, "BorderColor");
        this.props.onColorChanged(phColor, "PantosColor");
        this.props.setLogoColor(logoDefaultColor.DEFAULT_COLOR);
      }

      if (this.props.bgImage && this.props.bgImage.length > 0) {
        const bgData = {
          FILE: this.props.bgImage[0].value,
          PREVIEW: this.props.bgPreview,
          NAME: this.props.bgPreviewName,
        };
        this.props.onBGChanged(bgData, true);
      } else {
        const bgObj = getBgDefault(this.props.configData.BACKGROUNDS);

        if (bgObj !== -1) {
          this.props.onBGChanged(bgObj, true);
        }
      }

      this.props.setAdditionalColor(data.ADDITIONAL_COLORS);

      if (
        (parseInt(this.state.colorCode) === 2  || parseInt(this.state.colorCode) === 3 || parseInt(this.state.colorCode) === 4) &&
        data.FXS_COMPONENT &&
        data.FXS_COMPONENT.length > 0 &&
        data.COLORS[0].COLOR &&
        data.COLORS[0].COLOR.length > 0
      ) {
        const logoColor = getLogoColor(data);
        this.props.setLogoColorsArr(logoColor);
        if (
          logoType === 1 &&
          (parseInt(data.ADDITIONAL_COLORS) === 0 ||
            parseInt(data.ADDITIONAL_COLORS) === 1 ||
            parseInt(data.ADDITIONAL_COLORS) === 2)
        ) {
          if (logoColor.length > 0) {
            const colorObj = { ...this.state.colorObj };
            const colorNameStr = getColorNameString(
              this.colorArry,
              this.state.getPantoneTile,
              logoColor[0].DEFAULT_COLOR
            );
            let findColorIndex = this.colorArry.findIndex(
              (p) => p.NAME == colorNameStr
            );
            if (findColorIndex !== -1) {
              colorObj.bgBtn.color = this.colorArry[findColorIndex].rgba;
              colorObj.bgBtn.name = this.colorArry[findColorIndex].DISPLAY_NAME;

              colorObj.bgBtn.colorName = this.colorArry[findColorIndex].NAME;

              colorObj.lineBtn.color = this.colorArry[findColorIndex].rgba;
              colorObj.lineBtn.name = this.colorArry[
                findColorIndex
              ].DISPLAY_NAME;

              colorObj.lineBtn.colorName = this.colorArry[findColorIndex].NAME;

              colorObj.textBtn.color = this.colorArry[findColorIndex].rgba;
              colorObj.textBtn.name = this.colorArry[
                findColorIndex
              ].DISPLAY_NAME;

              colorObj.textBtn.colorName = this.colorArry[findColorIndex].NAME;

              this.setState({
                colorObj: colorObj,
              });
            } else {
              let findColorPantonIndex = this.state.getPantoneTile.findIndex(
                (p) => p.NAME == colorNameStr
              );
              if (findColorPantonIndex != -1) {
                colorObj.bgBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex
                ].rgba;
                colorObj.bgBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex
                ].DISPLAY_NAME;

                colorObj.bgBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex
                ].NAME;

                colorObj.lineBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex
                ].rgba;
                colorObj.lineBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex
                ].DISPLAY_NAME;

                colorObj.lineBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex
                ].NAME;

                colorObj.textBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex
                ].rgba;
                colorObj.textBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex
                ].DISPLAY_NAME;

                colorObj.textBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex
                ].NAME;

                this.setState({
                  colorObj: colorObj,
                });
              }
            }
            this.props.setLogoColor(colorNameStr);
            const defaultColor = colorNameStr + "| 100";
            const tinValue = getScreenshadeByColorName(colorNameStr, colorArr);
            const phColor = colorNameStr + "|" + tinValue;
            const isPhantomLogoExists=this.props.phantomLogo.findIndex(item=>item.value !=='None')
            const isPhantomLogoArr=this.props.phantomLogo.filter(item=>item.value !=='None')

         // if(((this.props.mainLogo.length==0 || this.props.mainLogo && this.props.mainLogo[0].value==='None') && isPhantomLogoExists==-1) || (isPhantomLogoExists!=-1 && isPhantomLogoArr.length==1 && this.props.mainLogo[0].value==='None' && DefaultMainLogo[0].value=='None')){
              this.props.onColorChanged(defaultColor, "DefaultColor");
              this.props.onColorChanged(defaultColor, "BorderColor");
              this.props.onColorChanged(phColor, "PantosColor");
        //  }
          }
        } else if (
          logoType === 2 &&
          (parseInt(data.ADDITIONAL_COLORS) === 0 ||
            parseInt(data.ADDITIONAL_COLORS) === 1|| parseInt(data.ADDITIONAL_COLORS) === 2)
        ) {
          if (logoColor.length > 0) {
            const colorObj = { ...this.state.colorObj };
            const colorNo1 = getColorNameString(
              this.colorArry,
              this.state.getPantoneTile,
              logoColor[0].DEFAULT_COLOR
            );
            const defaultColor =
              getColorNameString(
                this.colorArry,
                this.state.getPantoneTile,
                logoColor[0].DEFAULT_COLOR
              ) + "| 100";
            let findColorIndex1 = this.colorArry.findIndex(
              (p) => p.NAME == colorNo1
            );
            if (findColorIndex1 !== -1) {
              colorObj.bgBtn.color = this.colorArry[findColorIndex1].rgba;
              colorObj.bgBtn.name = this.colorArry[
                findColorIndex1
              ].DISPLAY_NAME;

              colorObj.bgBtn.colorName = this.colorArry[findColorIndex1].NAME;

              colorObj.lineBtn.color = this.colorArry[findColorIndex1].rgba;
              colorObj.lineBtn.name = this.colorArry[
                findColorIndex1
              ].DISPLAY_NAME;

              colorObj.lineBtn.colorName = this.colorArry[findColorIndex1].NAME;

              this.setState({
                colorObj: colorObj,
              });
            } else {
              let findColorPantonIndex1 = this.state.getPantoneTile.findIndex(
                (p) => p.NAME == colorNo1
              );
              if (findColorPantonIndex1 != -1) {
                colorObj.bgBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].rgba;
                colorObj.bgBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].DISPLAY_NAME;

                colorObj.bgBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].NAME;

                colorObj.lineBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].rgba;
                colorObj.lineBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].DISPLAY_NAME;

                colorObj.lineBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].NAME;

                this.setState({
                  colorObj: colorObj,
                });
              }
            }
            const colorNo2 = getColorNameString(
              this.colorArry,
              this.state.getPantoneTile,
              logoColor[1].DEFAULT_COLOR
            );
            const tinValue = getScreenshadeByColorName(colorNo2, colorArr);

            const phColor = colorNo2 + "|" + tinValue;

            let findColorIndex = this.colorArry.findIndex(
              (p) => p.NAME == colorNo2
            );
            if (findColorIndex !== -1) {
              colorObj.textBtn.color = this.colorArry[findColorIndex].rgba;
              colorObj.textBtn.name = this.colorArry[
                findColorIndex
              ].DISPLAY_NAME;

              colorObj.textBtn.colorName = this.colorArry[findColorIndex].NAME;

              this.setState({
                colorObj: colorObj,
              });
            } else {
              let findColorPantonIndex = this.state.getPantoneTile.findIndex(
                (p) => p.NAME == colorNo2
              );
              if (findColorPantonIndex != -1) {
                colorObj.textBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex
                ].rgba;
                colorObj.textBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex
                ].DISPLAY_NAME;

                colorObj.textBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex
                ].NAME;

                this.setState({
                  colorObj: colorObj,
                });
              }
            }
            const isPhantomLogoExists=this.props.phantomLogo.findIndex(item=>item.value !=='None')
            
          // if(((this.props.mainLogo.length==0 || this.props.mainLogo && this.props.mainLogo[0].value==='None') && isPhantomLogoExists==-1) || (isPhantomLogoExists!=-1 && this.props.mainLogo[0].value==='None' && DefaultMainLogo[0].value=='None')){
              this.props.onColorChanged(defaultColor, "DefaultColor");
              this.props.onColorChanged(defaultColor, "BorderColor");
              this.props.onColorChanged(phColor, "PantosColor");
         // }
          }
        } else if (logoType === 2 && parseInt(data.ADDITIONAL_COLORS) === 2) {
          if (this.props.DefaultColor && this.props.DefaultColor.length > 0) {
            const splitArr = this.props.DefaultColor[0].value.split("|");

            if (splitArr && splitArr[1] && splitArr[1] == 100) {
              this.setBGDefault();
              this.props.onColorChanged(
                getColorNameString(
                  this.colorArry,
                  this.state.getPantoneTile,
                  splitArr[0]
                ) +
                  "|" +
                  DEFAULT_SCREEN_SHADE,
                "PantosColor"
              );
            } else {
              this.setBGDefault();
              this.props.onColorChanged(
                this.props.DefaultColor[0].value,
                "PantosColor"
              );
            }
          }
        } else if (logoType === 4 || logoType === 3) {
          if (logoColor.length > 0) {
            const colorObj = { ...this.state.colorObj };
            const colorNo1 = getColorNameString(
              this.colorArry,
              this.state.getPantoneTile,
              logoColor[0].DEFAULT_COLOR
            );
            const defaultColor =
              getColorNameString(
                this.colorArry,
                this.state.getPantoneTile,
                logoColor[0].DEFAULT_COLOR
              ) + "| 100";
            let findColorIndex1 = this.colorArry.findIndex(
              (p) => p.NAME == colorNo1
            );
            if (findColorIndex1 !== -1) {
              colorObj.bgBtn.color = this.colorArry[findColorIndex1].rgba;
              colorObj.bgBtn.name = this.colorArry[
                findColorIndex1
              ].DISPLAY_NAME;

              colorObj.bgBtn.colorName = this.colorArry[findColorIndex1].NAME;

              colorObj.lineBtn.color = this.colorArry[findColorIndex1].rgba;
              colorObj.lineBtn.name = this.colorArry[
                findColorIndex1
              ].DISPLAY_NAME;

              colorObj.lineBtn.colorName = this.colorArry[findColorIndex1].NAME;

              this.setState({
                colorObj: colorObj,
              });
            } else {
              let findColorPantonIndex1 = this.state.getPantoneTile.findIndex(
                (p) => p.NAME == colorNo1
              );
              if (findColorPantonIndex1 != -1) {
                colorObj.bgBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].rgba;
                colorObj.bgBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].DISPLAY_NAME;

                colorObj.bgBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].NAME;

                colorObj.lineBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].rgba;
                colorObj.lineBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].DISPLAY_NAME;

                colorObj.lineBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex1
                ].NAME;

                this.setState({
                  colorObj: colorObj,
                });
              }
            }
            const colorNo2 = getColorNameString(
              this.colorArry,
              this.state.getPantoneTile,
              logoColor[1].DEFAULT_COLOR
            );
            let findColorIndex = this.colorArry.findIndex(
              (p) => p.NAME == colorNo2
            );
            if (findColorIndex !== -1) {
              colorObj.textBtn.color = this.colorArry[findColorIndex].rgba;
              colorObj.textBtn.name = this.colorArry[
                findColorIndex
              ].DISPLAY_NAME;

              colorObj.textBtn.colorName = this.colorArry[findColorIndex].NAME;

              this.setState({
                colorObj: colorObj,
              });
            } else {
              let findColorPantonIndex = this.state.getPantoneTile.findIndex(
                (p) => p.NAME == colorNo2
              );
              if (findColorPantonIndex != -1) {
                colorObj.textBtn.color = this.state.getPantoneTile[
                  findColorPantonIndex
                ].rgba;
                colorObj.textBtn.name = this.state.getPantoneTile[
                  findColorPantonIndex
                ].DISPLAY_NAME;

                colorObj.textBtn.colorName = this.state.getPantoneTile[
                  findColorPantonIndex
                ].NAME;

                this.setState({
                  colorObj: colorObj,
                });
              }
            }
            const tinValue = getScreenshadeByColorName(colorNo2, colorArr);
            const phColor = colorNo2 + "|" + tinValue;
            const isPhantomLogoExists=this.props.phantomLogo.findIndex(item=>item.value !=='None')
            
          //  if(((this.props.mainLogo.length==0 || this.props.mainLogo && this.props.mainLogo[0].value==='None') && isPhantomLogoExists==-1) || (isPhantomLogoExists!=-1 && this.props.mainLogo[0].value==='None' && DefaultMainLogo[0].value=='None')){
            this.props.onColorChanged(defaultColor, "DefaultColor");
            this.props.onColorChanged(defaultColor, "BorderColor");
            this.props.onColorChanged(phColor, "PantosColor");
           //}
          }
        }
      }

      this.props.onBlurHandaler();
    }
    this.setState({
      isLogoPopupOpen: false,
      isLogoSelected: false,
      isCustomerLogo: false,
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  toggleLogotypePopup
   * @desc   :  This function open or close the company logo popup according to flag
   * @return -
   */

  toggleLogotypePopup = (flag) => {
    if (document.getElementById("customerFile")) {
      document.getElementById("customerFile").value = "";
    }
    this.setState({
      OpenLogoOpup: flag,
      uploadErrorMsg: "",
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onfileChanged
   * @desc   :  This function getCalled uploading the file from user computer and open the logo placement popup.
   * @return -
   */

  onfileChanged = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.props.setImageType("Side");
      var file = event.target.files[0];
      var sFileName = file.name;
      var sFileExtension = sFileName
        .split(".")
        [sFileName.split(".").length - 1].toLowerCase();
      var iFileSize = file.size;

      let sFileNameWE = sFileName.split(".");
      sFileNameWE.pop();
      sFileNameWE = sFileNameWE.join("_");

      if (iFileSize > UPLOAD_IMAGE_FILE_SIZE) {
        alert("Selected file is larger than 10MB.");
        return false;
      }

      let isDisplayImage = false;
      if (IMAGE_FILES.includes(sFileExtension)) {
        isDisplayImage = true;
      }
      this.setState({
        isDisplayImage: isDisplayImage,
      });

      if (UPLOAD_IMAGE_FILE_TYPES.indexOf(sFileExtension) !== -1) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = function (e) {
          let OpenLogoOpup;
          let isCustomerLogo;
          if (this.state.imageTyle !== "main_logo") {
            OpenLogoOpup = false;
            isCustomerLogo = true;
          } else {
            OpenLogoOpup = true;
            isCustomerLogo = false;
          }
          this.setState({
            imgSrc: reader.result,
            OpenLogoOpup: OpenLogoOpup,
            file: file,
            sFileNameWE: sFileNameWE,
            uploadErrorMsg: "",
            isCustomerLogo: isCustomerLogo,
          });
          if (this.state.imageTyle !== "main_logo") {
            this.uploadFile();
          }
        }.bind(this);
      } else {
        this.setState({
          isTemplateModelOpen: true,
        });
      }
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  uploadFile
   * @desc   :  This function get Called click on button place logo on placement logo popup.
   * @return -
   */

  uploadFile = async () => {
    const formData = new FormData();
    if (this.state.file !== null) {
      formData.append("sampleFile", this.state.file);
      formData.append("folderName", this.state.imageFolder);
      this.props.fetchSessionDataStart();
      const fileupload = await action.uploadFile(formData);
      this.props.decgreseCount();
      if (fileupload && fileupload.filePath) {
        if (this.state.imageTyle === "main_logo") {
          if (!this.state.uploadLogoSelected) {
            const titleLines = [...this.props.companyInfo];
            titleLines.forEach((element) => {
              if (element.value) {
                if (!element.styleObj || element.styleObj == "undefined") {
                  element.styleObj = {};
                  element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
                } else {
                  element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
                }
                element.isInputChanged = true;
              }
            });
            this.props.setCompanyInfoState(titleLines);
          }
          this.props.onLogoChanged(
            fileupload.filePath,
            this.state.sFileNameWE,
            true
          );
          this.props.setCustomerLogoFlag(true);
        } else {
          this.props.onPhantomLogoChanged(
            fileupload.filePath,
            this.state.sFileNameWE,
            this.state.fieldName
          );
          const pantonCustermerUploadObj = {
            ...this.props.pantonCustermerUploadObj,
          };
          var phantomIndex = this.props.phantomLogo.findIndex(
            (element) => element.attributes.NAME == this.state.fieldName
          );
          pantonCustermerUploadObj[phantomIndex] = true;
          this.props.setCustomerLogoPhantomFlag(pantonCustermerUploadObj);
        }

        this.props.onBlurHandaler();
        this.setState({
          OpenLogoOpup: false,
          file: null,
          isLogoPopupOpen: false,
        });
      } else {
        this.setState({
          uploadErrorMsg: "Something went wrong error.",
        });
      }
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  ImagetypeChanged
   * @desc   :  This function set the logo type according to the radio button selected on placement logo.
   * @return -
   */

  ImagetypeChanged = (event) => {
    if (event.target.value === "Top") {
      const titleLines = [...this.props.companyInfo];
      titleLines.forEach((element) => {
        if (element.value && element.attributes.NAME !== "FXS_Title1") {
          if (!element.styleObj || element.styleObj == "undefined") {
            element.styleObj = {};
            element.styleObj.textAlign = "center";
          } else {
            element.styleObj.textAlign = "center";
          }
          element.isInputChanged = true;
        }
      });
      this.props.setCompanyInfoState(titleLines);
    } else {
      const titleLines = [...this.props.companyInfo];
      titleLines.forEach((element) => {
        if (element.value) {
          if (!element.styleObj || element.styleObj == "undefined") {
            element.styleObj = {};
            element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
          } else {
            element.styleObj.textAlign = element.BOXDEF.JUSTIFY.DEFAULT;
          }
          element.isInputChanged = true;
        }
      });
      this.props.setCompanyInfoState(titleLines);
    }
    this.setState({
      uploadLogoSelected: event.target.value,
    });
    this.props.setImageType(event.target.value, true);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  openClosedMOdelPopup
   * @desc   :  This function open the logo error popup modal and also click on open button from error message popup.
   * @return -
   */

  openClosedMOdelPopup = () => {
    const isTemplateModelOpen = !this.state.isTemplateModelOpen;
    this.setState({
      isTemplateModelOpen: isTemplateModelOpen,
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  removeMainLogo
   * @desc   :  This function used to remove company logo. this function get called clicked on cross button.
   * @return -
   */

  removeMainLogo = () => {
    const tempdata = [...this.props.jsonData];
    const logoIndex = tempdata.findIndex((p) => p.name === "FXS_Logo1");
    if (logoIndex != -1) {
      this.props.setImageType("", false);
      tempdata.push({
        name: "FXS_Title1",
        content: "",
        type: "Text",
      });
      tempdata[logoIndex].content = "";
      this.props.fetchJsonSuccess(tempdata, "showLoader");
    }

    const titleLines = [...this.props.companyInfo];
    titleLines.forEach((element) => {
      if (element.value) {
        if (!element.styleObj || element.styleObj == "undefined") {
          element.styleObj = {};
          element.styleObj.textAlign = "center";
        } else {
          element.styleObj.textAlign = "center";
        }
        element.isInputChanged = true;
      }
    });
    this.props.setCompanyInfoState(titleLines);

    this.props.onLogoChanged("None", "", true);
    this.isLogoPresent();
    setTimeout(() => {
      this.props.onBlurHandaler(true, true, true);
    }, 200);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  removeMainPh44atomLogo
   * @desc   :  This function used to remove Phantom logo. this function get called clicked on cross button.
   * @return -
   */

  removeMainPhatomLogo = (fieldName, currentImage) => {
    this.props.onPhantomLogoChanged("None", "", fieldName);
    this.isLogoPresent();
    this.setState({
      isCustomerLogo: false,
    });
    setTimeout(() => {
      this.props.onBlurHandaler(true, true, true);
    }, 200);
  };

  isLogoPresent = () => {
    let isLogoPreset = false;
    setTimeout(() => {
      if (this.props.mainLogoName && this.props.mainLogoName != "None") {
        isLogoPreset = true;
      }

      if (this.props.phantomLogo && this.props.phantomLogo.length > 0) {
        this.props.phantomLogo.forEach((element) => {
          if (element.value && element.value != "None") {
            isLogoPreset = true;
          }
        });
      }

      if (isLogoPreset == false) {
        this.props.setLogoType("");
        this.props.setAdditionalColor("");
        this.props.setLogoColorsArr("");
        if (
          (this.state.colorCode == 2 || this.state.colorCode == 3 || this.state.colorCode == 4) &&
          this.state.SKUvalues &&
          this.state.SKUvalues.startsWith("FP")
        ) {
          if (this.state.SKUvalues && this.state.SKUvalues.startsWith("FP")) {
            const tempmColorArr = [];
            this.state.colorArryCopy.forEach((p) => {
              if (p.SCREEN_SHADE != "") {
                tempmColorArr.push(p);
              }
            });
            this.colorArry = tempmColorArr;
          }

          const tileColors = getDefaultColor(this.colorArry);
          if (tileColors !== -1) {
            const defaultBg = getVlaueFormJsonByKey(
              this.props.jsonData,
              "FXS_Background",
              "content"
            );
            this.setBGDefault();

            const defaultColor = tileColors.NAME + "|100";
            const phColor =
              tileColors.NAME +
              "|" +
              getScreenshadeByColorName(tileColors.NAME, this.colorArry);
            this.props.onColorChanged(defaultColor, "DefaultColor");
            this.props.onColorChanged(defaultColor, "BorderColor");
            this.props.onColorChanged(phColor, "PantosColor");

            const stateColorObj = { ...this.state.colorObj };

            stateColorObj.textBtn.color = tileColors.rgba;
            stateColorObj.textBtn.name = tileColors.DISPLAY_NAME;

            stateColorObj.lineBtn.color = tileColors.rgba;
            stateColorObj.lineBtn.name = tileColors.DISPLAY_NAME;

            stateColorObj.bgBtn.color = tileColors.rgba;
            stateColorObj.bgBtn.name = tileColors.DISPLAY_NAME;
          }
        }
      }
    }, 100);
  };

  render() {
    const pantoneTileColor =
      this.props.pantoneColorArr && getPantoneTile(this.props.pantoneColorArr);
    
      let findDefaultIndex =
      this.props.DefaultColor &&
      this.props.DefaultColor[0].value &&
      this.colorArry.findIndex(
        (p) => p.NAME === this.props.DefaultColor[0].value.split("|")[0]
      );

    if (
      this.props.DefaultColor &&
      findDefaultIndex !== -1 &&
      !this.state.pantoneColorName
    ) {
      if(parseInt(this.state.colorCode)!=0){
      appliedColor(
        this.colorArry,
        findDefaultIndex,
        "PantosColor",
        "textBtn",
        this.state.SKUvalues
      );
      }
    } else {
      const findTTextIndex =
        this.colorArry.length >= 0 &&
        this.colorArry.findIndex((p) => p.default === true);
      if (this.colorArry.length > 0 && findTTextIndex !== -1) {
        const panPanIndex =
          (pantoneTileColor &&
            pantoneTileColor.length > 0 &&
            this.props.DefaultColor &&
            getValueArrayIndex(
              pantoneTileColor,
              "NAME",
              this.props.DefaultColor[0].value.split("|")[0]
            )) ||
          -1;
        if (this.state.pantoneColorName && this.state.pantoneColorName.name) {
          appliedPantoneColor(
            this.state.colorObj.textBtn,
            "PantosColor",
            "textBtn"
          );
          const findIndexPan = getValueArrayIndex(
            this.colorArry,
            "NAME",
            this.props.DefaultColor[0].value.split("|")[0]
          );
          if (
            findIndexPan === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            /**6125 Cant add cutome color issue code break resolve */
            if(pantoneTileColor[panPanIndex]){
            this.colorArry.push(pantoneTileColor[panPanIndex]);
            }
          }
        } else if (panPanIndex !== -1) {
          const findIndexPan = getValueArrayIndex(
            this.colorArry,
            "DISPLAY_NAME",
            this.props.DefaultColor[0].value.split("|")[0]
          );
          if (
            findIndexPan === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            this.colorArry.push(pantoneTileColor[panPanIndex]);
            appliedColor(
              pantoneTileColor,
              panPanIndex,
              "PantosColor",
              "textBtn",
              this.state.SKUvalues
            );
          }
        } else {
          if(parseInt(this.state.colorCode)!=0){

          appliedColor(
            this.colorArry,
            findTTextIndex,
            "PantosColor",
            "textBtn",
            this.state.SKUvalues
          );
          }
        }
      }
    }

    const findLineIndex =
      this.props.BorderColor &&
      this.colorArry.findIndex(
        (p) => p.NAME === this.props.BorderColor[0].value.split("|")[0]
      );
    if (
      this.props.BorderColor &&
      findLineIndex !== -1 &&
      !this.state.pantoneColorName
    ) {
      appliedColor(
        this.colorArry,
        findLineIndex,
        "BorderColor",
        "lineBtn",
        this.state.SKUvalues
      );
    } else {
      const findLTextIndex =
        this.colorArry.length >= 0 &&
        this.colorArry.findIndex((p) => p.default === true);
      if (this.colorArry.length > 0 && findLTextIndex !== -1) {
        const panBorIndex =
          (pantoneTileColor &&
            pantoneTileColor.length > 0 &&
            this.props.BorderColor &&
            getValueArrayIndex(
              pantoneTileColor,
              "NAME",
              this.props.BorderColor[0].value.split("|")[0]
            )) ||
          -1;
        if (this.state.pantoneColorName && this.state.pantoneColorName.name) {
          const appColor = appliedPantoneColor(
            this.state.colorObj.lineBtn,
            "BorderColor",
            "lineBtn"
          );
          const findIndexBr = getValueArrayIndex(
            this.colorArry,
            "NAME",
            this.props.BorderColor[0].value.split("|")[0]
          );
          if (
            findIndexBr === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            this.colorArry.push(pantoneTileColor[panBorIndex]);
          }
          if (appColor === -1) {
            appliedColor(
              this.colorArry,
              findLTextIndex,
              "BorderColor",
              "lineBtn",
              this.state.SKUvalues
            );
          }
        } else if (panBorIndex !== -1) {
          const findIndexBr = getValueArrayIndex(
            this.colorArry,
            "DISPLAY_NAME",
            this.props.BorderColor[0].value.split("|")[0]
          );
          if (
            findIndexBr === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            this.colorArry.push(pantoneTileColor[panBorIndex]);
            appliedColor(
              pantoneTileColor,
              panBorIndex,
              "BorderColor",
              "lineBtn",
              this.state.SKUvalues
            );
          }
        } else {
          appliedColor(
            this.colorArry,
            findLTextIndex,
            "BorderColor",
            "lineBtn",
            this.state.SKUvalues
          );
        }
      }
    }

    const findTextIndex =
      this.props.PantosColor &&
      this.colorArry.findIndex(
        (p) => p.NAME === this.props.PantosColor[0].value.split("|")[0]
      );
    if (
      this.props.PantosColor &&
      findTextIndex !== -1 &&
      !this.state.pantoneColorName
    ) {
      appliedColor(
        this.colorArry,
        findTextIndex,
        "DefaultColor",
        "bgBtn",
        this.state.SKUvalues
      );
    } else {
      const findDTextIndex =
        this.colorArry.length >= 0 &&
        this.colorArry.findIndex((p) => p.default === true);
      if (this.colorArry.length > 0 && findDTextIndex !== -1) {
        const pantoneTileColor =
          this.props.pantoneColorArr &&
          getPantoneTile(this.props.pantoneColorArr);
        const pandefIndex =
          (pantoneTileColor !== -1 &&
            this.props.PantosColor &&
            getValueArrayIndex(
              pantoneTileColor,
              "NAME",
              this.props.PantosColor[0].value.split("|")[0]
            )) ||
          -1;
        if (this.state.pantoneColorName && this.state.pantoneColorName.name) {
          appliedPantoneColor(
            this.state.colorObj.bgBtn,
            "DefaultColor",
            "bgBtn"
          );
          const findIndexPh = getValueArrayIndex(
            this.colorArry,
            "NAME",
            this.props.PantosColor[0].value.split("|")[0]
          );
          if (
            findIndexPh === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            this.colorArry.push(pantoneTileColor[pandefIndex]);
          }
        } else if (pandefIndex !== -1) {
          const findIndexPh = getValueArrayIndex(
            this.colorArry,
            "DISPLAY_NAME",
            this.props.PantosColor[0].value.split("|")[0]
          );
          if (
            findIndexPh === -1 &&
            this.state.colorCode &&
            parseInt(this.state.colorCode) > 0
          ) {
            this.colorArry.push(pantoneTileColor[pandefIndex]);
            appliedColor(
              pantoneTileColor,
              pandefIndex,
              "DefaultColor",
              "bgBtn",
              this.state.SKUvalues
            );
          }
        } else {
          appliedColor(
            this.colorArry,
            findDTextIndex,
            "DefaultColor",
            "bgBtn",
            this.state.SKUvalues
          );
        }
      }
    }

    const getTileData = getTile(this.colorArry);
    return (
      <React.Fragment>
        {this.state.isLogoDis &&(
          <h3><span className="title">Logo</span></h3>
        )}        
        <div className="set-logo-wrapper logo-margin">
          {this.state.isLogoDis && (
            <div className="logo-switch-wrapper">
              
              {/* <label className="switch">
                <input
                  type="checkbox"
                  checked={this.props.logoOption}
                  onChange={(event) => this.isEnabledLogoOption(event)}
                  disabled={this.state.documentType}
                />
                <span className="slider round"></span>
              </label> */}
              {this.state.sessFileType === "new order" && (
                <span className="logo-helper-text text-red">
                  &nbsp;&nbsp;
                  <i className="fa fa-arrow-left"></i> Click here to add logo
                </span>
              )}
            </div>
          )}

          { this.state.isLogoDis &&(
              <div className="inp-wrapper d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="MAIN LOGO"
                value={
                  this.props.mainLogo &&
                  this.props.mainLogo[0] &&
                  this.props.mainLogo[0].mainLogoName
                    ? this.props.mainLogo[0].mainLogoName
                    : ""
                }
                readOnly={true}
              />
              {this.props.mainLogo &&
                this.props.mainLogo[0] &&
                this.props.mainLogo[0].mainLogoName && (
                  <div className="delete-logo-wrapper">
                    <button
                      onClick={this.removeMainLogo}
                      className="delete-btn"
                    >
                      X
                    </button>
                  </div>
                )}

              <button
                id="myBtn"
                className="button-square button-blue"
                onClick={() => this.togglePopupLogo(true, "main_logo")}
                disabled={this.state.documentType}
              >
                Choose logo
              </button>
            </div>
          )}
            
          
          {
            this.props.phantomLogo.length > 0 &&
            this.props.phantomLogo.map((element, index) => {
              if (
                !checkInputIsInJsonData(
                  this.props.jsonData,
                  element.attributes.NAME
                )
              ) {
                return null;
              }
              return (
                <div className="inp-wrapper d-flex" key={index}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      index ? "PHANTOM LOGO" + index : "PHANTOM LOGO"
                    }
                    value={element.phatomLogoName ? element.phatomLogoName : ""}
                    readOnly={true}
                  />
                  {element.phatomLogoName && (
                    <div className="delete-logo-wrapper">
                      <button
                        onClick={(event) =>
                          this.removeMainPhatomLogo(
                            element.attributes.NAME,
                            element.phatomLogoName
                          )
                        }
                        className="delete-btn"
                      >
                        X
                      </button>
                    </div>
                  )}
                  <button
                    className="button-square button-blue"
                    onClick={() =>
                      this.togglePopupLogo(
                        true,
                        "phantom_logo",
                        element.attributes.NAME,
                        element.phatomLogoName
                      )
                    }
                    disabled={this.state.documentType}
                  >
                    Choose logo
                  </button>
                </div>
              );
            })}
        </div>
        <div className="m-b-25">
          {this.state.isSecuritybg && this.state.colorCode>1 &&(
            <h3>Colors </h3>
          )}          

          {this.state.colorCode > 1 &&
            this.state.textColor &&
            !this.state.SKUvalues.startsWith("FV") &&
            !this.state.SKUvalues.startsWith("FS") && (
              <div className="item-wrapper">
                <span>Text</span>
                <div
                  className="choose-color"
                  ref={(ref) => {
                    this.defaultColorRef = ref;
                  }}
                >
                  <button
                    id="bgBtn_elements_color"
                    className="button button-light-grey btnColorClick"
                    style={{
                      background:
                        this.state.colorObj && this.state.colorObj.bgBtn.color,
                    }}
                    onClick={(e) =>
                      this.clickBackgroundTogalEventEvent(e, "bgBtnShoWHide")
                    }
                    disabled={this.state.documentType}
                  >
                    {this.state.colorObj && this.state.colorObj.bgBtn.name
                      ? this.state.colorObj.bgBtn.name
                      : "Green 182"}
                  </button>
                  <div
                    className="color-options"
                    style={{ display: this.state.bgBtnShoWHide }}
                  >
                    <div className="selected-color-sec">
                      <span
                        className="selected-color"
                        style={{
                          background:
                            this.state.colorObj &&
                            this.state.colorObj.textBtn.color,
                        }}
                        id="DefaultColor_elements_color"
                      ></span>
                      <span
                        className="selected-color-name"
                        id="DefaultColor_elements_name"
                      >
                        {this.state.colorObj &&
                          this.state.colorObj.textBtn.name}
                      </span>
                      {/*Plus Button to add the color*/}
                      {this.state.User_Level === "Admin" &&
                       (this.state.colorCode === "1" || this.state.colorCode === "2" || this.state.colorCode === "3" || this.state.colorCode === "4") && (
                          <span
                            className="selected-color add-color-btn"
                            id="add_default_color_option"
                            onClick={() =>
                              this.toggleColorPopup(true, "DefaultColor")
                            }
                          >
                            +
                          </span>
                        )}
                    </div>

                    <div className="select-color">
                      {getTileData.length > 0 &&
                        getTileData.map((data, index) => {
                          return (
                            <span
                              key={index}
                              style={{ background: data.rgba }}
                              onClick={(event) =>
                                this.clickEvent(event, data, "DefaultColor")
                              }
                              onMouseEnter={(event) =>
                                this.mouseEnterHandler(
                                  event,
                                  data,
                                  "DefaultColor"
                                )
                              }
                              onMouseLeave={(event) =>
                                this.mouseLeaveHandler(
                                  event,
                                  this.state.colorObj.textBtn,
                                  "DefaultColor"
                                )
                              }
                            ></span>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {this.state.colorCode >= 2 &&
            this.state.lineColor &&
            !this.state.SKUvalues.startsWith("FV") &&
            !this.state.SKUvalues.startsWith("FS") && (
              <div className="item-wrapper">
                <span>Lines</span>
                <div
                  className="choose-color"
                  ref={(ref) => {
                    this.lineColorRef = ref;
                  }}
                >
                  <button
                    id="lineBtn_elements_color"
                    className="button button-dark-grey btnColorClick"
                    style={{
                      background:
                        this.state.colorObj &&
                        this.state.colorObj.lineBtn.color,
                    }}
                    onClick={(e) =>
                      this.clickLineTogalEventEvent(e, "lineBtnShoWHide")
                    }
                    disabled={this.state.documentType}
                  >
                    {this.state.colorObj && this.state.colorObj.lineBtn.name
                      ? this.state.colorObj.lineBtn.name
                      : "Green 182"}
                  </button>
                  <div
                    className="color-options"
                    style={{ display: this.state.lineBtnShoWHide }}
                  >
                    <div className="selected-color-sec">
                      <span
                        className="selected-color"
                        style={{
                          background:
                            this.state.colorObj &&
                            this.state.colorObj.lineBtn.color,
                        }}
                        id="BorderColor_elements_color"
                      ></span>
                      <span
                        className="selected-color-name"
                        id="BorderColor_elements_name"
                      >
                        {this.state.colorObj &&
                          this.state.colorObj.lineBtn.name}
                      </span>
                      {/*Plus Button to add the color*/}
                      {this.state.User_Level === "Admin" &&
                        (this.state.colorCode === "1" || this.state.colorCode === "2"  || this.state.colorCode === "3" || this.state.colorCode === "4") && (
                          <span
                            className="selected-color add-color-btn"
                            id="add_default_color_option"
                            onClick={() =>
                              this.toggleColorPopup(true, "BorderColor")
                            }
                          >
                            +
                          </span>
                        )}
                    </div>
                    <div className="select-color">
                      {getTileData.length > 0 &&
                        getTileData.map((data, index) => {
                          return (
                            <span
                              key={index}
                              style={{ background: data.rgba }}
                              onClick={(event) =>
                                this.clickEvent(event, data, "BorderColor")
                              }
                              onMouseEnter={(event) =>
                                this.mouseEnterHandler(
                                  event,
                                  data,
                                  "BorderColor"
                                )
                              }
                              onMouseLeave={(event) =>
                                this.mouseLeaveHandler(
                                  event,
                                  this.state.colorObj.textBtn,
                                  "BorderColor"
                                )
                              }
                            ></span>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          {this.state.colorCode != 1 &&
            (this.state.bgColor ||
              this.state.isSecurityCheck) && (this.state.backgroundImg != -1 || this.state.FXSBackgroundImg !==-1)&& this.state.isSecuritybg && (
              <div className="item-wrapper">
                <span>Background</span>
                <div
                  className="choose-color"
                  ref={(ref) => {
                    this.bgColorRef = ref;
                  }}
                >
                  <button
                    id="textBtn_elements_color"
                    className="button button-green btnColorClick"
                    onClick={(e) =>
                      this.clickTogalEventEvent(e, "textBtnShoWHide")
                    }
                    style={{
                      background:
                        this.state.colorObj &&
                        this.state.colorObj.textBtn.color,
                    }}
                    disabled={this.state.documentType}
                  >
                    {this.state.colorObj && this.state.colorObj.textBtn.name
                      ? this.state.colorObj.textBtn.name
                      : "Green 182"}
                  </button>
                  <div
                    className="color-options"
                    style={{ display: this.state.textBtnShoWHide }}
                  >
                    <div className="selected-color-sec">
                      <span
                        className="selected-color"
                        style={{
                          background:
                            this.state.colorObj &&
                            this.state.colorObj.bgBtn.color,
                        }}
                        id="PantosColor_elements_color"
                      ></span>
                      <span
                        className="selected-color-name"
                        id="PantosColor_elements_name"
                      >
                        {this.state.colorObj && this.state.colorObj.bgBtn.name}
                      </span>
                      {/*Plus Button to add the color*/}
                      {this.state.User_Level === "Admin" &&
                        (this.state.colorCode === "1" || this.state.colorCode === "2" || this.state.colorCode === "3" || this.state.colorCode === "4" ) && (                          <span
                            className="selected-color add-color-btn"
                            id="add_default_color_option"
                            onClick={() =>
                              this.toggleColorPopup(true, "PantosColor")
                            }
                          >
                            +
                          </span>
                        )}
                    </div>
                    <div className="select-color">
                      {getTileData.length > 0 &&
                        getTileData.map((data, index) => {
                          return (
                            <span
                              key={index}
                              style={{ background: data.rgba }}
                              onClick={(event) =>
                                this.clickEvent(event, data, "PantosColor")
                              }
                              onMouseEnter={(event) =>
                                this.mouseEnterHandler(
                                  event,
                                  data,
                                  "PantosColor"
                                )
                              }
                              onMouseLeave={(event) =>
                                this.mouseLeaveHandler(
                                  event,
                                  this.state.colorObj.textBtn,
                                  "PantosColor"
                                )
                              }
                            ></span>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* New function for all colors  */}
          {this.state.colorCode === "1" &&
          this.state.textColor &&
          // !this.state.SKUvalues.startsWith("FV") &&
          // !this.state.SKUvalues.startsWith("FS") &&
           (
            <div className="item-wrapper">
              <span>Choose Color</span>
              <div
                className="choose-color"
                ref={(ref) => {
                  this.defaultColorRef = ref;
                }}
              >
                <button
                  id="bgBtn_elements_color"
                  className="button button-light-grey btnColorClick"
                  style={{
                    background:
                      this.state.colorObj && this.state.colorObj.bgBtn.color,
                  }}
                  onClick={(e) =>
                    this.clickBackgroundTogalEventEvent(e, "bgBtnShoWHide")
                  }
                  disabled={this.state.documentType}
                >
                  {this.state.colorObj && this.state.colorObj.bgBtn.name
                    ? this.state.colorObj.bgBtn.name
                    : "Green 182"}
                </button>
                <div
                  className="color-options"
                  style={{ display: this.state.bgBtnShoWHide }}
                >
                  <div className="selected-color-sec">
                    <span
                      className="selected-color"
                      style={{
                        background:
                          this.state.colorObj &&
                          this.state.colorObj.textBtn.color,
                      }}
                      id="DefaultColor_elements_color"
                    ></span>
                    <span
                      className="selected-color-name"
                      id="DefaultColor_elements_name"
                    >
                      {this.state.colorObj && this.state.colorObj.textBtn.name}
                    </span>
                    {/*Plus Button to add the color*/}
                    {this.state.User_Level === "Admin" &&
                      // this.state.SKUvalues.startsWith("FP") && (
                        //# 5532 Cant add custom color to envelopes:Change for condition on color code basis
                        (this.state.colorCode === "1" || this.state.colorCode === "2"  || this.state.colorCode === "3" || this.state.colorCode === "4") && (
                        <span
                          className="selected-color add-color-btn"
                          id="add_default_color_option"
                          onClick={() =>
                            this.toggleColorPopup(true, "DefaultColor")
                          }
                        >
                          +
                        </span>
                      )}
                  </div>

                  <div className="select-color">
                    {getTileData.length > 0 &&
                      getTileData.map((data, index) => {
                        return (
                          <span
                            key={index}
                            style={{ background: data.rgba }}
                            onClick={(event) =>
                              this.clickChooseColorEvent(event, data)
                            }
                            onMouseEnter={(event) =>
                              this.mouseEnterHandler(
                                event,
                                data,
                                "DefaultColor"
                              )
                            }
                            onMouseLeave={(event) =>
                              this.mouseLeaveHandler(
                                event,
                                this.state.colorObj.textBtn,
                                "DefaultColor"
                              )
                            }
                          ></span>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        {/*End*/}
        </div>


        {!this.state.isSecurityCheck &&
        this.state.selectedImageBG !== "undefined" &&
        this.state.selectedImageBG.NAME &&
        this.state.backgroundImg != -1 &&
        !this.state.SKUvalues.startsWith("FV") ? (
        <div className="item-wrapper item-wrapper-bglabel">
              <span>Choose <br/>Background <br/> Pattern</span>
              <div className="choose-color">
                <div className="logo-with-title">
                  <div className="place-the-logo height-none">
                    <div className="choose-the-logo">
                      <i className="fa fa-pencil"></i>
                      <img
                        src={this.state.PREVIEWPATH + this.state.selectedImageBG.PREVIEW}
                        alt={this.state.selectedImageBG.NAME}
                        title={this.state.selectedImageBG.NAME}
                        onClick={() => this.togglePopup(true)}
                      />
                      <div>{this.state.selectedImageBG.NAME}</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        ) : (
          !this.state.isSecurityCheck &&
          this.state.colorCode >= 1 &&
          !this.state.SKUvalues.startsWith("FV") &&
          this.state.backgroundImg != -1 && (
            <div className="item-wrapper item-wrapper-bglabel">
              <span>Choose <br/>Background <br/> Pattern</span>
              <div className="choose-color">
                <button
                  className="button-square button-blue"
                  onClick={() => this.togglePopup(true)}
                  disabled={this.state.documentType}
                >
                  Set Background
                </button>
              </div>
            </div>
          )
        )}
        
        <div className="m-t-25 custom-select select-font-size item-wrapper item-wrapper-select">
          <span className="">Printer Paper<br/>Loading</span> 
          <select
            className="form-control selected-box-auto-width"
            onChange={(event) => this.faceUpDown(event)}
            disabled={this.state.documentType}
            data-place={"top"}
            data-tip="<p>This is the way you want your checks boxed. (Face Up or Face Down)<br/>
            This is determined by how you load your checks in your printer.</p>"
            data-html={true}
            value={this.props.JSONINFO && this.props.JSONINFO[0] && this.props.JSONINFO[0].value && JSON.parse(this.props.JSONINFO[0].value).faceUp}
          >
            <option value="1">FaceUp</option>
            <option value="0">FaceDown</option>
          </select>
          <ReactTooltip data-place="top" />
        </div>
        <BackgroundPopup
          togglePopup={this.state.isBgPopupOpen}
          PREVIEWPATH={this.state.PREVIEWPATH}
          FILEPATH={this.state.FILEPATH}
          bgArray={this.state.bgArray}
          closePopup={() => this.togglePopup(false)}
          imageSelected={this.imageSelected}
          setDefaultImage={this.state.selectedImageBG}
          setTheBG={this.setTheBG}
        ></BackgroundPopup>
        <ColorSelectorPopup
          togglePopup={this.state.isColorPopupOpen}
          colorArray={this.state.colorArray}
          isOpenDropdown={this.state.isOpenDropdown}
          pantoneColors={this.state.pantoneColors}
          filterColorArrayFn={this.filterColorArrayFn}
          filterColorArray={this.state.filterColorArray}
          toggleColorPopup={this.toggleColorPopup}
          setPantoneColor={this.setPantoneColor}
          applyPantoneColor={this.applyPantoneColor}
        ></ColorSelectorPopup>
        <LogoPopUp
          togglePopup={this.state.isLogoPopupOpen}
          logoFiles={this.props.logoFiles}
          imageFolder={this.state.imageFolder}
          customerFolder={this.state.customerFolder}
          imageTyle={this.state.imageTyle}
          imageSelected={this.imageLogoSelected}
          setTheLogo={this.setTheLogo}
          phantomLogo_image_name={this.state.phantomLogo_image_name_selected}
          mainLogo_image_name={this.state.mainLogo_image_name}
          phantomLogo={this.props.phantomLogo}
          mainLogo={this.props.mainLogo}
          fieldName={this.state.fieldName}
          closePopup={() => this.togglePopupLogo(false, "")}
          onfileChanged={this.onfileChanged}
          fileTypeStr={this.state.fileTypeStr}
          colorCode={this.state.colorCode}
          logoColorsArr={this.props.logoColorsArr}
          pathValue={this.state.pathValue}
          isCustomerLogo={this.state.isCustomerLogo}
          uploadFile={this.uploadFile}
          imageLoadingVal={this.props.imageLoadingVal}
          logoFrom={this.state.logoFrom}
          firstLogo={this.props.firstLogo}
          selectedImage={this.state.selectedLogo}
        ></LogoPopUp>
        <LogoType
          togglePopup={this.state.OpenLogoOpup}
          closePopup={() => this.toggleLogotypePopup(false, "")}
          imgSrc={this.state.imgSrc}
          companyInfo={this.props.companyInfo}
          uploadFile={this.uploadFile}
          uploadErrorMsg={this.state.uploadErrorMsg}
          imageType={this.props.imageType}
          ImagetypeChanged={this.ImagetypeChanged}
          isDisplayImage={this.state.isDisplayImage}
        ></LogoType>
        <div
          id="fileTypeModel"
          className="modal"
          style={{ display: this.state.isTemplateModelOpen ? "block" : "none" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-danger text-red">
                Selected file is not a supported type.
                <br /> File must be in one of <br />
                the following formats:{" "}
                {this.state.fileTypeStr
                  ? this.state.fileTypeStr
                  : "AI, EPS, JPG, PDF, PNG, PSD or TIF"}
                .
              </div>

              <div className="text-right pull-right">
                <button
                  className="button-square button-blue"
                  type="button"
                  onClick={(event) => this.openClosedMOdelPopup(event)}
                >
                  OK
                </button>
              </div>
              <div className="clear-float"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps 
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    configData: state.fetchDataReducer.fxConfig,
    sessionData: state.fetchDataReducer.sessionData,
    DefaultColor: state.inputDataReducer.DefaultColor,
    BorderColor: state.inputDataReducer.BorderColor,
    PantosColor: state.inputDataReducer.PantosColor,
    jsonData: state.fetchDataReducer.jsonData,
    bgImage: state.inputDataReducer.backgroundImage,
    bgPreview: state.inputDataReducer.bgPreview,
    bgPreviewName: state.inputDataReducer.bgPreviewName,
    JSONINFO: state.inputDataReducer.JSONINFO,
    checkInfo: state.inputDataReducer.checkInfo,
    mainLogoName: state.inputDataReducer.mainLogoName,
    mainLogo: state.inputDataReducer.mainLogo,
    additionalColor: state.inputDataReducer.additionalColor,
    logoDC: state.inputDataReducer.logoDC,
    logoColorsArr: state.inputDataReducer.logoColorsArr,
    LogoType: state.inputDataReducer.LogoType,
    pantoneColorArr: state.inputDataReducer.pantoneColorArr,
    //logo optios
    phantomLogo: state.inputDataReducer.phantomLogo,
    // mainLogo: state.inputDataReducer.mainLogo,
    logoOption: state.inputDataReducer.logoOption,
    phatomLogoName: state.inputDataReducer.phatomLogoName,
    // mainLogoName: state.inputDataReducer.mainLogoName,
    logoFiles: state.inputDataReducer.logoFiles,
    imageType: state.inputDataReducer.imageType,
    companyInfo: state.inputDataReducer.companyInfo,
    pantonCustermerUploadObj: state.inputDataReducer.pantonCustermerUploadObj,
    imageLoadingVal: state.fetchDataReducer.imageLoadingVal,
    isCustomerLogo: state.inputDataReducer.isCustomerLogo,
    tempArr:state.inputDataReducer,
    firstLogo:state.inputDataReducer.firstLogo
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    onColorChanged: (color, button) =>
      dispatch(inputSettingction.onColorChanged(color, button)),
    onBlurHandaler: (isFocusOut,isImage,isNew) => dispatch(action.onBlurHandaler(isFocusOut,isImage,isNew)),
    onBGChanged: (data, flag) =>
      dispatch(inputSettingction.onBGChanged(data, flag)),
    onSecurityBGChanged: (data, flag) =>
      dispatch(inputSettingction.onSecurityBGChanged(data, flag)),
    setNewJSONInfo: (setNewJSONInfo, flag) =>
      dispatch(inputSettingction.setDefaultJSONInfo(setNewJSONInfo, flag)),
    setLogoColor: (color) => dispatch(inputSettingction.setLogoColor(color)),
    onLogoChanged: (data, logoName, flag) =>
      dispatch(inputSettingction.onLogoChanged(data, logoName, flag)),
    setReduxPantoneColor: (colorArrr) =>
      dispatch(inputSettingction.setReduxPantoneColor(colorArrr)),
    onLogoFlagChanged: (data) =>
      dispatch(inputSettingction.onLogoFlagChanged(data)),
    onPhantomLogoChanged: (data, logoName, fieldName) =>
      dispatch(
        inputSettingction.onPhantomLogoChanged(data, logoName, fieldName)
      ),

    setImageType: (data, flag) =>
      dispatch(inputSettingction.setImageType(data, flag)),
    setLogoColor: (color) => dispatch(inputSettingction.setLogoColor(color)),
    setAdditionalColor: (addColor) =>
      dispatch(inputSettingction.setAdditionalColor(addColor)),
    setLogoColorsArr: (colorArr) =>
      dispatch(inputSettingction.setLogoColorsArr(colorArr)),
    setLogoType: (logoType) =>
      dispatch(inputSettingction.setLogoType(logoType)),
    fetchJsonSuccess: (data, flag) =>
      dispatch(action.fetchJsonSuccess(data, flag)),
    setCompanyInfoState: (titleLines) =>
      dispatch(inputSettingction.setCompanyInfoState(titleLines)),
    setCustomerLogoFlag: (flag) =>
      dispatch(inputSettingction.setCustomerLogoFlag(flag)),
    setCustomerLogoPhantomFlag: (obj) =>
      dispatch(inputSettingction.setCustomerLogoPhantomFlag(obj)),
    fetchSessionDataStart: () => dispatch(action.fetchSessionDataStart()),
    decgreseCount: () => dispatch(action.decgreseCount()),
    fetchPreviewData:(newArrState, focusout, type,isFocusOut,isImage,isFaceUpDownChange)=>dispatch(action.fetchPreviewData(newArrState, focusout, type,isFocusOut,isImage,isFaceUpDownChange))
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Styles);
