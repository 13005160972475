/**
 * @author    Clarion
 * @version 1.0
 * @file   :  BottomBar
 * @desc   :  This file contained the left pannel bottom bar functionality for Editor
 */

import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';

import {
  mergeBoxDefUserObject,
  getSectionByTitle,
  getFontIdByFontStyle,
  getVlaueFormSessionByKey,
  getInputSelection,
  setSessinoStorage,
  getSessionStore,
  getBgDefault,
} from "./../../utility/Utility";
import * as inputAction from "./../../store/action/inputSettings";
import * as action from "../../store/action/fetchData";
import * as _ from "lodash";

import "./BottomBar.css";

class BottomBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickEvent = this.handleClickEvent.bind(this);
    // this.markUnmarkBold = this.markUnmarkBold.bind(this);
    // this.markUnmarkItalic = this.markUnmarkItalic.bind(this);
    // this.markUnmarkUnderline = this.markUnmarkUnderline.bind(this);
    // this.setFontSize = this.setFontSize.bind(this);
    // this.setFontFamily = this.setFontFamily.bind(this);
    // this.setTextAlign = this.setTextAlign.bind(this);

    this.toggle = false;
    this.domainName = window.location.hostname;

    this.state = {
      popUpClass: "pop-up hidePopup",
      styleObject: { ...this.props.styleObj },
      toggleAlign: false,
      isDisabledItalic: false,
      isDisabledBold: false,
      tooltipText: "Click here to change the formatting of the text",
      displayMsgAlert: "none"
    };
  }

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  handleClickEvent
  //  * @desc   :  Toggle the text formating popup
  //  * @return -
  //  */

  handleClickEvent = (event) => {
    if (this.props.boxName === "FXS_Stub1Single") {
      var input = document.getElementById("FXS_Stub1Single");
      input.focus();

      var result = getInputSelection(input);
      const start = result.start;

      let newstart = start;
      while (input.value[newstart] == "•") newstart--;
      newstart = input.value.lastIndexOf("•", newstart) + 1;
      var end = input.value.indexOf("•", newstart);
      if (end == -1) end = input.value.length;
      let keyIncludesStr = input.value.substring(newstart, end);

      const spliStr = input.value.split("•");
      let foundKey = -1;
      for (const key in spliStr) {
        if (spliStr.hasOwnProperty(key) && spliStr[key] === keyIncludesStr) {
          foundKey = key;
        }
      }

      if (foundKey !== -1) {
        const styleTemoObj = this.props.styleObj[foundKey];

        this.setState({ styleObject: styleTemoObj });
      }
    } else {
      this.setState({ styleObject: this.props.styleObj });
    }
    this.toggle = !this.toggle;
    if (this.toggle) {
      if (!this.state.styleObject.fontFamily) {
        this.setFontFamily("", "Helvetica Neue LT STD");
      }

      this.setState({
        popUpClass: "pop-up displayPopup",
      });
    } else {
      this.setState({
        popUpClass: "pop-up hidePopup",
      });
      const objCompre = _.isEqual(this.state.styleObject, this.props.styleObj);
      if (!objCompre) {
        if (this.props.boxName === "FXS_Stub1Single") {
          var input = document.getElementById("FXS_Stub1Single");
          input.focus();

          var result = getInputSelection(input);
          const start = result.start;

          let newstart = start;
          while (input.value[newstart] == "•") newstart--;
          newstart = input.value.lastIndexOf("•", newstart) + 1;
          var end = input.value.indexOf("•", newstart);
          if (end == -1) end = input.value.length;
          let keyIncludesStr = input.value.substring(newstart, end);
          var stubIndex = this.props.companyStubData.findIndex(
            (element) => element.attributes.NAME == "FXS_Stub1Single"
          );

          if (this.props.companyStubData[stubIndex].styleObj !== "undefined") {
            var newStyleObj = {
              ...this.props.companyStubData[stubIndex].styleObj,
            };
          } else {
            var newStyleObj = {};
          }

          const spliStr = input.value.split("•");
          let foundKey = -1;
          for (const key in spliStr) {
            if (
              spliStr.hasOwnProperty(key) &&
              spliStr[key] === keyIncludesStr
            ) {
              foundKey = key;
            }
          }

          if (foundKey !== -1) {
            if (this.state.styleObject.fontId) {
              newStyleObj[foundKey].fontId = this.state.styleObject.fontId;
            }
            if (this.state.styleObject.fontFamily) {
              newStyleObj[
                foundKey
              ].fontFamily = this.state.styleObject.fontFamily;
            }
            if (this.state.styleObject.fontSize) {
              newStyleObj[foundKey].fontSize = this.state.styleObject.fontSize;
            }

            if (this.state.styleObject.textAlign) {
              newStyleObj[
                foundKey
              ].textAlign = this.state.styleObject.textAlign;
            }
          }

          this.props.onChangedStyle(
            newStyleObj,
            this.props.boxName,
            getSectionByTitle(this.props.boxName)
          );
          this.props.onBlurHandaler();
        } else {
          this.props.onChangedStyle(
            this.state.styleObject,
            this.props.boxName,
            getSectionByTitle(this.props.boxName)
          );
          this.props.onBlurHandaler();
        }
      }
    }
  };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  markUnmarkBold
  //  * @desc   :   This function called when clicked on bold Icon to toggle the bold
  //  * @return -
  //  */

  // markUnmarkBold = (event) => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   if (this.state.styleObject.fontWeight === "bold") {
  //     newStyleObject.fontWeight = "unset";
  //   } else {
  //     newStyleObject.fontWeight = "bold";
  //   }
  //   const newFontID = this.getFontID(
  //     this.state.styleObject.fontFamily,
  //     newStyleObject.fontWeight,
  //     this.state.styleObject.fontStyle
  //   );
  //   if (newFontID !== -1) {
  //     newStyleObject.fontId = newFontID.fontId;
  //   }
  //   this.setState({ styleObject: newStyleObject });
  // };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  markUnmarkItalic
  //  * @desc   :   This function called when clicked on italic Icon (I) to toggle the italic
  //  * @return -
  //  */

  // markUnmarkItalic = (event) => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   if (this.state.styleObject.fontStyle === "italic") {
  //     newStyleObject.fontStyle = "normal";
  //   } else {
  //     newStyleObject.fontStyle = "italic";
  //   }
  //   const newFontID = this.getFontID(
  //     this.state.styleObject.fontFamily,
  //     this.state.styleObject.fontWeight,
  //     newStyleObject.fontStyle
  //   );
  //   if (newFontID !== -1) {
  //     newStyleObject.fontId = newFontID.fontId;
  //   }
  //   this.setState({ styleObject: newStyleObject });
  // };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  markUnmarkUnderline
  //  * @desc   :   This function called when clicked on underline Icon (U) to toggle the underline functionality
  //  * @return -
  //  */

  // markUnmarkUnderline = (event) => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   if (this.state.styleObject.textDecoration === "underline") {
  //     newStyleObject.textDecoration = "none";
  //   } else {
  //     newStyleObject.textDecoration = "underline";
  //   }
  //   this.setState({ styleObject: newStyleObject });
  // };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  setFontSize
  //  * @desc   :   This function called After changing the font size
  //  * @return -
  //  */

  // setFontSize = (event) => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   newStyleObject.fontSize = event.target.value;
  //   this.setState({ styleObject: newStyleObject });
  // };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  setFontFamily
  //  * @desc   :   This function called After changing the font family
  //  * @return -
  //  */

  // getFontID = (fontName, fontWeight, fontStyle) => {
  //   const boxDefObj = mergeBoxDefUserObject(
  //     this.props.boxDef,
  //     this.props.sessionData
  //   );
  //   let fontObject = [];

  //   if (boxDefObj.FONT.FAMILY) {
  //     fontObject = this.props.fxsConfig.FONTFAMILIES[boxDefObj.FONT.FAMILY][
  //       "FONTS"
  //     ];
  //   } else {
  //     const userValue = getVlaueFormSessionByKey(
  //       this.props.sessionData,
  //       "User_Level",
  //       "cdata"
  //     );
  //     if (userValue === "Admin") {
  //       fontObject = this.props.fxsConfig.FONTFAMILIES["All"]["FONTS"];
  //     } else {
  //       fontObject = this.props.fxsConfig.FONTFAMILIES["STANDARD"]["FONTS"];
  //     }
  //   }
  //   return getFontIdByFontStyle(fontObject, fontWeight, fontStyle, fontName);
  // };

  // setFontFamily = (event, defaultFont = "") => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   let newFontID;
  //   if (event) {
  //     newFontID = this.getFontID(
  //       event.target.value,
  //       this.state.styleObject.fontWeight,
  //       this.state.styleObject.fontStyle
  //     );
  //   } else {
  //     newFontID = this.getFontID(
  //       defaultFont,
  //       this.state.styleObject.fontWeight,
  //       this.state.styleObject.fontStyle
  //     );
  //   }
  //   let isDisabledBold = true;
  //   let isDisabledItalic = true;

  //   if (newFontID !== -1) {
  //     if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
  //       for (let index = 0; index < newFontID.fontMapArr.length; index++) {
  //         if (
  //           newFontID.fontMapArr[index].bold === "true" &&
  //           newFontID.fontMapArr.length > 1
  //         )
  //           isDisabledBold = false;

  //         if (
  //           newFontID.fontMapArr[index].italic === "true" &&
  //           newFontID.fontMapArr.length > 1
  //         )
  //           isDisabledItalic = false;
  //       }
  //     }

  //     newStyleObject.fontId = newFontID.fontId;
  //   } else {
  //     delete newStyleObject.fontId;
  //   }
  //   if (event) {
  //     newStyleObject.fontFamily = event.target.value;
  //   } else {
  //     newStyleObject.fontFamily = defaultFont;
  //   }

  //   this.setState({
  //     styleObject: newStyleObject,
  //     isDisabledBold: isDisabledBold,
  //     isDisabledItalic: isDisabledItalic,
  //   });
  // };

  // /**
  //  * @author    Clarion
  //  * @version 1.0
  //  * @name   :  setTextAlign
  //  * @desc   :   This function called After changing the text alignment
  //  * @return -
  //  */

  // setTextAlign = (event) => {
  //   const newStyleObject = { ...this.state.styleObject };
  //   newStyleObject.textAlign = event.target.value;
  //   this.setState({ styleObject: newStyleObject });
  // };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  undoHistoryState
   * @desc   :   This function called After clicking on undo redo and rest all button to change the redux state accordingly
   * @return -
   */

  undoHistoryState = (type) => {
    if (type === "redo") {
      this.props.redoHistoryState(this.props.configData.BACKGROUNDS);
    } else if (type === "undo") {
      this.props.undoHistoryState(this.props.configData.BACKGROUNDS);
    } else {
      this.props.restHistoryState(this.props.configData.BACKGROUNDS);
      this.props.isChangedHappened(false);
    }
    setTimeout(() => {
      if (
        this.props.bgImage &&
        this.props.bgImage[0] &&
        !this.props.bgImage[0].value
      ) {
        const bgObj = getBgDefault(this.props.configData.BACKGROUNDS);

        if (bgObj !== -1) {
          this.props.onBGChanged(bgObj, true);
        }
      }
      this.props.onBlurHandaler(true, false);
    }, 500);
  };

  toggleAlignPopUp = (event, type) => {
    let toggleAlign = this.state.toggleAlign;
    toggleAlign = !toggleAlign;
    this.setState({ toggleAlign: toggleAlign });
    if (type) {
      const newStyleObject = { ...this.state.styleObject };
      newStyleObject.textAlign = type;
      this.setState({ styleObject: newStyleObject });
    }
  };

  componentDidUpdate(nextProps) {
    if (this.props.displayTooltip) {
      setTimeout(() => {
        this.props.setTootipFlag();
      }, 5000);
    }
  }
  componentDidMount() {
    const docType = getVlaueFormSessionByKey(this.props.sessionData,"Type", "cdata")
    this.setState({
      docType:docType
    })
    this.props.isChangedHappened(false);
    window.addEventListener('onbeforeunload', this.handleBeforeUnload);
//     window.addEventListener("beforeunload", (ev) => 
// {  
//   ev = ev || window.event;
   
//     // // alert("this is true")
//     // console.log("this is true")
//      this.handleOnPopState();
//      ev.preventDefault();
//     if (ev) {
//       return ev.returnValue = 'Are you sure you want to close?';
//   }

//   // For Safari
//   // window.stop();
//   // return 'Are you sure you want to close?';
//     // ev.returnValue = 'Are you sure you want to close?';
// });
    window.addEventListener('popstate', this.handleOnPopState);
    if(!window.location.href.includes("#")){
      window.history.pushState('forward', null, window.location.href+'#');
    }else{
      window.history.pushState('forward', null, window.location.href);
    }
      
  }
  handleBeforeUnload = e => {
    
  }

  saveDocument = () =>{
    this.props.setOverwriteSave(true);
    const doctype = getVlaueFormSessionByKey(this.props.sessionData,"Type", "cdata");
    let timeOut = 0;
    console.log("doctype",doctype)
    if(doctype && doctype.toLowerCase() ==="overwrite"){
      timeOut = 2000;
      this.props.onBlurHandaler(true,false)
    }
    setTimeout(() => {
      this.props.saveDocument();
    }, timeOut);
    
  }

  handleOnPopState = e => {
    const User_Level = getVlaueFormSessionByKey(
      this.props.sessionData,
      "User_Level",
      "cdata"
    );

    const docType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    
    var hashLocation = window.location.hash;
      var hashSplit = hashLocation.split("#!/");
      var hashName = hashSplit[1];

      if (hashName !== '') {
        var hash = window.location.hash;
        if (hash === '') {
          if(User_Level === "Admin" && docType==="Overwrite" && this.props.isChangedApplied){

            swal("Changes you have already made were applied to the saved document. Would you like to undo all changes?", {
              buttons: {                
                Yes: {
                  text: "Undo All",
                  value: "yes",
                },
                No: {
                  text: "Save",
                  value: "no"
                }
              },
            })
            .then(async (value) => {
              switch (value) {             
                case "yes":  
                  this.setState({
                    displayMsgAlert: "block"
                  })                
                  this.undoHistoryState();
                  this.props.isChangedHappened(false);
                  await action.deleteInstance(this.props.instanceID);
                  setTimeout(() => {
                    window.history.back();  
                  }, 5000);
                  break;             
                case "no":
                  this.props.setOverwriteSave(true);
                  const doctype = getVlaueFormSessionByKey(this.props.sessionData,"Type", "cdata");
                  let timeOut = 0;
                  if(doctype && doctype.toLowerCase() ==="overwrite"){
                    timeOut = 2000;
                    this.props.onBlurHandaler(true,false)
                  }
                  setTimeout(() => {
                    this.props.saveDocument();
                  }, timeOut);
                  break;             
                default:
                  // swal("Got away safely!");
              }
            })

            // var r = window.confirm("Changes you have already made were applied to the saved document. Would you like to undo all changes?");

            // if (r == true) {
            //   this.undoHistoryState();
            //   this.props.isChangedHappened(false);
            //   setTimeout(() => {
            //     window.history.back();  
            //   }, 5000);                
            // } else {
            //   window.history.back();
            // }
          }else{
            this.props.isChangedHappened(false);
              window.history.back();
          }
        }
      }

  }
 

  refreshedPreview = () => {
    this.props.onBlurHandaler();
  };

  render() {
    const opts = {};
    if (!this.props.isFocusInput) {
      opts["disabled"] = "disabled";
    }
    let boxDefObj = {};
    let fontList = "";
    let fontSizeList = "";
    let fontObject = [];
    var isDisabledBold = true;
    var isDisabledItalic = true;

    // if (typeof this.props.boxDef !== "undefined") {
    //   boxDefObj = mergeBoxDefUserObject(
    //     this.props.boxDef,
    //     this.props.sessionData
    //   );
    //   const userValue = getVlaueFormSessionByKey(
    //     this.props.sessionData,
    //     "User_Level",
    //     "cdata"
    //   );

    //   if (boxDefObj.FONT.FAMILY) {
    //     fontObject = this.props.fxsConfig.FONTFAMILIES[boxDefObj.FONT.FAMILY];
    //   } else {
    //     if (userValue === "Admin") {
    //       fontObject = this.props.fxsConfig.FONTFAMILIES["All"];
    //     } else {
    //       fontObject = this.props.fxsConfig.FONTFAMILIES["STANDARD"];
    //     }
    //   }

    //   fontList =
    //     fontObject.FONTS.length > 0 &&
    //     fontObject.FONTS.map((item, i) => {
    //       return (
    //         <option key={i} value={item.NAME}>
    //           {item.NAME}
    //         </option>
    //       );
    //     }, this);

    //   let fontSizeLength =
    //     parseInt(boxDefObj.FONTSIZE.MAX) - parseInt(boxDefObj.FONTSIZE.MIN);
    //   fontSizeLength = fontSizeLength + 1;
    //   let values = parseInt(boxDefObj.FONTSIZE.MIN);
    //   const fontArr = [];
    //   for (let index = 0; index < fontSizeLength; index++) {
    //     if (index !== 0 && boxDefObj.FONTSIZE.INCREMENT) {
    //       values = values + parseInt(boxDefObj.FONTSIZE.INCREMENT);
    //     } else if (index !== 0) {
    //       values = values + 1;
    //     }

    //     fontArr.push({ name: values });
    //   }

    //   fontSizeList =
    //     fontArr.length > 0 &&
    //     fontArr.map((item, i) => {
    //       return (
    //         <option key={i} value={item.name}>
    //           {item.name}
    //         </option>
    //       );
    //     }, this);
    // }
    let nextDisabled = true;
    if (this.props.tabIndex > 2) {
      nextDisabled = false;
    }
    let prevDisabled = true;
    if (this.props.tabIndex <= 0) {
      prevDisabled = false;
    }
    let textClass;
    if (
      this.state.styleObject &&
      this.state.styleObject.textAlign === "center"
    ) {
      textClass = "center";
    } else if (
      this.state.styleObject &&
      this.state.styleObject.textAlign === "right"
    ) {
      textClass = "right";
    } else {
      textClass = "left";
    }
    let alignmentBtnDisabled = false;
    if (
      this.props.filedFocusName &&
      (this.props.filedFocusName.includes("FXS_Title") ||
        this.props.filedFocusName.includes("FXS_GEN") ||
        this.props.filedFocusName.includes("FXS_Stub1Single"))
    ) {
      alignmentBtnDisabled = true;
    }

    return (
      <div>
        <div className={this.state.popUpClass} id={this.state.popUpClass}>
          <div className="rounded-corner">
            <div className="custom-select select-font" style={{ width: "40%" }}>
              <select
                style={{ width: "100%" }}
                onChange={this.setFontFamily}
                value={this.state.styleObject.fontFamily}
              >
                {fontList}
              </select>
            </div>
            <div className="custom-select select-font-size">
              <select
                onChange={this.setFontSize}
                value={this.state.styleObject.fontSize}
              >
                {fontSizeList}
              </select>
            </div>
            <div className="font-style">
              {boxDefObj.FORMAT && boxDefObj.FORMAT.BOLD == "true" && (
                <button
                  className="font_button"
                  onClick={this.markUnmarkBold}
                  style={{
                    background:
                      this.state.styleObject.fontWeight === "bold"
                        ? "rgba(0,0,0,.4)"
                        : "",
                  }}
                  disabled={this.state.isDisabledBold}
                >
                  <i className="fa fa-bold"></i>
                </button>
              )}

              {boxDefObj.FORMAT && boxDefObj.FORMAT.ITALIC == "true" && (
                <button
                  className="font_button"
                  onClick={this.markUnmarkItalic}
                  style={{
                    background:
                      this.state.styleObject.fontStyle === "italic"
                        ? "rgba(0,0,0,.4)"
                        : "",
                  }}
                  disabled={this.state.isDisabledItalic}
                >
                  <i className="fa fa-italic"></i>
                </button>
              )}

              {boxDefObj.FORMAT && boxDefObj.FORMAT.UNDERLINE == "true" && (
                <span onClick={this.markUnmarkUnderline}>
                  <i className="fa fa-underline"></i>
                </span>
              )}
            </div>
            <div className="custom-select select-font-alignment">
              <button
                className="selected font_button"
                onClick={this.toggleAlignPopUp}
                disabled={!alignmentBtnDisabled}
              >
                <i className="fa fa-sort-down"></i>
                <i className={"fa fa-align-" + textClass}></i>
              </button>
              <ul
                style={{
                  display: this.state.toggleAlign ? "block" : "none ",
                }}
              >
                <li onClick={(event) => this.toggleAlignPopUp(event, "left")}>
                  <i className="fa fa-align-left"></i>
                </li>
                <li onClick={(event) => this.toggleAlignPopUp(event, "center")}>
                  <i className="fa fa-align-center"></i>
                </li>
                <li onClick={(event) => this.toggleAlignPopUp(event, "right")}>
                  <i className="fa fa-align-right"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="sider-footer__icons">
          <div className="sider-footer__icons-left">
            {parseInt(this.props.tabIndex) !== 0 && (
              <button
                className="font_button"
                disabled={!prevDisabled}
                onClick={() =>
                  this.props.tabChanged(parseInt(this.props.tabIndex) - 1)
                }
              >
                <i className="fa fa-arrow-left" data-tip="Previous Tab"></i>
                <ReactTooltip />
              </button>
            )}
            <button
              className="font_button"
              disabled={!this.props.canUndo}
              onClick={() => this.undoHistoryState("resetall")}
            >
              <i className="fa fa-reply-all" data-tip="Start Over"></i>
            </button>

            <button
              className="font_button"
              disabled={!this.props.canUndo}
              onClick={() => this.undoHistoryState("undo")}
            >
              <i className="fa fa-reply" data-tip="Undo"></i>
            </button>

            <button
              className="icon-forward font_button"
              disabled={!this.props.canRedo}
              onClick={() => this.undoHistoryState("redo")}
            >
              <i className="fa fa-reply reverse" data-tip="Redo"></i>
            </button>
            <ReactTooltip />
          </div>
          <div className="sider-footer__icons-right">
            {parseInt(this.props.tabIndex) !== 3 && (
              <button
                className="font_button"
                onClick={() => this.refreshedPreview()}
              >
                {this.props.isInputChanged && getVlaueFormSessionByKey(this.props.sessionData,"Type", "cdata") !=="Overwrite" ? (
                  <i
                    className="fa fa-refresh"
                    data-tip="Refresh preview"
                    style={{ color: "red" }}
                  ></i>
                ) : (
                  <i className="fa fa-refresh" data-tip="Refresh preview"></i>
                )}
              </button>
            )}
            {parseInt(this.props.tabIndex) === 3 && (
              <button
                className="button-square button-blue add-cart-btn"
                onClick={() => this.saveDocument()}
              >
                ADD TO CART
              </button>
            )}

            {parseInt(this.props.tabIndex) !== 3 && (
              <button
                className="font_button"
                disabled={!nextDisabled}
                onClick={() =>
                  this.props.tabChanged(parseInt(this.props.tabIndex) + 1)
                }
              >
                <i className="fa fa-arrow-right" data-tip="Next Tab"></i>
              </button>
            )}
          </div>
        </div>
       
      <div id="display-alertBoxModel" className="modal" style={{display:this.state.displayMsgAlert}}>
        <div className="modal-content">
          
          <div className="modal-body">
            <div className="logo-position">
              <div className="place-the-logo-type" id="place-the-bg">
                <div className="disp-msg">Your changes are being reverted. You will be automatically redirected upon completion.</div>
                 
              </div>
            </div>
            
           
          </div>
        </div>
      </div>
    
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    fxsConfig: state.fetchDataReducer.fxConfig,
    isFocusInput: state.inputDataReducer.isFocusInput,
    boxDef: state.inputDataReducer.boxDef,
    boxName: state.inputDataReducer.boxName,
    companyInfo: state.inputDataReducer.companyInfo,
    defaultBoxDef: state.inputDataReducer.defaultBoxDef,
    styleObj: state.inputDataReducer.styleObj,
    isInputChanged: state.inputDataReducer.isInputChanged,
    tabIndex: state.inputDataReducer.tabIndex,
    canUndo: state.inputDataReducer.past.length > 0,
    canRedo: state.inputDataReducer.future.length > 0,
    sessionData: state.fetchDataReducer.sessionData,
    fontMapData: state.fetchDataReducer.fontMapData,
    filedFocusName: state.inputDataReducer.filedFocusName,
    sessionFileID: state.fetchDataReducer.sessionFileID,
    companyStubData: state.inputDataReducer.stubData,
    displayTooltip: state.inputDataReducer.displayTooltip,
    configData: state.fetchDataReducer.fxConfig,
    bgImage: state.inputDataReducer.backgroundImage,
    jsonData: state.fetchDataReducer.jsonData,
    isChangedApplied:state.inputDataReducer.isChangedApplied
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    onChangedStyle: (styleObj, title, section) =>
      dispatch(inputAction.onStyleChangeHandaler(styleObj, title, section)),
    onBlurHandaler: (isFocusOut, isNew) =>
      dispatch(action.onBlurHandaler(isFocusOut, isNew)),
    tabChanged: (tabIndex) => dispatch(inputAction.onTabChanged(tabIndex)),
    undoHistoryState: (bgArray) => dispatch(inputAction.undoHistoryState(bgArray)),
    redoHistoryState: (bgArray) => dispatch(inputAction.redoHistoryState(bgArray)),
    restHistoryState: (bgArray) => dispatch(inputAction.restHistoryState(bgArray)),
    saveDocument: () => dispatch(action.saveDocument()),
    setTootipFlag: () => dispatch(inputAction.setTootipFlag()),
    onBGChanged: (data, flag) => dispatch(inputAction.onBGChanged(data, flag)),
    isChangedHappened: (flag) => dispatch(inputAction.isChangedHappened(flag)),
    setOverwriteSave: (flag) => dispatch(inputAction.setOverwriteSave(flag))
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BottomBar));
