/**
 * @author    Clarion
 * @version 1.0
 * @file   :  PanAndZoomImage
 * @desc   :  This file diaply the preview Image and calculate the width of image
 */

import React, { useEffect, useRef, useState } from "react";
import { IMAGE_SCALE } from "./../../utility/AppsConst";
import "./PanAndZoomImage.css";

const PanAndZoomImage = ({ src, scale, setWidthToImage }) => {
  const [isPanning, setPanning] = useState(false);
  const [position, setPosition] = useState({
    oldX: 0,
    oldY: 0,
    x: 0,
    y: 0,
    z: 1,
  });

  const containerRef = useRef();

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onLoad
   * @desc   :  This function get called on image get loaded and set the content preview panel width.
   */

  const onLoad = ({ target: img }) => {
    if (scale === IMAGE_SCALE) {
      setWidthToImage(img.naturalWidth, IMAGE_SCALE);
    }

    if (
      document.getElementById("preview-image") &&
      img.offsetWidth > img.offsetHeight
    ) {
      document.getElementById("preview-image").style.height = "auto";
      if (
        document.getElementById("content-area-preview").clientWidth - 20 <
        img.offsetWidth
      ) {
        document.getElementById("preview-image").style.width =
          document.getElementById("content-area-preview").clientWidth -
          100 +
          "px";
      }
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onMouseDown
   * @desc   :  This function get called on mouse down event and set the old height and width of the image.
   */

  const onMouseDown = (e) => {
    e.preventDefault();
    setPanning(true);
    setPosition({
      ...position,
      oldX: e.clientX,
      oldY: e.clientY,
    });
  };

  /**
   * @author    React
   * @version 1.0
   * @name   :  useEffect
   * @desc   :  React hook call After component render.
   */

  useEffect(() => {
    const mouseup = () => {
      setPanning(false);
    };

    const mousemove = (event) => {
      if (isPanning) {
        setPosition({
          ...position,
          x: position.x + event.clientX - position.oldX,
          y: position.y + event.clientY - position.oldY,
          oldX: event.clientX,
          oldY: event.clientY,
        });
      }
    };

    window.addEventListener("mouseup", mouseup);
    window.addEventListener("mousemove", mousemove);

    return () => {
      window.removeEventListener("mouseup", mouseup);
      window.removeEventListener("mousemove", mousemove);
    };
  });

  return (
    <div
      className="PanAndZoomImage-container"
      ref={containerRef}
      onMouseDown={onMouseDown}
    >
      <div
        style={{
          transform: `translate(${position.x}px, ${position.y}px) scale(${position.z})`,
        }}
        className="PanAndZoomImage-container-element"
      >
        <img
          className="PanAndZoomImage-image"
          src={src}
          onLoad={onLoad}
          id="previewImg"
        />
      </div>
    </div>
  );
};

export default PanAndZoomImage;
