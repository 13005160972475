/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Bank
 * @desc   :  File For Rendering Bank tab section in control panel
 */

import React from "react";
import "./Bank.css";
import { connect } from "react-redux";
import * as _ from "lodash";
import * as action from "../../store/action/fetchData";
import * as inputSettingction from "../../store/action/inputSettings";
import RoutingAndAccountInput from "./../Common/RoutingAndAccountInput/RoutingAndAccountInput";
import * as viewRender from "../../utility/ViewRender";
import {InputViewRender,InputCheckInfoViewRender, FractionaliewRender} from "../../utility/ViewRender";
import { getVlaueFormSessionByKey } from "./../../utility/Utility";

class Bank extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.inputRefs = [];
    this.inputAccRefs = [];
    this.accountNumberLength = 20;
    let accountNumberLabelStart = 32;
    this.newAccountArray = [];
    this.routingFRef = React.createRef();
    for (let index = 0; index < this.accountNumberLength; index++) {
      this.newAccountArray.push({
        type: "input",
        lable: accountNumberLabelStart,
        value: "",
      });
      accountNumberLabelStart--;
    }
    let isDisplyBankInfo = true;
    const bankInfo = _.filter(this.props.jsonData, (p) => {
      return p && p.name.includes("FXS_Bank");
    });
    if (bankInfo && bankInfo.length === 0) {
      isDisplyBankInfo = false;
    }
    let isFractionalInfo = true;
    const checkFractionData = _.filter(this.props.jsonData, (element) => {
      return element.name && element.name.includes("FXS_Fractional");
    });

    if (checkFractionData && checkFractionData.length === 0) {
      isFractionalInfo = false;
    }
    let isRoutingInfo = true;
    const checkRoutingData = _.filter(this.props.jsonData, (element) => {
      return element.name && element.name.includes("FXS_Routing");
    });
    const checkAccountData = _.filter(this.props.jsonData, (element) => {
      return element.name && element.name.includes("FXS_Account");
    });
    if (checkRoutingData && checkRoutingData.length === 0 && checkAccountData && checkAccountData.length === 0) {
      isRoutingInfo = false;
    }

    const documentType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    let displyAccLine = true;
    if(documentType && documentType.toLowerCase() === "exact"){
      displyAccLine = false;
    }

    this.state = {
      routingNumbers: [
        { type: "image", lable: 43, imageUrl: "images/icon_route-check.png" },
        { type: "input", lable: 42, value: "" },
        { type: "input", lable: 41, value: "" },
        { type: "input", lable: 40, value: "" },
        { type: "input", lable: 39, value: "" },
        { type: "input", lable: 38, value: "" },
        { type: "input", lable: 37, value: "" },
        { type: "input", lable: 36, value: "" },
        { type: "input", lable: 35, value: "" },
        { type: "input", lable: 34, value: "" },
        { type: "image", lable: 33, imageUrl: "images/icon_route-check.png" },
      ],
      accountNumber: this.newAccountArray,
      routingFocusIndex: 1,
      routstrVal: undefined,
      accoutnStrVal: undefined,
      accountFocusIndex: 1,
      isDisplyBankInfo:isDisplyBankInfo,
      isFractionalInfo:isFractionalInfo,
      isRoutingInfo:isRoutingInfo,
      displyAccLine:displyAccLine
    };

    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  handleClickEvent
   * @desc   :  This function used for whenever user focus out after clicking outside the routing and account number fields
   * @return -
   */

  handleClickEvent(e) {
    if (!this.state.routstrVal && this.props.routingValue[0].value) {
      this.setState({
        routstrVal: this.props.routingValue[0].value,
      });
    } else {
      if(e.target.className && e.target.className.includes("fa fa-reply")){ //fa fa-reply reverse
        this.setState({
          classClick: true,
        });
        // e.preventDefault();
        return false;
      }else{
        this.setState({
          classClick: false,
        });
      }
      if (
        e.target.id.indexOf("routingID_") === -1 &&
        e.target.id.indexOf("accountID_") === -1 &&
        e.target.id.indexOf("routingIDBTN_1") === -1 &&
        e.target.id.indexOf("routingIDBTN_2") === -1 &&
        e.target.id.indexOf("routingIDBTN_3") === -1 &&
        e.target.id.indexOf("routingIDBTN_4") === -1 &&
        this.state.routstrVal &&
        this.props.routingValue[0].value !== this.state.routstrVal
      ) {
        
        this.props.onRoutingChange(this.state.routstrVal);
        this.props.onBlurHandaler();
        this.setState({
          routingChangedInput: false,
        });
        
      } 
      if (
        e.target.id.indexOf("routingID_") === -1 &&
        e.target.id.indexOf("accountID_") === -1 &&
        e.target.id.indexOf("routingIDBTN_1") === -1 &&
        e.target.id.indexOf("routingIDBTN_2") === -1 &&
        e.target.id.indexOf("routingIDBTN_3") === -1 &&
        e.target.id.indexOf("routingIDBTN_4") === -1 &&
        this.props.accountValue[0].value !== this.state.accoutnStrVal &&
        this.state.accoutnStrVal &&
        this.state.accoutnStrVal.trim() != ""
      ) {
        this.props.onAccountChangeChange(this.state.accoutnStrVal);
        this.props.onBlurHandaler();
        this.setState({
          accountChangedInput: false,
        });
      }
    }
  }

  /**
   * @author    React
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  Attaching the click event on dom when the components gets load
   * @return -
   */

  componentDidMount() {
    const documentType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    let isdocumentType = false;
    if (
      documentType &&
      documentType !== -1 &&
      (documentType === "Reorder-Exact" || documentType === "Exact")
    ) {
      isdocumentType = true;
    }
    this.setState({
      documentType: isdocumentType,
    });
    document.addEventListener("click", this.handleClickEvent);
    if (
      this.props.routingValue &&
      this.props.routingValue.length > 0 &&
      this.props.routingValue[0].value
    ) {
      const newRoutingval = [...this.state.routingNumbers];
      this.state.routingNumbers.forEach((Element, index) => {
        if (Element.lable !== 43 && Element.lable !== 33) {
          newRoutingval[index].value =
            this.props.routingValue[0].value[index - 1] ||
            this.props.routingValue[0].value[index - 1] === 0
              ? this.props.routingValue[0].value[index - 1]
              : " ";
        }
      });
      this.setState({ routingNumbers: newRoutingval });
    }

    if (
      this.props.accountValue &&
      this.props.accountValue.length > 0 &&
      this.props.accountValue[0].value !== "undefined"
    ) {
      const newAccountValueval = [...this.state.accountNumber];

      this.state.accountNumber.forEach((Element, index) => {
        newAccountValueval[index].value =
          this.props.accountValue[0].value[index] ||
          this.props.accountValue[0].value[index] === 0
            ? this.props.accountValue[0].value[index]
            : " ";
      });

      this.setState({ accountNumber: newAccountValueval });
    }
  }

  componentDidUpdate(nextProps){
    
    if(this.props.routingValue && this.props.routingValue[0] && this.props.routingValue[0].value !=this.state.routstrVal){
        
      if(!this.state.routingChangedInput){
        const newRoutingval = [...this.state.routingNumbers];
        this.state.routingNumbers.forEach((Element, index) => {
        if (Element.lable !== 43 && Element.lable !== 33) {
          newRoutingval[index].value =
            this.props.routingValue[0].value[index - 1] ||
            this.props.routingValue[0].value[index - 1] === 0
              ? this.props.routingValue[0].value[index - 1]
              : " ";
              if(document.getElementById("routingID_"+index)){
                document.getElementById("routingID_"+index).value = newRoutingval[index].value
                document.getElementById("routingID_"+index).select();
              }
        }
      });
      this.setState({ routingNumbers: newRoutingval,routstrVal: this.props.routingValue[0].value });
      }else if(this.state.classClick){
        const newRoutingval = [...this.state.routingNumbers];
        this.state.routingNumbers.forEach((Element, index) => {
          if (Element.lable !== 43 && Element.lable !== 33) {
            newRoutingval[index].value =
            this.props.routingValue[0].value[index - 1] ||
            this.props.routingValue[0].value[index - 1] === 0
              ? this.props.routingValue[0].value[index - 1]
              : " ";
              if(document.getElementById("routingID_"+index)){
                document.getElementById("routingID_"+index).value = newRoutingval[index].value
                document.getElementById("routingID_"+index).select();
              }
          }
        });
        this.setState({ routingNumbers: newRoutingval,routstrVal: this.props.routingValue[0].value });
      }     

    }

    if (
      !this.state.accountChangedInput &&
      this.props.accountValue &&
      this.props.accountValue.length > 0 &&
      this.props.accountValue[0].value !== "undefined" &&
      this.state.accoutnStrVal != this.props.accountValue[0].value
    ) {
      const newAccountValueval = [...this.state.accountNumber];

      this.state.accountNumber.forEach((Element, index) => {
        newAccountValueval[index].value =
          this.props.accountValue[0].value[index] ||
          this.props.accountValue[0].value[index] === 0
            ? this.props.accountValue[0].value[index]
            : " ";
            if(document.getElementById("accountID_"+index)){
              document.getElementById("accountID_"+index).value = newAccountValueval[index].value
              document.getElementById("accountID_"+index).select();
            }
      });

      this.setState({ accountNumber: newAccountValueval,accoutnStrVal: this.props.accountValue[0].value});
    }else if(this.state.classClick && this.props.accountValue &&
      this.props.accountValue.length > 0 &&
      this.props.accountValue[0].value !== "undefined" &&
      this.state.accoutnStrVal != this.props.accountValue[0].value){ 
      const newAccountValueval = [...this.state.accountNumber];

      this.state.accountNumber.forEach((Element, index) => {
        newAccountValueval[index].value =
          this.props.accountValue[0].value[index] ||
          this.props.accountValue[0].value[index] === 0
            ? this.props.accountValue[0].value[index]
            : " ";
            if(document.getElementById("accountID_"+index)){
              document.getElementById("accountID_"+index).value = newAccountValueval[index].value
              document.getElementById("accountID_"+index).select();
            }
      });

      this.setState({ accountNumber: newAccountValueval,accoutnStrVal: this.props.accountValue[0].value});
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onInputBoxChanged
   * @desc   :  This function is for concating string on change of inputs routing values
   * @return -
   */

  onInputBoxChanged = (event, index, isButton = false,id) => {
    let i = 0;
    const routingIndex = this.state.routingNumbers.findIndex((p) => {
      if (i === index) {
        return index;
      } else {
        i++;
      }
    });
    const newRoutingArr = [...this.state.routingNumbers];
    if(this.inputRefs[index]){
      newRoutingArr[routingIndex].value = this.inputRefs[index].value;
    }else if (event.target && event.target.value) {
      newRoutingArr[routingIndex].value = event.target.value;
    } else if (isButton) {
      newRoutingArr[routingIndex].value = event.value;
    } else {
      newRoutingArr[routingIndex].value = "";
    }

    this.setState({
      routingNumbers: newRoutingArr,
    });
    let routstrVal = "";
    for (const key in this.state.routingNumbers) {
      if (this.state.routingNumbers.hasOwnProperty(key)) {
        if (
          this.state.routingNumbers[key].value >= 0 ||
          this.state.routingNumbers[key].value === "D" ||
          this.state.routingNumbers[key].value === "C" ||
          this.state.routingNumbers[key].value === "B" ||
          this.state.routingNumbers[key].value === "A"
        ) {
          routstrVal += this.state.routingNumbers[key].value;
        }
      }
    }
    this.setState({
      routstrVal: routstrVal,
      routingChangedInput: true
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onInputAccountBoxChanged
   * @desc   :  This function is for concating string on change of inputs account inputs values
   * @return -
   */

  onInputAccountBoxChanged = (event, index, isButton = false) => {
    const newAccountArr = [...this.state.accountNumber];
    if(this.inputAccRefs[index]){
      newAccountArr[index].value = this.inputAccRefs[index].value;
    }else if (isButton) {
      newAccountArr[index].value = event.value;
    } else {
      newAccountArr[index].value = event.target.value;
    }
    this.setState({
      accountNumber: newAccountArr,
    });
    let accoutnStrVal = "";

    for (const key in this.state.accountNumber) {
      if (this.state.accountNumber.hasOwnProperty(key)) {
        accoutnStrVal += this.state.accountNumber[key].value
          ? this.state.accountNumber[key].value
          : " ";
      }
    }

    this.setState({
      accoutnStrVal: accoutnStrVal,
      accountChangedInput: true
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  iconRoutingHandler
   * @desc   :  This function is for concating string on click of special charactors button for routing values
   * @return -
   */

  iconRoutingHandler = (ID) => {
    const el = document.getElementById(ID + this.state.routingFocusIndex);
    if (el) {
      var input = el;
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set;
      nativeInputValueSetter.call(input, "D");
      this.onInputBoxChanged(el, this.state.routingFocusIndex, true);
      this.secondTextInputFocus(
        this.state.routingFocusIndex,
        this.state.accoutnStrVal.length,
        2,
        "routingID_"
      );
      this.setState({
        routingChangedInput: true
      })
    }
  };
  iconAccountHandler = (ID, type) => {
    const el = document.getElementById(ID + this.state.routingFocusIndex);
    if (el) {
      var input = el;
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set;
      if (type === "D") {
        nativeInputValueSetter.call(input, "D");
      } else if (type === "C") {
        nativeInputValueSetter.call(input, "C");
      } else if (type === "S") {
        nativeInputValueSetter.call(input, " ");
      }

      this.onInputAccountBoxChanged(el, this.state.routingFocusIndex, true);
      this.secondTextInputFocus(
        this.state.routingFocusIndex,
        this.state.accountNumber.length,
        0,
        "accountID_"
      );
      this.setState({
        accountChangedInput: true
      })
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  secondTextInputFocus
   * @desc   :  This function set the focus out index of Routing and account fields
   * @return -
   */

  secondTextInputFocus = (index, length, minus, id) => {
    const finalLen = length - minus;
    if (finalLen !== index) {
      const idNum = index + 1;
      const newFousIndex = idNum;
      this.setState({
        routingFocusIndex: newFousIndex,
      });

      if (document.getElementById(id + idNum)) {
        document.getElementById(id + idNum).focus();
        document.getElementById(id + idNum).select();
      }else if(id === "accountID_" && document.getElementById(id+index)){
        document.getElementById(id + index).focus();
        document.getElementById(id + index).select();
      }
    }else{
      const indAccNum = index-1;
      if (document.getElementById(id+index)) {
        document.getElementById(id + index).focus();
        document.getElementById(id + index).select();
      }else if(id === "accountID_" && document.getElementById(id+indAccNum)){
        document.getElementById(id + indAccNum).focus();
        document.getElementById(id + indAccNum).select();
      }
    }
  };

  onBlurHandaler = (isFocusOut, isNew, isImage, focusOutElement, event) => {
    this.props.onBlurHandaler(isFocusOut, isNew, isImage, focusOutElement, event)
  };

  render() {
    const RoutingInPuts = this.state.routingNumbers.map((data, index) => {
      return (
        <div className="routing-number__section" key={index}>
          <RoutingAndAccountInput
            data={data}
            index={index}
            araryLength={this.state.routingNumbers.length}
            routingNumber={index !== 0 && index !== 10 && data.value}
            minus={2}
            ID={"routingID_"}
            onInputBoxChanged={(event) => this.onInputBoxChanged(event, index)}
            secondTextInputFocus={(event) =>
              this.secondTextInputFocus(
                index,
                this.state.routingNumbers.length,
                2,
                "routingID_"
              )
            }
            onFocusHandaler={(event) => {
              event.target.select();
              this.setState({ routingFocusIndex: index })
            }}
            isFieldDisabled={this.state.documentType}
            ref={ref=>this.inputRefs[index]=ref}
          ></RoutingAndAccountInput>
        </div>
      );
    });

    const AccountNumberInPut = this.state.accountNumber.map((data, index) => {
      return (
        <div className="routing-number__section" key={index}>
          <RoutingAndAccountInput
            data={data}
            index={index}
            araryLength={this.state.accountNumber.length}
            routingNumber={data.value}
            minus={0}
            ID={"accountID_"}
            onInputBoxChanged={(event) =>
              this.onInputAccountBoxChanged(event, index)
            }
            secondTextInputFocus={(event) =>
              this.secondTextInputFocus(
                index,
                this.state.accountNumber.length,
                0,
                "accountID_"
              )
            }
            isFieldDisabled={this.state.documentType}
            onFocusHandaler={(event) =>{
              event.target.select();
              this.setState({ routingFocusIndex: index });              
            } 
            }
            ref={ref=>this.inputAccRefs[index]=ref}
          ></RoutingAndAccountInput>
        </div>
      );
    });

    return (
      <div>
        <div
          className="company__input-wrapper"
          style={{ display: this.state.documentType ? "none" : "block" }}
        >
          {this.state.isDisplyBankInfo && (
            <div className="text-uppercase font-semibold m-b-15">
              Bank name information
            </div>
          )}
          
          {
          // this.props.bankInfo &&
          //   viewRender.InputViewRender(
          //     this.props.bankInfo,
          //     "bankInfo",
          //     "BANK LINE",
          //     this.props,
          //     this.state.documentType,
          //     [],
          //     this.props.boxName,
          //     this.props.popUpClass
          //   )
          this.props.bankInfo && <InputViewRender
          renderArr={this.props.bankInfo}
          section={"bankInfo"}
          label={"BANK LINE "}
          jsonData={this.props.jsonData}
          isFieldDisabled={this.state.documentType}
          imageType={[]}
          boxName={this.props.boxName}
          popUpClass={this.props.popUpClass}
          onInputChanged={this.props.onInputChanged}
          onBlurHandaler={this.onBlurHandaler}
          onFocusHandaler={this.props.onFocusHandaler}
          setFontPopupClass={this.props.setFontPopupClass}
        ></InputViewRender>
          
            }
        {this.state.isFractionalInfo && (
          <div className="text-uppercase font-semibold m-t-35 m-b-15">
            Bank Fractional number
          </div>
        )}
          
          {
          // this.props.fractionalInfo &&
          //   viewRender.FractionaliewRender(
          //     this.props.fractionalInfo,
          //     this.props,
          //     this.state.documentType,
          //     this.props.boxName,
          //     this.props.popUpClass
          //   )
          this.props.fractionalInfo && <FractionaliewRender
        renderArr={this.props.fractionalInfo}
          // section={"checkInfo"}
          // label={"Start check"}
          jsonData={this.props.jsonData}
          isFieldDisabled={this.state.documentType}
          boxName={this.props.boxName}
          popUpClass={this.props.popUpClass}
          onInputChanged={this.props.onInputChanged}
          onBlurHandaler={this.onBlurHandaler}
          onFocusHandaler={this.props.onFocusHandaler}
          setFontPopupClass={this.props.setFontPopupClass}
        ></FractionaliewRender>
            }
        </div>
          {
            this.state.isRoutingInfo && (
              <div className="text-uppercase font-semibold m-t-35 m-b-15">
                Start check # {this.state.displyAccLine && "and account number"} 
              </div>
            )
          }
        
        <div className="company__input-wrapper">
        {
        // this.props.checkInfo &&
        //   viewRender.InputCheckInfoViewRender(
        //     this.props.checkInfo,
        //     "checkInfo",
        //     "Start check",
        //     this.props
        //   )
        this.props.checkInfo && <InputCheckInfoViewRender
        renderArr={this.props.checkInfo}
          section={"checkInfo"}
          label={"Start check "}
          jsonData={this.props.jsonData}
          sessionData={this.props.sessionData}
          isFieldDisabled={this.state.documentType}
          onInputChanged={this.props.onInputChanged}
          onBlurHandaler={this.onBlurHandaler}
          onFocusHandaler={this.props.onFocusHandaler}
          setFontPopupClass={this.props.setFontPopupClass}
        ></InputCheckInfoViewRender>
          }
        </div>
        <div style={{ display: this.state.documentType ? "none" : "block" }}>
          <div className="inp-wrapper white-bg routing-number">
            {RoutingInPuts}
            <button
              id="routingIDBTN_1"
              onClick={(event) => this.iconRoutingHandler("routingID_")}
            >
              <img src="images/icon_route-barcode.png" />
            </button>
            <label className="text-uppercase">Routing Number</label>
          </div>

          <div className="inp-wrapper white-bg routing-number account-routing-number">
            <div className="account-number-wrapper">{AccountNumberInPut}</div>
            <div className="button-wrapper">
              <button
                id="routingIDBTN_2"
                onClick={(event) => this.iconAccountHandler("accountID_", "S")}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;
              </button>
              <button
                id="routingIDBTN_3"
                onClick={(event) => this.iconAccountHandler("accountID_", "C")}
              >
                <img src="images/icon_route-barcode01.png" />
              </button>
              <button
                id="routingIDBTN_4"
                onClick={(event) => this.iconAccountHandler("accountID_", "D")}
              >
                <img src="images/icon_route-barcode.png" />
              </button>
            </div>
            <label className="text-uppercase">Account Number</label>
          </div>
        </div>
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    bankInfo: state.inputDataReducer.bankInfo,
    fractionalInfo: state.inputDataReducer.fractionalInfo,
    checkInfo: state.inputDataReducer.checkInfo,
    routingValue: state.inputDataReducer.RoutingValue,
    accountValue: state.inputDataReducer.accountValue,
    jsonData: state.fetchDataReducer.jsonData,
    sessionData: state.fetchDataReducer.sessionData,
    boxName: state.inputDataReducer.boxName,
    popUpClass: state.inputDataReducer.popUpClass,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    onInputChanged: (event, name, section,value,spanindex,sessionData) =>
      dispatch(action.onInputChanged(event, name, section,value,spanindex,sessionData)),
    onBlurHandaler: () => dispatch(action.onBlurHandaler()),
    onSignLineChange: (signLine) =>
      dispatch(inputSettingction.setOverSigLineState(signLine)),
    onRoutingChange: (routingValue) =>
      dispatch(inputSettingction.onRoutingChange(routingValue)),
    onAccountChangeChange: (accountValue) =>
      dispatch(inputSettingction.onAccountChangeChange(accountValue)),
    onFocusHandaler: (data) =>
      dispatch(inputSettingction.onFocusHandaler(data)),
    setFontPopupClass: (currentClass) =>
      dispatch(inputSettingction.setFontPopupClass(currentClass)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bank);
