import React from "react";
import Spinner from "./../Spinner/Spinner";
import { FRONT_END_LOGO_DISPLAY_PATH,DOMAIN_INFORMATION } from "./../../../utility/AppsConst";
import { displayColorMatchLogos } from "./../../../utility/Utility";
import "./LogoPopUp.css";

export const LogoPopUp = (props) => {
  /* Popup Show hide style according o flag*/
  let openPopup = {
    display: "none",
  };
  if (props.togglePopup) {
    openPopup = {
      display: "Block",
    };
  }

  /* Error messages*/
  let customerLogoNotFount = "No Customer Logos Available.";
  let sideLogoNotFound = "Side logo not found.";
  let topLogoNotFound = "Top logo not found.";
  const hotname = window.location.hostname;
  let domainInfo = DOMAIN_INFORMATION["default"];
  if (DOMAIN_INFORMATION[hotname] != "undefined") {
    domainInfo = DOMAIN_INFORMATION[hotname];
  }
  return (
    <div
      id="myModalLogo"
      className="modal popup-choose-a-logo"
      style={openPopup}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3 className="txt-uppercase"> Choose a Logo </h3>
          <span className="close" onClick={props.closePopup}>
            &times;
          </span>
        </div>
        <div className="modal-body">
          <div className="customer-logo logo-position logo-with-title">
            <h4>Customer Logos</h4>
            {!props.pathValue && (
                <em id="logo-message-contact">
                  If you would like to add a custom logo to your order please <a href={domainInfo["LOGIN_PAGE"] ? domainInfo["LOGIN_PAGE"] : "#"}>click here</a> to go back to the home page to log in or create an account or call us at <br />
                  {domainInfo["PHONE_NUMBER"] ? domainInfo["PHONE_NUMBER"] : "#"}
                </em>
              )}

            <div
              className={
                props.logoFiles.Customer_Logos &&
                props.logoFiles.Customer_Logos.length <= 0
                  ? "place-the-logo place-the-logo-with-error"
                  : "place-the-logo"
              }
            >
              {props.logoFiles.Customer_Logos &&
                props.logoFiles.Customer_Logos.length > 0 &&
                props.logoFiles.Customer_Logos.map((data, index) => {
                  
                  if (data.PREVIEW_FILE) {
                    
                    if (
                      parseInt(props.colorCode) >= 2 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length > 0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                      let isLogogFound = true;
                      for (const key in data.FXS_COMPONENT) {
                        if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                          if (
                            data.FXS_COMPONENT[key].ATTR &&
                            data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                            data.FXS_COMPONENT[
                              key
                            ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                          ) {
                            isLogogFound = false;
                          } else {
                            isLogogFound = true;
                          }
                        }
                      }
                      if (isLogogFound) {
                        customerLogoNotFount = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Customer_Logos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Customer_Logos/" +
                                  props.imageFolder +
                                  "/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Customer_Logos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                      console.log("data.FXS_COMPONENT_12")
                    } else if (
                      parseInt(props.colorCode) <= 1 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length > 0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                      let isLogogFound = true;
                      // #5322 Color count is fetched excluding Knockout Component for Session Tag Color =0 or 1 only 1 color logo will be displayed.
                      const colorCount=data.FXS_COMPONENT.filter((item)=>item.ATTR && !item.ATTR.IS_KNOCKOUT)
                      
                      if(colorCount.length!=1){
                        isLogogFound=false
                      }
                      // for (const key in data.FXS_COMPONENT) {
                      //   if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                      //     if (
                      //       data.FXS_COMPONENT[key].ATTR &&
                      //       data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                      //       data.FXS_COMPONENT[
                      //         key
                      //       ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                      //     ) {
                      //       isLogogFound = false;
                      //     }
                      //   }
                      // }

                      if (isLogogFound) {
                        customerLogoNotFount = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Customer_Logos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Customer_Logos/" +
                                  props.imageFolder +
                                  "/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Customer_Logos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                    }
                  }
                })}
              {customerLogoNotFount && props.pathValue && <div>{customerLogoNotFount}</div>}
            </div>
          </div>
          <div className="logo-position logo-with-title">
            <h4>Side Positioned Logos</h4>
            <div className="place-the-logo">
              {props.logoFiles.Generic_SideLogos &&
                props.logoFiles.Generic_SideLogos.length > 0 &&
                props.logoFiles.Generic_SideLogos.map((data, index) => {
                  if (data.PREVIEW_FILE) {
                    if (
                      parseInt(props.colorCode) > 1 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length > 0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                      let isLogogFound = true;
                      for (const key in data.FXS_COMPONENT) {
                        if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                          if (
                            data.FXS_COMPONENT[key].ATTR &&
                            data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                            data.FXS_COMPONENT[
                              key
                            ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                          ) {
                            isLogogFound = false;
                          } else {
                            isLogogFound = true;
                          }
                        }
                      }

                      if (isLogogFound) {
                        sideLogoNotFound = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Generic_SideLogos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Generic_SideLogos/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Generic_SideLogos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                    } else if (
                      parseInt(props.colorCode) <= 1 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length > 0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                     let isLogogFound = true;
                      // #5322-Color count is fetched excluding Knockout Component for Session Tag Color =0 or 1 only 1 color logo will be displayed.
                      const colorCount=data.FXS_COMPONENT.filter((item)=>item.ATTR && !item.ATTR.IS_KNOCKOUT)
                      
                      if(colorCount.length!=1){
                        isLogogFound=false
                      }
                      // for (const key in data.FXS_COMPONENT) {
                      //   if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                      //     if (
                      //       data.FXS_COMPONENT[key].ATTR &&
                      //       data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                      //       data.FXS_COMPONENT[
                      //         key
                      //       ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                      //     ) {
                      //       isLogogFound = false;
                      //     }
                      //   }
                      // }
                      if (isLogogFound) {
                        sideLogoNotFound = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Generic_SideLogos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo ,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles                   
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Generic_SideLogos/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Generic_SideLogos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                    }
                  }
                })}
              {sideLogoNotFound && <div>{sideLogoNotFound}</div>}
            </div>
          </div>
          <div className="logo-position logo-with-title">
            <h4>Top Positioned Logos</h4>
            <div className="place-the-logo">
              {props.logoFiles.Generic_TopLogos &&
                props.logoFiles.Generic_TopLogos.length > 0 &&
                props.logoFiles.Generic_TopLogos.map((data, index) => {
                  if (data.PREVIEW_FILE) {
                    if (
                      parseInt(props.colorCode) > 1 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length > 0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                      let isLogogFound = true;
                      for (const key in data.FXS_COMPONENT) {
                        if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                          if (
                            data.FXS_COMPONENT[key].ATTR &&
                            data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                            data.FXS_COMPONENT[
                              key
                            ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                          ) {
                            isLogogFound = false;
                          } else {
                            isLogogFound = true;
                          }
                        }
                      }
                      if (isLogogFound) {
                        topLogoNotFound = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Generic_TopLogos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo ,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles     
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Generic_TopLogos/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Generic_TopLogos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                    } else if (
                      parseInt(props.colorCode) <= 1 &&
                      data.FXS_COMPONENT &&
                      data.FXS_COMPONENT.length >0 &&
                      data.COLORS[0].COLOR &&
                      data.COLORS[0].COLOR.length > 0
                    ) {
                      let isLogogFound = true;
                     // #5322-Color count is fetched excluding Knockout Component for Session Tag Color =0 or 1 only 1 color logo will be displayed.
                      const colorCount=data.FXS_COMPONENT.filter((item)=>item.ATTR && !item.ATTR.IS_KNOCKOUT)
                   
                      if(colorCount.length!=1){
                        isLogogFound=false
                      }
                      // for (const key in data.FXS_COMPONENT) {
                      //   if (data.FXS_COMPONENT.hasOwnProperty(key)) {
                      //     if (
                      //       data.FXS_COMPONENT[key].ATTR &&
                      //       data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
                      //       data.FXS_COMPONENT[
                      //         key
                      //       ].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
                      //     ) {
                      //       isLogogFound = false;
                      //     }
                      //   }
                      // }

                      if (isLogogFound) {
                        topLogoNotFound = "";
                        return (
                          <div
                            className="choose-the-logo"
                            key={index}
                            style={{
                              border:
                              props.logoFrom === "Generic_TopLogos" && (props.mainLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "main_logo") ||
                                (props.phantomLogo_image_name ===
                                  data.DISPLAY_NAME &&
                                  props.imageTyle === "phantom_logo")
                                  ? "2px solid #2196f3"
                                  : "none",
                              display: displayColorMatchLogos(
                                props.logoColorsArr,
                                data,
                                props.colorCode,
                                props.firstLogo,
                                props.selectedImage,
                                props.mainLogo,
                                props.phantomLogo,
                                props.logoFiles                
                              ),
                            }}
                          >
                            <div className="place-logo-imgsec">
                              <img
                                src={
                                  FRONT_END_LOGO_DISPLAY_PATH +
                                  "Generic_TopLogos/" +
                                  data.subFolder +
                                  "/" +
                                  data.PREVIEW_FILE
                                }
                                alt={data.DISPLAY_NAME}
                                title={data.DISPLAY_NAME}
                                onClick={() =>
                                  props.imageSelected(
                                    data,
                                    props.imageTyle,
                                    "Generic_TopLogos"
                                  )
                                }
                              />
                            </div>
                            <div
                              className="logo-image-name"
                              title={data.DISPLAY_NAME}
                            >
                              {data.DISPLAY_NAME}
                            </div>
                          </div>
                        );
                      }
                    }
                  }
                })}
              {topLogoNotFound && <div>{topLogoNotFound}</div>}
            </div>
          </div>
          <div className="logo-popup-bottom">
            <div className="logo-popup-bottom__left">
              {/* {props.pathValue && ( */}
                <React.Fragment>
                  <input
                    type="file"
                    name="customerFile"
                    id="customerFile"
                    onChange={(event) => props.onfileChanged(event)}
                    disabled={props.pathValue ? false : true}
                    style={{cursor:"pointer"}}
                  />
                  <div className="logo-popup-bottom__left--upload">
                     <img
                          width="20"
                          src="images/button-Upload-icon.png"
                          alt="Upload-Logo"
                          title="Upload-Logo"
                          className="upload-logo"
                        />
                        
                    
                      <div>
                        {props.pathValue ?<span>upload logo</span> :  <span className="not-login-msg">Login to Upload Logo</span>}                        
                        
                        {props.pathValue &&(
                          <React.Fragment>
                          <small>Max 10 MB</small>
                          <small>
                            Formats:{" "}
                            {props.fileTypeStr
                              ? props.fileTypeStr
                              : "AI, EPS, JPG, PDF, PNG, PSD, TIF"}
                          </small>
                          </React.Fragment>
                        )}
                      </div>                    
                  </div>
                </React.Fragment>
              {/* )} */}
            </div>
            {props.imageLoadingVal > 0 && <Spinner />}

            <button
              className="button-square button-blue upload-button-right"
              onClick={props.setTheLogo}
            >
              Place Logo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
