/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Review
 * @desc   :  This file contained the review tab funcitonlaity
 *
 *  */

import React from "react";
import { connect } from "react-redux";
import { getVlaueFormSessionByKey } from "./../../utility/Utility";
import * as action from "../../store/action/fetchData";
import * as inputSettingction from "../../store/action/inputSettings";
import "./Review.css";

class Review extends React.Component {
  constructor(props) {
    super(props);
    const specialIntsructionIndex=props.sessionData.findIndex((item)=>item.name==="Special_Instructions")

    this.state = {
      userType: getVlaueFormSessionByKey(
        props.sessionData,
        "User_Level",
        "cdata"
      ),
      'specialInstructions':props.sessionData[specialIntsructionIndex] && props.sessionData[specialIntsructionIndex].cdata
    };
  }
/*#5731 special Intsruction Missing changes*/
  componentDidMount(){
    if(this.state.specialInstructions!=""){
     this.props.setDesignInstruction(this.state.specialInstructions, true);
    }
  }
  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setDesignInstruction
   * @desc   :  This function set the design instruction value to the redux store
   * @return -
   */

  setDesignInstruction = event => {
    if (event.target.value) {
      this.props.setDesignInstruction(event.target.value, true);
      //this.props.onBlurHandaler();
    }
  };

  render() {
    return (
      <div>
        <div className="review-wrapper">
          <div className="text-uppercase font-semibold m-b-15">
            Review carefully!
          </div>
          <p>
            Please review all the values on your form before continuing. If you
            have any special instructions, type it in the box below.
          </p>
          <p>
            If everything looks correct, click the Add to Cart button below to
            finish your order.
          </p>

          <div className="text-uppercase font-semibold m-b-15 spl-instruction">
            Special Instructions
          </div>
          <textarea
            placeholder="Special Instructions..."
            onBlur={event => this.setDesignInstruction(event)}
            defaultValue={
              this.props.Design_Instructions &&
              this.props.Design_Instructions[0]
                ? this.props.Design_Instructions[0].value
                :(this.state.specialInstructions !="")?this.state.specialInstructions:""
            }
          ></textarea>
          {this.state.userType === "Admin" ? (
            <button
              className="button-square button-blue"
              onClick={() => this.props.createPDF("pdf")}
            >
              Preview PDF
            </button>
          ) : (
            <button
              className="button-square button-blue"
              onClick={() => this.props.createPDF("pdfproof")}
            >
              Preview PDF
            </button>
          )}
        </div>
      </div>
    );
  }
}
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = state => {
  return {
    sessionData: state.fetchDataReducer.sessionData,
    Design_Instructions: state.inputDataReducer.Design_Instructions
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action and data
 */

const mapDispatchToProps = dispatch => {
  return {
    onBlurHandaler: () => dispatch(action.onBlurHandaler()),
    createPDF: downloadType => dispatch(action.downloadPDF(downloadType)),
    setDesignInstruction: (data, flag) =>
      dispatch(inputSettingction.setDesignInstruction(data, flag))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Review);
