/**
 * @author    React
 * @version 1.0
 * @file   :  App
 * @desc   :  App.js is an root component file for CFI project, In this component we are added the routing functionality
 */
import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import TopBar from "./containers/TopBar/TopBar";
import PreviewControlPanel from "./containers/PreviewControlPanel/PreviewControlPanel";

class App extends React.Component {
  render() {
    return (
      <div>
        {this.props.sessionDataError && (
          <div>{this.props.sessionDataError}</div>
        )}

        <React.Fragment>
          <TopBar />
          <div className="content">
            <div className="container">
              <Switch>
                <Route path="/" exact component={PreviewControlPanel} />
              </Switch>
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = dispatch => {
  return {};
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = state => {
  return {
    sessionDataError: state.fetchDataReducer.errMessageSession
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
