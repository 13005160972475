/**
 * @author    Clarion
 * @version 1.0
 * @file   :  TopBar
 * @desc   :  This file contained the logo and top bar functionality for Editor. This includes the domain logo, page nummbers, zoom in/out/reset and download functionality.
 */
import React from "react";
import { connect } from "react-redux";
import * as action from "../../store/action/fetchData";
import * as inputSettingction from "../../store/action/inputSettings";
import { getVlaueFormJsonByKey } from "./../../utility/Utility";
import ReactTooltip from "react-tooltip";

import "./TopBar.css";

import {
  IMAGE_WIDTH_INCREMENT,
  IMAGE_SCALE,
  DOMAIN_INFORMATION,
} from "./../../utility/AppsConst";

class TopBar extends React.Component {
  constructor(props) {
    super(props);
    const hotname = window.location.hostname;
    let domainInfo = DOMAIN_INFORMATION["default"];

    if (DOMAIN_INFORMATION[hotname] != "undefined") {
      domainInfo = DOMAIN_INFORMATION[hotname];
    }

    this.state = {
      currentPage: 1,
      displayDropDown: true,
      domainInfo: domainInfo,
    };
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  handleClickEvent
   * @desc   :  Function is called on resizing the scrren and set the state flag for displayDropDown to toggle the download list option on mobile device
   * @returns:
   */

  handleClickEvent(e) {
    let displayDropDown;
    if (window.innerWidth <= 991) {
      displayDropDown = false;
    } else {
      displayDropDown = true;
    }
    this.setState({ displayDropDown: displayDropDown });
  }

  /**
   * @author    React
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  componentDidMount funciton get called once after component get initilised. We set the displayDropDown flag in state according to width of screen.
   * @returns:
   */

  componentDidMount() {
    if (window.innerWidth <= 991) {
      this.setState({
        displayDropDown: false,
      });
    }
    window.addEventListener("resize", this.handleClickEvent);
  }
  /**
   * @author    React
   * @version 1.0
   * @name   :  componentWillUnmount
   * @desc   :  componentWillUnmount funciton get called once after component get destroied. We can dedached the events.
   * @returns:
   */

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleClickEvent);
  }

  /**
   * @author    React
   * @version 1.0
   * @name   :  nextPreviousPage
   * @desc   :  This funciton get called on clicked left and right arrows button on top bar for next and previous page.
   * @returns:
   */

  nextPreviousPage = (type) => {
    let currentPage = this.state.currentPage;
    if (type === "next") {
      currentPage = currentPage + 1;
    } else {
      currentPage = currentPage - 1;
    }
    this.setState({
      currentPage: currentPage,
    });
    this.props.setCurentPage(currentPage, true);
    this.props.onBlurHandaler();
  };

  showHideDropDown = (event) => {
    const isDisplay = !this.state.displayDropDown;
    this.setState({
      displayDropDown: isDisplay,
    });
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  IncreseZoom
   * @desc   :  This function called clicked on increase zoom options and set the new increased width for preview image in redux stored
   * @return -
   */

  IncreseZoom = () => {
    let newWidth = 0;
    const scale = this.props.scale + IMAGE_SCALE;

    if (this.props.imageWith > 0) {
      const add = IMAGE_WIDTH_INCREMENT * (scale * 2);
      newWidth = this.props.imageWith + add;
    }

    this.props.setImageWidth(newWidth, scale);

    if (newWidth > this.props.lastImageWidth) {
      this.props.inputChangedOnZoom();
      this.props.setLatIncresedWidth(newWidth);
      this.props.onBlurHandaler(true, false);
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  DecreaseZoom
   * @desc   :  This function called clicked on increase zoom options and set the new increased width for preview image in redux stored
   * @return -
   */

  DecreaseZoom = () => {
    let newWidth = 0;
    const scale = this.props.scale - IMAGE_SCALE;
    if (this.props.imageWith > 0) {
      const dec = IMAGE_WIDTH_INCREMENT * (scale * 2) + 400;
      newWidth = this.props.imageWith - dec;
    }

    if (scale >= 1) {
      this.props.setImageWidth(newWidth, scale);
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  restZoomImage
   * @desc   :  This function called on click on rest zoom button and reset the image original width and update the redux store
   * @return -
   */

  restZoomImage = () => {
    if (this.props.scale > IMAGE_SCALE) {
      if (this.props.imageWith > 0) {
        const imageWith =
          this.props.imageWith -
          IMAGE_WIDTH_INCREMENT * (this.props.scale - IMAGE_SCALE);
        this.props.setImageWidth(imageWith, IMAGE_SCALE);
      }
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  toggleMobileDisplay
   * @desc   :  This function called on click on rest zoom button and reset the image original width and update the redux store
   * @return -
   */

  toggleMobileDisplay = (type) => {
    const isDisplayControlPanel = !this.props.isDisplayControlPanel;
    const isDisplayPreviewPanel = !this.props.isDisplayPreviewPanel;

    this.props.setToggelePreviewData({
      isDisplayPreviewPanel: isDisplayPreviewPanel,
      isDisplayControlPanel: isDisplayControlPanel,
    });
  };

  render() {
    let isAdmin;
    const findUserIndex = this.props.sessionData.findIndex(
      (element) => element.name === "User_Level"
    );
    if (
      findUserIndex !== -1 &&
      this.props.sessionData[findUserIndex].cdata === "Admin"
    ) {
      isAdmin = true;
    } else {
      isAdmin = false;
    }
    let isMultiPageDocument = false;
    let pageCount = 0;
    if (typeof this.props.jsonData !== "undefined") {
      pageCount = getVlaueFormJsonByKey(
        this.props.jsonData,
        "PageCount",
        "content"
      );

      if (pageCount && pageCount !== -1) {
        isMultiPageDocument = true;
      }
    }

    return (
      <div>
        <header className="header" id="header">
          <div className="container">
            <div className="header__content">
              <div className="header__logo">
                {this.props.isDisplayPreviewPanel && this.props.isMobileFlag && (
                  <button
                  className="font_button font_button--left"
                    onClick={(event) => this.toggleMobileDisplay("left")}
                  >
                    <i className="fa fa-edit" data-tip="Edit Document"></i>
                    <ReactTooltip />
                  </button>
                )}
                {this.props.isDisplayControlPanel && (
                  <React.Fragment>
                    <a href="#">
                      {this.state.domainInfo && (
                        <img
                          src={"images/" + this.state.domainInfo.logo}
                          alt="Computer Forms, Inc."
                          title="Computer Forms, Inc."
                          className="img-flexible"
                        />
                      )}
                    </a>
                    {this.props.isMobileFlag && (
                      <button
                      className="font_button"
                        onClick={() => this.toggleMobileDisplay("right")}
                      >
                        <i className="fa fa-eye" data-tip="Preview Document"></i>
                        <ReactTooltip />
                      </button>
                    )}
                  </React.Fragment>
                )}
              </div>

              {this.props.isDisplayPreviewPanel && (
                <div className="header__content-right">
                  <div
                    className="header__content-right-wrapper hideshowoptions"
                    style={{
                      display: this.state.displayDropDown ? "flex" : "none",
                    }}
                  >
                    <div className="screen-adjust-icon">
                      <a
                        className="cursor-pointer"
                        onClick={this.restZoomImage}
                        data-tip="Reset Zoom"
                      >
                        <img
                          width="20"
                          src="images/full_screen.png"
                          alt="full screen"
                          title="full screen"
                        />
                      </a>
                      <a
                        className="cursor-pointer"
                        alt="Zoom In"
                        title="Zoom In"
                        onClick={this.IncreseZoom}
                        data-tip="Zoom In"
                      >
                        <i className="fa fa-search-plus"></i>
                      </a>
                      <a
                        className="cursor-pointer"
                        alt="Zoom Out"
                        title="Zoom Out"
                        onClick={this.DecreaseZoom}
                        data-tip="Zoom Out"
                      >
                        <i className="fa fa-search-minus"></i>
                      </a>
                    </div>
                    <div className="view-options">
                      <a
                        className="cursor-pointer"
                        onClick={() => this.props.createPDF("pdf")}
                        style={{ display: isAdmin ? "block" : "none" }}
                        data-tip="Download PDF"
                      >
                        <img
                          width="20"
                          src="images/icon_pdf.png"
                          alt="Download PDF"
                          title="Download PDF"
                        />
                      </a>
                      <a className="cursor-pointer" data-tip="Download PDF Proof">
                        <img
                          width="18"
                          src="images/icon_create-pdf.png"
                          alt="Create PDF"
                          title="Create PDF"
                          onClick={() => this.props.createPDF("pdfproof")}
                        />
                      </a>
                      <a
                        href="#"
                        id="linkid"
                        target="_blank"
                        className="display-none"
                      ></a>
                    </div>
                    {/* <ReactTooltip /> */}
                  </div>
                  <button
                    className="dots-dropdown font_button"
                    onClick={(event) => this.showHideDropDown(event)}
                  >
                    <i className="fa fa-ellipsis-v"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    // isInputChanged: state.inputDataReducer.isInputChanged,
    sessionData: state.fetchDataReducer.sessionData,
    jsonData: state.fetchDataReducer.jsonData,
    scale: state.fetchDataReducer.scale,
    imageWith: state.fetchDataReducer.imageWith,
    lastImageWidth: state.fetchDataReducer.lastImageWidth,
    isDisplayPreviewPanel: state.fetchDataReducer.isDisplayPreviewPanel,
    isDisplayControlPanel: state.fetchDataReducer.isDisplayControlPanel,
    isMobileFlag: state.fetchDataReducer.isMobileFlag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    onBlurHandaler: (isFocusOut, isNew) =>
      dispatch(action.onBlurHandaler(isFocusOut, isNew)),
    createPDF: (downloadType) => dispatch(action.downloadPDF(downloadType)),
    setCurentPage: (currentPage, flag) =>
      dispatch(inputSettingction.setCurentPage(currentPage, flag)),
    setImageWidth: (width, scale) =>
      dispatch(action.setImageWidth(width, scale)),
    inputChangedOnZoom: () => dispatch(inputSettingction.inputChangedOnZoom()),
    setToggelePreviewData: (data) =>
      dispatch(action.setToggelePreviewData(data)),
    setLatIncresedWidth: (data) => dispatch(action.setLatIncresedWidth(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
