/**
 * @author    Clarion
 * @version 1.0
 * @file   :  PreviewControlPanel
 * @desc   :  This file render the left control panel and preview image panel
 */

import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import swal from 'sweetalert';

import * as action from "./../../store/action/fetchData";
import * as inputSettingction from "./../../store/action/inputSettings";
import {
  mergeBoxDefUserObject,
  getSectionByTitle,
  getFontIdByFontStyle,
  getVlaueFormSessionByKey,
  getInputSelection,
  setSessinoStorage,
  getSessionStore,
  getBgDefault,
} from "./../../utility/Utility";

import "./FormatingPopup.css";

class FormatingPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popUpClass: "pop-up hidePopup",
      styleObject: { ...this.props.styleObj },
      toggleAlign: false,
      isDisabledItalic: false,
      isDisabledBold: false,
      tooltipText: "Click here to change the formatting of the text",
    };
    this.markUnmarkBold = this.markUnmarkBold.bind(this);
    this.markUnmarkItalic = this.markUnmarkItalic.bind(this);
    this.markUnmarkUnderline = this.markUnmarkUnderline.bind(this);
    this.setFontSize = this.setFontSize.bind(this);
    this.setFontFamily = this.setFontFamily.bind(this);
    this.setTextAlign = this.setTextAlign.bind(this);
  }

  /**
   * @author    React
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  componentDidMount funciton get called once after component get initilised.
   *            Get the session file ID to red the session file and start the sesstion.
   * @returns:
   */

  componentDidMount = async () => {
    document.addEventListener("click", this._handleClick);
  };

/**5918-Single Stub line formatting on load set method */
  setFontFamilyonLoad(styleObj){
      let styleObjNew ={
      'fontFamily':this.getFontFamilyFromFontId(styleObj.fontId).fontName,
      'fontWeight':this.getFontFamilyFromFontId(styleObj.fontId).fontWeight,
      'fontStyle':this.getFontFamilyFromFontId(styleObj.fontId).fontStyle,
      ...styleObj
      }
  return styleObjNew
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  markUnmarkBold
   * @desc   :   This function called when clicked on bold Icon to toggle the bold
   * @return -
   */

  markUnmarkBold = (event) => {
    
      let newStyleObject = { ...this.state.styleObject };
      if (this.state.styleObject.fontWeight === "bold") {
        newStyleObject.fontWeight = "unset";
      } else {
        newStyleObject.fontWeight = "bold";
      } 
      const newFontID = this.getFontID(
        this.state.styleObject.fontFamily,
        newStyleObject.fontWeight,
        this.state.styleObject.fontStyle
      );
      if (newFontID !== -1) {
        newStyleObject.fontId = newFontID.fontId;
      }
      
        this.setState({ styleObject: newStyleObject });
        this.isFlag = true;
     
        
    this.props.setIsInputChangedState(true);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  markUnmarkItalic
   * @desc   :   This function called when clicked on italic Icon (I) to toggle the italic
   * @return -
   */

  markUnmarkItalic = (event) => {
    const newStyleObject = { ...this.state.styleObject };
    // console.log("newStyleObject",newStyleObject)
    if (this.state.styleObject.fontStyle === "italic") {
      newStyleObject.fontStyle = "normal";
    } else {
      newStyleObject.fontStyle = "italic";
    }
    const newFontID = this.getFontID(
      this.state.styleObject.fontFamily ? this.state.styleObject.fontFamily:"Helvetica Neue LT STD",
      this.state.styleObject.fontWeight,
      newStyleObject.fontStyle,
      this.state.styleObject.fontId
    );
    if (newFontID !== -1) {
      newStyleObject.fontId = newFontID.fontId;
    }
    this.setState({ styleObject: newStyleObject });
    this.props.setIsInputChangedState(true);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  markUnmarkUnderline
   * @desc   :   This function called when clicked on underline Icon (U) to toggle the underline functionality
   * @return -
   */

  markUnmarkUnderline = (event) => {
    const newStyleObject = { ...this.state.styleObject };
    if (this.state.styleObject.textDecoration === "underline") {
      newStyleObject.textDecoration = "none";
    } else {
      newStyleObject.textDecoration = "underline";
    }
    this.setState({ styleObject: newStyleObject });
    this.props.setIsInputChangedState(true);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setFontSize
   * @desc   :   This function called After changing the font size
   * @return -
   */

  setFontSize = (event) => {
    
      const newStyleObject = { ...this.state.styleObject };
      newStyleObject.fontSize = event.target.value;
      // console.log("newStyleObject",newStyleObject)
      this.setState({ styleObject: newStyleObject });
    
    this.props.setIsInputChangedState(true);
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setFontFamily
   * @desc   :   This function called After changing the font family
   * @return -
   */

  getFontID = (fontName, fontWeight, fontStyle) => {
    const boxDefObj = mergeBoxDefUserObject(
      this.props.boxDef,
      this.props.sessionData
    );
    let fontObject = [];
    if(!fontName){
      fontName = "Helvetica Neue LT STD";
     
  } 
    if (boxDefObj.FONT.FAMILY) {
      fontObject = this.props.fxsConfig.FONTFAMILIES[boxDefObj.FONT.FAMILY][
        "FONTS"
      ];
    } else {
      const userValue = getVlaueFormSessionByKey(
        this.props.sessionData,
        "User_Level",
        "cdata"
      );
      if (userValue === "Admin") {
        fontObject = this.props.fxsConfig.FONTFAMILIES["All"]["FONTS"];
      } else {
        fontObject = this.props.fxsConfig.FONTFAMILIES["STANDARD"]["FONTS"];
      }
           
    }
    return getFontIdByFontStyle(fontObject, fontWeight, fontStyle, fontName);
  };

  /**5918-Single Stub line formatting on load set method */

  getFontFamilyFromFontId(fontId){
    const boxDefObj = mergeBoxDefUserObject(
      this.props.boxDef,
      this.props.sessionData
    );
    let fontObject = [];
    if (boxDefObj.FONT.FAMILY) {
      fontObject = this.props.fxsConfig.FONTFAMILIES[boxDefObj.FONT.FAMILY][
        "FONTS"
      ];
    } else {
      const userValue = getVlaueFormSessionByKey(
        this.props.sessionData,
        "User_Level",
        "cdata"
      );
      if (userValue === "Admin") {
        fontObject = this.props.fxsConfig.FONTFAMILIES["All"]["FONTS"];
      } else {
        fontObject = this.props.fxsConfig.FONTFAMILIES["STANDARD"]["FONTS"];
      }           
    }
    const fontfamily=fontObject.find(field => field.fontMap.some(item => item.id === fontId))
    let isDisabledBold = true;
    let isDisabledItalic = true;
    let fontStyle,fontWeight
    if(fontfamily){
    if(fontId!="Other"){
      const fontIdMap=fontfamily.fontMap.find(field => field.id === fontId)
            if (fontIdMap.bold === "true" 
            ){
              isDisabledBold = false;
              fontWeight='bold'
            }
            if (
              fontIdMap.italic === "true"
            ){
              isDisabledItalic = false;
              fontStyle='italic'
          }
          if(isDisabledBold === true && isDisabledItalic === true){
            fontStyle = "normal";
            fontWeight = "unset";
          }else if(isDisabledBold === true && isDisabledItalic === false){
            fontWeight = "unset";
          }else if(isDisabledBold === false && isDisabledItalic === true){
            fontStyle = "normal";
          }
    }
    }


    if(fontId==="Other"){
      return {fontName:fontId,fontStyle,fontWeight,isDisabledItalic,isDisabledBold}
    } else {
      return {fontName:fontfamily && fontfamily.NAME,fontStyle,fontWeight,isDisabledItalic,isDisabledBold}
    }
  }

  setFontFamily = (event, defaultFont = "") => {
    const newStyleObject = _.cloneDeep(this.state.styleObject);
    let newFontID;
    let selectedFontName;
    // console.log("newStyleObject",newStyleObject)
    const documentType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    if(newStyleObject.fontid === "Other" || newStyleObject.fontId === "Other" && documentType.toLowerCase() !== "new order"){
      let eventVal;
      if(event){
        eventVal = event.target.value
      }
      // swal("This change cannot be undone. Do you wish to proceed?", {
      //   buttons: {                
      //     Yes: {
      //       text: "Yes",
      //       value: "yes",
      //     },
      //     No: {
      //       text: "No",
      //       value: "no"
      //     }
      //   },
      // })
      // .then((value) => {
      //   switch (value) {             
      //     case "yes":  
      //       if (eventVal) {
      //         console.log("eventVal",eventVal)
      //         newFontID = this.getFontID(
      //           eventVal,
      //           this.state.styleObject.fontWeight,
      //           this.state.styleObject.fontStyle
      //         );
      //         selectedFontName = eventVal;
      //       } else {
      //         newFontID = this.getFontID(
      //           defaultFont,
      //           this.state.styleObject.fontWeight,
      //           this.state.styleObject.fontStyle
      //         );
      //         selectedFontName = defaultFont;
      //       }
      //       let isDisabledBold = true;
      //       let isDisabledItalic = true;
        
      //       if (newFontID !== -1) {
      //         if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
      //           for (let index = 0; index < newFontID.fontMapArr.length; index++) {
      //             if (
      //               newFontID.fontMapArr[index].bold === "true" &&
      //               newFontID.fontMapArr.length > 1
      //             )
      //               isDisabledBold = false;
        
      //             if (
      //               newFontID.fontMapArr[index].italic === "true" &&
      //               newFontID.fontMapArr.length > 1
      //             )
      //               isDisabledItalic = false;
      //           }
      //         }
        
      //         newStyleObject.fontId = newFontID.fontId;
      //       } else {
      //         delete newStyleObject.fontId;
      //       }
      //       if(isDisabledBold === true && isDisabledItalic === true){
      //         newFontID = this.getFontID(
      //           selectedFontName,
      //           false,
      //           false
      //         );
      //         newStyleObject.fontId = newFontID.fontId;
      //         newStyleObject.fontStyle = "normal";
      //         newStyleObject.fontWeight = "unset";
      //       }else if(isDisabledBold === true && isDisabledItalic === false){
      //         newFontID = this.getFontID(
      //           selectedFontName,
      //           false,
      //           this.state.styleObject.fontStyle
      //         );
      //         newStyleObject.fontId = newFontID.fontId;
      //         // newStyleObject.fontStyle = false;
      //         newStyleObject.fontWeight = "unset";
      //       }else if(isDisabledBold === false && isDisabledItalic === true){
      //         newFontID = this.getFontID(
      //           selectedFontName,
      //           this.state.styleObject.fontWeight,
      //           false
      //         );
      //         newStyleObject.fontId = newFontID.fontId;
      //         newStyleObject.fontStyle = "normal";
      //         // newStyleObject.fontWeight = false;
      //       }
      //       if (newFontID !== -1) {
      //         if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
      //           for (let index = 0; index < newFontID.fontMapArr.length; index++) {
      //             if (
      //               newFontID.fontMapArr[index].bold === "true" &&
      //               newFontID.fontMapArr.length > 1
      //             )
      //               isDisabledBold = false;
        
      //             if (
      //               newFontID.fontMapArr[index].italic === "true" &&
      //               newFontID.fontMapArr.length > 1
      //             )
      //               isDisabledItalic = false;
      //           }
      //         }
        
      //         newStyleObject.fontId = newFontID.fontId;
      //       } else {
      //         delete newStyleObject.fontId;
      //       }
        
      //       if (eventVal) {
      //         newStyleObject.fontFamily = eventVal;
      //       } else {
      //         newStyleObject.fontFamily = defaultFont;
      //       }
        
      //       this.setState({
      //         styleObject: newStyleObject,
      //         isDisabledBold: isDisabledBold,
      //         isDisabledItalic: isDisabledItalic,
      //       });
      //       this.props.setIsInputChangedState(true);
          
      //       break;             
      //     case "no":
            
      //       break;             
      //     default:
      //       // swal("Got away safely!");
      //   }
      // })
      
      if(window.confirm("This change cannot be undone. Do you wish to proceed?")){
        if (event) {
          newFontID = this.getFontID(
            event.target.value,
            this.state.styleObject.fontWeight,
            this.state.styleObject.fontStyle
          );
          selectedFontName = event.target.value;
        } else {
          newFontID = this.getFontID(
            defaultFont,
            this.state.styleObject.fontWeight,
            this.state.styleObject.fontStyle
          );
          selectedFontName = defaultFont;
        }
        let isDisabledBold = true;
        let isDisabledItalic = true;
    
        if (newFontID !== -1) {
          if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
            for (let index = 0; index < newFontID.fontMapArr.length; index++) {
              if (
                newFontID.fontMapArr[index].bold === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledBold = false;
    
              if (
                newFontID.fontMapArr[index].italic === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledItalic = false;
            }
          }
    
          newStyleObject.fontId = newFontID.fontId;
        } else {
          delete newStyleObject.fontId;
        }
        if(isDisabledBold === true && isDisabledItalic === true){
          newFontID = this.getFontID(
            selectedFontName,
            false,
            false
          );
          newStyleObject.fontId = newFontID.fontId;
          newStyleObject.fontStyle = "normal";
          newStyleObject.fontWeight = "unset";
        }else if(isDisabledBold === true && isDisabledItalic === false){
          newFontID = this.getFontID(
            selectedFontName,
            false,
            this.state.styleObject.fontStyle
          );
          newStyleObject.fontId = newFontID.fontId;
          // newStyleObject.fontStyle = false;
          newStyleObject.fontWeight = "unset";
        }else if(isDisabledBold === false && isDisabledItalic === true){
          newFontID = this.getFontID(
            selectedFontName,
            this.state.styleObject.fontWeight,
            false
          );
          newStyleObject.fontId = newFontID.fontId;
          newStyleObject.fontStyle = "normal";
          // newStyleObject.fontWeight = false;
        }
        if (newFontID !== -1) {
          if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
            for (let index = 0; index < newFontID.fontMapArr.length; index++) {
              if (
                newFontID.fontMapArr[index].bold === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledBold = false;
    
              if (
                newFontID.fontMapArr[index].italic === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledItalic = false;
            }
          }
    
          newStyleObject.fontId = newFontID.fontId;
        } else {
          delete newStyleObject.fontId;
        }
    
        if (event) {
          newStyleObject.fontFamily = event.target.value;
        } else {
          newStyleObject.fontFamily = defaultFont;
        }
        
        this.setState({
          styleObject: newStyleObject,
          isDisabledBold: isDisabledBold,
          isDisabledItalic: isDisabledItalic,
        });
        this.props.setIsInputChangedState(true);
    
      }
    }else{
     
      if (event) {
        newFontID = this.getFontID(
          event.target.value,
          this.state.styleObject.fontWeight,
          this.state.styleObject.fontStyle
        );
        selectedFontName = event.target.value;
      } else {
        newFontID = this.getFontID(
          defaultFont,
          this.state.styleObject.fontWeight,
          this.state.styleObject.fontStyle
        );
        selectedFontName = defaultFont;
      }
      let isDisabledBold = true;
      let isDisabledItalic = true;
      if (newFontID !== -1) {
        if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
          for (let index = 0; index < newFontID.fontMapArr.length; index++) {
            if (
              newFontID.fontMapArr[index].bold === "true" &&
              newFontID.fontMapArr.length > 1
            )
              isDisabledBold = false;
  
            if (
              newFontID.fontMapArr[index].italic === "true" &&
              newFontID.fontMapArr.length > 1
            )
              isDisabledItalic = false;
          }
        }
  
        newStyleObject.fontId = newFontID.fontId;
      } else {
        delete newStyleObject.fontId;
      }
      if(isDisabledBold === true && isDisabledItalic === true){
        newFontID = this.getFontID(
          selectedFontName,
          false,
          false
        );
        newStyleObject.fontId = newFontID.fontId;
        newStyleObject.fontStyle = "normal";
        newStyleObject.fontWeight = "unset";
      }else if(isDisabledBold === true && isDisabledItalic === false){
        newFontID = this.getFontID(
          selectedFontName,
          false,
          this.state.styleObject.fontStyle
        );
        newStyleObject.fontId = newFontID.fontId;
        // newStyleObject.fontStyle = false;
        newStyleObject.fontWeight = "unset";
      }else if(isDisabledBold === false && isDisabledItalic === true){
        newFontID = this.getFontID(
          selectedFontName,
          this.state.styleObject.fontWeight,
          false
        );
        newStyleObject.fontId = newFontID.fontId;
        newStyleObject.fontStyle = "normal";
        // newStyleObject.fontWeight = false;
      }
      if (newFontID !== -1) {
        if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
          for (let index = 0; index < newFontID.fontMapArr.length; index++) {
            if (
              newFontID.fontMapArr[index].bold === "true" &&
              newFontID.fontMapArr.length > 1
            )
              isDisabledBold = false;
  
            if (
              newFontID.fontMapArr[index].italic === "true" &&
              newFontID.fontMapArr.length > 1
            )
              isDisabledItalic = false;
          }
        }
  
        newStyleObject.fontId = newFontID.fontId;
      } else {
        delete newStyleObject.fontId;
      }
  
      if (event) {
        newStyleObject.fontFamily = event.target.value;
      } else {
        newStyleObject.fontFamily = defaultFont;
      }
  
      this.setState({
        styleObject: newStyleObject,
        isDisabledBold: isDisabledBold,
        isDisabledItalic: isDisabledItalic,
      });
      this.props.setIsInputChangedState(true);
    }
    
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setTextAlign
   * @desc   :   This function called After changing the text alignment
   * @return -
   */

  setTextAlign = (event) => {
    const newStyleObject = { ...this.state.styleObject };
    newStyleObject.textAlign = event.target.value;
    this.setState({ styleObject: newStyleObject });
  };

  toggleAlignPopUp = (event, type) => {
    let toggleAlign = this.state.toggleAlign;
    toggleAlign = !toggleAlign;
    this.setState({ toggleAlign: toggleAlign });
    if (type) {
      const newStyleObject = { ...this.state.styleObject };
      newStyleObject.textAlign = type;
      this.setState({ styleObject: newStyleObject });
      this.props.setIsInputChangedState(true);
    }
  };

  _handleClick = (event) => {
    const isOpenDropdown = this.props.popUpClass;
    let wasOutside = false;
    if (this.formatingPoopup) {
      if (this.formatingPoopup.contains(event.target)) {
        wasOutside = false;
      } else {
        wasOutside = true;
      }
    }

    if (
      wasOutside &&
      isOpenDropdown === "pop-up displayPopup" &&
      event.target.id != "font-icon" &&
      event.target.id !== "font-icon-i"
      //  &&
      // event.target.id !== "routingIDBTN_2"
    ) {
      this.props.setFontPopupClass("pop-up hidePopup");
      const objCompre = _.isEqual(this.state.styleObject, this.props.styleObj);
      if (this.props.isInputChanged) {
        if(this.props.boxName && this.props.boxName.includes("FXS_Stub1Single")){
          this.props.onChangedStyle(
            this.state.styleObject,
            "FXS_Stub1Single",
            getSectionByTitle("FXS_Stub1Single")
          );
        }else{
          this.props.onChangedStyle(
            this.state.styleObject,
            this.props.boxName,
            getSectionByTitle(this.props.boxName)
          );
        }
        this.props.onBlurHandaler();
      }
    }
  };

  componentWillUpdate(nextProps) {
    // console.log("nextProps.popUpClass",nextProps.popUpClass)
    if (
      this.state.popUpClass != nextProps.popUpClass
    ) {
      
      this.setState({
        popUpClass: nextProps.popUpClass,
        
      });
      if(nextProps.popUpClass !== "pop-up hidePopup"){
        let styleObjeTemp = this.props.styleObj;
        // console.log("styleObjeTemp",nextProps.styleObj)
        let curretStyleApp = nextProps.styleObj;
        if(this.props.boxName.includes("FXS_Stub1Single") && this.props.styleObj && this.props.styleObj[this.props.spanindex]){
              const styleObjNew=this.setFontFamilyonLoad(this.props.styleObj[this.props.spanindex])
            console.log('styleObjNew 12344',styleObjNew)
          // styleObjeTemp = this.props.styleObj[this.props.spanindex]; 
          styleObjeTemp=styleObjNew
          curretStyleApp = styleObjeTemp;
          this.setState({
            styleObject: styleObjeTemp
          });
        } else {
          const styleObjNew1=this.setFontFamilyonLoad(this.state.styleObject)
          styleObjeTemp=styleObjNew1
        }
       
        const newFontID = this.getFontID(
          styleObjeTemp.fontFamily,
          styleObjeTemp.fontWeight,
          styleObjeTemp.fontStyle
        );
  
        let isDisabledBold =true;
        let isDisabledItalic =true;
        if (newFontID !== -1) {
          if (newFontID.fontMapArr && newFontID.fontMapArr.length > 0) {
            for (let index = 0; index < newFontID.fontMapArr.length; index++) {
              if (
                newFontID.fontMapArr[index].bold === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledBold = false;
    
              if (
                newFontID.fontMapArr[index].italic === "true" &&
                newFontID.fontMapArr.length > 1
              )
                isDisabledItalic = false;
            }
          }
          
          this.setState({
            isDisabledItalic: isDisabledItalic,
            isDisabledBold:isDisabledBold
          });
    
        }
        if(curretStyleApp.fontid === "Other" || curretStyleApp.fontId === "Other"){
          isDisabledBold = true;
          isDisabledItalic = true;
          // console.log("isDisabledItalic",isDisabledItalic)
        }
        this.setState({ 
          isDisabledItalic: isDisabledItalic,
          isDisabledBold:isDisabledBold,
          styleObject: styleObjeTemp
        });
      }
      
      setTimeout(() => {
        // console.log("styleObj")
        const objCompre = _.isEqual(this.state.styleObject, this.props.styleObj);
        if (!objCompre && this.state.popUpClass === "pop-up hidePopup" && this.state.styleObject != nextProps.styleObj && this.props.isInputChanged) {
          if(this.props.boxName && this.props.boxName.includes("FXS_Stub1Single")){
            const styleObj = _.cloneDeep(this.props.styleObj);
            styleObj[this.props.spanindex] = this.state.styleObject;
            const updatevalStyle = _.cloneDeep(this.state.styleObject);
            if(styleObj.value){
              updatevalStyle.value = styleObj.value;
            }            
            
            this.props.onChangedStyle(
              updatevalStyle,
              "FXS_Stub1Single",
              getSectionByTitle("FXS_Stub1Single")
            );
              
            this.props.onBlurHandaler(); 
            this.setState({
              styleObject: this.props.styleObj
            }); 
            
          }else{
            this.props.onChangedStyle(
              this.state.styleObject,
              this.props.boxName,
              getSectionByTitle(this.props.boxName)
            );
            this.props.onBlurHandaler(); 
          
            this.setState({
              styleObject: this.props.styleObj
            }); 
          }    
          
        } 
       
      }, 100);
      
      
    }
  }

  // handale refresh Event

  refreshEvent = () => {
    // this.props.setFontPopupClass("pop-up hidePopup");
    if(this.props.boxName && this.props.boxName.includes("FXS_Stub1Single")){
      this.props.onChangedStyle(
        this.state.styleObject,
        "FXS_Stub1Single",
        getSectionByTitle("FXS_Stub1Single")
      );
      this.props.onBlurHandaler();
      setTimeout(() => {
        this.setState({
          styleObject: this.props.styleObj[this.props.spanindex]
        });
      }, 100);
    }else{
      this.props.onChangedStyle(
        this.state.styleObject,
        this.props.boxName,
        getSectionByTitle(this.props.boxName)
      );
      this.props.onBlurHandaler();
      setTimeout(() => {
        this.setState({
          styleObject: this.props.styleObj
        });
      }, 100);
    }
    

    
  };

  render() {
    let boxDefObj = {};
    let fontList = "";
    let fontSizeList = "";
    let fontObject = [];
    const objCompre = _.isEqual(this.state.styleObject, this.props.styleObj);

    if (typeof this.props.boxDef !== "undefined") {
      boxDefObj = mergeBoxDefUserObject(
        this.props.boxDef,
        this.props.sessionData
      );
      const userValue = getVlaueFormSessionByKey(
        this.props.sessionData,
        "User_Level",
        "cdata"
      );

      if (boxDefObj.FONT.FAMILY) {
        fontObject = this.props.fxsConfig.FONTFAMILIES[boxDefObj.FONT.FAMILY];
      } else {
        if (userValue === "Admin") {
          fontObject = this.props.fxsConfig.FONTFAMILIES["All"];
        } else {
          fontObject = this.props.fxsConfig.FONTFAMILIES["STANDARD"];
        }
      }

      fontList =
        fontObject.FONTS.length > 0 &&
        fontObject.FONTS.map((item, i) => {
          return (
            <option key={i} value={item.NAME}>
              {item.NAME}
            </option>
          );
        }, this);

      let fontSizeLength =
        parseInt(boxDefObj.FONTSIZE.MAX) - parseInt(boxDefObj.FONTSIZE.MIN);
      fontSizeLength = fontSizeLength + 1;
      let values = parseInt(boxDefObj.FONTSIZE.MIN);
      const fontArr = [];
      for (let index = 0; index < fontSizeLength; index++) {
        if (index !== 0 && boxDefObj.FONTSIZE.INCREMENT) {
          values = values + parseInt(boxDefObj.FONTSIZE.INCREMENT);
        } else if (index !== 0) {
          values = values + 1;
        }

        fontArr.push({ name: values });
      }

      fontSizeList =
        fontArr.length > 0 &&
        fontArr.map((item, i) => {
          return (
            <option key={i} value={item.name}>
              {item.name}
            </option>
          );
        }, this);
    }
    let textClass;
    if (
      this.state.styleObject &&
      this.state.styleObject.textAlign === "center"
    ) {
      textClass = "center";
    } else if (
      this.state.styleObject &&
      this.state.styleObject.textAlign === "right"
    ) {
      textClass = "right";
    } else {
      textClass = "left";
    }
    let alignmentBtnDisabled = false;
    if (
      this.props.filedFocusName &&
      (this.props.filedFocusName.includes("FXS_Title") ||
        this.props.filedFocusName.includes("FXS_GEN") ||
        this.props.filedFocusName.includes("FXS_Stub1Single"))
    ) {
      alignmentBtnDisabled = true;
    }
    return (
      <div>
        {this.props.popUpClass === "pop-up displayPopup" && (
          <div
            className={this.props.popUpClass ? this.props.popUpClass : "pop-up"}
            id={this.props.popUpClass}
            ref={(ref) => {
              this.formatingPoopup = ref;
            }}
          >
            <div className="rounded-corner">
              <div
                className="custom-select select-font"
                style={{ width: "40%" }}
              >
                <select
                  style={{ width: "100%" }}
                  onChange={this.setFontFamily}
                  value={this.state.styleObject.fontFamily}
                >
                  {this.state.styleObject && this.state.styleObject.fontId === "Other" ? <option value="other">Other</option>:""}
                  {fontList}
                </select>
              </div>
              <div className="custom-select select-font-size">
                <select
                  onChange={this.setFontSize}
                  value={this.state.styleObject.fontSize}
                >
                  {fontSizeList}
                </select>
              </div>
              <div className="font-style d-flex">
                {boxDefObj.FORMAT && boxDefObj.FORMAT.BOLD == "true" && (
                  <button
                    className="font_button"
                    onClick={this.markUnmarkBold}
                    style={{
                      background:
                        this.state.styleObject.fontWeight === "bold"
                          ? "rgba(0,0,0,.4)"
                          :"",
                    }}
                    disabled={this.state.isDisabledBold}
                  >
                    <i className="fa fa-bold"></i>
                  </button>
                )}

                {boxDefObj.FORMAT && boxDefObj.FORMAT.ITALIC == "true" && (
                  <div>
                  <button
                    className="font_button"
                    onClick={this.markUnmarkItalic}
                    style={{
                      background:
                        this.state.styleObject.fontStyle === "italic"
                          ? "rgba(0,0,0,.4)"
                          : "",
                    }}
                    disabled={this.state.isDisabledItalic}
                  >
                    <i className="fa fa-italic"></i>
                  </button>
                  </div>
                )}

                {boxDefObj.FORMAT && boxDefObj.FORMAT.UNDERLINE == "true" && (
                  <span onClick={this.markUnmarkUnderline}>
                    <i className="fa fa-underline"></i>
                  </span>
                )}
              </div>
              <div className="custom-select select-font-alignment">
                <button
                  className="selected font_button"
                  onClick={this.toggleAlignPopUp}
                  disabled={!alignmentBtnDisabled}
                >
                  <i className="fa fa-sort-down"></i>
                  <i className={"fa fa-align-" + textClass}></i>
                </button>
                <ul
                  style={{
                    display: this.state.toggleAlign ? "block" : "none ",
                  }}
                >
                  <li onClick={(event) => this.toggleAlignPopUp(event, "left")}>
                    <i className="fa fa-align-left"></i>
                  </li>
                  <li
                    onClick={(event) => this.toggleAlignPopUp(event, "center")}
                  >
                    <i className="fa fa-align-center"></i>
                  </li>
                  <li
                    onClick={(event) => this.toggleAlignPopUp(event, "right")}
                  >
                    <i className="fa fa-align-right"></i>
                  </li>
                </ul>
              </div>
              <div className="font-style">
                <button
                  className="font_button"
                  onClick={this.refreshEvent}
                  style={{ color: this.props.isInputChanged ? "red" : "#a0a2a3" }}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    setFontPopupClass: (currentClass) =>
      dispatch(inputSettingction.setFontPopupClass(currentClass)),
    onChangedStyle: (styleObj, title, section) =>
      dispatch(
        inputSettingction.onStyleChangeHandaler(styleObj, title, section)
      ),
    onBlurHandaler: (isFocusOut, isNew) =>
      dispatch(action.onBlurHandaler(isFocusOut, isNew)),
    setIsInputChangedState: (flag) =>
      dispatch(inputSettingction.setIsInputChangedState(flag)),
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    fxsConfig: state.fetchDataReducer.fxConfig,
    isFocusInput: state.inputDataReducer.isFocusInput,
    boxDef: state.inputDataReducer.boxDef,
    boxName: state.inputDataReducer.boxName,
    sessionData: state.fetchDataReducer.sessionData,
    fontMapData: state.fetchDataReducer.fontMapData,
    filedFocusName: state.inputDataReducer.filedFocusName,
    sessionFileID: state.fetchDataReducer.sessionFileID,
    popUpClass: state.inputDataReducer.popUpClass,
    styleObj: state.inputDataReducer.styleObj,
    spanindex:state.inputDataReducer.spanindex,
    isInputChanged:state.inputDataReducer.isInputChanged,
    sessionData: state.fetchDataReducer.sessionData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormatingPopup);
