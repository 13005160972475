/**
 * @author    Clarion
 * @version 1.0
 * @file   :  PreviewPanel
 * @desc   :  This file is right preview panel Image Functionality
 */
import React from "react";
import { connect } from "react-redux";
import "./PreviewPanel.css";
import Spinner from "./../../components/Common/Spinner/Spinner";
import PanAndZoomImage from "./../../components/PanAndZoomImage/PanAndZoomImage";
import { IMAGE_SCALE } from "./../../utility/AppsConst";
import * as action from "././../../store/action/fetchData";

class PreviewPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSetOnOnload: false,
    };
    this.onResizeWindow = this.onResizeWindow.bind(this);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  componentDidUpdate
   * @desc   :  This function called whener redux state or compoenent state get changed.
   *            We are setting the Error during image preview operations.
   */

  componentDidUpdate() {
    if (this.props.errMessage) {
      document.getElementById("imgWrap").textContent = this.props.errMessage;
    }
    this.onResizeWindow();
    window.addEventListener("load", this.onResizeWindow);
    window.addEventListener("resize", this.onResizeWindow);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  setWidthToImage
   * @desc   :  This function set the original image width on preview image load.
   */

  setWidthToImage = (width) => {
    if (this.props.scale === IMAGE_SCALE) {
      this.props.setImageWidth(width, IMAGE_SCALE);
    }
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onResizeWindow
   * @desc   :  This function calculate and set the righ preview panel height according to screen height.
   */

  onResizeWindow(event) {
    if (document.getElementById("header")) {
      // setTimeout(() => {
      // const headerHeight = document.getElementById("header").clientHeight;
      // const bodyHeight = window.innerHeight;
      // const sidebarHeight = bodyHeight - headerHeight;
      // if (document.getElementById("content-area-preview"))
      //   document.getElementById("content-area-preview").style.height =
      //     sidebarHeight - 5 + "px";
      // if (document.getElementById("previewImg"))
      //   document.getElementById("previewImg").style.height =
      //     sidebarHeight - 40 + "px";
      const headerHeight = document.getElementById("header")
        ? document.getElementById("header").clientHeight
        : 0;
      const headerSubHeight = document.getElementById("preview-panel-top")
        ? document.getElementById("preview-panel-top").clientHeight
        : 0;
      const bodyHeight = window.innerHeight;
      const sidebarHeight = bodyHeight - headerHeight - headerSubHeight;

      if (document.getElementById("content-area-preview")) {
        document.getElementById("content-area-preview").style.height =
          sidebarHeight - 5 + "px";
        if (
          document.getElementsByClassName("PanAndZoomImage-container ") &&
          document.getElementsByClassName("PanAndZoomImage-container ")[0]
        ) {
          document.getElementsByClassName(
            "PanAndZoomImage-container "
          )[0].style.height = sidebarHeight - 40 + "px";
        }
      }

      if (
        document.getElementById("previewImg") &&
        this.state.isSetOnOnload === false
      ) {
        document.getElementById("previewImg").style.height =
          sidebarHeight - 40 + "px";
        this.setState({ isSetOnOnload: true });
      } else if (document.getElementById("previewImg")) {
        const img = document.getElementById("previewImg");

        if (img.offsetWidth > img.offsetHeight) {
          document.getElementById("previewImg").style.height = "auto";
          if (
            document.getElementById("content-area-preview").clientWidth - 20 <
            img.offsetWidth
          ) {
            document.getElementById("previewImg").style.width =
              document.getElementById("content-area-preview").clientWidth -
              100 +
              "px";
          }
        } else {
          document.getElementById("previewImg").style.height =
            sidebarHeight - 40 + "px";
        }
      }
      const zoomDiv = document.getElementsByClassName(
        "PanAndZoomImage-container-element"
      );
      if (zoomDiv && zoomDiv[0] && this.props.scale === 1) {
        zoomDiv[0].style.transform = "translate(0px, 0px) scale(1)";
      }
      // }, 800);
    }
  }

  render() {
    this.onResizeWindow();
    return (
      <React.Fragment>
        <div
          id="image-view-wrapper"
          style={{
            position: "relative",
            padding: 15,
            transform: `scale(${this.props.scale})`,
          }}
        >
          <div id="imgWrap"></div>

          <PanAndZoomImage
            src={this.props.previewImageUrl}
            scale={this.props.scale}
            setWidthToImage={this.setWidthToImage}
          />
        </div>
        {this.props.imageLoadingVal > 0 && <Spinner />}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setImageWidth: (width, scale) =>
      dispatch(action.setImageWidth(width, scale)),
  };
};

const mapStateToProps = (state) => {
  return {
    previewImageUrl: state.fetchDataReducer.previewImageUrl,
    loading: state.fetchDataReducer.loadingImage,
    errMessage: state.fetchDataReducer.errMessage,
    imageLoadingVal: state.fetchDataReducer.imageLoadingVal,
    scale: state.fetchDataReducer.scale,
    imageWith: state.fetchDataReducer.imageWith,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewPanel);
