/**
 * @author    Clarion
 * @version 1.0
 * @file   :  PreviewControlPanel
 * @desc   :  This file render the left control panel and preview image panel
 */

import React from "react";
import { connect } from "react-redux";

import PreviewPanel from "./../PreviewPanel/PreviewPanel";
import ControlPanel from "./../ControlPanel/ControlPanel";
import * as action from "./../../store/action/fetchData";
import { setLocalStorage } from "./../../utility/Utility";

import "./PreviewControlPanel.module.css";

class PreviewControlPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileFlag: false,
      toggleDropBoxPopup: false,
      isDisplayAddPopup: false,
      queueArr: [],
    };
    this.onWindowResize = this.onWindowResize.bind(this);
  }

  /**
   * @author    React
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  componentDidMount funciton get called once after component get initilised.
   *            Get the session file ID to red the session file and start the sesstion.
   * @returns:
   */

  componentDidMount = async () => {
    const query = new URLSearchParams(this.props.location.search);
    let sessionFileId;
    for (let params of query.entries()) {
      sessionFileId = params[1];
    }
    if (sessionFileId) {
      const sessionStart = await action.startSession();

      if (sessionStart && sessionStart.data && sessionStart.data.success) {
        setLocalStorage("authObj", sessionStart.data);
        
        this.props.fetchSessionData(sessionFileId);
      }
    } else {
      alert("session ID not found");
    }
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
  };

  /**
   * @author    React
   * @version 1.0
   * @name   :  onWindowResize
   * @desc   :  onWindowResize funciton get called on window resize to check the device mobile or desktop and
   *            set the flag accroding to width of the screen.
   * @returns:
   */

  onWindowResize = () => {
    let check = false;
    if (window.innerWidth <= 991) {
      check = true;
    }
    this.props.setMobilePreviewData(check);
    let isDisplayControlPanel = this.props.isDisplayControlPanel;
    let isDisplayPreviewPanel = this.props.isDisplayPreviewPanel;
    if (check) {
      isDisplayControlPanel = true;
      isDisplayPreviewPanel = false;
    } else {
      isDisplayControlPanel = true;
      isDisplayPreviewPanel = true;
    }

    this.props.setToggelePreviewData({
      isDisplayPreviewPanel: isDisplayPreviewPanel,
      isDisplayControlPanel: isDisplayControlPanel,
    });

    this.setState({
      isMobileFlag: check,
      isDisplayControlPanel: isDisplayControlPanel,
      isDisplayPreviewPanel: isDisplayPreviewPanel,
    });
  };

  componentDidUpdate = (prevProps) => {
    console.log("this.props.queueArr",this.props.queueArr[0])

    // queueArr
    // if (
    //   this.props.queueArr &&
    //   this.props.queueArr.length != this.state.queueArr.length
    // ) {
      if(prevProps.queueArr !== this.props.queueArr){
        this.setState({
          queueArr: this.props.queueArr,
        });
      if (this.props.queueArr.length > 0) {
    
        setTimeout(() => {
          const firstElemt = this.props.queueArr[0];
          const focusOutElement=firstElemt && firstElemt.focusOutElement
          const result = firstElemt && firstElemt.newArrState.find(item => item.attributes.NAME === firstElemt.focusOutElement);
          console.log('result',result)
          if(result && result.isInputChanged==true || focusOutElement==""){
          if (firstElemt && firstElemt.isFocusOut === true && firstElemt.isImage === true) {
            this.props.fetchPreviewData(firstElemt.newArrState, "focusout");
          } else if (
            firstElemt && firstElemt.isFocusOut === true &&
            firstElemt.isImage === false
          ) {
            this.props.fetchPreviewData(firstElemt.newArrState, "json");
          } else if (
            firstElemt && firstElemt.isFocusOut === false &&
            firstElemt.isImage === false
          ) {
            this.props.fetchPreviewData(
              firstElemt.newArrState,
              "focusout_json",
              "json",
              firstElemt.isFocusOut,
              firstElemt.isImage
            );

            this.props.fetchPreviewData(
              firstElemt.newArrState,
              "focusout_json",
              "jpg"
            );
          }
        }
        }, 100);
      
      }
    }
  };

  render() {
    return (
      <div>
        <div className="grid-item-wrapper">
          {this.props.isDisplayControlPanel && (
            <div className="sidebar grid-item grid-item-5 p-none">
              <ControlPanel></ControlPanel>
            </div>
          )}
          {this.props.isDisplayPreviewPanel && (
            <div
              className="content-right grid-item grid-item-7"
              id="content-area-preview"
            >
              <div className="white-bg bg-grey">
                <PreviewPanel></PreviewPanel>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    fetchSessionData: (sessionFileId) =>
      dispatch(action.fetchSessionData(sessionFileId)),
    setMobilePreviewData: (data) => dispatch(action.setMobilePreviewData(data)),
    setToggelePreviewData: (data) =>
      dispatch(action.setToggelePreviewData(data)),
    saveInstanceList: (data) => dispatch(action.saveInstanceList(data)),
    fetchPreviewData: (newArrState, focusout_json, json, isFocusOut, isImage) =>
      dispatch(
        action.fetchPreviewData(
          newArrState,
          focusout_json,
          json,
          isFocusOut,
          isImage
        )
      ),
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    sessionDataError: state.fetchDataReducer.errMessageSession,
    loading: state.fetchDataReducer.loading,
    isDisplayPreviewPanel: state.fetchDataReducer.isDisplayPreviewPanel,
    isDisplayControlPanel: state.fetchDataReducer.isDisplayControlPanel,
    queueArr: state.inputDataReducer.queueArr,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewControlPanel);
