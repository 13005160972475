/**
 * @author    Clarion
 * @version 1.0
 * @file   :  ControlPanel
 * @desc   :  This file create the dynamic tab and import the tab content component respectivly
 */
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { connect } from "react-redux";
import "react-tabs/style/react-tabs.css";
import * as _ from "lodash";
import Styles from "../../components/Styles/Styles";
import Company from "../../components/Company/Company";
import Bank from "../../components/Bank/Bank";
import Review from "../../components/Review/Review";
import BottomBar from "../../components/BottomBar/BottomBar";
import * as inputAction from "./../../store/action/inputSettings";
import { getVlaueFormSessionByKey } from "./../../utility/Utility";

import classes from "./ControlPanel.module.css";
import "./ControlPanel.module.css";

class ControlPanel extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      tabIndex: 0,
      isDisplaybanckTab: true,
      isDisplaysetUpTab: true,
      isDisplayCompTab: true,
    };
    this.onResizeWindow = this.onResizeWindow.bind(this);
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onResizeWindow
   * @desc   :  This function calculate the dynamic height for left control panel element according to screen.
   */

  onResizeWindow(event) {
    if (document.getElementById("header")) {
      setTimeout(() => {
        if(document.getElementById("header")){
          const headerHeight = document.getElementById("header").clientHeight;
          const tabHeight = document.getElementById("tabs")
            ? document.getElementById("tabs").clientHeight
            : 0;
          const bodyHeight = window.innerHeight;
          const sidebarHeight = bodyHeight - (headerHeight - tabHeight);
          if (document.getElementById("content-area")) {
            document.getElementById("content-area").style.height =
              sidebarHeight - 130 + "px";
          }
        }
        
      }, 800);
    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  componentDidMount
   * @desc   :  This function attaching the resize event.
   */

  componentDidMount() {
    window.addEventListener("load", this.onResizeWindow);
    window.addEventListener("resize", this.onResizeWindow);
  }

  componentDidUpdate(){
    
    if (this.props.sessionData) {
      const documentType = getVlaueFormSessionByKey(
        this.props.sessionData,
        "Type",
        "cdata"
      );

      if (
        documentType &&
        documentType !== -1 &&
        (documentType === "Reorder-Exact" || documentType === "Exact")
      ) {
        
        if(this.state.currenttabIndex !== 2 && this.props.tabIndex === 0 && this.state.isDisplaybanckTab === true){
          this.setState({currenttabIndex:2})
            this.props.tabChanged(2)
        }

        if(this.state.currenttabIndex !== 3 && this.state.isDisplaybanckTab === false){
          this.setState({currenttabIndex:3})
          this.props.tabChanged(3)
        }
        
        
      }

    }
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  componentWillUnmount
   * @desc   :  This function removing the resize event.
   */

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleClickEvent);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.jsonData && nextProps.jsonData != this.state.jsonData) {
      this.setState({ jsonData: nextProps.jsonData });
      const checkBackData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_Bank");
      });
      const checkFractionData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_Fractional");
      });
      const checkRoutingData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_Routing");
      });
      const checkAccountData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_Account");
      });
      const checkNumberData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_CheckNumber");
      });

      if (
        checkBackData &&
        checkBackData.length === 0 &&
        checkFractionData &&
        checkFractionData.length === 0 &&
        checkRoutingData &&
        checkRoutingData.length === 0 &&
        checkAccountData &&
        checkAccountData.length === 0 &&
        checkNumberData &&
        checkNumberData.length === 0
      ) {
        this.setState({
          isDisplaybanckTab: false,
        });
        
      }

      const checkCompanyData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_Title");
      });

      const checkGenerationData = _.filter(nextProps.jsonData, (element) => {
        return element.name && element.name.includes("FXS_GEN");
      });
      if (checkCompanyData && checkCompanyData.length === 0 && checkGenerationData && checkGenerationData.length === 0) {
        this.setState({
          isDisplayCompTab: false,
        });
      }

      const checkDefaultData = _.filter(
        nextProps.jsonData,
        (p) => p.name === "Default"
      );
      const checkPantosData = _.findIndex(
        nextProps.jsonData,
        (p) => p.name === "Pantos, Screens"
      );
      const checkBorderData = _.findIndex(
        nextProps.jsonData,
        (p) => p.name === "Borders, Lines"
      );

      if (
        checkDefaultData &&
        checkDefaultData.length === 0 &&
        checkPantosData &&
        checkPantosData.length === 0 &&
        checkBorderData &&
        checkBorderData.length === 0
      ) {
        this.setState({
          isDisplaysetUpTab: false,
        });
      }

    }
  }
  //Render method create the dynamic tabs and render it

  render() {
    this.onResizeWindow();

    let isdocumentType = false;
    if (this.props.sessionData) {
      const documentType = getVlaueFormSessionByKey(
        this.props.sessionData,
        "Type",
        "cdata"
      );

      if (
        documentType &&
        documentType !== -1 &&
        (documentType === "Reorder-Exact" || documentType === "Exact")
      ) {
        isdocumentType = true;
      }
    }

    const controlPanelClasses = ["col-3", classes.ControlPanel];
    const tabsLength =
      typeof this.props.fxsConfig.TABS !== "undefined" &&
      this.props.fxsConfig.TABS.length > 0;
    let tabItems = [];
    if (typeof this.props.fxsConfig.TABS !== "undefined") {
      if (this.props.fxsConfig.TABS.length > 0) {
        tabItems = this.props.fxsConfig.TABS.map((item, index) => {
          let className = "";
          if (index === this.props.tabIndex) {
            className = "current";
          } else if (
            this.props.tabIndex === 0 &&
            isdocumentType === true &&
            index === 2
          ) {
            className = "current";
          }
          let disply = "block";
          if (
            isdocumentType === true &&
            (item.NAME === "SETUP" || item.NAME === "COMPANY")
          ) {
            disply = "none";
          }
          if (this.state.isDisplaybanckTab === false && item.NAME === "BANK") {
            disply = "none";
          }
          if (
            this.state.isDisplayCompTab === false &&
            item.NAME === "COMPANY"
          ) {
            disply = "none";
          }
          if (this.state.isDisplaysetUpTab === false && item.NAME === "SETUP") {
            disply = "none";
          }
          if (this.state.isDisplaybanckTab === false && isdocumentType === true) {
            className = "current";
          }
          return (
            <Tab
              key={index}
              className={"tab-link " + className}
              style={{
                display: disply,
              }}
            >
              <a  className="control-tab cursor-pointer">{item.NAME}</a>
            </Tab>
          );
        });
      }
    }

    const loadComponent = (item) => {
      if (isdocumentType === false && item.NAME === "COMPANY") {
        return <Company></Company>;
      } else if (item.NAME === "BANK") {
        return <Bank></Bank>;
      } else if (item.NAME === "REVIEW") {
        return <Review></Review>;
      } else if (isdocumentType === false) {
        return <Styles></Styles>;
      }
    };

    let selectedtab = this.props.tabIndex;
    if(this.props.tabIndex === 0 && isdocumentType === true){
      selectedtab = 2;
    }
    if(this.state.isDisplaybanckTab === false  && this.state.isDisplaysetUpTab === false && this.state.isDisplayCompTab === false){
      selectedtab = 3;
    }
    if(this.state.isDisplaybanckTab === false  && isdocumentType === true){
      selectedtab = 3;
    }
    // console.log(selectedtab)
    // console.log("this.state.isDisplaysetUpTab",this.state.isDisplaysetUpTab)
    // console.log("this.state.isDisplayCompTab",this.state.isDisplayCompTab)
    return (
      <React.Fragment>
        <div className={controlPanelClasses.join(" ")}>
          <div className="custom-h-tabs" id="leftContentHeight">
            <Tabs
              selectedIndex={
                selectedtab
              }
              onSelect={(tabIndex) => this.props.tabChanged(tabIndex)}
            >
              <TabList id="tabs" className="tabs">
                {tabItems}
              </TabList>
              <div className="content-area" id="content-area">
                {tabsLength &&
                  this.props.fxsConfig.TABS.map((item, index) => {
                    return (
                      <TabPanel key={index}>
                        <div
                          id={"tab-" + index}
                          className="tab-content current"
                        >
                          {loadComponent(item)}
                        </div>
                      </TabPanel>
                    );
                  })}
              </div>
            </Tabs>
            <div className="sider-footer">
              <BottomBar></BottomBar>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    fxsConfig: state.fetchDataReducer.fxConfig,
    sessionData: state.fetchDataReducer.sessionData,
    tabIndex: state.inputDataReducer.tabIndex,
    jsonData: state.fetchDataReducer.jsonData,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    tabChanged: (tabIndex) => dispatch(inputAction.onTabChanged(tabIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlPanel);
