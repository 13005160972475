/**
 * @author    Clarion
 * @version 1.0
 * @file   :  fetchData
 * @desc   :  This file used for ajax api call for FXS config file, session file and save data on focus out api call
 */

import Axios from "axios";

import * as actionType from "./actionTypes";
import * as errorMessage from "../../const/errorMessages";
import * as _ from "lodash";
import * as inPutSettingOption from "./inputSettings";
import {
  convertData,
  convertXML2Json,
  getErrorMessage,
  getDataWithSetting,
  setValueFromSession,
  convertDataOnFocusOut,
  getColorDefaultSetting,
  getVlaueFormSessionByKey,
  getVlaueFormJsonByKey,
  getBGObjectFormByJsonData,
  getLogoDisplayNameByFolder,
  getNodeURL,
  getLolcalStore,
  setSessinoStorage,
  getSessionStore,
  getLogoColor
} from "../../utility/Utility";
import { convertXMLData } from "../../utility/configXMLFISdata";
import {
  XML_FILE_PATH,
  QUEUE_BY_TEMPLATE,
  API_URL,
  DOMAIN_INFORMATION,
  PANTONE_COLOR_FILE_PATH,
  SESSION_FILE_PATH,
} from "./../../utility/AppsConst";

let setChangesFalse = true;
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchSessionDataStart
 * @desc   :  This function call befor api call to load the loader on screen
 */

export const fetchSessionDataStart = () => {
  return {
    type: actionType.FETCH_SESSION_DATA_START,
  };
};

export const decgreseCount = () => {
  return {
    type: actionType.DECGRISE_LOADER_COUNT,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setSessionDataID
 * @desc   :  This function to set session file id to redux store
 */

const setSessionDataID = (sessionFileID) => {
  return {
    type: actionType.SET_SESSION_FILE_ID,
    sessionFileID: sessionFileID,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchSessionDataFail
 * @desc   :  This function call  when session file returns the error  and set the error in redux store
 */

const fetchSessionDataFail = (message) => {
  return {
    type: actionType.FETCH_SESSION_DATA_FAIL,
    errMessage: message,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewStart
 * @desc   :  This function call  befor the image api call
 */

const fetchPreviewStart = () => {
  return {
    type: actionType.FETCH_PREVIEW_START,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewFail
 * @desc   :  This function call  when simage Api call returns the error and set the error in redux store
 */

const fetchPreviewFail = (message) => {
  return {
    type: actionType.FETCH_PREVIEW_FAIL,
    errMessage: message,
  };
};

//#5293 -Text not saving in document
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewAbort
 * @desc   :  This function call to cancel the request when same api is called again
 */

const fetchPreviewAbort = (message) => {
  return {
    type: actionType.FETCH_PREVIEW_ABORT,
    errMessage: "",
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewSuccess
 * @desc   :  This function call  After Api call responce to set the preview image url in redux store
 */

const fetchPreviewSuccess = (response) => {
  return {
    type: actionType.FETCH_PREVIEW_SUCCESS,
    previewImageUrl: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewSuccess
 * @desc   :  This function call  After Api call responce to set the preview image url in redux store
 */

const fetchPDFSuccess = () => {
  return {
    type: actionType.HIDE_LOADER,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchFxConfigStart
 * @desc   :  This function call befor the reading for fxs config file start
 */

const fetchFxConfigStart = () => {
  return {
    type: actionType.FETCH_FX_CONFIG_START,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchFxConfigFail
 * @desc   :  This function call when fxs config file returns the error and set the error in redux store
 */

const fetchFxConfigFail = (message) => {
  return {
    type: actionType.FETCH_FX_CONFIG_FAIL,
    errMessage: message,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchFxConfigSuccess
 * @desc   :  This function call after geting responce from fxs config file  and set this data to redux store
 */

const fetchFxConfigSuccess = (config) => {
  return {
    type: actionType.FETCH_FX_CONFIG_SUCCESS,
    fxConfig: config,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  createUrlData
 * @desc   :  This is for get the object  and convert it into url string
 * @return -  url string
 */

const createUrlData = (data) => {
  let url = {};
  for (const x in data) {
    if (
      x === "DefaultColor" ||
      x === "PantosColor" ||
      x === "BorderColor" ||
      x === "SigLine" ||
      x === "FXSFracline" ||
      x === "FXSLogo1" ||
      (x.includes("FXSPhantom") && data[x]) ||
      x === "FXSBackground" ||
      x === "FXSSecurity" ||
      x === "JSONINFO" ||
      x === "FXS_Job_Number" ||
      x === "page" ||
      x === "LogoType"
      // x === "Design_Instructions"
    ) {
      url[x] = data[x];
    } else if (
      (x === "setElement.FXS_Fractional1" ||
        x === "setElement.FXS_Fractional2") &&
      !data[x]
    ) {
      url[x] = data[x];
    } else if (data[x] && x!=="Design_Instructions") url[x] = data[x];
  }
  return url;
};

export const setAppliedLogoColor = (displayName)=>{
 
    return (dispatch, getState) => {
      const state = getState();
      //#5277 change for logo check 
      if(state.inputDataReducer.logoFiles && displayName !=-1){
        const foundCustIndex = state.inputDataReducer.logoFiles.Customer_Logos.findIndex(p=>p.DISPLAY_NAME === displayName);
  
        let foundSideIndex;
        if(foundCustIndex !=-1){
          const logoColor1 = getLogoColor(state.inputDataReducer.logoFiles.Customer_Logos[foundCustIndex]);
          dispatch(inPutSettingOption.setLogoColorsArr(logoColor1));
          dispatch(inPutSettingOption.setAdditionalColor(state.inputDataReducer.logoFiles.Customer_Logos[foundCustIndex].ADDITIONAL_COLORS));
        }else {
         foundSideIndex = state.inputDataReducer.logoFiles.Generic_SideLogos.findIndex(p=>p.DISPLAY_NAME === displayName);
          if(foundSideIndex !=-1){
            const logoColor2 = getLogoColor(state.inputDataReducer.logoFiles.Generic_SideLogos[foundSideIndex]);
            dispatch(inPutSettingOption.setLogoColorsArr(logoColor2));
            dispatch(inPutSettingOption.setAdditionalColor(state.inputDataReducer.logoFiles.Generic_SideLogos[foundSideIndex].ADDITIONAL_COLORS));
          }
        
          else{
            const foundTopIndex = state.inputDataReducer.logoFiles.Generic_TopLogos.findIndex(p=>p.DISPLAY_NAME === displayName);
            if(foundTopIndex !=-1){
              const logoColor3 = getLogoColor(state.inputDataReducer.logoFiles.Generic_TopLogos[foundTopIndex]);
              dispatch(inPutSettingOption.setLogoColorsArr(logoColor3));
              dispatch(inPutSettingOption.setAdditionalColor(state.inputDataReducer.logoFiles.Generic_TopLogos[foundTopIndex].ADDITIONAL_COLORS));
            }
          }
        }
      }
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchFxConfigData
 * @desc   :  This function is for reading the fxs config file
 */

export const fetchFxConfigData = () => {
  return (dispatch, getState) => {
    dispatch(fetchFxConfigStart());
    const hotname = window.location.hostname;
    let domainInfo = DOMAIN_INFORMATION["default"];
    if (DOMAIN_INFORMATION[hotname] != "undefined") {
      domainInfo = DOMAIN_INFORMATION[hotname];
    }

    Axios.get(domainInfo["CONFIG_FILE"])
      .then((response) => {
        const properJSON = convertXMLData(convertXML2Json(response.data));

        dispatch(fetchFxConfigSuccess(properJSON));
        const fxsConfig = _.cloneDeep(properJSON);
        const textData = _.filter(fxsConfig.BOXES, (boxes) => {
          return boxes.elementType === "text";
        });

        const companyNameAddress = _.filter(textData, (text) => {
          return text.attributes.NAME.includes("FXS_Title");
        });

        //Set the state for the compnay information
        getDataWithSetting(companyNameAddress, fxsConfig);
        const state = getState();
        const docType = getVlaueFormSessionByKey(
          state.fetchDataReducer.sessionData,
          "Type",
          "cdata"
        );

        const colorObj = getColorDefaultSetting(
          state.fetchDataReducer.jsonData,
          properJSON,
          state.fetchDataReducer.sessionData,
          docType
        );
       
        const colorCode1 = getVlaueFormSessionByKey(
          state.fetchDataReducer.sessionData,
          "Colors",
          "cdata"
        );
        // if (parseInt(colorCode1) != 1) {
          dispatch(inPutSettingOption.onColorDefault(colorObj));
        //}
        setValueFromSession(
          companyNameAddress,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        dispatch(inPutSettingOption.setCompanyInfoState(companyNameAddress));

        //set the state for company stub information
        const sigData = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Sig")
          );
        });
        getDataWithSetting(sigData, fxsConfig);
        setValueFromSession(sigData, state.fetchDataReducer.sessionData);
        dispatch(inPutSettingOption.setCompanySignState(sigData));

        //set the state for company signInformation

        const OverSigData = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes &&
            (config.attributes.NAME.includes("FXS_OverSig") ||
              config.attributes.NAME.includes("FXS_UnderSig"))
          );
        });
        getDataWithSetting(OverSigData, fxsConfig);
        setValueFromSession(OverSigData, state.fetchDataReducer.sessionData, state.fetchDataReducer.jsonData);
        
        dispatch(inPutSettingOption.setCompanyOverSigState(OverSigData));

        if (
          state.fetchDataReducer.jsonData &&
          state.fetchDataReducer.jsonData.length > 0
        ) {
          const findColorIndex = state.fetchDataReducer.jsonData.findIndex(
            (element) => element.name === "SigLine"
          );
          if (findColorIndex != -1) {
            dispatch(
              inPutSettingOption.setOverSigLineState(
                state.fetchDataReducer.jsonData[findColorIndex].content
              )
            );
          } else {
            dispatch(inPutSettingOption.setOverSigLineState("1"));
          }
        }

        //set the state for stub information copany info

        const stubData = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Stub")
          );
        });
        getDataWithSetting(stubData, fxsConfig);
        
        setValueFromSession(
          stubData,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        // console.log("stubData",stubData);

        const findSignleLine = stubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
        if(findSignleLine !==-1){
          const tempStubdataValue = _.cloneDeep(stubData[findSignleLine]);

          if(tempStubdataValue.value && tempStubdataValue.value.includes("•")){
            dispatch(inPutSettingOption.setToggleBulletBtn(true));    
          }else{
            dispatch(inPutSettingOption.setToggleBulletBtn(false));
          }
          if(tempStubdataValue.styleObj){
            // console.log("in")
            for (const key in tempStubdataValue.styleObj) {
              if (tempStubdataValue.styleObj.hasOwnProperty(key)) {
                
                // const element = object[key];
                if(tempStubdataValue.styleObj[key].value){
                  tempStubdataValue.styleObj[key].value =  tempStubdataValue.styleObj[key].value.replace("•","").trim() 

                }                
              }
            }
            // console.log("tempStubdataValue_1",tempStubdataValue)
            stubData[findSignleLine] = tempStubdataValue;
          }
        }

        dispatch(inPutSettingOption.setStubDataState(stubData));

        // set State for GEN

        const genData = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_GEN")
          );
        });
        getDataWithSetting(genData, fxsConfig);
        setValueFromSession(
          genData,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        dispatch(inPutSettingOption.setGenDataState(genData));

        //set the state for bankInformation

        const bankInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Bank")
          );
        });
        getDataWithSetting(bankInfo, fxsConfig);
        setValueFromSession(
          bankInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        dispatch(inPutSettingOption.setBankInfoState(bankInfo));

        //fractional number set state data
        const fractionalInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes &&
            config.attributes.NAME.includes("FXS_Fractional")
          );
        });
        getDataWithSetting(fractionalInfo, fxsConfig);
        setValueFromSession(
          fractionalInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        dispatch(inPutSettingOption.setfractionalInfoState(fractionalInfo));

        //set State for Check information from bank section

        const checkInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes &&
            config.attributes.NAME.includes("FXS_CheckNumber")
          );
        });
        getDataWithSetting(checkInfo, fxsConfig);
        setValueFromSession(
          checkInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
        dispatch(inPutSettingOption.setcheckInfoState(checkInfo));

        //set Routing state
        const RoutingInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Routing")
          );
        });
        getDataWithSetting(RoutingInfo, fxsConfig);
        setValueFromSession(
          RoutingInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );

        dispatch(inPutSettingOption.setRoutingInfoState(RoutingInfo));

        //set Account state
        const AccountInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Account")
          );
        });
        getDataWithSetting(AccountInfo, fxsConfig);
        setValueFromSession(
          AccountInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData
        );
       
        const itm = state.fetchDataReducer.jsonData[_.findIndex(state.fetchDataReducer.jsonData, ['name', 'FXS_Account'])]
        if(itm){
        const pos = itm.content.indexOf('>')        
        AccountInfo[0].value = itm.content.substring(pos + 1, itm.content.indexOf('<', pos))
        }
 
        dispatch(inPutSettingOption.setAccountInfoState(AccountInfo));

        //set default background
        if (docType.toLowerCase() !== "new order") {
          const FXSBackground = getVlaueFormJsonByKey(
            state.fetchDataReducer.jsonData,
            "FXS_Background",
            "content"
          );

          if (FXSBackground && FXSBackground !== -1) {
            const bgFileObj = getBGObjectFormByJsonData(
              fxsConfig.BACKGROUNDS,
              FXSBackground
            );
            if (bgFileObj !== -1) {
              dispatch(inPutSettingOption.onBGChanged(bgFileObj));
            }
          }
        }

        const SKUvalues = getVlaueFormSessionByKey(
          state.fetchDataReducer.sessionData,
          "SKU",
          "cdata"
        );
        const colorCode = getVlaueFormSessionByKey(
          state.fetchDataReducer.sessionData,
          "Colors",
          "cdata"
        );
        if (parseInt(colorCode) === 0 && SKUvalues.startsWith("FS")) {
          if (
            properJSON.COLORSETS &&
            properJSON.COLORSETS.SECURITY &&
            properJSON.COLORSETS.SECURITY.length > 0
          ) {
            let defaultCheckIndex = properJSON.COLORSETS.SECURITY.findIndex(
              (p) => p.default === true
            );
            const FXSBackground = getVlaueFormJsonByKey(
              state.fetchDataReducer.jsonData,
              "FXS_Security",
              "content"
            );
            if(FXSBackground!==-1 && docType.toLowerCase() !== "new order"){
              defaultCheckIndex = properJSON.COLORSETS.SECURITY.findIndex(
                (p) => p.BACKGROUNDFILE === FXSBackground
              );
            }
            if (FXSBackground!==-1 && defaultCheckIndex !== -1) {
              const bgObj = {
                FILE:
                  properJSON.COLORSETS.SECURITY[defaultCheckIndex]
                    .BACKGROUNDFILE,
                NAME:
                  properJSON.COLORSETS.SECURITY[defaultCheckIndex]
                    .BACKGROUNDFILE,
                PREVIEW:
                  properJSON.COLORSETS.SECURITY[defaultCheckIndex]
                    .BACKGROUNDFILE,
                TYPE: "PICT",
              };
              // alert("dd")
              dispatch(inPutSettingOption.onSecurityBGChanged(bgObj));
            }
          }
          const FXSBackground = getVlaueFormJsonByKey(
            state.fetchDataReducer.jsonData,
            "FXS_Security",
            "content"
          );
          if(FXSBackground!==-1 && docType.toLowerCase() !== "new order"){
            const defaultCheckColorIndex = properJSON.COLORSETS.SECURITY.findIndex(
              (p) => p.BACKGROUNDFILE === FXSBackground
            );
            dispatch(inPutSettingOption.onColorChanged(properJSON.COLORSETS.SECURITY[defaultCheckColorIndex].DISPLAY_NAME+"|100","PantosColor",false))
          }else if(FXSBackground!==-1){
            const defaultCheckColorIndex = properJSON.COLORSETS.SECURITY.findIndex(
              (p) => p.default === true
            );
            ;
              // const FXSBackground = properJSON.COLORSETS.SECURITY[
              //   defaultCheckColorIndex
              // ].BACKGROUNDFILE;
            dispatch(inPutSettingOption.onColorChanged(properJSON.COLORSETS.SECURITY[defaultCheckColorIndex].DISPLAY_NAME+"|100","PantosColor",false))
          } 
        }

        
        const jsonInfo = getVlaueFormJsonByKey(
          state.fetchDataReducer.jsonData,
          "JSON_XTENSION_INFO",
          "content"
        );

        if (jsonInfo && jsonInfo !== -1) {
          dispatch(inPutSettingOption.setDefaultJSONInfo(jsonInfo));
        }

        const FXSLogo1 = getVlaueFormJsonByKey(
          state.fetchDataReducer.jsonData,
          "FXS_Logo1",
          "content"
        );
        if(FXSLogo1 !==-1){
          if (
            FXSLogo1 &&
            FXSLogo1 !== "FXS_Logo1" &&
            docType.toLowerCase() !== "new order"
          ) {
            let displayName = -1;
            if (
              FXSLogo1.split("/").length > 0 &&
              FXSLogo1.split("/")[0] === "Customer_Logos"
            ) {
              displayName = getLogoDisplayNameByFolder(
                state.inputDataReducer.logoFiles[FXSLogo1.split("/")[0]],
                FXSLogo1.split("/")[2]
              );
            } else if (
              FXSLogo1.split("\\").length > 0 &&
              FXSLogo1.split("\\")[0] === "Customer_Logos"
            ) {
              displayName = getLogoDisplayNameByFolder(
                state.inputDataReducer.logoFiles[FXSLogo1.split("\\")[0]],
                FXSLogo1.split("\\")[2]
              );
  
              if (displayName != -1 && FXSLogo1.split("\\")[2]) {
                displayName = FXSLogo1.split("\\")[2];
              }
  
            } else {
                //#5277 Check logo by finding in logo folder of Customer Logos / Generic Side or Top Logos folder if logo path is incomplete 
                 if(FXSLogo1.split("/").length===2){
                const customerLogos=state.inputDataReducer.logoFiles.Customer_Logos
                let customerLogosName=getLogoDisplayNameByFolder(
                  customerLogos,
                  FXSLogo1.split("/")[0]
                );
                if(customerLogosName){
                    displayName= customerLogosName
                }  
                const genericSideLogos=state.inputDataReducer.logoFiles.Generic_SideLogos
                let genericSideName=getLogoDisplayNameByFolder(
                  genericSideLogos,
                  FXSLogo1.split("/")[0]
                );
                if(genericSideName){
                  displayName= genericSideName
                }
                const genericTopLogos=state.inputDataReducer.logoFiles.Generic_TopLogos
                let genericTopName=getLogoDisplayNameByFolder(
                  genericTopLogos,
                  FXSLogo1.split("/")[0]
                );
                if(genericTopName){
                  displayName= genericTopName
                }                 
              } else {
              displayName = getLogoDisplayNameByFolder(
                state.inputDataReducer.logoFiles[FXSLogo1.split("/")[0]],
                FXSLogo1.split("/")[1]
              );
              }
            }
            // console.log("state.inputDataReducer.logoFiles",state.inputDataReducer.logoFiles)
            if(state.inputDataReducer.logoFiles && displayName){
              dispatch(setAppliedLogoColor(displayName))
            }          
            
            dispatch(fetchPreviousSessionData(displayName));
            dispatch(inPutSettingOption.onLogoChanged(FXSLogo1, displayName));
          } else if (
            state.inputDataReducer.mainLogo &&
            state.inputDataReducer.mainLogo.length === 0
          ) {
            dispatch(inPutSettingOption.onLogoChanged("None", ""));
          }  
        }
        

        const phatomInfo = _.filter(fxsConfig.BOXES, (config) => {
          return (
            config.attributes && config.attributes.NAME.includes("FXS_Phantom")
          );
        });
        getDataWithSetting(phatomInfo, fxsConfig);
        setValueFromSession(
          phatomInfo,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.jsonData,
          true
        );
        
        for (let index = 0; index < phatomInfo.length; index++) {
          var FXSPhantom = getVlaueFormJsonByKey(
            state.fetchDataReducer.jsonData,
            phatomInfo[index].attributes.NAME,
            "content"
          );
          if (FXSPhantom !== -1 && FXSPhantom.split("/").length > 1) {
            if (
              FXSPhantom.split("/").length > 0 &&
              FXSPhantom.split("/")[0] === "Customer_Logos"
            ) {
              phatomInfo[index].phatomLogoName = getLogoDisplayNameByFolder(
                state.inputDataReducer.logoFiles[FXSPhantom.split("/")[0]],
                FXSPhantom.split("/")[2]
              );
              if(state.inputDataReducer.logoFiles && phatomInfo[index].phatomLogoName){
                              dispatch(setAppliedLogoColor(phatomInfo[index].phatomLogoName))
              }
              dispatch(fetchPreviousSessionData(phatomInfo[index].phatomLogoName));
            } else {
              //#5277 Check logo by finding in logo folder of Customer Logos / Generic Side or Top Logos folder if logo path is incomplete 
              let LogoDisplayName
              if(FXSPhantom.split("/").length===2){
                const customerLogos=state.inputDataReducer.logoFiles.Customer_Logos
                if(customerLogos){
                  LogoDisplayName=getLogoDisplayNameByFolder(
                    customerLogos,
                    FXSPhantom.split("/")[0]
                  );
                  if(LogoDisplayName){
                    phatomInfo[index].phatomLogoName=LogoDisplayName 
                  }
                }

                const genericTopLogos=state.inputDataReducer.logoFiles.Generic_TopLogos
                if(genericTopLogos){
                  LogoDisplayName=getLogoDisplayNameByFolder(
                    genericTopLogos,
                    FXSPhantom.split("/")[0]
                  );
                  if(LogoDisplayName){
                    phatomInfo[index].phatomLogoName=LogoDisplayName
                  }
                }
                const genericSideLogos=state.inputDataReducer.logoFiles.Generic_SideLogos
                if(genericSideLogos){
                  LogoDisplayName=getLogoDisplayNameByFolder(
                    genericSideLogos,
                    FXSPhantom.split("/")[0]
                  );
                  if(LogoDisplayName){
                    phatomInfo[index].phatomLogoName=LogoDisplayName
                  }
                }
              } else {              
              phatomInfo[index].phatomLogoName = getLogoDisplayNameByFolder(
                state.inputDataReducer.logoFiles[FXSPhantom.split("/")[0]],
                FXSPhantom.split("/")[1]
              );
              }
              if(state.inputDataReducer.logoFiles && phatomInfo[index].phatomLogoName){
                dispatch(setAppliedLogoColor(phatomInfo[index].phatomLogoName))
              }
              dispatch(fetchPreviousSessionData(phatomInfo[index].phatomLogoName));
            }
          } else {
            phatomInfo[index].phatomLogoName = FXSPhantom;
            phatomInfo[index].value = "None";
            if(state.inputDataReducer.logoFiles && phatomInfo[index].phatomLogoName){
              dispatch(setAppliedLogoColor(phatomInfo[index].phatomLogoName))
            }
            dispatch(fetchPreviousSessionData(phatomInfo[index].phatomLogoName));
          }
        }
        dispatch(inPutSettingOption.setPhantomLogoChanged(phatomInfo));
        dispatch(onBlurHandaler(false));
      })
      .catch((error) => {
        dispatch(
          fetchFxConfigFail(
            getErrorMessage(error, errorMessage.FX_CONFIG_ERROR)
          )
        );
      });
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  simulateClick
 * @desc   :  funciton used for click event from program
 */

const simulateClick = (elem) => {
  var evt = new MouseEvent("click", {
    bubbles: true,
    cancelable: true,
    view: window,
  });

  var canceled = !elem.dispatchEvent(evt);
};


/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewData
 * @desc   :  This function is for call the preview image Api
 */
const getChangedArray = (stateArray, elementName) => {
  
  let isChangeDone = false;
  const stateArray1 = [];
  // console.log("elementName",elementName)

  stateArray && stateArray.forEach((element, index) => {
    // console.log("elementName",elementName)
    if (
      
      element && element.attributes &&
      elementName == element.attributes.NAME
    ) { //element.isInputChanged === true
      if(setChangesFalse){
        element.isInputChanged = false;
      }
      element.isInputChangedFlag = false;
      isChangeDone = true;
    }else if (element && elementName == "lastcall") {
      if(setChangesFalse){
        element.isInputChanged = false;
      }
      element.isInputChangedFlag = false;
      isChangeDone = true;
    }else if(element){
      if(setChangesFalse){
        element.isInputChanged = false;
      }
      isChangeDone = true;
    }

    if(element)
    stateArray1.push(element)
  });
  return { isChangeDone: isChangeDone, stateArray: _.cloneDeep(stateArray1) };
};

const checkChangedNotHapped = (stateArray) => {
  let isChangeDone = false;
  const stateArray1 = [];
  stateArray.forEach((element, index) => {
    if (element && element.isInputChangedFlag == true) {
      element.isInputChanged = true;
      isChangeDone = true;
    }
    if(element){
      stateArray1.push(element);
    }
  });
  return { isChangeDone: isChangeDone, stateArray:stateArray1 };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setInputChangedFalse
 * @desc   :  This function called on focus out event on each field to set the ischanged flag false
 */

export const setInputChangedFalse = () => {

  return (dispatch, getState) => {
    const state = getState();
    const doctype = getVlaueFormSessionByKey(state.fetchDataReducer.sessionData,"Type", "cdata");
    setChangesFalse = true;
    if(doctype && doctype.toLowerCase() ==="overwrite" && !state.inputDataReducer.issaveOverwriteInst){
      setChangesFalse = false;
    }else{
      setChangesFalse = false;
    }
    
    const newQueueArr = _.cloneDeep(state.inputDataReducer.queueArr);
    if(setChangesFalse === false){
      dispatch(inPutSettingOption.updateInProgressFlag(false));
      if (newQueueArr.length > 0) {
        newQueueArr.shift();
        dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
      } 
      return false;
    }
    if (newQueueArr.length > 0) {
      
      const companyInfoArr = getChangedArray(
      _.cloneDeep(state.inputDataReducer.companyInfo),
        newQueueArr[0].focusOutElement
      );
      // console.log("companyInfoArr_",companyInfoArr)

      if (companyInfoArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setCompanyInfoState(companyInfoArr.stateArray)
        );
      }

      const newSignOverInfoArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.companySignOverInfo),
        newQueueArr[0].focusOutElement
      );
      if (newSignOverInfoArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setCompanyOverSigState(
            newSignOverInfoArr.stateArray
          )
        );
      }

      const stubDataArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.stubData),
        newQueueArr[0].focusOutElement
      );
      if (stubDataArr.isChangeDone) {
        dispatch(inPutSettingOption.setStubDataState(stubDataArr.stateArray));
      }

      const compnaySignInfoArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.compnaySignInfo),
        newQueueArr[0].focusOutElement
      );

      if (compnaySignInfoArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setCompanySignState(compnaySignInfoArr.stateArray)
        );
      }

      const bankInfoArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.bankInfo),
        newQueueArr[0].focusOutElement
      );
      if (bankInfoArr.isChangeDone) {
        dispatch(inPutSettingOption.setBankInfoState(bankInfoArr.stateArray));
      }

      const fractionalInfoArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.fractionalInfo),
        newQueueArr[0].focusOutElement
      );
      if (fractionalInfoArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setfractionalInfoState(
            fractionalInfoArr.stateArray
          )
        );
      }

      // const checkInfoArr = getChangedArray(
      //   _.cloneDeep(state.inputDataReducer.checkInfo),
      //   newQueueArr[0].focusOutElement
      // );
      // if (checkInfoArr.isChangeDone) {
      //   dispatch(inPutSettingOption.setcheckInfoState(checkInfoArr.stateArray));
      // }

      const RoutingValueArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.RoutingValue),
        newQueueArr[0].focusOutElement
      );
      if (RoutingValueArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setRoutingInfoState(RoutingValueArr.stateArray)
        );
      }

      const accountValueArr = getChangedArray(
        _.cloneDeep((state.inputDataReducer.accountValue)||[]),
        newQueueArr[0].focusOutElement
      );
      if (accountValueArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setAccountInfoState(accountValueArr.stateArray)
        );
      }

      const phantomLogoArr = getChangedArray(
        _.cloneDeep(state.inputDataReducer.phantomLogo),
        newQueueArr[0].focusOutElement
      );
      if (phantomLogoArr.isChangeDone) {
        dispatch(
          inPutSettingOption.setPhantomLogoChanged(phantomLogoArr.stateArray)
        );
      }

      const genDataArr = getChangedArray(_.cloneDeep(state.inputDataReducer.genData),newQueueArr[0].focusOutElement);
      if (genDataArr.isChangeDone) {
        dispatch(inPutSettingOption.setGenDataState(genDataArr.stateArray));
      }

      const DefaultColorArr1 = 
      getChangedArray(_.cloneDeep(state.inputDataReducer.DefaultColor),newQueueArr[0].focusOutElement)
      ;

      const DefaultColorArr = DefaultColorArr1.stateArray;
      const PantosColorArr1 = 
      getChangedArray(_.cloneDeep(state.inputDataReducer.PantosColor),newQueueArr[0].focusOutElement)
      ;
      
      const PantosColorArr = PantosColorArr1.stateArray
      ;
      const BorderColorArr1 = 
      getChangedArray(_.cloneDeep(state.inputDataReducer.BorderColor),newQueueArr[0].focusOutElement)
      ;
      const BorderColorArr = 
      BorderColorArr1.stateArray
      ;

      const colortObj = {
        textBtn: { name: "", color: "" },
        lineBtn: { name: "", color: "" },
        bgBtn: { name: "", color: "" },
      };

      if (DefaultColorArr.length > 0 && DefaultColorArr[0].value) {
        colortObj.bgBtn.name = DefaultColorArr[0].value;
      }

      if (BorderColorArr.length > 0 && BorderColorArr[0].value) {
        colortObj.lineBtn.name = BorderColorArr[0].value;
      }

      if (PantosColorArr.length > 0 && PantosColorArr[0].value) {
        colortObj.textBtn.name = PantosColorArr[0].value;
      }

      dispatch(inPutSettingOption.onColorDefault(colortObj));
      let signLineArr = [];
      if (state.inputDataReducer.signLine) {
        signLineArr = getChangedArray(
          _.cloneDeep(state.inputDataReducer.signLine),
          newQueueArr[0].focusOutElement
        );
        if (signLineArr.isChangeDone) {
          dispatch(
            inPutSettingOption.setOverSigLineState(
              signLineArr.stateArray[0].value
            )
          );
        }
      }
      let backgroundImageArr=[];
      if (state.inputDataReducer.backgroundImage) {
        backgroundImageArr = getChangedArray(
          _.cloneDeep(state.inputDataReducer.backgroundImage),
          newQueueArr[0].focusOutElement
        );
        if (backgroundImageArr.isChangeDone) {
          dispatch(
            inPutSettingOption.onBGChanged({
              FILE: backgroundImageArr.stateArray[0].value,
              PREVIEW: state.inputDataReducer.bgPreview,
              NAME: state.inputDataReducer.bgPreviewName,
            })
          );
        }
      }
      let mainLogoArr = [];
      if (state.inputDataReducer.mainLogo) {
        mainLogoArr = getChangedArray(
          _.cloneDeep(state.inputDataReducer.mainLogo),
          newQueueArr[0].focusOutElement
        );
        if (mainLogoArr.isChangeDone) {
          dispatch(
            inPutSettingOption.onLogoChanged(
              mainLogoArr.stateArray[0].value,
              mainLogoArr.stateArray[0].mainLogoName
            )
          );
        }
      }
      let setDesignInstructionArr = [];
      if (state.inputDataReducer.setDesignInstruction) {
        setDesignInstructionArr = getChangedArray(
          _.cloneDeep(state.inputDataReducer.setDesignInstruction),
          newQueueArr[0].focusOutElement
        );
        if (setDesignInstructionArr.isChangeDone) {
          dispatch(
            inPutSettingOption.setDesignInstruction(
              setDesignInstructionArr.stateArray[0].value
            )
          );
        }
      }
      let imageTypeArr = [];
      if (state.inputDataReducer.imageType) {
        imageTypeArr = getChangedArray(
          _.cloneDeep(state.inputDataReducer.imageType),
          newQueueArr[0].focusOutElement
        );
        if (imageTypeArr.isChangeDone) {
          dispatch(
            inPutSettingOption.setImageType(imageTypeArr.stateArray[0].value)
          );
        }
      }
      // let JSONINFOArr = [];
      // if (state.inputDataReducer.JSONINFO) {
      //   JSONINFOArr = getChangedArray(
      //     _.cloneDeep(state.inputDataReducer.JSONINFO),
      //     newQueueArr[0].focusOutElement
      //   );
      //   if (JSONINFOArr.isChangeDone) {
      //     dispatch(
      //       inPutSettingOption.setDefaultJSONInfo(
      //         JSONINFOArr.stateArray[0].value
      //       )
      //     );
      //   }
      // }

      

      // const newQueueArr = _.cloneDeep(state.inputDataReducer.queueArr);
      if (newQueueArr.length > 0) {
        newQueueArr.shift();
        dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
      } 
      if(doctype.toLowerCase() !="overwrite"){
        dispatch(inPutSettingOption.setIsInputChangedState(false));
      }else if(doctype.toLowerCase() === "overwrite" && state.inputDataReducer.issaveOverwriteInst){
        dispatch(inPutSettingOption.setIsInputChangedState(false));
      }       
      
      if (newQueueArr.length === 0 && doctype.toLowerCase() !="exact") { 
        //checkChangedNotHapped
        setTimeout(() => {
          
          let previewChanged = false;
          
          const newArrStateIsTrue = [
            ..._.cloneDeep(companyInfoArr.stateArray),
            // ..._.cloneDeep(newSignOverInfoArr.stateArray),
            // ..._.cloneDeep(stubDataArr.stateArray),
            // ..._.cloneDeep(compnaySignInfoArr.stateArray),
            // ..._.cloneDeep(bankInfoArr.stateArray),
            // ..._.cloneDeep(fractionalInfoArr.stateArray),
            // ..._.cloneDeep(checkInfoArr.stateArray),
            // ..._.cloneDeep(RoutingValueArr.stateArray),
            // ..._.cloneDeep(accountValueArr.stateArray),
            // ..._.cloneDeep(phantomLogoArr.stateArray),
            // ..._.cloneDeep(genDataArr.stateArray),
            // ..._.cloneDeep(DefaultColorArr1.stateArray),
            // ..._.cloneDeep(PantosColorArr1.stateArray),
            // ..._.cloneDeep(BorderColorArr1.stateArray),
            // ..._.cloneDeep(signLineArr.stateArray),
            // ..._.cloneDeep(backgroundImageArr.stateArray),
            // ..._.cloneDeep(mainLogoArr.stateArray),
            // ..._.cloneDeep(setDesignInstructionArr.stateArray ? setDesignInstructionArr.stateArray : []),
            // ...JSONINFOArr.stateArray,
            // ..._.cloneDeep(imageTypeArr.stateArray),
          ];

          // console.log("newArrStateIsTrue",newArrStateIsTrue)
          
          const companyInfoNotApplied = checkChangedNotHapped(
            _.cloneDeep(companyInfoArr.stateArray),
          );  
          if (companyInfoNotApplied.isChangeDone) {
            // console.log("companyInfoNotApplied",companyInfoNotApplied)
            dispatch(
              inPutSettingOption.setCompanyInfoState(companyInfoNotApplied.stateArray)
            );
            previewChanged = true;
          }

          const newSignOverInfoArrNotAppied = checkChangedNotHapped(
            _.cloneDeep(newSignOverInfoArr.stateArray)
          );
          if (newSignOverInfoArrNotAppied.isChangeDone) {
            dispatch(
              inPutSettingOption.setCompanyOverSigState(
                newSignOverInfoArrNotAppied.stateArray
              )
            );
            previewChanged = true;
          }
    
          const stubDataArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(stubDataArr.stateArray)
          );
          if (stubDataArrNotApplied.isChangeDone) {
            dispatch(inPutSettingOption.setStubDataState(stubDataArrNotApplied.stateArray));
            previewChanged = true;
          }
    
          const compnaySignInfoArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(compnaySignInfoArr.stateArray)
          );
    
          if (compnaySignInfoArrNotApplied.isChangeDone) {
            dispatch(
              inPutSettingOption.setCompanySignState(compnaySignInfoArrNotApplied.stateArray)
            );
            previewChanged = true;
          }
    
          const bankInfoArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(bankInfoArr.stateArray),
          );
          if (bankInfoArrNotApplied.isChangeDone) {
            dispatch(inPutSettingOption.setBankInfoState(bankInfoArrNotApplied.stateArray));
            previewChanged = true;
          }
    
          const fractionalInfoArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(fractionalInfoArr.stateArray),
          );
          if (fractionalInfoArrNotApplied.isChangeDone) {
            dispatch(
              inPutSettingOption.setfractionalInfoState(
                fractionalInfoArrNotApplied.stateArray
              )
            );
            previewChanged = true;
          }
    
          // const checkInfoArrNotApplied = checkChangedNotHapped(
          //   _.cloneDeep(checkInfoArr.stateArray)
          // );
          // if (checkInfoArrNotApplied.isChangeDone) {
          //   dispatch(inPutSettingOption.setcheckInfoState(checkInfoArrNotApplied.stateArray));
          //   previewChanged = true;
          // }
    
          const RoutingValueArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(RoutingValueArr.stateArray),
          );
          if (RoutingValueArrNotApplied.isChangeDone) {
            dispatch(
              inPutSettingOption.setRoutingInfoState(RoutingValueArrNotApplied.stateArray)
            );
            previewChanged = true;
          }
    
          const accountValueArrNotApplied = checkChangedNotHapped(
            _.cloneDeep(accountValueArr.stateArray)
          );
          if (accountValueArrNotApplied.isChangeDone) {
            dispatch(
              inPutSettingOption.setAccountInfoState(accountValueArrNotApplied.stateArray)
            );
            previewChanged = true;
          }
    
          // const phantomLogoArrNotApplied = getChangedArray(
          //   _.cloneDeep(phantomLogoArr.stateArray)
          // );
          // if (phantomLogoArrNotApplied.isChangeDone) {
          //   dispatch(
          //     inPutSettingOption.setPhantomLogoChanged(phantomLogoArrNotApplied.stateArray)
          //   );
          // }
    
          const genDataArrNotApplied = checkChangedNotHapped(_.cloneDeep(genDataArr.stateArray));
          if (genDataArrNotApplied.isChangeDone) {
            dispatch(inPutSettingOption.setGenDataState(genDataArrNotApplied.stateArray));
          }
    
          // const DefaultColorArr1 = 
          // getChangedArray(_.cloneDeep(state.inputDataReducer.DefaultColor),newQueueArr[0].focusOutElement)
          // ;
    
          // const DefaultColorArr = DefaultColorArr1.stateArray;
          // const PantosColorArr1 = 
          // getChangedArray(_.cloneDeep(state.inputDataReducer.PantosColor),newQueueArr[0].focusOutElement)
          // ;
          
          // const PantosColorArr = PantosColorArr1.stateArray
          // ;
          // const BorderColorArr1 = 
          // getChangedArray(_.cloneDeep(state.inputDataReducer.BorderColor),newQueueArr[0].focusOutElement)
          // ;
          // const BorderColorArr = 
          // BorderColorArr1.stateArray
          // ;
    
          // const colortObj = {
          //   textBtn: { name: "", color: "" },
          //   lineBtn: { name: "", color: "" },
          //   bgBtn: { name: "", color: "" },
          // };
    
          // if (DefaultColorArr.length > 0 && DefaultColorArr[0].value) {
          //   colortObj.bgBtn.name = DefaultColorArr[0].value;
          // }
    
          // if (BorderColorArr.length > 0 && BorderColorArr[0].value) {
          //   colortObj.lineBtn.name = BorderColorArr[0].value;
          // }
    
          // if (PantosColorArr.length > 0 && PantosColorArr[0].value) {
          //   colortObj.textBtn.name = PantosColorArr[0].value;
          // }
    
          // dispatch(inPutSettingOption.onColorDefault(colortObj));
          // let signLineArr = [];
          // if (state.inputDataReducer.signLine) {
          //   signLineArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.signLine),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (signLineArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.setOverSigLineState(
          //         signLineArr.stateArray[0].value
          //       )
          //     );
          //   }
          // }
          // let backgroundImageArr=[];
          // if (state.inputDataReducer.backgroundImage) {
          //   backgroundImageArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.backgroundImage),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (backgroundImageArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.onBGChanged({
          //         FILE: backgroundImageArr.stateArray[0].value,
          //         PREVIEW: state.inputDataReducer.bgPreview,
          //         NAME: state.inputDataReducer.bgPreviewName,
          //       })
          //     );
          //   }
          // }
          // let mainLogoArr = [];
          // if (state.inputDataReducer.mainLogo) {
          //   mainLogoArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.mainLogo),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (mainLogoArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.onLogoChanged(
          //         mainLogoArr.stateArray[0].value,
          //         state.inputDataReducer.mainLogoName
          //       )
          //     );
          //   }
          // }
          // let setDesignInstructionArr = [];
          // if (state.inputDataReducer.setDesignInstruction) {
          //   setDesignInstructionArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.setDesignInstruction),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (setDesignInstructionArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.setDesignInstruction(
          //         setDesignInstructionArr.stateArray[0].value
          //       )
          //     );
          //   }
          // }
          // let imageTypeArr = [];
          // if (state.inputDataReducer.imageType) {
          //   imageTypeArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.imageType),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (imageTypeArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.setImageType(imageTypeArr.stateArray[0].value)
          //     );
          //   }
          // }
          // let JSONINFOArr = [];
          // if (state.inputDataReducer.JSONINFO) {
          //   JSONINFOArr = getChangedArray(
          //     _.cloneDeep(state.inputDataReducer.JSONINFO),
          //     newQueueArr[0].focusOutElement
          //   );
          //   if (JSONINFOArr.isChangeDone) {
          //     dispatch(
          //       inPutSettingOption.setDefaultJSONInfo(
          //         JSONINFOArr.stateArray[0].value
          //       )
          //     );
          //   }
          // }
          
          if(previewChanged === true){
             
              let newSignOverInfo = [];
              if (state.inputDataReducer.signOverLine != "none") {
                newSignOverInfo = [...state.inputDataReducer.companySignOverInfo];
              }
    
              const newArrState = [
                ..._.cloneDeep(companyInfoNotApplied.stateArray),
                ..._.cloneDeep(newSignOverInfoArrNotAppied.stateArray),
                ..._.cloneDeep(stubDataArrNotApplied.stateArray),
                ..._.cloneDeep(compnaySignInfoArrNotApplied.stateArray),
                ..._.cloneDeep(bankInfoArrNotApplied.stateArray),
                ..._.cloneDeep(fractionalInfoArrNotApplied.stateArray),
                // ..._.cloneDeep(checkInfoArrNotApplied.stateArray),
                ..._.cloneDeep(RoutingValueArrNotApplied.stateArray),
                ..._.cloneDeep(accountValueArrNotApplied.stateArray),
                ..._.cloneDeep(genDataArrNotApplied.stateArray),
                // ..._.cloneDeep(newSignOverInfo),
                // ..._.cloneDeep(state.inputDataReducer.stubData),
                // ..._.cloneDeep(state.inputDataReducer.compnaySignInfo),
                // ..._.cloneDeep(state.inputDataReducer.bankInfo),
                // ..._.cloneDeep(state.inputDataReducer.fractionalInfo),
                // ..._.cloneDeep(state.inputDataReducer.checkInfo),
                // ..._.cloneDeep(state.inputDataReducer.RoutingValue),
                // ..._.cloneDeep(state.inputDataReducer.accountValue),
                // ..._.cloneDeep(state.inputDataReducer.DefaultColor),
                // ..._.cloneDeep(state.inputDataReducer.PantosColor),
                // ..._.cloneDeep(state.inputDataReducer.BorderColor),
                // ..._.cloneDeep(state.inputDataReducer.signLine),
                // ..._.cloneDeep(state.inputDataReducer.backgroundImage),
                // ..._.cloneDeep(state.inputDataReducer.mainLogo),
                // ..._.cloneDeep(state.inputDataReducer.phantomLogo),
                // ..._.cloneDeep(state.inputDataReducer.genData),
                // ..._.cloneDeep(state.inputDataReducer.JSONINFO),
                // ..._.cloneDeep(state.inputDataReducer.page),
                // ..._.cloneDeep(state.inputDataReducer.Design_Instructions),
                // ..._.cloneDeep(state.inputDataReducer.imageType),
              ];
    
              // const newObj = {
              //   companyInfo: _.cloneDeep(state.inputDataReducer.companyInfo),
              //   companySignOverInfo: _.cloneDeep(newSignOverInfo),
              //   stubData: _.cloneDeep(state.inputDataReducer.stubData),
              //   compnaySignInfo: _.cloneDeep(
              //     state.inputDataReducer.compnaySignInfo
              //   ),
              //   bankInfo: _.cloneDeep(state.inputDataReducer.bankInfo),
              //   fractionalInfo: _.cloneDeep(state.inputDataReducer.fractionalInfo),
              //   checkInfo: _.cloneDeep(state.inputDataReducer.checkInfo),
              //   RoutingValue: _.cloneDeep(state.inputDataReducer.RoutingValue),
              //   accountValue: _.cloneDeep(state.inputDataReducer.accountValue),
              //   DefaultColor: _.cloneDeep(state.inputDataReducer.DefaultColor),
              //   PantosColor: _.cloneDeep(state.inputDataReducer.PantosColor),
              //   BorderColor: _.cloneDeep(state.inputDataReducer.BorderColor),
              //   signLine: _.cloneDeep(state.inputDataReducer.signLine),
              //   backgroundImage: _.cloneDeep(
              //     state.inputDataReducer.backgroundImage
              //   ),
              //   mainLogo: _.cloneDeep(state.inputDataReducer.mainLogo),
              //   phantomLogo: _.cloneDeep(state.inputDataReducer.phantomLogo),
              //   genData: _.cloneDeep(state.inputDataReducer.genData),
              //   JSONINFO: _.cloneDeep(state.inputDataReducer.JSONINFO),
              //   page: _.cloneDeep(state.inputDataReducer.page),
              //   Design_Instructions: _.cloneDeep(
              //     state.inputDataReducer.Design_Instructions
              //   ),
              //   imageType: _.cloneDeep(state.inputDataReducer.imageType),
              //   LogoType: _.cloneDeep(state.inputDataReducer.LogoType),
              //   logoColorsArr: _.cloneDeep(state.inputDataReducer.logoColorsArr),
              //   additionalColor: _.cloneDeep(
              //     state.inputDataReducer.additionalColor
              //   ),
              // };
              // dispatch(inPutSettingOption.addHistoryState(newObj, "NEW"));
    
              const newQueueArr = _.cloneDeep(state.inputDataReducer.queueArr);
              const queueObj = {
                isFocusOut: true,
                isImage: true,
                newArrState: newArrState,
                focusOutElement: "lastcall",
              };
              
              newQueueArr.push(queueObj);
    
              dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
              
            
          }
          dispatch(inPutSettingOption.updateInProgressFlag(false));
           
        }, 1000);
      }
    }
      dispatch(inPutSettingOption.updateInProgressFlag(false));
    
  };
};


const CancelToken = Axios.CancelToken;
let cancelRequest;


/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchPreviewData
 * @param  : tempArray,
  dataFrom = "session/focusout/focusout_json",
  type = "jpg/json/pdf"
 * @desc   :  This function called the render API and return the responce according to type
 */

export const fetchPreviewData = (
  tempArray,
  dataFrom = "session",
  type = "jpg",
  isFocusOut = true,
  isImage = true,
  isFaceUpDownChange=false
) => {
  return (dispatch, getState) => {
    let apiData;
    const state = getState();
    console.log('state.inputDataReducer.previewAPIFlag',state.inputDataReducer.previewAPIFlag)
    // if (state.inputDataReducer.previewAPIFlag === true) {
    //   return false;
    // }

    //cancel previous request if multiple hits done.
   cancelRequest && cancelRequest();
    console.log('api getting called')
    if (type === "jpg" && (dataFrom === "focusout" || dataFrom === "focusout_json")) {
      dispatch(inPutSettingOption.updateInProgressFlag(true));
    }
    /**#5279 Code Change-No loader should be visible for face up and down isFaceUpDownChange=true*/
    if(isFaceUpDownChange==false){
      dispatch(fetchPreviewStart());
    }

    if (dataFrom === "focusout" || dataFrom === "focusout_json") {
      apiData = convertDataOnFocusOut(
        tempArray,
        state.fetchDataReducer.sessionData,
        state.inputDataReducer.bulletFlag
      );
    } else {
      const templateType = getVlaueFormSessionByKey(tempArray, "Type", "cdata");
      if (
        templateType.toLowerCase() !== "copy" &&
        templateType.toLowerCase() !== "exact" &&
        templateType.toLowerCase() !== "overwrite"
      ) {
        apiData = convertData(
          tempArray,
          state.fetchDataReducer.sessionData,
          state.fetchDataReducer.fxConfig.BOXDEFS,
          state.fetchDataReducer,
          type
        );
      }
    }

    const getSesionIndex = state.fetchDataReducer.sessionData.findIndex(
      (p) => p.name === "SKU"
    );
    let templateId;
    const instance = localStorage.getItem("instance");
    if (instance) {
      const paseInst = JSON.parse(instance);
      templateId = paseInst.entities[0].name;
    } else {
      alert("Instnace not found");
      return false;
    }

    if (apiData && apiData.template) delete apiData.template;

    let urlDataText = createUrlData(apiData);
  
    let urlData = urlDataText;
    urlData.fmt = type;
    urlData.loglevel = "debug";

    const colors = getVlaueFormSessionByKey(
      state.fetchDataReducer.sessionData,
      "Colors",
      "cdata"
    ); 

    if (parseInt(colors) === 0) {
      urlData.LogoBlack = "true";
    }

    if (parseInt(colors) === 1 && state.inputDataReducer.logoDC) {
      urlData.LogoDC = state.inputDataReducer.logoDC;
    }
    // logoDC
    let InstanceType = getVlaueFormSessionByKey(state.fetchDataReducer.sessionData, "Type", "cdata");
    if (type !== "json" && type !== "pdf" && type !== "pdfproof") {
      
      if(InstanceType.toLowerCase() !="overwrite"){
        urlData.req = "saveinstance";  
      }else if(InstanceType.toLowerCase() === "overwrite" && state.inputDataReducer.issaveOverwriteInst){
        urlData.req = "saveinstance";
      }
      
    }
    const contentAreaElement = document.getElementById("content-area");

    if (state.fetchDataReducer.imageWith > 0) {
      urlData.wid = state.fetchDataReducer.imageWith;
      dispatch(setLatIncresedWidth(state.fetchDataReducer.imageWith));
    } else if (window.innerWidth) {
      const imageWidth = window.innerWidth;
      urlData.wid = imageWidth;
      dispatch(setLatIncresedWidth(imageWidth));
    }

    const headerData = {};
    if (type === "jpg" || type === "pdf" || type === "pdfproof") {
      headerData.responseType = "arraybuffer";
    }

    if(type === "pdf" || type === "pdfproof"){
      urlData.fmt =  type ;
    }
    console.log("urlData", urlData);
    Axios({
      method: "post",
      url: `${QUEUE_BY_TEMPLATE}`,
      data: {
        instanceID: templateId,
        ...urlData,
      },
      ...headerData,
      cancelToken: new CancelToken(function executor(c) {
        cancelRequest = c;
    })
    })
      .then((response) => {
        if(InstanceType.toLowerCase() !="overwrite"){
          dispatch(inPutSettingOption.setIsInputChangedState(false));
        }else if(InstanceType.toLowerCase() === "overwrite" && state.inputDataReducer.issaveOverwriteInst){
          dispatch(inPutSettingOption.setIsInputChangedState(false));
        }
        
        if (type === "jpg") {
          var blob = new Blob([response.data]);
          var imgUrl = window.URL.createObjectURL(blob);

          dispatch(fetchPreviewSuccess(imgUrl));
          setTimeout(() => {
            dispatch(setInputChangedFalse(tempArray));
          }, 500);
        } else if (type === "pdf" || type === "pdfproof") {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });

          var fileURL;
          let userAgentString = navigator.userAgent;

          let chromeAgent = userAgentString.indexOf("Chrome") > -1;
          let safariAgent = userAgentString.indexOf("Safari") > -1;

          if (chromeAgent && safariAgent) safariAgent = false;

          if (safariAgent) {
            let u8 = new Uint8Array(response.data);
            let b64encoded = btoa(
              [].reduce.call(
                new Uint8Array(response.data),
                function (p, c) {
                  return p + String.fromCharCode(c);
                },
                ""
              )
            );
            let mimetype = "application/pdf";
            fileURL = "data:" + mimetype + ";base64," + b64encoded;
          } else if (window.webkitURL) {
            fileURL = window.webkitURL.createObjectURL(file);
          } else if (window.URL && window.URL.createObjectURL) {
            fileURL = window.URL.createObjectURL(file);
          } else {
            fileURL = URL.createObjectURL(file);
          }

          dispatch(fetchPDFSuccess());
          var elem = document.getElementById("linkid");
          if (elem) {
            elem.setAttribute("href", fileURL);
            elem.setAttribute("target", "_blank");
            simulateClick(elem);
          }
          /**#5279 Code Change-Check for not updating preview in faceUpDown value change*/
        } else if(isFaceUpDownChange==true){
          console.log('on face up no update in preview')
        } else {
          dispatch(fetchJsonSuccess(response.data));
          if (isFocusOut !== false && isImage !== false) {
            dispatch(fetchFxConfigData());
            setTimeout(() => {
              dispatch(fetchPreviewData(state.fetchDataReducer.sessionData));
            }, 1000);
          }
        }
      })
      .catch((error) => {
        if (Axios.isCancel(error)) {
          console.log('Error: ', error.message); // => prints: Api is being canceled
          dispatch(fetchPreviewAbort());
          return true
        } 
        dispatch(
          fetchPreviewFail(getErrorMessage(error, errorMessage.FX_CONFIG_ERROR))
        );
      });
    setTimeout(() => {
      InstanceType = getVlaueFormSessionByKey(state.fetchDataReducer.sessionData, "Type", "cdata");
      if(InstanceType && InstanceType.toLowerCase() !="overwrite"){
        dispatch(inPutSettingOption.setIsInputChangedState(false));
      }else if(InstanceType && InstanceType.toLowerCase() === "overwrite" && state.inputDataReducer.issaveOverwriteInst){
        dispatch(inPutSettingOption.setIsInputChangedState(false));
      }
    }, 1000);
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchSessionDataSuccess
 * @desc   :  This function is for called after session file responce and set session data to redux store
 * @return -  object of new state
 */

const fetchSessionDataSuccess = (response) => {
  return {
    type: actionType.FETCH_SESSION_DATA_SUCCESS,
    sessionData: response,
  };
};

//

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setOldInstanceData
 * @desc   :  This function is for called after session file responce and set session data to redux store
 * @return -  object of new state
 */

const setOldInstanceData = (data) => {
  return {
    type: actionType.SET_OLD_INSTANCE_DATA,
    data: data,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchSessionData
 * @desc   :  This function is for read the session file
 */

export const fetchSessionData = (sessionFileId) => {
  const sessionFile = SESSION_FILE_PATH + sessionFileId;
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchSessionDataStart());
    dispatch(setSessionDataID(sessionFileId));
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    };

    const headerData = {};
    var content,
      newContent = "";
    headerData.ContentType = "text/xml;charset=utf-8";
    headerData.Accept = "text/xml";

    function load_binary_resource(url) {
      var req = new XMLHttpRequest();
      req.open("GET", url, false);

      req.overrideMimeType("text/plain; charset=x-user-defined");
      req.send(null);
      if (req.status != 200) return "";
      return req.responseText;
    }

    var filestream = load_binary_resource(sessionFile);

    const jsonData = convertXML2Json(filestream);

    let tempArr = [];
    if (jsonData.elements[0].elements[0].elements) {
      const userData = [...jsonData.elements[0].elements[0].elements];
      tempArr = userData.map((element) => {
        let cdata =
          element.elements && element.elements[0] && element.elements[0].cdata
            ? element.elements[0].cdata
            : "";
        if (!cdata) {
          cdata =
            element.elements && element.elements[0] && element.elements[0].text
              ? element.elements[0].text
              : "";
        }
        return {
          type: element.type,
          name: element.name,
          cdata: cdata,
        };
      });
      dispatch(fetchLogoImagesData(tempArr));
      dispatch(fetchSessionDataSuccess(tempArr));
      // dispatch(fetchFxConfigData());
      const templateType = getVlaueFormSessionByKey(tempArr, "Type", "cdata");
      dispatch(inPutSettingOption.setOrderType(templateType))
      if (
        templateType.toLowerCase() === "copy" ||
        templateType.toLowerCase() === "exact"
      ) {
        const Previous_Order_ID = getVlaueFormSessionByKey(
          tempArr,
          "Previous_Order_ID",
          "cdata"
        );

        const copyTemp = await copyInstance(Previous_Order_ID);
        if (copyTemp.data && copyTemp.data.success) {
          const updateInstanceResult = await updateInstance(
            copyTemp.data.entities[0].id,
            sessionFileId
          );

          if (updateInstanceResult.data && updateInstanceResult.data.success) {
            const getInstanceById = await getInstance(
              updateInstanceResult.data.entities[0].id
            );

            if (getInstanceById.data && getInstanceById.data.success) {
              localStorage.setItem(
                "instance",
                JSON.stringify(getInstanceById.data)
              );
              dispatch(fetchPreviewData(tempArr, "session", "json"));
            } else if (localStorage.getItem("instance")) {
              alert(getInstanceById.data.message);
            }
          } else {
            alert(updateInstanceResult.data.message);
          }
        } else {
          alert(copyTemp.data.message);
        }
      } else if (templateType.toLowerCase() === "overwrite") {
        // const OrderNumber = getVlaueFormSessionByKey(
        //   tempArr,
        //   "OrderNumber",
        //   "cdata"
        // );

        // const getOldInstanceById = await getInstance(OrderNumber);
        // if (getOldInstanceById && getOldInstanceById.data.success) {
        //   dispatch(setOldInstanceData(getOldInstanceById.data.entities))
        // }
        // const timestamp = Math.floor(Date.now() / 1000);
        // const copyTemp = await copyInstance(OrderNumber);
        // let updateInstanceResult;
        // if(copyTemp.data && copyTemp.data.success){
        //   updateInstanceResult = await updateInstance(
        //     copyTemp.data.entities[0].id,
        //     OrderNumber+"-"+timestamp
        //   );  
        // }else{
        //   alert(copyTemp.data.message);
        // }
        

        // if (updateInstanceResult && updateInstanceResult.data && updateInstanceResult.data.success) {
        //   const getInstanceById = await getInstance(
        //     updateInstanceResult.data.entities[0].id
        //   );
        //   if (getInstanceById && getInstanceById.data.success) {
        //       localStorage.setItem(
        //         "instance",
        //         JSON.stringify(getInstanceById.data)
        //       );
        //       dispatch(fetchPreviewData(tempArr, "session", "json"));
        //     } else {
        //       alert(getInstanceById.data.message);
        //     }
        // }

        const OrderNumber = getVlaueFormSessionByKey(
          tempArr,
          "OrderNumber",
          "cdata"
        );

        const getInstanceById = await getInstance(OrderNumber);
        if (getInstanceById && getInstanceById.data.success) {
          localStorage.setItem(
            "instance",
            JSON.stringify(getInstanceById.data)
          );
          dispatch(fetchPreviewData(tempArr, "session", "json"));
        } else {
          alert(getInstanceById.data.message);
        }

      } else {
        const cretaeinstResc = await createInstance(tempArr, sessionFileId);
        if (cretaeinstResc && cretaeinstResc.data && cretaeinstResc.data.success) {
          localStorage.setItem("instance", JSON.stringify(cretaeinstResc.data));
          dispatch(fetchPreviewData(tempArr, "session", "json"));
        } else {
          const getInstanceById = await getInstance(sessionFileId);
          if (getInstanceById && getInstanceById.data.success) {
            localStorage.setItem(
              "instance",
              JSON.stringify(getInstanceById.data)
            );
            dispatch(fetchPreviewData(tempArr, "session", "json"));
          } else {
            alert(getInstanceById.data.message);
          }
        }
      }
    }
  };
};

export const fetchPreviousSessionData = (logoName) => {
  
  return async (dispatch, getState) => {
    const state = getState();
    const logoObject = state.inputDataReducer.logoFiles;
    const checkCmLogo = logoObject.Customer_Logos.indexOf((p)=> p.DISPLAY_NAME == logoName);
    const checkTopLogo = logoObject.Generic_SideLogos.indexOf((p)=> p.DISPLAY_NAME == logoName);
    const checkSideLogo = logoObject.Generic_TopLogos.indexOf((p)=> p.DISPLAY_NAME == logoName);
    if(checkCmLogo==-1 || checkTopLogo==-1 || checkSideLogo ==-1){
      dispatch(inPutSettingOption.setCustomerLogoFlag(true));
    }
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onBlurHandaler
 * @desc   :  This function called on focus out event of each field collect all the states and send to the fetchPreviewData function to call the preview image api
 */

export const onBlurHandaler = (
  isFocusOut = true,
  isNew = true,
  isImage = true,
  focusOutElement = "",
  event
) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.inputDataReducer.DefaultColor)
      state.inputDataReducer.DefaultColor = [];

    if (!state.inputDataReducer.PantosColor)
      state.inputDataReducer.PantosColor = [];

    if (!state.inputDataReducer.BorderColor)
      state.inputDataReducer.BorderColor = [];

    if (!state.inputDataReducer.backgroundImage)
      state.inputDataReducer.backgroundImage = [];

    if (!state.inputDataReducer.mainLogo) state.inputDataReducer.mainLogo = [];

    if (!state.inputDataReducer.phantomLogo)
      state.inputDataReducer.phantomLogo = [];

    if (!state.inputDataReducer.Design_Instructions)
      state.inputDataReducer.Design_Instructions = [];

    if (!state.inputDataReducer.page) state.inputDataReducer.page = [];

    if (!state.inputDataReducer.signLine)
      state.inputDataReducer.signLine = [{ NAME: "SigLine", value: "1" }];

    if (!state.inputDataReducer.imageType)
      state.inputDataReducer.imageType = [];
      console.log('focusOutElement',focusOutElement)   
      console.log('state.inputDataReducer.isInputChanged',state.inputDataReducer.isInputChanged)     
    // if (state.inputDataReducer.isInputChanged) {
      let newSignOverInfo = [];
      if (state.inputDataReducer.signOverLine != "none") {
        newSignOverInfo = [...state.inputDataReducer.companySignOverInfo];
      }
      if(!state.inputDataReducer.companyInfo){
        state.inputDataReducer.companyInfo = [];
      }
      if(!state.inputDataReducer.stubData){
        state.inputDataReducer.stubData = [];
      }
      if(!state.inputDataReducer.compnaySignInfo){
        state.inputDataReducer.compnaySignInfo = []
      }
      if(!state.inputDataReducer.bankInfo){
        state.inputDataReducer.bankInfo =[];
      }
      if(!state.inputDataReducer.fractionalInfo){
        state.inputDataReducer.fractionalInfo =[];
      }
      if(!state.inputDataReducer.checkInfo){
        state.inputDataReducer.checkInfo = []
      }
      if(!state.inputDataReducer.JSONINFO){
        state.inputDataReducer.JSONINFO = [];
      }
      const newArrState = [
        ..._.cloneDeep((state.inputDataReducer.companyInfo)||[]),
        ..._.cloneDeep(newSignOverInfo),
        ..._.cloneDeep((state.inputDataReducer.stubData)||[]),
        ..._.cloneDeep((state.inputDataReducer.compnaySignInfo)||[]),
        ..._.cloneDeep((state.inputDataReducer.bankInfo)||[]),
        ..._.cloneDeep((state.inputDataReducer.fractionalInfo)||[]),
        ..._.cloneDeep((state.inputDataReducer.checkInfo)||[]),
        ..._.cloneDeep((state.inputDataReducer.RoutingValue)||[]),
        ..._.cloneDeep((state.inputDataReducer.accountValue)||[]),
        ..._.cloneDeep((state.inputDataReducer.DefaultColor)||[]),
        ..._.cloneDeep((state.inputDataReducer.PantosColor)||[]),
        ..._.cloneDeep((state.inputDataReducer.BorderColor)||[]),
        ..._.cloneDeep((state.inputDataReducer.signLine)||[]),
        ..._.cloneDeep((state.inputDataReducer.backgroundImage)||[]),
        ..._.cloneDeep((state.inputDataReducer.mainLogo)||[]),
        ..._.cloneDeep((state.inputDataReducer.phantomLogo)||[]),
        ..._.cloneDeep((state.inputDataReducer.genData)||[]),
        ..._.cloneDeep((state.inputDataReducer.JSONINFO)||[]),
        ..._.cloneDeep((state.inputDataReducer.page)||[]),
        ..._.cloneDeep((state.inputDataReducer.Design_Instructions)||[]),
        ..._.cloneDeep((state.inputDataReducer.imageType)||[]),
      ];

      const newObj = {
        companyInfo: _.cloneDeep((state.inputDataReducer.companyInfo)||[]),
        companySignOverInfo: _.cloneDeep(newSignOverInfo),
        stubData: _.cloneDeep((state.inputDataReducer.stubData)||[]),
        compnaySignInfo: _.cloneDeep((state.inputDataReducer.compnaySignInfo)||[]),
        bankInfo: _.cloneDeep((state.inputDataReducer.bankInfo)||[]),
        fractionalInfo: _.cloneDeep((state.inputDataReducer.fractionalInfo)||[]),
        checkInfo: _.cloneDeep((state.inputDataReducer.checkInfo)||[]),
        RoutingValue: _.cloneDeep((state.inputDataReducer.RoutingValue)||[]),
        accountValue: _.cloneDeep((state.inputDataReducer.accountValue)||[]),
        DefaultColor: _.cloneDeep((state.inputDataReducer.DefaultColor)||[]),
        PantosColor: _.cloneDeep((state.inputDataReducer.PantosColor)||[]),
        BorderColor: _.cloneDeep((state.inputDataReducer.BorderColor)||[]),
        signLine: _.cloneDeep((state.inputDataReducer.signLine)||[]),
        backgroundImage: _.cloneDeep((state.inputDataReducer.backgroundImage)||[]),
        mainLogo: _.cloneDeep((state.inputDataReducer.mainLogo)||[]),
        phantomLogo: _.cloneDeep((state.inputDataReducer.phantomLogo)||[]),
        genData: _.cloneDeep((state.inputDataReducer.genData)||[]),
        JSONINFO: _.cloneDeep((state.inputDataReducer.JSONINFO)||[]),
        page: _.cloneDeep((state.inputDataReducer.page)||[]),
        Design_Instructions: _.cloneDeep((state.inputDataReducer.Design_Instructions)||[]),
        imageType: _.cloneDeep((state.inputDataReducer.imageType)||[]),
        LogoType: _.cloneDeep((state.inputDataReducer.LogoType)||[]),
        logoColorsArr: _.cloneDeep((state.inputDataReducer.logoColorsArr)||[]),
        additionalColor: _.cloneDeep((state.inputDataReducer.additionalColor)||[]),
      };
      if (isNew){ dispatch(inPutSettingOption.addHistoryState(newObj, "NEW",state.inputDataReducer.bulletFlag))};

    //  const newQueueArr = _.cloneDeep((state.inputDataReducer.queueArr)||[]);
    const newQueueArr=[]
      const queueObj = {
        isFocusOut: isFocusOut,
        isImage: isImage,
        newArrState: newArrState,
        focusOutElement: focusOutElement,
      };
      if (isFocusOut === true && isImage === true) {
        if(isNew){
          dispatch(inPutSettingOption.isChangedHappened(true))
        }
        newQueueArr.push(queueObj);
        // console.log("newQueueArr",newQueueArr)
        dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
      } else if (isFocusOut === true && isImage === false) {
        newQueueArr.push(queueObj);

        dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
      } else if (isFocusOut === false && isImage === false) {
        // console.log("onBlurHandaler")
        newQueueArr.push(queueObj);

        dispatch(inPutSettingOption.updateQueueArray(newQueueArr));
      }
      
    //}
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onBlurHandaler
 * @desc   :  This function called on focus out event of each field collect all the states and send to the fetchPreviewData function to call the preview image api
 */

export const downloadPDF = (downloadType) => {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.inputDataReducer.DefaultColor)
      state.inputDataReducer.DefaultColor = [];

    if (!state.inputDataReducer.PantosColor)
      state.inputDataReducer.PantosColor = [];

    if (!state.inputDataReducer.BorderColor)
      state.inputDataReducer.BorderColor = [];

    if (!state.inputDataReducer.backgroundImage)
      state.inputDataReducer.backgroundImage = [];

    if (!state.inputDataReducer.mainLogo) state.inputDataReducer.mainLogo = [];

    if (!state.inputDataReducer.phantomLogo)
      state.inputDataReducer.phantomLogo = [];

    if (!state.inputDataReducer.signLine)
      state.inputDataReducer.signLine = [{ NAME: "SigLine", value: "1" }];

    if (!state.inputDataReducer.page) state.inputDataReducer.page = [];

    let newSignOverInfo = [];
    if (state.inputDataReducer.signOverLine != "none") {
      newSignOverInfo = [...state.inputDataReducer.companySignOverInfo];
    }

    if (!state.inputDataReducer.imageType)
      state.inputDataReducer.imageType = [];

    const newArrState = [
      ...(state.inputDataReducer.companyInfo||[]),
      ...newSignOverInfo,
      ...(state.inputDataReducer.stubData||[]),
      ...(state.inputDataReducer.compnaySignInfo||[]),
      ...(state.inputDataReducer.bankInfo||[]),
      ...(state.inputDataReducer.fractionalInfo||[]),
      ...(state.inputDataReducer.checkInfo||[]),
      ...(state.inputDataReducer.RoutingValue||[]),
      ...(state.inputDataReducer.accountValue||[]),
      ...(state.inputDataReducer.DefaultColor||[]),
      ...(state.inputDataReducer.PantosColor||[]),
      ...(state.inputDataReducer.BorderColor||[]),
      ...(state.inputDataReducer.signLine||[]),
      ...(state.inputDataReducer.backgroundImage||[]),
      ...(state.inputDataReducer.mainLogo||[]),
      ...(state.inputDataReducer.phantomLogo||[]),
      ...(state.inputDataReducer.genData||[]),
      ...(state.inputDataReducer.JSONINFO||[]),
      ...(state.inputDataReducer.imageType||[]),
    ];
    dispatch(fetchPreviewData(newArrState, "focusout", downloadType));
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onInputChanged
 * @desc   :  This function called on  input changed value to update the redux state values
 */

export const onInputChanged = (event, name, section, value = false,spanindex=-1,sessionData) => {
  if (
    !getSessionStore("isChangedOnce") &&
    getSessionStore("isChangedOnce") != 2
  ) {
    setSessinoStorage("isChangedOnce", 1);
  }
  switch (section) {
    case "companyInfo":
      return {
        type: actionType.SET_INPUT_DATA,
        event: event,
        name: name,
      };

    case "companySignInfo":
      return {
        type: actionType.SET_SIGN_INPUT_DATA,
        event: event,
        name: name,
      };
    case "companysigOverInfo":
      return {
        type: actionType.SET_SIGN_OVER_INPUT_DATA,
        event: event,
        name: name,
      };
    case "companyStubInfo":
      return {
        type: actionType.SET_STUB_INPUT_DATA,
        event: event,
        name: name,
        value: value,
        spanindex:spanindex
      };

    case "bankInfo":
      return {
        type: actionType.SET_BANK_INFO_INPUT_DATA,
        event: event,
        name: name,
      };
    case "fractionalInfo":
      return {
        type: actionType.SET_FRACTIONAL_INFO_INPUT_DATA,
        event: event,
        name: name,
      };
    case "checkInfo":
      return {
        type: actionType.SET_CHECK_INFO_INPUT_DATA,
        event: event,
        name: name,
        sessionData:sessionData
      };
    case "companyGenInfo":
      return {
        type: actionType.SET_GEN_INPUT_DATA,
        event: event,
        name: name,
      };
    default:
      break;
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setCompanyInfoState
 * @desc   :  This function set an action and company information for the redux state
 */

export const setCompanyInfoState = (response) => {
  return {
    type: actionType.SET_COMPANY_INFORMATION,
    companyInfo: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setCompanyInfoState
 * @desc   :  This function set an action and company information for the redux state
 */

export const setCompanySignState = (response) => {
  return {
    type: actionType.SET_COMPANY_SIGN_INFORMATION,
    companySignInfo: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchJsonSuccess
 * @desc   :  This function set the json data to redux store
 */

export const fetchJsonSuccess = (response, flag = "") => {
  return {
    type: actionType.FETCH_JSON_SUCCESS,
    jsonData: response,
    flag: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getStateData
 * @desc   :  return all the data from redux store
 */

export const getStateData = () => {
  return (dispatch, getState) => {
    return getState();
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  imageLogoDataSuccess
 * @desc   :  set the main logo data to redux store
 */

const imageLogoDataSuccess = (response) => {
  return {
    type: actionType.SET_LOGO_IMAGES,
    imagrData: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fontMapDataSuccess
 * @desc   :  This function set the fontMap data to redux store
 */

const fontMapDataSuccess = (response) => {
  return {
    type: actionType.SET_FONT_MAP_DATA,
    fontMapData: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchLogoImagesData
 * @desc   :  This function is for to get the logo images
 */

export const fetchLogoImagesData = (tempArr) => {
  return (dispatch, getState) => {
    const state = getState();
    const pathValue = getVlaueFormSessionByKey(tempArr, "Logo_Path", "cdata");
    const splitArr = pathValue.split(/[\\\/]/);
    Axios.get(getNodeURL() + "get-logo-images/" + splitArr[splitArr.length - 2])
      .then((response) => {
        if (response.data) dispatch(imageLogoDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          fetchSessionDataFail(
            getErrorMessage(error, errorMessage.SESSION_DATA_ERROR)
          )
        );
      });
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  fetchFontMap
 * @desc   :  This function is for get the font map from fontmapFile
 */

export const fetchFontMap = () => {
  return (dispatch, getState) => {
    Axios.get("jsons/FontMap.json")
      .then((response) => {
        if (response.data) dispatch(fontMapDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(
          fetchSessionDataFail(
            getErrorMessage(error, errorMessage.SESSION_DATA_ERROR)
          )
        );
      });
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  saveDocument
 * @desc   :  called on click of add to card button, to create an new session file and redirect to exit url
 */

export const saveDocument = () => {
  return async (dispatch, getState) => {
    const instanceData = getLolcalStore("instance");
    let InstanceId = "";
    let InstanceName = "";
    if (instanceData !== -1) {
      InstanceId = instanceData.entities[0].id;
      InstanceName = instanceData.entities[0].name;
    }
    const state = getState();
    // const documentType = getVlaueFormSessionByKey(
    //   state.fetchDataReducer.sessionData,
    //   "Type",
    //   "cdata"
    // );
    // if(documentType && documentType.toLowerCase() === "overwrite"){
    //   const deleteIns = await deleteInstance(state.fetchDataReducer.previousInstance[0].id);//await updateInstance(InstanceId,state.fetchDataReducer.sessionFileID) 
    //   if(deleteIns.data && deleteIns.data.success){
    //     await updateInstance(InstanceId,state.fetchDataReducer.sessionFileID)
        
    //   }
    // }
    const ScreensColorDefault = _.filter(state.fetchDataReducer.jsonData, (config) => {
      return (
        (config.name && config.name.includes("Pantos")) ||
        config.name.includes("Screens")
      );
    });
    let isPantosColorExists=true
    if(ScreensColorDefault && ScreensColorDefault[0] && ScreensColorDefault[0].content===""){
      isPantosColorExists=false
    }
    if(state.inputDataReducer.Design_Instructions.length>0 && state.inputDataReducer.Design_Instructions[0].isInputChanged===true){
      state.inputDataReducer.present.Design_Instructions=state.inputDataReducer.Design_Instructions
    }
//console.log("documentObj-present", state.inputDataReducer.present)
    const documentObj = {
      sessionData: state.fetchDataReducer.sessionData,
      // presentData: {...state.inputDataReducer.present,logoColorsArr:state.inputDataReducer.tempLogoColorsArray
      // },
      presentData: state.inputDataReducer.present,
      instanceID: InstanceId,
      instanceName: InstanceName,
      Id: state.fetchDataReducer.sessionFileID,
      logoColors: state.inputDataReducer.logoColorsArr,
      //logoColors: state.inputDataReducer.tempLogoColorsArray,
      isPantosColorExists
    };
    
    if (state.inputDataReducer.isCustomerLogo) {
      documentObj.mainLogo = state.inputDataReducer.mainLogo;
      documentObj.isCustomerLogo = state.inputDataReducer.isCustomerLogo;
    }
    documentObj.pantonCustermerUploadObj =
      state.inputDataReducer.pantonCustermerUploadObj;
    documentObj.phantomLogo = state.inputDataReducer.phantomLogo;

    const hotname = window.location.hostname;
    let domainInfo = DOMAIN_INFORMATION["default"];
    if (DOMAIN_INFORMATION[hotname] != "undefined") {
      domainInfo = DOMAIN_INFORMATION[hotname];
    }
    
    // default/pantos color hack
    const colorCode = getVlaueFormSessionByKey(
      state.fetchDataReducer.sessionData,
      "Colors",
      "cdata"
    );
    const colorCodeInt = parseInt(colorCode);
    if (colorCodeInt === 2 && documentObj.presentData) {
    if (documentObj.presentData.DefaultColor.length > 0 && documentObj.presentData.PantosColor.length > 0){
    const defaultColor = documentObj.presentData.DefaultColor[0].value
    const pantosColor = documentObj.presentData.PantosColor[0].value
    documentObj.presentData.DefaultColor[0].value = pantosColor
    documentObj.presentData.PantosColor[0].value = defaultColor
    };
    
    };
    if(state.inputDataReducer.bgPreviewName){
      documentObj.presentData.backgroundImage[0].bgPreviewName=state.inputDataReducer.bgPreviewName
    }
console.log("documentObj-present--save-document-data",documentObj)
    Axios.post(getNodeURL() + "save-document-data", documentObj)
      .then((response) => {
        if (response.data && response.data.success) {
          let redirectUrl = "";
          if (state.fetchDataReducer.sessionData) {
            const orderType = getVlaueFormSessionByKey(
              state.fetchDataReducer.sessionData,
              "Type",
              "cdata"
            );
            let orderIdStr = "";
            if (
              getVlaueFormSessionByKey(
                state.fetchDataReducer.sessionData,
                "Order_ID",
                "cdata"
              ) !== -1
            ) {
              orderIdStr = getVlaueFormSessionByKey(
                state.fetchDataReducer.sessionData,
                "Order_ID",
                "cdata"
              );
            }

            if (orderType.toLowerCase() === "new order") {
              redirectUrl = domainInfo["CHECKOUT_NEW_ORDER"];
            } else if (orderType.toLowerCase() === "overwrite") {
              redirectUrl = domainInfo["CHECKOUT_OVERWRITE_ORDER"];
            } else if (orderType.toLowerCase() === "copy") {
              redirectUrl = domainInfo["CHECKOUT_COPY_ORDER"];
            } else if (orderType.toLowerCase() === "exact") {
              redirectUrl = domainInfo["CHECKOUT_EXACT_ORDER"];
            }
            window.location.href =
              redirectUrl +
              "?sid=" +
              response.data.filename +
              "&oid=" +
              orderIdStr;
           }
        }
      })
      .catch((error) => {
        dispatch(
          fetchSessionDataFail(
            getErrorMessage(error, errorMessage.SESSION_DATA_ERROR)
          )
        );
      });
   };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setImageWidth
 * @param  : data, scale
 * @desc   :  This function is calling the user case SET_IMAGE_WIDTH from fetchData reduser
 */

export const setImageWidth = (data, scale) => {
  return {
    type: actionType.SET_IMAGE_WIDTH,
    data: data,
    scale: scale,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setLatIncresedWidth
 * @param  : data
 * @desc   :  This function is calling the user case SET_LAST_IMAGE_WIDTH  from fetchData reduser to set the latest width of preview image
 */

export const setLatIncresedWidth = (data) => {
  return {
    type: actionType.SET_LAST_IMAGE_WIDTH,
    data: data,
  };
};

export const uploadFile = async (formData) => {
  return await Axios.post(getNodeURL() + "upload", formData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

export const setToggelePreviewData = (data) => {
  return {
    type: actionType.SET_DATA_TOGGLE_PREVIEW,
    data: data,
  };
};
export const setMobilePreviewData = (data) => {
  return {
    type: actionType.SET_MOBILE_TOGGLE,
    data: data,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  createInstance
 * @param  : sessionFiledata, sessionFileID
 * @desc   :  This function is to create a new instance with tempale Id and sessionfile name
 */

export const createInstance = async (tempArr, sessionFileId) => {
  const getToken = getLolcalStore("authObj");

  const finalUrl = API_URL + "entity/instances?token=" + getToken.token;
  const templateId = getVlaueFormSessionByKey(tempArr, "SKU", "cdata");
  const postObj = {
    template: templateId,
    name: sessionFileId,
  };
  return Axios.post(finalUrl, postObj)
    .then((responce) => responce)
    .catch((error) => error);
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  updateInstance
 * @param  : instancesId, sessionFileID
 * @desc   :  This function is to update instance
 */

export const updateInstance = async (instancesId, sessionFileId) => {
  const getToken = getLolcalStore("authObj");

  const finalUrl =
    API_URL +
    "entity/instances/" +
    instancesId +
    "?token=" +
    getToken.token +
    "&name=" +
    sessionFileId +
    "&_method=put";
  const postObj = {
    name: sessionFileId,
  };
  return Axios.post(finalUrl, postObj)
    .then((responce) => responce)
    .catch((error) => error);
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  startSession
 * @param  : -
 * @desc   :  This function called when the app initilized to get the access token.
 */

export const startSession = async () => {
  const finalUrl = getNodeURL() + "session-start";
  return Axios.post(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getAllInstances
 * @param  : -
 * @desc   :  This function get the all instances
 */

export const getAllInstances = async () => {
  const getToken = getLolcalStore("authObj");
  const finalUrl = API_URL + "entity/instances?token=" + getToken.token;
  return Axios.get(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  saveInstanceList
 * @param  : - dataList
 * @desc   :  This function set and called the SET_INSTANCE_LIST user case in reduser
 */

export const saveInstanceList = (dataList) => {
  return {
    type: actionType.SET_INSTANCE_LIST,
    data: dataList,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  copyInstance
 * @param  : - instanceId,Previous_Order_ID
 * @desc   :  This function create the copy of instance from existance instance.
 */

export const copyInstance = async (instanceId, Previous_Order_ID) => {
  const getToken = getLolcalStore("authObj");
  const finalUrl =
    API_URL +
    "entity/instances/" +
    instanceId +
    "/copy?token=" +
    getToken.token;
  return Axios.get(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  convertInstanceToTemplate
 * @param  : - instanceId, sessionFileId
 * @desc   :  This function create the copy of instance from existance instance.
 */

export const convertInstanceToTemplate = async (instanceId, sessionFileId) => {
  const getToken = getLolcalStore("authObj");
  const finalUrl =
    API_URL +
    "entity/instances/" +
    instanceId +
    "/convert?token=" +
    getToken.token +
    "&name=" +
    sessionFileId;
  return Axios.get(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getInstance
 * @param  : - instanceId
 * @desc   :  This function get the instance information by instance ID.
 */

export const getInstance = async (instanceId) => {
  const getToken = getLolcalStore("authObj");
  const finalUrl =
    API_URL + "entity/instances/" + instanceId + "?token=" + getToken.token;
  return Axios.get(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  deleteInstance
 * @param  : - instanceId
 * @desc   :  This function get the instance information by instance ID.
 */

export const deleteInstance = async (instanceId) => {
  const getToken = getLolcalStore("authObj");
  const finalUrl =
    API_URL + "entity/instances/" + instanceId + "?token=" + getToken.token;
  return Axios.delete(finalUrl)
    .then((responce) => {
      return responce;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getPantoneColors
 * @param  : -
 * @desc   :  This function get pantone color List.
 */

export const getPantoneColors = async () => {
  const finalUrl = PANTONE_COLOR_FILE_PATH;

  function load_binary_resource(url) {
    var req = new XMLHttpRequest();
    req.open("GET", url, false);

    req.overrideMimeType("text/plain; charset=x-user-defined");
    req.send(null);
    if (req.status != 200) return "";
    return req.responseText;
  }

  var filestream = load_binary_resource(finalUrl);
  const jsonData = convertXML2Json(filestream);
  if (
    jsonData &&
    jsonData.elements &&
    jsonData.elements[0] &&
    jsonData.elements[0].elements
  ) {
    return jsonData.elements[0].elements;
  } else {
    return [];
  }
};
