/**
 * @author    Clarion
 * @version 1.0
 * @file   :  BackgroundPopup
 * @desc   :  This file contained the background pattren  list
 *
 *  */

import React, { useRef } from "react";
import { BACKGROUND_IMAGES_PATH } from "./../../../utility/AppsConst";
import "./BackgroundPopup.css";
export const BackgroundPopup = (props) => {
  //show hide logic according to flag set
  let openPopup = {
    display: "none",
  };
  if (props.togglePopup) {
    openPopup = {
      display: "Block",
    };
  }

  return (
    <div id="myModal" className="modal" style={openPopup}>
      <div className="modal-content">
        <div className="modal-header">
          <h3> CHOOSE A BACKGROUND PATTERN </h3>
          <span className="font-size-12">
            The following patterns have been prepared for use period
          </span>
          <span className="close" onClick={props.closePopup}>
            &times;
          </span>
        </div>
        <div className="modal-body">
          <div className="logo-position logo-with-title">
            <div className="place-the-logo" id="place-the-bg">
              {props.bgArray.length > 0 &&
                props.bgArray.map((data, index) => {
                  return (
                    <div className="choose-the-logo" key={index}>
                      <img
                        style={{
                          border:
                            props.setDefaultImage &&
                            props.setDefaultImage.PREVIEW === data.PREVIEW
                              ? "1px solid #175DAC"
                              : "1px solid #C3c3c3",
                        }}
                        src={BACKGROUND_IMAGES_PATH + data.PREVIEW}
                        alt={data.NAME}
                        title={data.NAME}
                        onClick={() => props.imageSelected(data)}
                      />
                      <div className="logo-image-name">{data.NAME}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="text-right pull-right">
            <button
              className="button-square button-blue"
              onClick={props.setTheBG}
            >
              PLACE BACKGROUND
            </button>
          </div>
          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
};
