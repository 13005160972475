/**
 * @author    Clarion
 * @version 1.0
 * @file   :  ColorSelectorPopup
 * @desc   :  This file Color selecor
 *
 *  */

import React from "react";
import "./ColorSelectorPopup.css";
export const ColorSelectorPopup = (props) => {
  //show hide logic according to flag set
  let openPopup = {
    display: "none",
  };
  if (props.togglePopup) {
    openPopup = {
      display: "Block",
    };
  }

  return (
    <div id="myModal" className="modal" style={openPopup}>
      <div className="modal-content">
        <div className="modal-header">
          <h3> SELECT THE COLOR </h3>
          <span className="close" onClick={() => props.toggleColorPopup(false)}>
            &times;
          </span>
        </div>
        <div className="modal-body">
          <div className="logo-position logo-with-title">
            <div className="inp-wrapper-colorPopup">
              <input
                type="text"
                className="form-control"
                value={props.pantoneColors ? props.pantoneColors : ""}
                placeholder="Pantone Colors"
                onChange={props.filterColorArrayFn}
              />

              <div
                className="preview-panel-top__download"
                id="filter-selector-dropdown"
              >
                <ul
                  style={{
                    display: props.isOpenDropdown ? "block" : "none",
                  }}
                >
                  {props.filterColorArray &&
                    props.filterColorArray.map((element, index) => (
                      <li key={index}>
                        <label
                          htmlFor={"checkbox_" + index}
                          onClick={() =>
                            props.setPantoneColor(element.attributes)
                          }
                        >
                          {element.attributes.name}
                        </label>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="text-right pull-right">
            <button
              className="button-square button-blue"
              onClick={props.applyPantoneColor}
            >
              PLACE COLOR
            </button>
          </div>
          <div className="clear-float"></div>
        </div>
      </div>
    </div>
  );
};
