/**
 * @author    Clarion
 * @version 1.0
 * @file   :  RoutingAndAccountInput
 * @desc   :  This file contained RoutingAndAccountInput acions and component
 *
 *  */

import React, { useRef } from "react";

const RoutingAndAccountInput =React.forwardRef(( props,ref) => {
  const refContainer = useRef(null);
  let secondTextInput = [];

  /**
   * @author    Clarion
   * @version 1.0
   * @name: _handleKeyDown
   * @desc   : This function called on every keypress to check valid key pressed or not
   * @return - boolean
   */

  const _handleKeyDown = e => {
    if (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      (e.keyCode >= 65 && e.keyCode <= 68) ||
      e.keyCode === 8
    ) {
      return true;
    } else {
      e.preventDefault();
      e.stopPropagation();
    }
    return false;
  };

  return (
    <div>
      {props.data && props.data.type === "image" ? (
        <div>
          <span className="square">
            <img src={props.data.imageUrl} />
          </span>
          <span>{props.data.lable}</span>
        </div>
      ) : (
        <div>
          <input
            type="text"
            className="form-control"
            onKeyUp={(event) => {
              props.onInputBoxChanged(event, props.index);

              props.secondTextInputFocus(
                props.index,
                props.araryLength,
                props.minus,
                props.ID
              );
            }}
            placeholder=""
            ref={ref}
            defaultValue={props.routingNumber && props.routingNumber.trim()}
            id={props.ID + props.index}
            onFocus={props.onFocusHandaler}
            maxLength="1"
            onKeyDown={event => _handleKeyDown(event)}
            disabled={props.isFieldDisabled}
          />
          <span>{props.data && props.data.lable ? props.data.lable : ""}</span>
        </div>
      )}
    </div>
  );
});

export default RoutingAndAccountInput;
