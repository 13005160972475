/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Input
 * @desc   :  This file contained input acions and component
 *
 *  */
import React, { useRef } from "react";

import { getSettingProperty } from "../../../utility/Utility";
import classes from "./Input.module.css";


var tbIndex = 0;
const Input = (props) => {
  const refContainer = useRef(null);
  tbIndex = tbIndex++;
  const maxLengthForInput = () => {
    return getSettingProperty(props.settings, "EDITABLE").MAXCHARS;
  };

  const transformText = () => {
    let switchCase =
      getSettingProperty(props.settings, "TEXTCASE") &&
      getSettingProperty(props.settings, "TEXTCASE").RESTRICT;
    switch (switchCase) {
      case "upper":
        return props.value && props.value.toUpperCase();
      case "lower":
        return props.value && props.value.toLowerCase();
      default:
        return props.value;
    }
  };
  return (
    <React.Fragment>
      <div className={classes.Input}>
        <input
          className="form-control"
          name={props.name}
          id={props.name}
          // maxLength={maxLengthForInput() === "0" ? "" : maxLengthForInput()}
          placeholder={props.placeholder}
          type="text"
          ref={refContainer}
          value={transformText()}
          onBlur={(event) => {
          event['refContainer']=refContainer
          props.onBlurHandaler(event,true, true, true, props.name)}}
          onFocus={(event)=>{refContainer.current.setSelectionRange(props.value && props.value.length, props.value && props.value.length);props.onFocusHandaler(event)}}
          onChange={(event)=>props.onInputChanged(event,props.name,props.section,props.spanIndex)}
          disabled={props.isFieldDisabled}
          autoComplete={"off"}
          tabIndex={props.tabindex}
        />
        <label className="">{props.label}</label>
      </div>
    </React.Fragment>
  );
};

export default Input;
