/**
 * @author    Clarion
 * @version 1.0
 * @file   :  Utility
 * @desc   :  All the common function are written in this file
 */

import convert from "xml-js";
import * as _ from "lodash";
import { NODE_API_URL, DEFAULT_SCREEN_SHADE } from "./AppsConst";

const htmlToText = require('html-to-text');

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getNodeURL
 * @desc   :  Node applicaion URL
 * @returns: string
 */

export const getNodeURL = () => {
  return NODE_API_URL;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  convertData
 * @desc   :  function for creating data to pass the image preview api after getting session data, the function is taking session data
 * @returns: object of setElementObj for session data
 */

export const convertData = (
  data,
  sessionData,
  fxsConfig,
  inputDataReducer,
  callType
) => {
  const sessionType = getVlaueFormSessionByKey(sessionData, "Type", "cdata");
  const colorCode = getVlaueFormSessionByKey(sessionData, "Colors", "cdata");

  const setElementObj = {};
  let checkNewLogo = true;
  data.forEach((element) => {
    let spanElm = `<span`;
    const getSesionIndex = sessionData.findIndex(
      (p) => p.name === "User_Level"
    );
    const userType = sessionData[getSesionIndex].cdata;
    if (element.name === "Company_Name") {
      if (callType === "jpg") {
        if (
          userType === "Admin" &&
          fxsConfig &&
          fxsConfig.Title1 &&
          typeof fxsConfig.Title1.USER !== "undefined"
        ) {
          const newUserObj = { ...fxsConfig.Title1.USER };
          const newBoxDiff = { ...fxsConfig.Title1 };
          delete newBoxDiff.USER;
          const newObject = { ...newBoxDiff, ...newUserObj };
          spanElm += getDefaultSettingData(newObject, true);
        } else {
          if (fxsConfig) {
            spanElm += getDefaultSettingData(fxsConfig.Title1, true);
          }
        }
      }

      spanElm += `>`;
      if (element.cdata) {
        setElementObj["setElement.FXS_Title1"] = encodeURIComponent(
          `<content><p>` + spanElm + element.cdata + `</span></p></content>`
        );
      } else if (inputDataReducer.jsonData) {
        const FXS_Title1 = htmlToText.fromString(getVlaueFormJsonByKey(
          inputDataReducer.jsonData,
          "FXS_Title1",
          "content"
        ));
        setElementObj["setElement.FXS_Title1"] = encodeURIComponent(
          `<content><p>` + spanElm + FXS_Title1 + `</span></p></content>`
        );
      }
    } else if (
      element.name.includes("City") ||
      element.name.includes("State") ||
      element.name.includes("Zip_Code") ||
      element.name.includes("CSZ")
    ) {
      if (callType === "jpg") {
        if (
          userType === "Admin" &&
          fxsConfig.Title1 &&
          typeof fxsConfig.Title.USER !== "undefined"
        ) {
          const newUserObj = { ...fxsConfig.Title.USER };
          const newBoxDiff = { ...fxsConfig.Title };
          delete newBoxDiff.USER;
          const newObject = { ...newBoxDiff, ...newUserObj };
          spanElm += getDefaultSettingData(newObject, true);
        } else {
          spanElm += getDefaultSettingData(fxsConfig.Title, true);
        }
      }

      spanElm += `>`;
      if (element.cdata)
        setElementObj["setElement.FXS_Title3"] = encodeURIComponent(
          `<content><p>` + spanElm + element.cdata + `</span></p></content>`
        );
    } else if (element.name === "Address_1" || element.name === "Phone") {
      if (callType === "jpg") {
        if (
          userType === "Admin" &&
          fxsConfig.Title1 &&
          typeof fxsConfig.Title.USER !== "undefined"
        ) {
          const newUserObj = { ...fxsConfig.Title.USER };
          const newBoxDiff = { ...fxsConfig.Title };
          delete newBoxDiff.USER;
          const newObject = { ...newBoxDiff, ...newUserObj };

          spanElm += getDefaultSettingData(newObject, true);
        } else if(fxsConfig.Title){
          spanElm += getDefaultSettingData(fxsConfig.Title, true);
        }
      }

      spanElm += `>`;
      if (element.name === "Address_1") {
        if (element.cdata)
          setElementObj["setElement.FXS_Title2"] = encodeURIComponent(
            `<content><p>` + spanElm + element.cdata + `</span></p></content>`
          );
      } else if (element.name === "Phone") {
        if (element.cdata)
          setElementObj["setElement.FXS_Title4"] = encodeURIComponent(
            `<content><p>` + spanElm + element.cdata + `</span></p></content>`
          );
      }
    } else if (element.name === "Start_Number") {
      spanElm += `>`;
      if (element.cdata)
        setElementObj["setElement.FXS_CheckNumber1"] = encodeURIComponent(
          encodeURIComponent(
            `<content><p>` + spanElm + element.cdata + `</span></p></content>`
          )
        );
      if (element.cdata) {
        let FXS_CheckNumber1 = element.cdata;
        let preEndStr = "";
        if (FXS_CheckNumber1 && FXS_CheckNumber1.length < 6) {
          let FXS_CheckNumber1Len = 6 - FXS_CheckNumber1.length;
          for (let index = 0; index < FXS_CheckNumber1Len; index++) {
            preEndStr = preEndStr + "0";
          }

          FXS_CheckNumber1 = preEndStr + FXS_CheckNumber1;
        }
        FXS_CheckNumber1 = "C" + FXS_CheckNumber1;
        setElementObj["setElement.FXS_CheckNumber2"] = encodeURIComponent(
          `<content><p>` + spanElm + FXS_CheckNumber1 + `</span></p></content>`
        );
      }
    }

    if (element.name === "SKU") {
      setElementObj["template"] = element.cdata;
    }
  });

  if (inputDataReducer.jsonData && inputDataReducer.jsonData.length > 0) {
    const findColorIndex = inputDataReducer.jsonData.findIndex(
      (element) => element.name === "SigLine"
    );

    if (
      (findColorIndex !== -1 && sessionType.toLowerCase() === "new order") ||
      sessionType === "Overwrite"
    ) {
      setElementObj["SigLine"] = 1;
    } else if (findColorIndex !== -1) {
      setElementObj["SigLine"] =
        inputDataReducer.jsonData[findColorIndex].content;
    }
  }
  const SKUvalues = getVlaueFormSessionByKey(sessionData, "SKU", "cdata");

  if (inputDataReducer.jsonData && inputDataReducer.fxConfig.COLORSETS) {
    const colorData = getColorDefaultSetting(
      inputDataReducer.jsonData,
      inputDataReducer.fxConfig,
      sessionData,
      sessionType
    );

    const pantosColorC = getVlaueFormJsonByKey(
      inputDataReducer.jsonData,
      "FXS_Background",
      "content"
    );

    if (
      inputDataReducer.BorderColor &&
      inputDataReducer.BorderColor != "undefined" &&
      inputDataReducer.BorderColor.length > 0
    ) {
      setElementObj["BorderColor"] = inputDataReducer.BorderColor[0].value;
    } else if (
      colorData &&
      colorData.lineBtn &&
      colorData.lineBtn.name &&
      parseInt(colorCode) !=0 &&
      sessionType.toLowerCase() === "new order"
      // !SKUvalues.startsWith("FS") &&
      // !SKUvalues.startsWith("FV")
    ) {
      setElementObj["BorderColor"] = colorData.lineBtn.name;
    }

    if (
      inputDataReducer.DefaultColor &&
      inputDataReducer.DefaultColor !== "undefined" &&
      inputDataReducer.DefaultColor.length > 0
    ) {
      setElementObj["DefaultColor"] = inputDataReducer.DefaultColor[0].value;
    } else if (
      colorData &&
      colorData.textBtn &&
      colorData.textBtn.name &&
      parseInt(colorCode) !=0 &&
      sessionType.toLowerCase() === "new order"
      // !SKUvalues.startsWith("FS") &&
      // !SKUvalues.startsWith("FV")
    ) {
      setElementObj["DefaultColor"] = colorData.textBtn.name;
    }

    if (
      inputDataReducer.PantosColor &&
      inputDataReducer.PantosColor !== "undefined" &&
      inputDataReducer.PantosColor.length > 0
    ) {
      setElementObj["PantosColor"] = inputDataReducer.PantosColor[0].value;
    } else if (
      pantosColorC !== -1 &&
      colorData &&
      colorData.bgBtn &&
      colorData.bgBtn.name && 
      parseInt(colorCode) !=0 &&
      sessionType.toLowerCase() === "new order"
    ) {
      setElementObj["PantosColor"] = colorData.bgBtn.name;
    }

    const defaultBg = getVlaueFormJsonByKey(
      inputDataReducer.jsonData,
      "FXS_Background",
      "content"
    );

    if (sessionType.toLowerCase() === "new order") {
      const bgDefaultObj = getBgDefault(inputDataReducer.fxConfig.BACKGROUNDS);
      if (parseInt(colorCode) === 0 && SKUvalues.startsWith("FS")) {
        if (
          inputDataReducer.fxConfig.COLORSETS &&
          inputDataReducer.fxConfig.COLORSETS.SECURITY &&
          inputDataReducer.fxConfig.COLORSETS.SECURITY.length > 0
        ) {
           const defaultCheckIndex = inputDataReducer.jsonData.findIndex(
            (p) => p.name === 'FXS_Security'
          );
          if (defaultCheckIndex !== -1) {
            setElementObj["FXSSecurity"]=inputDataReducer.jsonData[defaultCheckIndex].content
          }
        }
      } else if (parseInt(colorCode) === 0 && bgDefaultObj !== -1) {
        setElementObj["FXSBackground"] = bgDefaultObj.FILE;
      }else{
        setElementObj["FXSBackground"] = bgDefaultObj.FILE;
      }
    } else if (defaultBg && defaultBg !== -1) {
      setElementObj["FXSBackground"] = defaultBg;
    } else {
      if (parseInt(colorCode) === 0 && SKUvalues.startsWith("FS")) {
        if (
          inputDataReducer.fxConfig.COLORSETS &&
          inputDataReducer.fxConfig.COLORSETS.SECURITY &&
          inputDataReducer.fxConfig.COLORSETS.SECURITY.length > 0
        ) {
          const defaultCheckIndex = inputDataReducer.jsonData.findIndex(
            (p) => p.name === 'FXS_Security'
          );
          if (defaultCheckIndex !== -1) {
            setElementObj["FXSSecurity"]=inputDataReducer.jsonData[defaultCheckIndex].content
          }
        }
      }
    }

    const mainLogo = getVlaueFormJsonByKey(
      inputDataReducer.jsonData,
      "FXS_Logo1",
      "content"
    );

    if (mainLogo !== -1 && mainLogo !== "FXS_Logo1" && mainLogo !== "" && sessionType.toLowerCase() !== "new order") {
      setElementObj["FXSLogo1"] = mainLogo;
      checkNewLogo = false;
    }

    const phatomInfo = _.filter(inputDataReducer.jsonData, (config) => {
      return config.name && config.name.includes("FXS_Phantom");
    });
    for (let index = 0; index < phatomInfo.length; index++) {
      if (phatomInfo[index].name !== phatomInfo[index].content)
        setElementObj[phatomInfo[index].name.replace("_", "")] =
          phatomInfo[index].content;
    }
    const Order_ID = getVlaueFormSessionByKey(sessionData, "Order_ID", "cdata");
    if (Order_ID && Order_ID !== -1) {
      setElementObj["FXS_Job_Number"] = Order_ID;
    }
  }
  if(inputDataReducer.jsonData && inputDataReducer.jsonData.length > 0){
    const FXS_Logo1Index = inputDataReducer.jsonData.findIndex(
      (element) => element.name === "FXS_Logo1"
    );
    if (checkNewLogo && sessionType.toLowerCase() === "new order" && FXS_Logo1Index !==-1) {
      setElementObj["FXSLogo1"] = "None";
      
    }
    const FXS_RoutingIndex = inputDataReducer.jsonData.findIndex(
      (element) => element.name === "FXS_Routing"
    );
     if (sessionType.toLowerCase() === "new order") {
      if(FXS_RoutingIndex !==-1)
        setElementObj["setElement.FXS_Routing"] = encodeURIComponent(
          `<content><p><span></span></p></content>`
        );
      
        const FXS_AccountIndex = inputDataReducer.jsonData.findIndex(
          (element) => element.name === "FXS_AccountIndex"
        );
      if(FXS_AccountIndex!==-1){
        setElementObj["setElement.FXS_Account"] = encodeURIComponent(`<content><p><span></span></p></content>`);

      }
     }
  
  }
  
  
  if (
    inputDataReducer.DefaultColor &&
    inputDataReducer.DefaultColor !== "undefined" &&
    inputDataReducer.DefaultColor.length > 0
  ) {
    setElementObj["DefaultColor"] = inputDataReducer.DefaultColor[0].value;
  }

  if (SKUvalues.startsWith("FV")) {
    setElementObj["FXSBackground"] = "VOID.TIF";
  }
  // if (sessionType.toLowerCase() === "new order") {
  //   setElementObj["setElement.FXS_Routing"] = encodeURIComponent(
  //     `<content><p><span></span></p></content>`
  //   );
  //   setElementObj["setElement.FXS_Account"] = encodeURIComponent(`<content><p><span></span></p></content>`);
  // }
  return setElementObj;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getDefaultSettingData
 * @desc   :  function for creating string of default style element for every input value
 * @returns: string of default box diff data
 */

const getDefaultSettingData = (boxDiff, isInitialLoad = false) => {
  let spanElm = "";
  // if (boxDiff && typeof boxDiff.FONT !== "undefined") {
  //   spanElm += ' fontFamily="' + boxDiff.FONT.DEFAULT + '" ';
  // }

  if (boxDiff && typeof boxDiff.FONTSIZE !== "undefined") {
    spanElm += ' fontSize="' + boxDiff.FONTSIZE.DEFAULT + '" ';
  }

  if (boxDiff && typeof boxDiff.FORMAT !== "undefined") {
    if (
      boxDiff &&
      (boxDiff.FORMAT.DEFAULT == "bold" || boxDiff.FORMAT.DEFAULT == "plain")
    ) {
      spanElm += ' fontWeight="' + boxDiff.FORMAT.DEFAULT + '" ';
    } else if (boxDiff && boxDiff.FORMAT.DEFAULT == "italic") {
      spanElm += ' fontStyle="' + boxDiff.FORMAT.DEFAULT + '" ';
    } else if (boxDiff && boxDiff.FORMAT.DEFAULT == "underline") {
      spanElm += ' textDecoration="' + boxDiff.FORMAT.DEFAULT + '" ';
    }
  }

  if (
    boxDiff &&
    typeof boxDiff.JUSTIFY !== "undefined" &&
    isInitialLoad === false
  ) {
    spanElm += ' textAlign="center"';
  } else {
    spanElm += 'textAlign="center"';
  }
  return spanElm;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  convertDataOnFocusOut
 * @desc   :  function for creating data to pass the image preview api after focus out the input field, the function is taking session data and array of input fields
 * @returns: object of setElementObj
 */

export const convertDataOnFocusOut = (data, sessionData,isbulletFlag) => {
  const aContainsB = (a, b) => {
    return a.indexOf(b) >= 0;
  };

  const setElementObj = {};
  data && data.length>0 && data.forEach((element) => {
    let spanElm = `<span `;
    if (
      element &&
      element.attributes &&
      element.attributes.NAME !== "FXS_Account" &&
      element.attributes.NAME !== "FXS_Routing" &&
      element.attributes.NAME !== "FXS_CheckNumber2"
    ) {
      if (element.styleObj && element.attributes.NAME !== "FXS_Stub1Single") {
        for (const key in element.styleObj) {
          if (element.styleObj.hasOwnProperty(key) && element.styleObj[key]) {
            if(key!=="fontFamily"){
              spanElm += key + ' ="' + element.styleObj[key] + '" ';
            }            
          }
        }
      } else {
        const getSesionIndex = sessionData.findIndex(
          (p) => p.name === "User_Level"
        );
        const userType = sessionData[getSesionIndex].cdata;
        if (
          userType === "Admin" &&
          element.BOXDEF &&
          typeof element.BOXDEF.USER !== "undefined"
        ) {
          const newUserObj = { ...element.BOXDEF.USER };
          const newBoxDiff = { ...element.BOXDEF };
          delete newBoxDiff.USER;
          const newObject = { ...newBoxDiff, ...newUserObj };

          spanElm += getDefaultSettingData(newObject);
        } else {
          spanElm += getDefaultSettingData(element.BOXDEF);
        }
      }
    }

    spanElm += `>`;
    let elemSetName;
    if (
      element &&
      element.attributes &&
      (element.attributes.NAME !== "DefaultColor" ||
        element.attributes.NAME !== "PantosColor" ||
        element.attributes.NAME !== "BorderColor" ||
        element.attributes.NAME !== "SigLine" ||
        element.attributes.NAME !== "JSONINFO" ||
        element.attributes.NAME !== "FXS_Job_Number" ||
        element.attributes.NAME !== "page" ||
        element.attributes.NAME !== "LogoType" ||
        (!element.attributes.NAME.includes("FXS_Phantom") &&
          element.value !== element.attributes.NAME))
    ) {
      elemSetName = "setElement." + element.attributes.NAME;
      if (element.isInputChanged) {
        if (element.value) {
          if (element.attributes.NAME !== "FXS_Stub1Single") {
            setElementObj[elemSetName] = encodeURIComponent(
              `<content><p>` + spanElm + element.value + `</span></p></content>`
            );
          } else {
            const elmVal = element.value;

            // const spliStr = elmVal.split("•");
            let createStr = "<content><p>";

            if (
              element.styleObj &&
              element.attributes.NAME === "FXS_Stub1Single"
            ) {
              // console.log("element.styleObj",element.styleObj)
              for (const key in element.styleObj) {
                if (element.styleObj.hasOwnProperty(key)) {
                  createStr += "<span ";

                  if (key) {
                    const keysArr = Object.keys(element.styleObj[key]);

                    for (let index = 0; index < keysArr.length; index++) {
                      if(keysArr[index] !== "value" && keysArr[index] !== "fontFamily"){
                        createStr +=
                        " " +
                        keysArr[index] +
                        '="' +
                        element.styleObj[key][keysArr[index]] +
                        '"';
                      }
                      
                    }
                  }

                  if (element.styleObj[key] && isbulletFlag) { //isbulletFlag
                    if(key == 0){
                      createStr += ">" + element.styleObj[key].value + "  •  </span>";

                    }else{
                      createStr += ">" +  element.styleObj[key].value +  "</span>";
                    }
                    
                  } else {
                    // console.log("key",key)
                    if(key == 0){
                    createStr += ">" + element.styleObj[key].value +  "  </span>";

                    }else{
                      createStr += ">" +  element.styleObj[key].value +  "</span>";
                    }
                  }
                  
                }
              }
            }
            // console.log(createStr + "_1")
            createStr += "</p></content>";
            // console.log("createStr",createStr)

            setElementObj[elemSetName] = encodeURIComponent(createStr);
            //setElementObj["setElement_" + element.attributes.NAME] = encodeURIComponent(createStr);
            //
          }
        } else {
          if (element.attributes.NAME !== "FXS_Stub1Single") {
            setElementObj[elemSetName] = encodeURIComponent(
              `<content><p>` + spanElm + element.value + `</span></p></content>`
            );
          } else {
            const elmVal = element.value;

            // const spliStr = elmVal && elmVal.split("•");
            let createStr = "<content><p>";

            if (
              element.styleObj &&
              element.attributes.NAME === "FXS_Stub1Single"
            ) {
              // console.log("element.styleObj",element.styleObj)
              for (const key in element.styleObj) {
                if (element.styleObj.hasOwnProperty(key)) {
                  createStr += "<span ";

                  if (key) {
                    const keysArr = Object.keys(element.styleObj[key]);

                    for (let index = 0; index < keysArr.length; index++) {
                      if(keysArr[index] !== "value" && keysArr[index] !== "fontFamily"){
                        createStr +=
                        " " +
                        keysArr[index] +
                        '="' +
                        element.styleObj[key][keysArr[index]] +
                        '"';
                      }
                      
                    }
                  }

                  if (element.styleObj[key]) {
                    createStr += ">" + element.styleObj[key].value + "</span>";
                  } else {
                    createStr += ">" + element.styleObj[key].value + "</span>";
                  }
                }
              }
            }

            createStr += "</p></content>";

            setElementObj[elemSetName] = encodeURIComponent(createStr);
          }
        }
      }
    }

    if (
      element.value === "" &&
      (element.attributes.NAME === "FXS_Fractional1" ||
        element.attributes.NAME === "FXS_Fractional2")
    ) {
      setElementObj[elemSetName] = "";
    }

    if (
      element &&
      element.attributes &&
      (element.attributes.NAME === "DefaultColor" ||
        element.attributes.NAME === "PantosColor" ||
        element.attributes.NAME === "BorderColor" ||
        element.attributes.NAME === "SigLine" ||
        elemSetName === "setElement.DefaultColor" ||
        elemSetName === "setElement.PantosColor" ||
        elemSetName === "setElement.BorderColor" ||
        elemSetName === "setElement.SigLine" ||
        elemSetName === "JSONINFO" ||
        elemSetName === "page" ||
        elemSetName === "LogoType" ||
        elemSetName === "Design_Instructions" ||
        elemSetName === "FXS_Job_Number" ||
        element.attributes.NAME === "FXS_Logo1" ||
        element.attributes.NAME === "JSONINFO" ||
        element.attributes.NAME === "FXS_Job_Number" ||
        element.attributes.NAME === "page" ||
        element.attributes.NAME === "LogoType" ||
        element.attributes.NAME === "Design_Instructions" ||
        element.attributes.NAME.includes("FXS_Phantom") ||
        (elemSetName.includes("FXS_Phantom") &&
          element.value !== element.attributes.NAME))
    ) {
      if (
        element.attributes.NAME.includes("FXS_Phantom") ||
        elemSetName.includes("FXS_Phantom") ||
        element.attributes.NAME === "FXS_Logo1"
      ) {
        if (element.isInputChanged) {
          setElementObj[element.attributes.NAME.replace("_", "")] =
            element.value;
        }
      } else {
        if(element.isInputChanged || elemSetName==="JSONINFO") {
          setElementObj[element.attributes.NAME] = element.value;
        }
      }
      setElementObj[elemSetName] = "";

      if (
        element.value === element.attributes.NAME &&
        element.attributes.NAME.includes("FXS_Phantom")
      ) {
        setElementObj[element.attributes.NAME] = "";
      }
    }

    const SKUvalues = getVlaueFormSessionByKey(sessionData, "SKU", "cdata");
    const colorCode = getVlaueFormSessionByKey(sessionData, "Colors", "cdata");
    if (
      element.attributes &&
      (element.attributes.NAME === "PantosColor" ||
        elemSetName === "setElement.PantosColor") &&
      parseInt(colorCode) === 0 &&
      SKUvalues.startsWith("FS")
    ) {
      delete setElementObj[element.attributes.NAME];
    }

    if (
      element.attributes &&
      (element.attributes.NAME === "FXSBackground" ||
        elemSetName === "setElement.FXSBackground")
    ) {
      if (element.isInputChanged) {
        setElementObj["FXSBackground"] = element.value;
        setElementObj[elemSetName] = "";
      }
    }

    if (
      element.attributes &&
      (element.attributes.NAME === "FXSSecurity" ||
        elemSetName === "setElement.FXSSecurity")
    ) {
      if (element.isInputChanged) {
        setElementObj["FXSSecurity"] = element.value;
        setElementObj[elemSetName] = "";
      }
    }

    if (element === "SKU") {
      setElementObj["template"] = element.cdata;
    }
  });
  const Order_ID = getVlaueFormSessionByKey(sessionData, "Order_ID", "cdata");
  if (Order_ID && Order_ID !== -1) {
    setElementObj["FXS_Job_Number"] = Order_ID;
  }
  return setElementObj;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  convertXML2Json
 * @desc   :  function is used for convert the xml data to json data
 * @returns: object
 */

export const convertXML2Json = (xmlData) => {
  const result = convert.xml2json(xmlData, {
    compact: false,
    spaces: 4,
  });
  return JSON.parse(result);
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getErrorMessage
 * @desc   :  function is  for return the custom erros
 * @returns: string of error message
 */

export const getErrorMessage = (error, customMessage) => {
  return error ? error.message : customMessage;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getErrorMessage
 * @desc   :  function is  for return the custom erros
 * @returns: string of error message
 */

export const getSettingProperty = (setting, key) => {
  if (!_.isEmpty(setting.USER) && !_.isEmpty(setting.USER[key])) {
    return setting.USER[key];
  }
  return setting[key];
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getDataWithSetting
 * @desc   :  function  takes the fxsconfig file data and array for return the box diff according to input field
 * @returns: array of boxDiff
 */

export const getDataWithSetting = (array, fxsConfig) => {
  _.forEach(array, (name, index) => {
    if (_.isEmpty(name.BOXDEF)) {
      array[index].BOXDEF = fxsConfig.BOXDEFS[name.attributes.BOXDEF];
    } else {
      _.forEach(
        Object.keys(fxsConfig.BOXDEFS[name.attributes.BOXDEF]),
        (boxDefKey) => {
          if (Object.keys(name.BOXDEF).includes(boxDefKey)) {
            return;
          } else {
            array[index].BOXDEF[boxDefKey] =
              fxsConfig.BOXDEFS[name.attributes.BOXDEF][boxDefKey];
          }
        }
      );
    }
  });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setValueFromSession
 * @desc   :  function for setting the preloaded data to inputs fields
 * @returns: array of session data
 */

export const setValueFromSession = (
  array,
  sessionData,
  jsonData,
  isImages = false
) => {
  const templateType = getVlaueFormSessionByKey(sessionData, "Type", "cdata");

  _.forEach(array, (element, index) => {
    let csz = "";
    _.forEach(jsonData, (data) => {
      if (data.content) {
        if (
          array[index].attributes.NAME !== "DefaultColor" &&
          array[index].attributes.NAME !== "BorderColor" &&
          array[index].attributes.NAME !== "PantosColor"
        ) {
          // console.log("array[index].value",array[index].value)
          const jsondataPreLoad = getValueFromJsonData(
            element.attributes.NAME,
            jsonData
          );
          
          
          if(array[index].attributes.NAME !== "FXS_Stub1Single"){
            var temporalDivSpanElement1 = document.createElement("div");
            temporalDivSpanElement1.innerHTML = jsondataPreLoad;
            // console.log(array[index].attributes.NAME,temporalDivSpanElement1[0].childNodes)
            
            if (
              temporalDivSpanElement1 && temporalDivSpanElement1.hasChildNodes() && temporalDivSpanElement1.childNodes[0].attributes &&
              temporalDivSpanElement1.childNodes[0].attributes.length > 0
            ){
              let styleObj = {};
              // styleObj[index] = {}
              for (let index1 = 0; index1 < temporalDivSpanElement1.childNodes[0].attributes.length; index1++) {
                if(temporalDivSpanElement1.childNodes[0].attributes[index1].name === "fontid"){
                  styleObj["fontId"] = temporalDivSpanElement1.childNodes[0].attributes[index1].nodeValue;
                }else if(temporalDivSpanElement1.childNodes[0].attributes[index1].name === "fontfamily"){
                  styleObj["fontFamily"] = temporalDivSpanElement1.childNodes[0].attributes[index1].nodeValue;
                }else if(temporalDivSpanElement1.childNodes[0].attributes[index1].name === "fontsize"){
                  styleObj["fontSize"] = temporalDivSpanElement1.childNodes[0].attributes[index1].nodeValue;
                }else if(temporalDivSpanElement1.childNodes[0].attributes[index1].name === "textalign"){
                  styleObj["textAlign"] = temporalDivSpanElement1.childNodes[0].attributes[index1].nodeValue;
                }
              }              
              array[index].styleObj = styleObj;
            }
            array[index].value = htmlToText.fromString(jsondataPreLoad)
            
          }
          else{
            array[index].value = jsondataPreLoad;
          }
          
        } else if (templateType.toLowerCase() !== "new order") {
          array[index].value = getValueFromJsonData(
            element.attributes.NAME,
            jsonData
          );
        }

        if (
          array[index].value &&
          array[index].attributes.NAME === "FXSLogo1" &&
          templateType.toLowerCase() === "new order"
        ) {
          array[index].value = "None";
        }

        if (
          
          array[index].attributes.NAME === "FXS_Stub1Single" && array[index].value
        ) {
          const newElement = array[index].value.match(/(<span [^>]*>)>/g);

          let attributes = array[index].value.match(/[\w-]+="[^"]*"/g);

          const styleObj = {};
          var temporalDivSpanElement = document.createElement("div");
          temporalDivSpanElement.innerHTML = array[index].value;
          if (
            temporalDivSpanElement.childNodes.length > 0 &&
            temporalDivSpanElement.childNodes[0].tagName !== "TEXT"
          ) {
            for (
              let index = 0;
              index < temporalDivSpanElement.childNodes.length;
              index++
            ) {
              const textStr =
                temporalDivSpanElement.childNodes[index].innerHTML ||
                temporalDivSpanElement.childNodes[index].innerText;
              const newkey = textStr.replace(/[^A-Z0-9]+/gi, "_");
              
              styleObj[index] = {};
              if (
                temporalDivSpanElement.childNodes[index].attributes &&
                temporalDivSpanElement.childNodes[index].attributes.length > 0
              ) {
                for (
                  let j = 0;
                  j <
                  temporalDivSpanElement.childNodes[index].attributes.length;
                  j++
                ) {
                  const keyName =
                    temporalDivSpanElement.childNodes[index].attributes[j].name;
                  const keyValue =
                    temporalDivSpanElement.childNodes[index].attributes[j]
                      .value;
                  
                }
              }
              
              var temporalDivElement = document.createElement("div");
              temporalDivElement.innerHTML = textStr;
  
              styleObj[index].value =
                temporalDivElement.textContent ||
                temporalDivElement.innerText ||
                "";
                /*5918-Single stub line formating */
              for (let index1 = 0; index1 < temporalDivSpanElement.childNodes.length; index1++) {
                const fontid= temporalDivSpanElement.childNodes[index].getAttribute('fontid');
                styleObj[index]["fontId"]=fontid
                const fontfamily= temporalDivSpanElement.childNodes[index].getAttribute('fontfamily');
                styleObj[index]["fontfamily"]=fontfamily
                const fontSize= temporalDivSpanElement.childNodes[index].getAttribute('fontSize');
                styleObj[index]["fontSize"]=fontSize
                const textAlign= temporalDivSpanElement.childNodes[index].getAttribute('textAlign');
                styleObj[index]["textAlign"]=textAlign
              }              
            }
          }

          if (attributes && attributes.length > 0) {
            array[index].styleObj = styleObj;
            var temporalDivElement = document.createElement("div");
            temporalDivElement.innerHTML = array[index].value;

            array[index].value =
              temporalDivElement.textContent ||
              temporalDivElement.innerText ||
              "";
          }
        }

        if (isImages) {
          array[index].phatomLogoName = getValueFromJsonData(
            element.attributes.NAME,
            jsonData
          );
        }
      }
    });

    _.forEach(sessionData, (data) => {
      if (
        (data.name === "City" ||
          data.name === "State" ||
          data.name === "Zip_Code") &&
        data.cdata
      ) {
        csz += data.cdata + " ";
        data["name"] = "CSZ";
        data["cdata"] = csz;
      }
      if (data.name.toUpperCase() === element.attributes.PRELOAD) {
        if (data.cdata) {
          if (templateType.toLowerCase() === "new order") {
            array[index].value = htmlToText.fromString(data.cdata);
          }
        } else {
          if (templateType.toLowerCase() === "new order") {
            array[index].value = htmlToText.fromString(getValueFromJsonData(
              element.attributes.NAME,
              jsonData
            ));
          }
        }
      }
    });
  });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getValueFromJsonData
 * @param   : attribute name, sesstion json data or default json data
 * @desc   :  find the attribute from json array and return the value of that atrribute
 * @returns: string
 */

const getValueFromJsonData = (attrName, jsonData) => {
  let contentVa;
  jsonData.forEach((data, index) => {
    if (data.name === attrName) {
      contentVa = data.content;
    }
  });
  return contentVa;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  checkInputIsInJsonData
 * @param  : Json data, field name
 * @desc   :  function  for checking the fxs config fild is present or not in json data return by api call
 * @returns: return the bolean
 */

export const checkInputIsInJsonData = (jsonData, fieldName) => {
  if (jsonData) {
    const findIndex = jsonData.findIndex((element) => {
      return element.name === fieldName;
    });
    if (findIndex !== -1) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setBoxDiff
 * @param  : style object, box value
 * @desc   :  function  for return object with boxdef css style
 * @returns: object
 */

export const setBoxDiff = (styleObj, boxValue) => {
  if (boxValue && typeof boxValue.FONT !== "undefined") {
    styleObj.fontFamily = boxValue.FONT.DEFAULT;
  }

  if (boxValue && typeof boxValue.FONTSIZE !== "undefined") {
    styleObj.fontSize = boxValue.FONTSIZE.DEFAULT;
  }

  if (boxValue && typeof boxValue.FORMAT !== "undefined") {
    if (
      boxValue &&
      (boxValue.FORMAT.DEFAULT == "bold" || boxValue.FORMAT.DEFAULT == "plain")
    ) {
      styleObj.fontWeight = boxValue.FORMAT.DEFAULT;
    } else if (boxValue && boxValue.FORMAT.DEFAULT == "italic") {
      styleObj.fontStyle = boxValue.FORMAT.DEFAULT;
    } else if (boxValue && boxValue.FORMAT.DEFAULT == "underline") {
      styleObj.textDecoration = boxValue.FORMAT.DEFAULT;
    }
  }

  if (boxValue && typeof boxValue.JUSTIFY !== "undefined") {
    styleObj.textAlign = "center";
  }

  if (
    boxValue &&
    typeof boxValue.USER !== "undefined" &&
    boxValue.USER.ACCESS === "ADMIN"
  ) {
    const newUserObj = { ...boxValue.USER };
    delete newUserObj.ACCESS;
    setBoxDiff(styleObj, newUserObj);
  }

  return styleObj;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mergeBoxDefUserObject
 * @param  : box object, sesstion file data
 * @desc   :  function return new object with merging of two object
 * @returns: Object
 */

export const mergeBoxDefUserObject = (boxDefObject, sessionData) => {
  const userLevel = getVlaueFormSessionByKey(
    sessionData,
    "User_Level",
    "cdata"
  );
  const newUserObj = { ...boxDefObject.USER };
  const newBoxDiff = { ...boxDefObject };
  delete newBoxDiff.USER;
  if (userLevel !== "Admin") {
    return { ...newBoxDiff };
  } else {
    const newObject = { ...newBoxDiff, ...newUserObj };
    return newObject;
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getSectionByTitle
 * @param  : title name for section
 * @desc   :  function return string of focus section
 * @returns: string
 */

export const getSectionByTitle = (title) => {
  if (title.includes("FXS_Title")) {
    return "companyInfo";
  } else if (title.includes("FXS_Sig")) {
    return "companySignInfo";
  } else if (title.includes("FXS_OverSig") || title.includes("FXS_UnderSig")) {
    return "companysigOverInfo";
  } else if (title.includes("FXS_Stub")) {
    return "companyStubInfo";
  } else if (title.includes("FXS_Bank")) {
    return "bankInfo";
  } else if (title.includes("FXS_Fractional")) {
    return "fractionalInfo";
  } else if (title.includes("FXS_CheckNumber")) {
    return "checkInfo";
  } else if (title.includes("FXS_GEN")) {
    return "companyGenInfo";
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @param: section name
 * @name   :  getArrayNameBySectionName
 * @desc   :  function return string of focus section
 * @returns: string
 */

export const getArrayNameBySectionName = (section) => {
  if (section.includes("companyInfo")) {
    return "companyInfo";
  } else if (section.includes("companySignInfo")) {
    return "compnaySignInfo";
  } else if (section.includes("companysigOverInfo")) {
    return "companySignOverInfo";
  } else if (section.includes("companyStubInfo")) {
    return "stubData";
  } else if (section.includes("bankInfo")) {
    return "bankInfo";
  } else if (section.includes("fractionalInfo")) {
    return "fractionalInfo";
  } else if (section.includes("checkInfo")) {
    return "checkInfo";
  } else if (section.includes("companyGenInfo")) {
    return "genData";
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getArrayNameBySectionName
 * @param  : section name, object
 * @desc   :  function return object to set the new status with style object
 * @returns: object
 */

export const getSectionSateObject = (section, newObject) => {
  if (section === "companyInfo") {
    return {
      companyInfo: newObject,
    };
  } else if (section === "companySignInfo") {
    return {
      compnaySignInfo: newObject,
    };
  } else if (section === "companysigOverInfo") {
    return {
      companySignOverInfo: newObject,
    };
  } else if (section === "companyStubInfo") {
    return {
      stubData: newObject,
    };
  } else if (section === "bankInfo") {
    return {
      bankInfo: newObject,
    };
  } else if (section === "fractionalInfo") {
    return { fractionalInfo: newObject };
  } else if (section === "checkInfo") {
    return { checkInfo: newObject };
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mergeTwoObjectColors
 * @param   : object 1, object 2
 * @desc   :  combine the two object and return the new object
 * @returns: object
 */

export const mergeTwoObjectColors = (colorObj1, colorObj2) => {
  colorObj1.pop();
  const newObj = [...colorObj1, ...colorObj2];
  return newObj;
};

/* @author    Clarion
 * @version 1.0
 * @name   :  hexToRgbA
 * @param   : hex, shade
 * @desc   :  comvert hex to RGBA color
 * @returns: string
 */

export const hexToRgbA = (hex, shade) => {
  var c;
  var opacity = shade / 100;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  return "Bad Hex";
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getTile
 * @param   : colorArray
 * @desc   :  function return array of colors tile
 * @returns: array of colors tile
 */

export const getTile = (colorArr) => {
  return (
    colorArr.length > 0 &&
    colorArr.map((element) => {
      const hex = "#" + element.RED + element.GREEN + element.BLUE;
      var newStr = "";
      if (element.SCREEN_SHADE && element.SCREEN_SHADE !== "") {
        newStr = element.SCREEN_SHADE.substring(
          0,
          element.SCREEN_SHADE.length - 1
        );
      }

      let rgba = hexToRgbA(hex, newStr);

      if (rgba === "Bad Hex") {
        rgba = element.rgba;
      }

      return {
        NAME: element.NAME,
        DISPLAY_NAME: element.DISPLAY_NAME,
        rgba: rgba,
        SCREEN_SHADE: newStr,
        BACKGROUNDFILE: element.BACKGROUNDFILE,
      };
    })
  );
};

export const getPantoneTile = (colorArr) => {
  if (colorArr === "undefined") return (colorArr = []);
  return (
    colorArr.length > 0 &&
    colorArr.map((element) => {
      const hex = element.attributes.hex;
      var newStr = "";
      if (element.SCREEN_SHADE && element.SCREEN_SHADE !== "") {
        newStr = element.SCREEN_SHADE.substring(
          0,
          element.SCREEN_SHADE.length - 1
        );
      }

      return {
        NAME: element.attributes.name,
        DISPLAY_NAME: element.attributes.name,
        rgba: element.attributes.hex,
        SCREEN_SHADE: newStr,
      };
    })
  );
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getColorDefaultSetting
 * @param  :jsonData render api, config data form config file and session data from sestion file
 * @desc   :  function object of color set to send AFI in default
 * @returns: object
 */

export const getColorDefaultSetting = (
  jsonData,
  configData,
  sessionData,
  docType
) => {
  let colorArry = [];
  const colorObj = {
    textBtn: {
      name: "",
      color: "",
    },
    lineBtn: {
      name: "",
      color: "",
    },
    bgBtn: {
      name: "",
      color: "",
    },
  };
  if (configData) {
    const findColorIndex = sessionData.findIndex(
      (element) => element.name === "Colors"
    );
    const findUserIndex = sessionData.findIndex(
      (element) => element.name === "User_Level"
    );

    if (sessionData[findColorIndex].cdata != 0) {
      if (sessionData[findUserIndex].cdata === "Admin") {
        colorArry = mergeTwoObjectColors(
          configData.COLORSETS.DEFAULT,
          configData.COLORSETS.DEFAULT.ADMIN
        );
      } else {
        const defaultColor = [...configData.COLORSETS.DEFAULT];
        colorArry = defaultColor;
      }
    } else {
      colorArry = configData.COLORSETS.VALUELINE;
    }
  }

  const defaultColors = _.filter(jsonData, (config) => {
    return config.name && config.name.includes("Default");
  });
  const lineColor = _.filter(jsonData, (config) => {
    return (
      (config.name && config.name.includes("Borders")) ||
      config.name.includes("Lines")
    );
  });
  const ScreensColor = _.filter(jsonData, (config) => {
    return (
      (config.name && config.name.includes("Pantos")) ||
      config.name.includes("Screens")
    );
  });

  if (
    defaultColors &&
    defaultColors.length > 0 &&
    defaultColors[0].content &&
    docType.toLowerCase() !== "new order"
  ) {
    colorObj.textBtn.name =
      defaultColors[0].content + "|" + defaultColors[0].tint;
  } else {
    const findDTextIndex =
      colorArry.length >= 0 && colorArry.findIndex((p) => p.default === true);
    if (colorArry.length > 0 && findDTextIndex != -1) {
      const strSub = colorArry[findDTextIndex].SCREEN_SHADE.slice(0, -1);

      colorObj.textBtn.name = colorArry[findDTextIndex].NAME + "| 100";
    }
  }

  if (
    ScreensColor &&
    ScreensColor.length > 0 &&
    ScreensColor[0].content &&
    docType.toLowerCase() !== "new order"
  ) {
    colorObj.bgBtn.name = ScreensColor[0].content + "|" + ScreensColor[0].tint;
  } else {
    const findDTextIndex =
      colorArry.length >= 0 && colorArry.findIndex((p) => p.default === true);
    if (colorArry.length > 0 && findDTextIndex != -1) {
      const strSub = colorArry[findDTextIndex].SCREEN_SHADE.slice(0, -1);

      colorObj.bgBtn.name = colorArry[findDTextIndex].NAME + "|" + strSub;
    }
  }

  if (
    lineColor &&
    lineColor.length > 0 &&
    lineColor[0].content &&
    docType.toLowerCase() !== "new order"
  ) {
    colorObj.lineBtn.name = lineColor[0].content + "|" + lineColor[0].tint;
  } else {
    const findDTextIndex =
      colorArry.length >= 0 && colorArry.findIndex((p) => p.default === true);
    if (colorArry.length > 0 && findDTextIndex != -1) {
      const strSub = colorArry[findDTextIndex].SCREEN_SHADE.slice(0, -1);

      colorObj.lineBtn.name = colorArry[findDTextIndex].NAME + "| 100";
    }
  }
  return colorObj;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  appliedColor
 * @param  :colorArry, findDefaultIndex,lable,btn
 * @desc   :  set the background colors for style section buttons
 * @returns: -
 */

export const appliedColor = (colorArry, findDefaultIndex, lable, btn, sku) => {
  // console.log("colorArry", colorArry);
  // console.log("findDefaultIndex", findDefaultIndex);
  //   console.log("lable", lable);
  if (findDefaultIndex !== -1) {
    const hex =
      "#" +
      colorArry[findDefaultIndex].RED +
      colorArry[findDefaultIndex].GREEN +
      colorArry[findDefaultIndex].BLUE;
    let rgbaColor = hexToRgbA(hex, 1);
    if (rgbaColor === "Bad Hex" && sku && !sku.startsWith("FS")) {
      rgbaColor = colorArry[findDefaultIndex].rgba;
    }
    if (document.getElementById(lable + "_elements_name"))
      document.getElementById(lable + "_elements_name").innerHTML =
        colorArry[findDefaultIndex].DISPLAY_NAME;

    if (document.getElementById(lable + "_elements_color"))
      document.getElementById(
        lable + "_elements_color"
      ).style.background = rgbaColor;
    setTimeout(() => {
      if (document.getElementById(btn + "_elements_color")) {
        document.getElementById(
          btn + "_elements_color"
        ).style.background = rgbaColor;
        document.getElementById(btn + "_elements_color").innerHTML =
          colorArry[findDefaultIndex].DISPLAY_NAME;
      }
    }, 10);
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  appliedPantoneColor
 * @param  :colorObject
 * @desc   :  set the background colors for style section buttons
 * @returns: -
 */

export const appliedPantoneColor = (colorObject, lable, btn) => {
  if (colorObject && colorObject.name && colorObject.color) {
    if (document.getElementById(lable + "_elements_name"))
      document.getElementById(lable + "_elements_name").innerHTML =
        colorObject.name;

    if (document.getElementById(lable + "_elements_color"))
      document.getElementById(lable + "_elements_color").style.background =
        colorObject.color;
    setTimeout(() => {
      if (document.getElementById(btn + "_elements_color")) {
        document.getElementById(btn + "_elements_color").style.background =
          colorObject.color;
        document.getElementById(btn + "_elements_color").innerHTML =
          colorObject.name;
      }
    }, 10);
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getVlaueFormJsonByKey
 * @param  : jsonaData, compaireKey, attributeKey
 * @desc   :  function return value from array of json data
 * @returns: string
 */

export const getVlaueFormJsonByKey = (jsonaData, compaireKey, attrKey) => {
  const findUserIndex = jsonaData.findIndex(
    (element) => element.name === compaireKey
  );
  if (findUserIndex !== -1 && jsonaData[findUserIndex] !== "undefined") {
    return jsonaData[findUserIndex][attrKey];
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getVlaueFormSessionByKey
 * @param  : sessionData, compaireKey, attrKey
 * @desc   :   function return value from array of session data
 * @returns: string
 */

export const getVlaueFormSessionByKey = (sessionData, compaireKey, attrKey) => {
  const findUserIndex = sessionData.findIndex(
    (element) => element.name === compaireKey
  );
  if (findUserIndex !== -1 && sessionData[findUserIndex] !== "undefined") {
    return sessionData[findUserIndex][attrKey];
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getFontIDFormFontMap
 * @desc   :  function for find the fontid from fontmap array
 * @returns: string
 */

export const getFontIDFormFontMap = (fontMapData, fontFamily) => {
  for (const key in fontMapData) {
    if (fontMapData.hasOwnProperty(key)) {
      if (fontMapData[key].fontFamily === fontFamily) {
        return key;
      }
    }
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getBGObjectFormByJsonData
 * @desc   :  function for find the fontid from fontmap array
 * @returns: object
 */

export const getBGObjectFormByJsonData = (bgArr, background) => {
  const findbgArrIndex = bgArr.findIndex(
    (element) => element.FILE === background
  );
  if (findbgArrIndex !== -1) {
    return bgArr[findbgArrIndex];
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getLogoDisplayNameByFolder
 * @desc   :  function for find the display name of logo
 * @returns: string
 */

export const getLogoDisplayNameByFolder = (logoFiles, subFolderName) => {
  //#5277 Exceptional handling done
 try{
    if(logoFiles){
    const findSubFolderArrIndex = logoFiles.findIndex(
      (element) => element.subFolder === subFolderName
    );
    if (findSubFolderArrIndex !== -1) {
      return logoFiles[findSubFolderArrIndex].DISPLAY_NAME;
    }
    } else {
      return -1;  
    }
  }catch(err){
  console.log(err)
}
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getFontIdByFontStyle
 * @desc   :  function for find the display name of logo
 * @returns: string
 */

export const getFontIdByFontStyle = (fontArr, bold, italic, fontName) => {
  
  if (bold === "bold") {
    bold = "true";
  } else {
    bold = "false";
  }
  if (italic === "italic") {
    italic = "true";
  } else {
    italic = "false";
  }
  const fontFNameIndex = fontArr.findIndex(
    (element) => element.NAME === fontName
  );
  if (fontFNameIndex !== -1 && fontArr[fontFNameIndex]["fontMap"]) {
    const fontMapIndex = fontArr[fontFNameIndex]["fontMap"].findIndex(
      (element) => {
        return element.bold === bold && element.italic === italic;
      }
    );
    if (fontMapIndex !== -1) {
      return {
        fontMapArr: fontArr[fontFNameIndex]["fontMap"],
        fontId: fontArr[fontFNameIndex]["fontMap"][fontMapIndex]["id"],
      };
    } else {
      if (fontArr[fontFNameIndex]["fontMap"].length == 1) {
        return {
          fontMapArr: fontArr[fontFNameIndex]["fontMap"],
          fontId: fontArr[fontFNameIndex]["fontMap"][0]["id"],
        };
      }
    }
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getImageNameFormImgArr
 * @param  : phantomImageArray, attributeName
 * @desc   :  function for find and the display name of phantom logo
 * @returns: string
 */

export const getImageNameFormImgArr = (imgStateArr, attrName) => {
  const imgDFindIndex = imgStateArr.findIndex(
    (p) => p.attributes.NAME === attrName
  );
  if (imgDFindIndex !== -1) {
    return imgStateArr[imgDFindIndex].phatomLogoName;
  }
  return "";
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setLocalStorage
 * @param  :keyName, Value
 * @desc   :  set the data in local storage
 * @returns: -
 */

export const setLocalStorage = (keyName, value) => {
  let newVal = value;
  if (typeof value == "object") {
    newVal = JSON.stringify(value);
  }
  localStorage.setItem(keyName, newVal);
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getLolcalStore
 * @param  :keyName
 * @desc   :  return the data from local storage
 * @returns: object/string
 */

export const getLolcalStore = (keyName) => {
  const dataFromStore = localStorage.getItem(keyName);
  if (dataFromStore) {
    return JSON.parse(dataFromStore);
  }
  return -1;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getFileName
 * @param  :filenamewithExtension
 * @desc   :  return the object of file information
 * @returns: object
 */

export const getFileName = (filenameWithExtension) => {
  if (filenameWithExtension) {
    const sFileName = filenameWithExtension;
    const sFileExtension = sFileName
      .split(".")
      [sFileName.split(".").length - 1].toLowerCase();

    let sFileNameWE = sFileName.split(".");
    sFileNameWE.pop();
    sFileNameWE = sFileNameWE.join("_");
    return {
      extension: sFileExtension,
      filename: sFileName,
      fileNameWithoutEx: sFileNameWE,
    };
  } else {
    return -1;
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getInputSelection
 * @param  :event
 * @desc   :  return the object with mouse postion x and y
 * @returns: object
 */

export const getInputSelection = (el) => {
  var start = 0,
    end = 0,
    normalizedValue,
    range,
    textInputRange,
    len,
    endRange;

  if (
    typeof el.selectionStart == "number" &&
    typeof el.selectionEnd == "number"
  ) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    range = document.selection.createRange();

    if (range && range.parentElement() == el) {
      len = el.value.length;
      normalizedValue = el.value.replace(/\r\n/g, "\n");

      textInputRange = el.createTextRange();
      textInputRange.moveToBookmark(range.getBookmark());

      endRange = el.createTextRange();
      endRange.collapse(false);

      if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
        start = end = len;
      } else {
        start = -textInputRange.moveStart("character", -len);
        start += normalizedValue.slice(0, start).split("\n").length - 1;

        if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
          end = len;
        } else {
          end = -textInputRange.moveEnd("character", -len);
          end += normalizedValue.slice(0, end).split("\n").length - 1;
        }
      }
    }
  }

  return {
    start: start,
    end: end,
  };
};

export const getBgDefault = (bgArr) => {
  const index = bgArr.findIndex((p) => {
    return p.default === "true";
  });
  if (index !== -1) {
    return bgArr[index];
  }
  return index;
};

export const getDefaultColor = (colorArr) => {
  const index = colorArr.findIndex((p) => {
    return p.default == true;
  });
  if (index !== -1) {
    return colorArr[index];
  }
  return index;
};

export const getLogoType = (data) => {
  let logoType = 0;
  for (const key in data.FXS_COMPONENT) {
    if (data.FXS_COMPONENT.hasOwnProperty(key)) {
      if (
        data.FXS_COMPONENT[key].ATTR &&
        data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT &&
        data.FXS_COMPONENT[key].ATTR.IS_KNOCKOUT.toLowerCase() === "true"
      ) {
        logoType = logoType;
      } else {
        logoType = logoType + 1;
      }
    }
  }
  return logoType;
};

export const getLogoColor = (data) => {
  const logoColorArray = [];
  for (const key in data.FXS_COMPONENT) {
    if (data.FXS_COMPONENT.hasOwnProperty(key)) {
      if (data.FXS_COMPONENT[key].DEFAULT_COLOR) {
        logoColorArray.push({
          DEFAULT_COLOR: data.FXS_COMPONENT[key].DEFAULT_COLOR[0],
          DEFAULT_SHADE: data.FXS_COMPONENT[key].DEFAULT_SHADE[0],
          PHANTOM_SHADE: data.FXS_COMPONENT[key].PHANTOM_SHADE[0],
        });
      }
    }
  }
  return logoColorArray;
};

export const removeCharAtLast = (string) => {
  if (string) {
    return string.substring(0, string.length - 1);
  }
  return string;
};

export const checkColorPresent = (colorArray, color) => {
  if (!colorArray) {
    colorArray = [];
  }
  const colorIndex =
    colorArray && colorArray.findIndex((p) => p.DEFAULT_COLOR == color);
  if (colorIndex !== -1) {
    return true;
  }
  return false;
};

export const getValueArrayIndex = (arr, compaireKey, value) => {
  if (arr && arr.length > 0) {
    return arr.findIndex((p) => p[compaireKey] === value);
  } else {
    return -1;
  }
};

export const displayColorMatchLogos = (setLogoColors, data, colorCode,firstLogo,selectedImage,mainLogo,phantomLogo,logoFiles) => {
  const selectedLogoColors=setLogoColors
  if (!setLogoColors || colorCode != 2) {
    return "block";
  }
      
  //#5290 Logo placement/color issue (another) Additonal color condition on logo selection

  const logoColor = getLogoColor(data);

  if(firstLogo!='undefined' && (firstLogo && firstLogo.length>0)){
    setLogoColors=firstLogo
  }

  let setLogoColorsArray = _.map(setLogoColors, item => ({
    'DEFAULT_COLOR': item.DEFAULT_COLOR
  }))
  
  let logoColorArray = _.map(logoColor, item => ({
      'DEFAULT_COLOR': item.DEFAULT_COLOR
  }))
   const isPhantomLogoExists=phantomLogo.findIndex(item=>item.value !='None')
   const isPhantomLogoArray=phantomLogo.filter(item=>item.value !='None')
   let selectedLogoColorsArray1 = _.map(selectedLogoColors, item => ({
    'DEFAULT_COLOR': item.DEFAULT_COLOR
  }))
  //5290 If a Logo of 1 color is selected and the additional color is 0 then Logos having with same selected color and logos with 2 colors where one of them is the same as the first should be visible.
  if (_.isEqual(setLogoColorsArray.sort(), logoColorArray.sort())) {
    return "block";
  } else if(selectedImage && selectedImage.ADDITIONAL_COLORS==0 && setLogoColors.length==1 ){
    if(logoColor.length==1){   
      if((mainLogo && mainLogo.length>0 && mainLogo[0].value!='None') && isPhantomLogoExists!=-1 && (_.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))|| _.some(selectedLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR])))){
        return "block"
      } else if((mainLogo && mainLogo.length>0 && mainLogo[0].value!='None') && isPhantomLogoExists!=-1 && (_.isEqual(setLogoColorsArray.sort(), selectedLogoColorsArray1.sort()))){
        return "block"
      } else if((mainLogo && mainLogo.length==0 && mainLogo[0].value=='None') || isPhantomLogoExists==-1){
        return "block" 
      } else if(isPhantomLogoArray.length==1 && isPhantomLogoExists!=-1 && mainLogo[0].value=='None'){
        return "block" 
      } else if(isPhantomLogoArray.length>1 && getPhantomLogoColorsMatch(logoFiles,phantomLogo)==false && isPhantomLogoExists!=-1 && mainLogo[0].value=='None' && (_.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))|| _.some(selectedLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR])))){
        return "block" 
      } else if(isPhantomLogoArray.length>1 && getPhantomLogoColorsMatch(logoFiles,phantomLogo)==true && isPhantomLogoExists!=-1 && mainLogo[0].value=='None'){
        return "block" 
      } else {
       return "None"
      }
    } else if(logoColor.length==2){
      if((mainLogo && mainLogo.length>0 && mainLogo[0].value!='None') && isPhantomLogoExists!=-1 && setLogoColors.length===1 && selectedLogoColors.length===1){
          return "None"
        // } else if((mainLogo && mainLogo.length>0 && mainLogo[0].value!='None') && isPhantomLogoExists!=-1 && setLogoColors.length===1 && selectedLogoColors.length==2 && _.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))){
//          return "block"
        } else if((mainLogo && mainLogo.length>0 && mainLogo[0].value!='None') && isPhantomLogoExists!=-1 && setLogoColors.length===1 && selectedLogoColors.length==2){
          if(checkColorsMatched(selectedLogoColors,logoColor,selectedImage.ADDITIONAL_COLORS)){
            return "block"
          } else {
            return "None"
          }
       } else if(((mainLogo && mainLogo.length==0 && mainLogo[0].value=='None') || isPhantomLogoExists==-1) && _.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))){
          return "block" 
        } else if(isPhantomLogoExists!=-1 && mainLogo[0].value=='None' && _.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))){
          return "block" 
        } else {
          return "None"
        }
    } else {
      return "None";
    }
  } else if((selectedImage && (selectedImage.ADDITIONAL_COLORS==0)) && setLogoColors.length>1){
    if(checkColorsMatched(setLogoColors,logoColor,selectedImage.ADDITIONAL_COLORS)){
      return "block"
    }else{
      return "None"
    }
  } else if(selectedImage && (selectedImage.ADDITIONAL_COLORS==1 || selectedImage.ADDITIONAL_COLORS==2)){
    //  if(logoColor.length==1 && _.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))){
    if(logoColor.length==1){
       return "block";
     } else if(logoColor.length==2 && _.some(setLogoColors, field => _.find(logoColor, ['DEFAULT_COLOR', field.DEFAULT_COLOR]))){
      return "block";
     } else if((logoColor.length==3 || logoColor.length==4) && checkColorsMatched(setLogoColors,logoColor,selectedImage.ADDITIONAL_COLORS)){
       return "block";
     }else {
       return "None"
     }
  } else {
    return "None";
  }
};

export const getScreenshadeByColorName = (colorName, colorArray) => {
  if (colorArray && colorArray.length > 0) {
    const colorIndex = colorArray.findIndex((p) => p.NAME === colorName);
    if (colorIndex !== -1) {
      if (colorArray[colorIndex].SCREEN_SHADE) {
        return removeCharAtLast(colorArray[colorIndex].SCREEN_SHADE);
      } else {
        return DEFAULT_SCREEN_SHADE;
      }
    }
    return DEFAULT_SCREEN_SHADE;
  }
  return DEFAULT_SCREEN_SHADE;
};

export const getColorNameString = (colorArr, pantoneColorArr, colorName) => {
  const colorIndex = colorArr && colorArr.length > 0 && colorArr.findIndex(
    (p) => p.NAME.toLowerCase() === colorName.toLowerCase()
  );
  if (colorIndex !== -1) {
    return colorName;
  }

  const colordIndex = colorArr && colorArr.length > 0 && colorArr.findIndex(
    (p) => p.DISPLAY_NAME.toLowerCase() === colorName.toLowerCase()
  );
  if (colordIndex !== -1) {
    return colorName;
  }

  const colorPindex = pantoneColorArr && pantoneColorArr.length>0 && pantoneColorArr.findIndex(
    (p) => p.NAME.toLowerCase() === colorName.toLowerCase()
  );

  if (colorPindex !== -1) {
    return colorName;
  }

  var hasNumber = /\d/;
  if (hasNumber.test(colorName)) {
    const strPos = colorName.search(hasNumber);

    return "PANTONE " + colorName.substring(strPos, colorName.length);
  }
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setLocalStorage
 * @param  :keyName, Value
 * @desc   :  set the data in local storage
 * @returns: -
 */

export const setSessinoStorage = (keyName, value) => {
  let newVal = value;
  if (typeof value == "object") {
    newVal = JSON.stringify(value);
  }
  sessionStorage.setItem(keyName, newVal);
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  getLolcalStore
 * @param  :keyName
 * @desc   :  return the data from local storage
 * @returns: object/string
 */

export const getSessionStore = (keyName) => {
  const dataFromStore = sessionStorage.getItem(keyName);

  return dataFromStore;
};

/**
 * Logo color having additonal colors is matched upto limit  
 * @param {*} selectedLogoColors 
 * @param {*} logoColorsArray 
 * @param {*} additionalColor 
 */
export const checkColorsMatched = (selectedLogoColors,logoColorsArray,additionalColor) => {
  let count=0
  _.some(selectedLogoColors, field => {
    const obj=_.find(logoColorsArray, ['DEFAULT_COLOR', field.DEFAULT_COLOR])
    if(obj){
      count++
    }
  })
  if(count>=logoColorsArray.length-additionalColor){
    return true
  } else {
    return false
  }
};

/**
 * Get Image details from partx.xml by logo name
 * @param {*} logoFiles 
 * @param {*} logoName 
 */
export const getLogoDetailsByFolder = (logoFiles, logoName) => {
try {
   let logoDisplayName
  if(logoName.split("/").length===3){
    logoDisplayName=logoName.split('/')[1]
  } else if(logoName.split("/").length===4){
    logoDisplayName=logoName.split('/')[2]
  } else {
    logoDisplayName= logoName.split('/')[0];  
  }

  const logoObject = logoFiles;
  const checkCmLogo = logoObject.Customer_Logos.findIndex((p)=> p.subFolder == logoDisplayName);
  const checkTopLogo = logoObject.Generic_TopLogos.findIndex((p)=> p.subFolder == logoDisplayName);
  const checkSideLogo = logoObject.Generic_SideLogos.findIndex((p)=> p.subFolder == logoDisplayName);

  if(checkCmLogo===-1 && checkTopLogo===-1 && checkSideLogo ===-1){
     return -1
   } else if(checkCmLogo!==-1){
     return logoObject.Customer_Logos[checkCmLogo]
   } else if(checkTopLogo!==-1){
    return logoObject.Generic_TopLogos[checkTopLogo]
   } else if(checkSideLogo!==-1){
    return logoObject.Generic_SideLogos[checkSideLogo]
   } else {
    return -1
   }
} catch(err){
  console.log(err)
}
  
};
export const getPhantomLogoColorsMatch = (logoFiles, phantomLogo) => {
  const phantomLogo1=getLogoDetailsByFolder(logoFiles, phantomLogo[0].value)
  const phantomLogo2=getLogoDetailsByFolder(logoFiles, phantomLogo[2].value)
  const color1=getLogoColor(phantomLogo1)
  const color2=getLogoColor(phantomLogo2)
  const result=(color1.length>0 && color2.length>0 && _.isEqual(color1.sort(), color2.sort())) || false
  return result
}