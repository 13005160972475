/**
 * @author    Clarion
 * @version 1.0
 * @file   :  ControlPanel
 * @desc   :  File For Rendering company tab inputs
 */

import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";

import { LogoPopUp } from "../Common/LogoPopUp/LogoPopUp";
import * as action from "../../store/action/fetchData";
import * as inputSettingction from "../../store/action/inputSettings";
import * as viewRender from "../../utility/ViewRender";
import {InputViewRender,CompanySignOverViewRender,InputStubViewRender} from "../../utility/ViewRender";
import {
  getVlaueFormSessionByKey,
  getInputSelection,
} from "./../../utility/Utility";
import { UPLOAD_IMAGE_FILE_TYPES } from "./../../utility/AppsConst";
import { LogoType } from "./../LogoType/LogoType";

import "./Company.css";

class Company extends React.Component {
  constructor(props) {
    super(props);

    const genInfo = _.filter(this.props.jsonData, (jsonEle) => {
      return jsonEle && jsonEle.name.includes("FXS_GEN");
    });

    const documentType = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Type",
      "cdata"
    );
    let isdocumentType = false;
    if (
      documentType &&
      documentType !== -1 &&
      (documentType === "Reorder-Exact" || documentType === "Exact")
    ) {
      isdocumentType = true;
    }
    const fileTypeStr = UPLOAD_IMAGE_FILE_TYPES.join(",").toUpperCase();
    let SignLingVal = this.props.signLine[0].value;
    let sessFileType = documentType.toLowerCase();
    if (documentType.toLowerCase() === "new order" ) {
      SignLingVal = "1";
    }
    const colorCode = getVlaueFormSessionByKey(
      this.props.sessionData,
      "Colors",
      "cdata"
    );
    /** #5535-condition to show stub information changes */
    let isDisplyStub = false,isDisplyStubSingle=false
    const stubInfo = _.filter(this.props.jsonData, (p) => {
      return p && p.name.includes("FXS_Stub") && p.name!="FXS_Stub1Single";
    });
    if (stubInfo && stubInfo.length > 0 ) {
      isDisplyStub = true;
    }
  
    const propsStubData = _.cloneDeep(this.props.companyStubData);
    const findSignleLine = propsStubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
    if(findSignleLine!=-1 && propsStubData[findSignleLine].styleObj){
      isDisplyStubSingle=true
    }
    let isDisplySigLine = true;
    const SigLineInfo = _.filter(this.props.jsonData, (p) => {
      return p && p.name.includes("SigLine");
    });
    if (SigLineInfo && SigLineInfo.length === 0) {
      isDisplySigLine = false;
    }

    let isDisplyCompanyLine = true;
    const companyInfo = _.filter(this.props.jsonData, (p) => {
      return p && p.name.includes("FXS_Title");
    });
    if (companyInfo && companyInfo.length === 0) {
      isDisplyCompanyLine = false;
    }

    this.state = {
      signLine: SignLingVal,
      isLogoPopupOpen: false,
      genInfo: genInfo,
      documentType: isdocumentType,
      file: null,
      isTemplateModelOpen: false,
      fileTypeStr: fileTypeStr,
      isDisplayImage: true,
      colorCode: colorCode,
      sessFileType: sessFileType,
      isDisplyStub: isDisplyStub,
      isDisplySigLine: isDisplySigLine,
      isDisplyCompanyLine:isDisplyCompanyLine,
      isDisplyStubSingle:isDisplyStubSingle
    };
  }

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  onSignChanged
   * @desc   :  This function update the local state for signLine clicked on Radio buttons in signature section of company information
   * @return -
   */

  onSignChanged = (event, signLine) => {
    this.setState({
      signLine: signLine,
    });
    this.props.onSignLineChange(signLine, true);
    this.props.onBlurHandaler();
  };

  /**
   * @author    Clarion
   * @version 1.0
   * @name   :  dotBtnClick
   * @desc   :  This function get called when user clicked on bullet dot to add the dot to string.
   * @return -
   */

  dotBtnClick = (event) => {
    // if (!this.props.boxName.includes("FXS_Stub1Single")) {
    //   return false;
    // }
    // var input = document.getElementById("FXS_Stub1Single_"+this.props.spanindex);
    // var inputContent = input.value.length;
    // const bullateLoc = input.value.indexOf("•");
    // input.focus();
    // var result = getInputSelection(input);

    // const start = result.start;
    // var result = input.value.slice(0, start) + "•" + input.value.slice(start);
    
    // this.props.onInputChanged(
    //   input,
    //   "FXS_Stub1Single",
    //   "companyStubInfo",
    //   result,
    //   this.props.spanindex
    // );
    // input.blur();
    let currentBulletFlag = this.props.bulletFlag;
    // if(currentBulletFlag){
      currentBulletFlag = !currentBulletFlag;
      this.props.setToggleBulletBtn(currentBulletFlag);
    // }
    const propsStubData = _.cloneDeep(this.props.companyStubData);
    const findSignleLine = propsStubData.findIndex(p=>p.attributes.NAME === "FXS_Stub1Single");
    if(findSignleLine !==-1){
      propsStubData[findSignleLine].isInputChanged = true;
      if(propsStubData[findSignleLine].styleObj){
        if(currentBulletFlag){
          propsStubData[findSignleLine].value = propsStubData[findSignleLine].styleObj[0].value+" • "+ propsStubData[findSignleLine].styleObj[1].value;
        }else{
          propsStubData[findSignleLine].value = propsStubData[findSignleLine].styleObj[0].value+" "+ propsStubData[findSignleLine].styleObj[1].value;
        }
      }
      this.props.setStubDataState(propsStubData,true);
      this.props.onBlurHandaler();
    }
  };

  //5287-Add dot/bullet button to 2 line stubs
  /**Add or Remove dot from double stub lines jobs*/
  dotBtnClickDoubleStubLine = (event) => {
      const propsStubData = _.cloneDeep(this.props.companyStubData);
      const findStubLine = propsStubData.findIndex(p=>p.attributes.NAME === this.state.stubLineElement);
      const stubText=this.insertDot(this.state.selectionStart,propsStubData[findStubLine].value)
      propsStubData[findStubLine].value=stubText
      propsStubData[findStubLine].isInputChanged=true
      propsStubData[findStubLine].isInputChangedFlag=true
      event.target.blur();
      this.props.setStubDataState(propsStubData,true);
      this.state.stubInputRef && this.state.stubInputRef.current.focus()
     this.props.onBlurHandaler();
  }

  onBlurHandaler = (isFocusOut, isNew, isImage, focusOutElement, event) => {
    this.setState({'stubInputRef':event.refContainer,'selectionStart':event && event.target && event.target.selectionStart,'stubLineElement':focusOutElement})
    // console.log("isFocusOut, isNew, isImage, focusOutElement, event",isFocusOut, isNew, isImage, focusOutElement, event)
    this.props.onBlurHandaler(isFocusOut, isNew, isImage, focusOutElement, event)
  };

  onInputStubSign1Changed = (event, name, section, spanindex) =>{
   this.props.onInputChanged(event, name, section, false,spanindex)
  }
  
  //5287-Add dot/bullet button to 2 line stubs
  /** Add or Remove dot at cursor position*/
  insertDot = (selectionStart,stublineText) => {
    let cursorPosition = selectionStart
    let textBeforeCursorPosition = stublineText.substring(0, cursorPosition)
    let textAfterCursorPosition = stublineText.substring(cursorPosition, stublineText.length)
    return textBeforeCursorPosition + "•" + textAfterCursorPosition
  }
  //clicked on font A button Icon

  //Render the company tab data
  render() {
    return (
      <React.Fragment>
        <div className="company__input-wrapper">
          {this.state.isDisplyCompanyLine && (
            <div className="text-uppercase font-semibold m-b-15">
              Company name and address
            </div>
          )}
          
          {/* {viewRender.InputViewRender(
            this.props.companyInfo,
            "companyInfo",
            "LINE",
            this.props,
            this.state.documentType,
            this.props.imageType,
            this.props.boxName,
            this.props.popUpClass
          )} */}
          <InputViewRender
            renderArr={this.props.companyInfo}
            section={"companyInfo"}
            label={"LINE "}
            jsonData={this.props.jsonData}
            isFieldDisabled={this.state.documentType}
            imageType={this.props.imageType ?this.props.imageType:[] }
            boxName={this.props.boxName}
            popUpClass={this.props.popUpClass}
            onInputChanged={this.props.onInputChanged}
            onBlurHandaler={this.onBlurHandaler}
            onFocusHandaler={this.props.onFocusHandaler}
            setFontPopupClass={this.props.setFontPopupClass}
          ></InputViewRender>

          {(this.state.isDisplyStub===true || this.state.isDisplyStubSingle===true) && (
            <div className="text-uppercase font-semibold m-t-35 m-b-15">
              Check stub information
            </div>
          )}

          {/* {this.state.isDisplyStub &&
            viewRender.InputViewRender(
              this.props.companyStubData,
              "companyStubInfo",
              "LINE",
              this.props,
              this.state.documentType,
              [],
              this.props.boxName,
              this.props.popUpClass
            )
          } */}
          {/*#5535 Stub Line condition changes */}
          {(this.state.isDisplyStub || this.state.isDisplyStubSingle) && <InputStubViewRender
            renderArr={this.props.companyStubData}
            section={"companyStubInfo"}
            label={"LINE "}
            jsonData={this.props.jsonData}
            isFieldDisabled={this.state.documentType}
            imageType={[]}
            boxName={this.props.boxName}
            popUpClass={this.props.popUpClass}
            onInputChanged={this.onInputStubSign1Changed}
            onBlurHandaler={this.onBlurHandaler}
            onFocusHandaler={this.props.onFocusHandaler}
            setFontPopupClass={this.props.setFontPopupClass}
            bulletFlag={this.props.bulletFlag}
            dotBtnClick={this.dotBtnClick}
            dotBtnClickDoubleStubLine={this.dotBtnClickDoubleStubLine}
          ></InputStubViewRender>}
          {/* {this.state.isDisplyStub && (
            <div className="button-wrapper">
              <button
                id="routingIDBTN_2"
                onClick={(event) => this.dotBtnClick(event)}
              >
                •
              </button>
            </div>
          )} */}
          {this.state.isDisplySigLine && (
            <React.Fragment>
              <div className="text-uppercase font-semibold m-t-35 m-b-15">
                Signature line information
              </div>
              <div className="radio-wrapper">
                <span>
                  <input
                    type="radio"
                    checked={this.state.signLine === "None" ? true : false}
                    id="test1"
                    name="radio-group"
                    onChange={(event) => this.onSignChanged(event, "None")}
                    disabled={this.state.documentType}
                  />
                  <label htmlFor="test1">None</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="test2"
                    name="radio-group"
                    onChange={(event) => this.onSignChanged(event, "1")}
                    checked={this.state.signLine === "1" ? true : false}
                    disabled={this.state.documentType}
                  />
                  <label htmlFor="test2">First line</label>
                </span>
                <span>
                  <input
                    type="radio"
                    id="test3"
                    name="radio-group"
                    onChange={(event) => this.onSignChanged(event, "2")}
                    checked={this.state.signLine === "2" ? true : false}
                    disabled={this.state.documentType}
                  />
                  <label htmlFor="test3">Second line</label>
                </span>
              </div>
            </React.Fragment>
          )}

          {
          // viewRender.companySignOverViewRender(
          //   this.props.companySignOverInfo,
          //   "companysigOverInfo",
          //   "LINE",
          //   this.props,
          //   this.state,
          //   this.state.documentType,
          //   this.props.boxName,
          //   this.props.popUpClass
          // )
          }
          <CompanySignOverViewRender
            renderArr={this.props.companySignOverInfo}
            section={"companysigOverInfo"}
            label={"LINE "}
            jsonData={this.props.jsonData}
            signLine={this.state.signLine}
            isFieldDisabled={this.state.documentType}
            imageType={[]}
            boxName={this.props.boxName}
            popUpClass={this.props.popUpClass}
            onInputChanged={this.props.onInputChanged}
            onBlurHandaler={this.onBlurHandaler}
            onFocusHandaler={this.props.onFocusHandaler}
            setFontPopupClass={this.props.setFontPopupClass}
          ></CompanySignOverViewRender>
          {/* {this.state.genInfo && this.state.genInfo.length > 0 && (
            <div className="text-uppercase font-semibold m-t-35 m-b-15">
              Gen information
            </div>
          )} */}

          {
          //   viewRender.InputViewRender(
          //   this.props.genData,
          //   "companyGenInfo",
          //   "LINE",
          //   this.props,
          //   this.state.documentType,
          //   [],
          //   this.props.boxName,
          //   this.props.popUpClass
          // )
        }
        <InputViewRender
            renderArr={this.props.genData}
            section={"companyGenInfo"}
            label={"LINE "}
            jsonData={this.props.jsonData}
            isFieldDisabled={this.state.documentType}
            imageType={[]}
            boxName={this.props.boxName}
            popUpClass={this.props.popUpClass}
            onInputChanged={this.props.onInputChanged}
            onBlurHandaler={this.onBlurHandaler}
            onFocusHandaler={this.props.onFocusHandaler}
            setFontPopupClass={this.props.setFontPopupClass}
          ></InputViewRender>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapStateToProps
 * @desc   :  get the data from redux store
 * @return -  object
 */

const mapStateToProps = (state) => {
  return {
    fxsConfig: state.fetchDataReducer,
    companyInfo: state.inputDataReducer.companyInfo,
    compnaySignInfo: state.inputDataReducer.compnaySignInfo,
    companySignOverInfo: state.inputDataReducer.companySignOverInfo,
    companyStubData: state.inputDataReducer.stubData,
    jsonData: state.fetchDataReducer.jsonData,
    signLine: state.inputDataReducer.signLine,
    logoFiles: state.inputDataReducer.logoFiles,
    sessionData: state.fetchDataReducer.sessionData,
    mainLogoName: state.inputDataReducer.mainLogoName,
    genData: state.inputDataReducer.genData,
    imageType: state.inputDataReducer.imageType,
    boxName: state.inputDataReducer.boxName,
    bgImage: state.inputDataReducer.backgroundImage,
    bgPreview: state.inputDataReducer.bgPreview,
    bgPreviewName: state.inputDataReducer.bgPreviewName,
    logoColorsArr: state.inputDataReducer.logoColorsArr,
    configData: state.fetchDataReducer.fxConfig,
    PantosColor: state.inputDataReducer.PantosColor,
    popUpClass: state.inputDataReducer.popUpClass,
    spanindex:state.inputDataReducer.spanindex,
    bulletFlag:state.inputDataReducer.bulletFlag
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  mapDispatchToProps
 * @desc   :  update data to redux store, action with action inputs
 */

const mapDispatchToProps = (dispatch) => {
  return {
    onChangedStyle: (styleObj, title, section) =>
      dispatch(
        inputSettingction.onStyleChangeHandaler(styleObj, title, section)
      ),
    onInputChanged: (event, name, section, value,spanindex) =>
      dispatch(action.onInputChanged(event, name, section, value,spanindex)),
    onBlurHandaler: (isFocusOut, isNew, isImage, focusOutElement, event) =>
      dispatch(action.onBlurHandaler(isFocusOut, isNew, isImage, focusOutElement, event)),
    onSignLineChange: (signLine, flag) =>
      dispatch(inputSettingction.setOverSigLineState(signLine, flag)),
    onFocusHandaler: (data,event,spanIndex) =>
      dispatch(inputSettingction.onFocusHandaler(data,spanIndex)),
    onColorChanged: (color, button) =>
      dispatch(inputSettingction.onColorChanged(color, button)),
    onBGChanged: (data, flag) =>
      dispatch(inputSettingction.onBGChanged(data, flag)),
    setFontPopupClass: (currentClass) =>
      dispatch(inputSettingction.setFontPopupClass(currentClass)),
    setToggleBulletBtn:(flag)=>dispatch(inputSettingction.setToggleBulletBtn(flag)),
    setStubDataState:(data,flag)=>dispatch(inputSettingction.setStubDataState(data,flag))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
