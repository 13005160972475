import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { createStore, applyMiddleware, compose, combineReducers } from "redux";


import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { fetchDataReducer } from "./store/reducer/fetchData";
import { inputDataReducer } from "./store/reducer/inputSettings";

const routerApp = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  fetchDataReducer: fetchDataReducer,
  inputDataReducer: inputDataReducer,
});

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

ReactDOM.render(
  <Provider store={store}>{routerApp}</Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
