import * as actionType from "./actionTypes";
import { getArrayNameBySectionName } from "./../../utility/Utility";

export const getInputSettings = (response) => {
  return {
    type: actionType.GET_INPUT_SETTINGS,
    setting: response,
  };
};

export const onInputChanged = (response) => {
  return {
    type: actionType.SET_INPUT_DATA,
    event: response,
  };
};

export const setCompanyOverSigState = (response) => {
  return {
    type: actionType.SET_COMPANY_OVER_SIGN_INFORMATION,
    companySignOverInfo: response,
  };
};

export const setOverSigLineState = (response, flag) => {
  return {
    type: actionType.SET_OVER_SIGN_LINE_FLAG,
    signOverLine: response,
    flag: flag,
  };
};

export const setStubDataState = (response,flag=false) => {
  return {
    type: actionType.SET_STUB_DATA,
    stubData: response,
    flag:flag
  };
};

export const setGenDataState = (response) => {
  return {
    type: actionType.SET_GEN_DATA,
    stubData: response,
  };
};

export const setBankInfoState = (response) => {
  return {
    type: actionType.SET_BANK_INFO_DATA,
    bankInfo: response,
  };
};

export const setfractionalInfoState = (response) => {
  return {
    type: actionType.SET_FRACTIONAL_INFO_DATA,
    fractionalInfo: response,
  };
};

export const setcheckInfoState = (response) => {
  return {
    type: actionType.SET_CHECK_INFO_DATA,
    checkInfo: response,
  };
};

export const onRoutingChange = (response) => {
  return {
    type: actionType.SET_ROUTING_VALUE,
    routingValue: response,
  };
};

export const setRoutingInfoState = (response) => {
  return {
    type: actionType.SET_ROUTING_STATE,
    routingValue: response,
  };
};

export const onAccountChangeChange = (response) => {
  return {
    type: actionType.SET_ACCOUNT_VALUE,
    accountValue: response,
  };
};

export const setAccountInfoState = (response) => {
  return {
    type: actionType.SET_ACCOUNT_STATE,
    accountValue: response,
  };
};

export const onFocusHandaler = (data,FXS_Stub1Single_index=-1) => {
  // console.log("FXS_Stub1Single_index",FXS_Stub1Single_index)
  return {
    type: actionType.SET_DEFAULT_BOX_DIFF,
    boxValues: data,
    FXS_Stub1Single_index : FXS_Stub1Single_index
  };
};

export const onStyleChangeHandaler = (styleObj, name, section) => {
  return {
    type: actionType.SET_STYLE_OBJECT,
    styleObj: styleObj,
    name: name,
    section: getArrayNameBySectionName(section),
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setCompanyInfoState
 * @desc   :  This function set an action and company information for the redux state
 */

export const setCompanyInfoState = (response) => {
  return {
    type: actionType.SET_COMPANY_INFORMATION,
    companyInfo: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setCompanyInfoState
 * @desc   :  This function set an action and company information for the redux state
 */

export const setCompanySignState = (response) => {
  return {
    type: actionType.SET_COMPANY_SIGN_INFORMATION,
    companySignInfo: response,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setFocusFalseState
 * @desc   :  This function set an action focusin false action in the redux state
 */

export const setFocusFalseState = () => {
  return {
    type: actionType.SET_INPUT_FOCUS_OUT_FALSE,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setIsInputChangedState
 * @desc   :  This function set an action set the flag input changes false action in the redux state
 */

export const setIsInputChangedState = (flag = false) => {
  return {
    type: actionType.SET_IS_INPUT_CHANGED,
    isInputChanged: flag,
  };
};

export const onColorChanged = (color, button,flag=true) => {
  return {
    type: actionType.SET_COLOR_CHANGED,
    button: button,
    color: color,
    flag:flag
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onColorDefault
 * @desc   :  This function set an action  default colors in redux
 */

export const onColorDefault = (colors) => {
  return {
    type: actionType.SET_COLOR_DEFAULT,
    colors: colors,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onColorDefault
 * @desc   :  This function set an action  default colors in redux
 */

export const onTabChanged = (index) => {
  return {
    type: actionType.SET_TAB_INDEX,
    index: index,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onColorDefault
 * @desc   :  This function set an action  default colors in redux
 */

export const onBGChanged = (data, flag = false) => {
  return {
    type: actionType.SET_BACKGROUND_IMAGE,
    data: data,
    flag: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onSecurityBGChanged
 * @desc   :  This function set an action  default colors in redux
 */

export const onSecurityBGChanged = (data, flag = false) => {
  return {
    type: actionType.SET_SECURITY_BACKGROUND_IMAGE,
    data: data,
    flag: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  addHistoryState
 * @desc   :  This function add the history of redux state
 */

export const addHistoryState = (data, type,bulleteFlag) => {
  return {
    type: actionType.SET_REDUX_HISTORY,
    historyType: type,
    dataObj: data,
    bulleteFlag:bulleteFlag
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  undoHistoryState
 * @desc   :  This function set the future state to redux state
 */

export const undoHistoryState = (bgArray) => {
  return {
    type: actionType.SET_REDUX_HISTORY,
    historyType: "UNDO",
    bgArray:bgArray
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  redoHistoryState
 * @desc   :  This function  set the past state of redux state states
 */

export const redoHistoryState = (bgArray) => {
  return {
    type: actionType.SET_REDUX_HISTORY,
    historyType: "REDO",
    bgArray:bgArray
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  restHistoryState
 * @desc   :  This function  reset the all history of states
 */

export const restHistoryState = (bgArray) => {
  return {
    type: actionType.SET_REDUX_HISTORY,
    historyType: "RESTALL",
    bgArray:bgArray
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onLogoChanged
 * @desc   :  This function set an action  default main logo in redux
 */

export const onLogoChanged = (data, logoName, flag = false) => {
  // console.log(flag);
  return {
    type: actionType.SET_MAIN_LOGO_IMAGE,
    data: data,
    logoName: logoName,
    flag: flag,
  };
};
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setPhantomLogoChanged
 * @desc   :  This function set an action  default phantom logo in redux
 */
export const setPhantomLogoChanged = (data, logoName) => {
  return {
    type: actionType.SET_PHANTOM_LOGO_IMAGE,
    data: data,
    logoName: logoName,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onPhantomLogoChanged
 * @desc   :  This function set an action  default phantom logo in redux
 */
export const onPhantomLogoChanged = (data, logoName, fieldName) => {
  return {
    type: actionType.SET_ON_CHANGED_PHANTOM_LOGO_IMAGE,
    data: data,
    logoName: logoName,
    fieldName: fieldName,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  onLogoFlagChanged
 * @desc   :  This function set an action  display logo flag in redux
 */
export const onLogoFlagChanged = (data) => {
  return {
    type: actionType.LOGO_OPTION,
    data: data,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setDefaultJSONInfo
 * @desc   :  This function set an action  default phantom logo in redux
 */
export const setDefaultJSONInfo = (data, flag = false) => {
  return {
    type: actionType.SET_DEFAULT_JSONINFO,
    data: data,
    flag: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setCurentPage
 * @desc   :  This function set an action current page in redux
 */
export const setCurentPage = (data, flag = false) => {
  return {
    type: actionType.SET_CURRENT_PAGE,
    data: data,
    flag: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setDesignInstruction
 * @desc   :  This function set an action current page in redux
 */
export const setDesignInstruction = (data, flag = false) => {
  return {
    type: actionType.SET_DESIGN_INSTRUCTIONS,
    data: data,
    flag: flag,
  };
};

export const inputChangedOnZoom = () => {
  return {
    type: actionType.INPUT_CHNAGED_ON_ZOOPM,
  };
};

export const setImageType = (data, flag = false) => {
  return {
    type: actionType.SET_IMAGE_TYPE,
    data: data,
    flag: flag,
  };
};

//setLogoColor
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setLogoColor
 * @desc   :  This function set an action for logo color in redux
 */
export const setLogoColor = (data) => {
  return {
    type: actionType.SET_LOGO_COLOR,
    data: data,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setAdditionalColor
 * @desc   :  This function set an action for additional color in redux
 */
export const setAdditionalColor = (data) => {
  return {
    type: actionType.ADDITIONAL_COLORS,
    data: data,
  };
};

export const setLogoColorsArr = (data) => {
  return {
    type: actionType.SET_LOGO_COLOR_ARR,
    data: data,
  };
};

export const setLogoType = (data) => {
  return {
    type: actionType.SET_LOGO_TYPE,
    data: data,
  };
};

export const setReduxPantoneColor = (data) => {
  return {
    type: actionType.SET_PANTONE_COLORS_ARRAY,
    data: data,
  };
};

export const setTootipFlag = (data) => {
  return {
    type: actionType.SET_TOOLTIP_FLAG,
    flag: data,
  };
};

//setCustomerLogoFlag
export const setCustomerLogoFlag = (data) => {
  return {
    type: actionType.SET_CUSTOMER_LOGO_FLAG,
    data: data,
  };
};

//setCustomerLogoFlag
export const setCustomerLogoPhantomFlag = (data) => {
  return {
    type: actionType.SET_CUSTOMER_LOGO_FLAG_PHANTOM,
    data: data,
  };
};

//set formating popup close open
export const setFontPopupClass = (data) => {
  return {
    type: actionType.SET_FORMATING_POPUP_CURRENT_CLASS,
    data: data,
  };
};

export const updateQueueArray = (newArr) => {
  return {
    type: actionType.UPDATE_QUEUE_ARRAY,
    data: newArr,
  };
};

export const updateInProgressFlag = (flag) => {
  return {
    type: actionType.API_INPROGRESS_FLAG,
    data: flag,
  };
};


export const setToggleBulletBtn = (flag) => {
  return {
    type: actionType.SET_BULLATE_TOGGLE_FLAG,
    data: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setIsInputChangedState
 * @desc   :  This function set an action set the flag input changes false action in the redux state
 */

export const isChangedHappened = (flag = false) => {
  return {
    type: actionType.IS_CHANGED_APPLIED,
    data: flag,
  };
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setOverwriteSave
 * @desc   :  This function set an action set the flag input changes false action in the redux state
 */

export const setOverwriteSave = (flag = false) => {
  return {
    type: actionType.IS_SAVE_OVERWRITE_INST,
    data: flag,
  };
};
/**
 * @author    Clarion
 * @version 1.0
 * @name   :  setOrderType
 * @desc   :  This function set an action set the flag input changes false action in the redux state
 */

export const setOrderType = (Type) => {
  return {
    type: actionType.SET_ORDER_TYPE,
    data: Type,
  };
};