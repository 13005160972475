/**
 * @author    Clarion
 * @version 1.0
 * @file   :  ViewRender
 * @desc   :  All the common function For Rendering Inputs according to array inputs are written in this file
 */

import React from "react";
import Input from "../components/Common/Input/Input";
import FormatingPopup from "./../containers/FormatingPopup/FormatingPopup";
import { checkInputIsInJsonData } from "./../utility/Utility";
import ReactTooltip from "react-tooltip";

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  InputViewRender
 * @desc   :  This function create the input html dynamic according to values get from renderarray
 * @returns: html string
 */
var tabindex = 0;
export const InputViewRender = (props) => {
  return props.renderArr && props.renderArr.map((data, index) => {
    if (!checkInputIsInJsonData(props.jsonData, data.attributes.NAME)) {
      return null;
    }

    if (
      props.imageType.length > 0 &&
      props.imageType[0].value == "Top" &&
      data.attributes.NAME === "FXS_Title1"
    ) {
      return null;
    }

    return (
      <div key={data.attributes.NAME}>
        <div className="inp-wrapper">
          <Input
            className="input-cmpt"
            name={data.attributes.NAME}
            value={data.value}
            label={
              data.attributes.LABEL
                ? data.attributes.LABEL
                : data.value
                ? props.label + `${index + 1}`
                : ""
            }
            placeholder={data.value ? "" : props.label + `${index + 1}`}
            settings={data.BOXDEF}
            onInputChanged={(event) =>
              props.onInputChanged(event, data.attributes.NAME, props.section)
            }
            onBlurHandaler={(event) =>{
              console.log('data.isInputChangedFlag',data)
              // if(data.isInputChangedFlag==true){
                props.onBlurHandaler(true, true, true, data.attributes.NAME,event)
             // }
            }}
            onFocusHandaler={(event) => {
              setTimeout(()=>{
                props.onFocusHandaler(data, event)
              },300)
            }}
            isFieldDisabled={props.isFieldDisabled}
            tabindex={tabindex++}
            section={props.section}
          ></Input>

          {props.boxName && props.boxName === data.attributes.NAME && (
            <React.Fragment>
              <span title="Icon Font" className="font-icon-input">
                <div
                  type="button"
                  className="font_button"
                  id="font-icon"
                  onClick={(event) =>
                    props.popUpClass === "pop-up displayPopup"
                      ? props.setFontPopupClass("pop-up hidePopup")
                      : props.setFontPopupClass("pop-up displayPopup")
                  }
                >
                  <i
                    className="fa fa-font"
                    id="font-icon-i"
                    data-tip="Format Text"
                  ></i>
                  <div className="tooltip-text"></div>
                </div>
              </span>
              <FormatingPopup></FormatingPopup>
              <ReactTooltip />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  InputCheckInfoViewRender
 * @desc   :  This function create the input html dynamic according to values get from renderarray
 * @returns: html string
 */

export const InputCheckInfoViewRender = (
 props
) => {
  const isFSXCheckNumber1=props.renderArr.findIndex(item=>((item.value =="" || (item.displayValue && item.displayValue=="")) && item.attributes.NAME=='FXS_CheckNumber1'))

  //5489-Job missing start number to show.Changes Checknumber 2 when check number 1 is not available
  return props.renderArr.map((data, index) => {
    if (
      !checkInputIsInJsonData(props.jsonData, data.attributes.NAME) && data.value==""
    ) {
      return null;
    } else if(data.attributes.NAME=="FXS_CheckNumber2" && isFSXCheckNumber1==-1){
     return null;
    } else if(data.attributes.NAME=="FXS_CheckNumber2" && isFSXCheckNumber1==0 && data.value ==""){
      return null;
     } 
    return (
      <div key={data.attributes.NAME}>
        <div className="inp-wrapper">
          <Input
            name={data.attributes.NAME}
            value={(data.displayValue || data.displayValue=="")?data.displayValue:(data.value.includes('C'))?data.value.substring(1):data.value}
            // value={(data.attributes.NAME=="FXS_CheckNumber2" && data.value.includes('C'))?data.value.substring(1):data.value}
            label={
              data.attributes.LABEL
                ? data.attributes.LABEL
                : data.value
                ? props.label + `${index + 1}`
                : ""
            }
            placeholder={data.value ? "" : props.label + `${index + 1}`}
            settings={data.BOXDEF}
            onInputChanged={(event) =>{
             props.onInputChanged(event, data.attributes.NAME, props.section,false,-1,props.sessionData)
            }}
            onBlurHandaler={(event) =>{
             props.onBlurHandaler(true, true, true, data.attributes.NAME)
            }}
            // onFocusHandaler={(event) => props.onFocusHandaler(data, event)}
            onFocusHandaler={(event)=>{setTimeout(()=>{
              props.onFocusHandaler(data,event)
            },300)}}
            // isFieldDisabled={props.isFieldDisabled}
            tabindex={tabindex++}
          ></Input>
        </div>
      </div>
    );
  });
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  companySignOverViewRender
 * @desc   :  This function  as like InputViewRender the main diffrence is the data will changes according to local stage change form respective component
 * @returns: html string
 */

export const CompanySignOverViewRender = (
  props
) => {
  let sigOverInput = null;
// console.log("props.signLine",props.signLine)
  if (props.signLine !== "None") {
    sigOverInput = props.renderArr.map((data, index) => {
      if (props.signLine === "1" && index >= 4) {
        return null;
      }
      if (!checkInputIsInJsonData(props.jsonData, data.attributes.NAME)) {
        return null;
      }

      return (
        <div key={data.attributes.NAME}>
          <div className="inp-wrapper">
            <Input
              name={data.attributes.NAME}
              value={data.value}
              label={
                data.attributes.LABEL
                  ? data.attributes.LABEL
                  : data.value
                  ? props.label + ` ${index + 1}`
                  : ""
              }
              placeholder={data.attributes.LABEL ? data.attributes.LABEL : ""}
              onInputChanged={(event) =>
                props.onInputChanged(event, data.attributes.NAME, props.section)
              }
              onBlurHandaler={(event) =>
                props.onBlurHandaler(true, true, true, data.attributes.NAME,event)
              }
              settings={data.BOXDEF}
              onFocusHandaler={() => {
                setTimeout(()=>{
                  props.onFocusHandaler(data)
                },300)
              }
              }
              isFieldDisabled={props.isFieldDisabled}
              tabindex={tabindex++}
            ></Input>
            {props.boxName && props.boxName === data.attributes.NAME && (
              <React.Fragment>
                <span title="Icon Font" className="font-icon-input">
                  <div
                    type="button"
                    className="font_button"
                    id="font-icon"
                    onClick={(event) =>
                      props.popUpClass === "pop-up displayPopup"
                        ? props.setFontPopupClass("pop-up hidePopup")
                        : props.setFontPopupClass("pop-up displayPopup")
                    }
                  >
                    <i
                      className="fa fa-font"
                      id="font-icon-i"
                      data-tip="Format Text"
                    ></i>
                    <div className="tooltip-text"></div>
                  </div>
                </span>
                <FormatingPopup></FormatingPopup>
                <ReactTooltip />
              </React.Fragment>
            )}
          </div>
        </div>
      );
    });
  }
  return sigOverInput;
};

/**
 * @author    Clarion
 * @version 1.0
 * @name   :  FractionaliewRender
 * @desc   :  This function  as like InputViewRender the main diffrence is the data will changes according to local stage change form respective component
 * @returns: html string
 */

export const FractionaliewRender = (
  props
) => {
  return props.renderArr && props.renderArr.map((data, index) => {
    if (!checkInputIsInJsonData(props.jsonData, data.attributes.NAME)) {
      return null;
    }

    return (
      <div key={data.attributes.NAME}>
        <div className="inp-wrapper">
          <Input
            name={data.attributes.NAME}
            value={data.value}
            label={
              index === 0
                ? `Fractional Top`
                : index === 1
                ? `Fractional Bottom`
                : ""
            }
            placeholder={
              index === 0
                ? `Fractional Top`
                : index === 1
                ? `Fractional Bottom`
                : ""
            }
            settings={data.BOXDEF}
            onInputChanged={(event) =>
              props.onInputChanged(
                event,
                data.attributes.NAME,
                "fractionalInfo"
              )
            }
            onBlurHandaler={() =>
              props.onBlurHandaler(true, true, true, data.attributes.NAME)
            }
            onFocusHandaler={() => {setTimeout(()=>{props.onFocusHandaler(data)},300)}}
            isFieldDisabled={props.isFieldDisabled}
            tabindex={tabindex++}
          ></Input>
          {props.boxName && props.boxName === data.attributes.NAME && (
            <React.Fragment>
              <span title="Icon Font" className="font-icon-input">
                <div
                  type="button"
                  className="font_button"
                  id="font-icon"
                  onClick={(event) =>
                    props.popUpClass === "pop-up displayPopup"
                      ? props.setFontPopupClass("pop-up hidePopup")
                      : props.setFontPopupClass("pop-up displayPopup")
                  }
                >
                  <i
                    className="fa fa-font"
                    id="font-icon-i"
                    data-tip="Format Text"
                  ></i>
                  <div className="tooltip-text"></div>
                </div>
              </span>
              <FormatingPopup></FormatingPopup>
              <ReactTooltip />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  });
};



export const InputStubViewRender = (props) => {
  // console.log("props.props.renderArr",props.renderArr)
  return props.renderArr && props.renderArr.map((data, index) => {
    if (!checkInputIsInJsonData(props.jsonData, data.attributes.NAME)) {
      return null;
    }

  if(data.attributes.NAME == "FXS_Stub1Single"){
    const styleObj = data.styleObj && Object.values(data.styleObj)
          if(styleObj && styleObj.length > 0){
            // console.log("temporalDivSpanElement.childNodes",temporalDivSpanElement.childNodes.NodeList)
            return(<div className="stub_1_wrapper">

                { styleObj && styleObj.length>0 && styleObj.map((spandata, spanIndex) => {
                
            
                  // console.log("spanIndex",spanIndex)
                  return (
                    <React.Fragment>
                  
                  <div key={data.attributes.NAME + "_" + spanIndex } className="stub_single_span">
                    <div className="inp-wrapper">
                      <Input
                        className="input-cmpt"
                        name={data.attributes.NAME + "_" + spanIndex}
                        value={spandata.value}
                        label={
                          data.attributes.LABEL
                            ? data.attributes.LABEL
                            : spandata.value
                            ? props.label + `${index + 1}`
                            : ""
                        }
                        placeholder={spandata.value ? "" : props.label + `${index + 1}`}
                        settings={data.BOXDEF}
                        onInputChanged={(event) =>
                          props.onInputChanged(event, data.attributes.NAME,props.section,spanIndex)
                        }
                        onBlurHandaler={(event) =>{
                          props.onBlurHandaler(true, true, true, data.attributes.NAME,event)
                        }
                        }
                        onFocusHandaler={(event) => {
                              setTimeout(()=>{
                                props.onFocusHandaler(data, event,spanIndex)
                              },300)
                       
                        }
                        }
                        isFieldDisabled={props.isFieldDisabled}
                        tabindex={tabindex++}
                        section={props.section}
                        spanIndex={spanIndex}
                      ></Input>
                      {/* {console.log("props.boxName",props.boxName)} */}
                      {props.boxName && props.boxName === data.attributes.NAME+ "_" + spanIndex && (
                        <React.Fragment>
                          <span title="Icon Font" className="font-icon-input" style={{
                            "margin-right":spanIndex ===0 ? "10px" : "0px" 
                          }}>
                            <div
                              type="button"
                              className="font_button"
                              id="font-icon"
                              onClick={(event) =>
                                props.popUpClass === "pop-up displayPopup"
                                  ? props.setFontPopupClass("pop-up hidePopup")
                                  : props.setFontPopupClass("pop-up displayPopup")
                              }
                            >
                              <i
                                className="fa fa-font"
                                id="font-icon-i"
                                data-tip="Format Text"
                              ></i>
                              <div className="tooltip-text"></div>
                            </div>
                          </span>
                          <FormatingPopup></FormatingPopup>
                          <ReactTooltip />
                        </React.Fragment>
                      )}
                      
                    </div>
                  </div>
                  {spanIndex === 0 && (
                    <div className="button-wrapper">
                      <button
                        id="routingIDBTN_2"
                        onClick={(event) => props.dotBtnClick(event)}
                        className="bullet-str-btn"
                        data-tip="Insert/Remove bullet between text boxes"
                      >
                        {props.bulletFlag  ? "•" : ""}
                      </button>
                      <ReactTooltip />
                    </div>
                  )}
                  

                </React.Fragment>
                );
              })
            }
         </div>)
        }
  }else{

    return (
      // {data.attributes.NAME == ""}
      <div key={data.attributes.NAME}>
        <div className="inp-wrapper">
          <Input
            className="input-cmpt"
            name={data.attributes.NAME}
            value={data.value}
            label={
              data.attributes.LABEL
                ? data.attributes.LABEL
                : data.value
                ? props.label + `${index + 1}`
                : ""
            }
            placeholder={data.value ? "" : props.label + `${index + 1}`}
            settings={data.BOXDEF}
            onInputChanged={(event) =>
              props.onInputChanged(event, data.attributes.NAME, props.section)
            }
            onBlurHandaler={(event) =>
              props.onBlurHandaler(true, true, true, data.attributes.NAME,event)
            }
            onFocusHandaler={(event) => {
              setTimeout(()=>{
                props.onFocusHandaler(data,event)
              },300)
              }}
            isFieldDisabled={props.isFieldDisabled}
            tabindex={tabindex++}
            section={props.section}
          ></Input>

          {props.boxName && props.boxName === data.attributes.NAME && (
            <React.Fragment>
              <span title="Icon Font" className="font-icon-input">
                <div
                  type="button"
                  className="font_button"
                  id="font-icon"
                  onClick={(event) =>
                    props.popUpClass === "pop-up displayPopup"
                      ? props.setFontPopupClass("pop-up hidePopup")
                      : props.setFontPopupClass("pop-up displayPopup")
                  }
                >
                  <i
                    className="fa fa-font"
                    id="font-icon-i"
                    data-tip="Format Text"
                  ></i>
                  <div className="tooltip-text"></div>
                </div>
              </span>
              <FormatingPopup></FormatingPopup>
              <ReactTooltip />
              <div className="button-wrapper">
                      <button
                        id="routingIDBTN_2"
                        onClick={(event) => props.dotBtnClickDoubleStubLine(event)}
                        className="bullet-str-btn"
                        data-tip="Insert/Remove bullet between text boxes"
                      >
                        {"•"}
                      </button>
                      <ReactTooltip />
                    </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
  });

};
